import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledAlert,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { listEmployee, EmployeeDelete, CDN_URL, EmployeeChangeStatus } from "../../../services/main";
import {
  listEmployeeReq,
  magicnum,
  BaseRequest,
  EmployeeChangeStatusReq,
} from "../../../services/Request";
import CustomBootsrapTable from "../../../components/CustomBootsrapTable/CustomBootsrapTable";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import AddMerchantMember from "./AddMerchantMember";
import { confirmAlert } from "react-confirm-alert";

class Employee extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.addSuccess = this.addSuccess.bind(this);
    this.customRowFormat = this.customRowFormat.bind(this);
    this.StatusRowFormat = this.StatusRowFormat.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      sizePerPage: 15,
      loading: true,
      apierror: false,
      data: [],
      apierrormsg: "",
      PID: 1,
      PTotal: 0,
      dropdownOpen: false,
      status: [
        { Text: "Active", Value: 1 },
        { Text: "DeActive", Value: 2 },
      ],
      statusName: "Active",
      statusValue: 0,
      popoverOpen: [false, false],
      popoverOpenIndex: 1,
      t: this.props.t,
      TypeID: "",
    };

    this.getList = this.getList.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  getList() {
    const { TypeID } = this.state;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = listEmployeeReq;
    req.pid = this.state.PID;
    req.pSize = this.state.sizePerPage;
    req.filter.TypeID = TypeID;
    listEmployee(req, this.onSuccess, this.onFailed, "POST");
  }

  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }

  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.mEmployees,
    });
  }
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }

  onConfirmDelete(deletID) {
    EmployeeDelete(
      deletID,
      BaseRequest,
      this.addSuccess,
      this.onFailed,
      "DELETE"
    );
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid }, function () {
        this.getList();
      });
    }
  }

  StatusRowFormat(cell) {
    const { t } = this.state;
    if (cell == magicnum.Status.Active) {
      return t("common:magicnum.Status.Active");
    } else {
      return t("common:magicnum.Status.InActive");
    }
  }

  TypeRowFormat = (cell) => {
    const { t } = this.state;
    return t("common:magicnum.UserType." + cell);
  };

  DateRowFormat(cell) {
    if (cell !== null) {
      return <span>{cell.substr(0, 10)}</span>;
    } else {
      return <span>{cell}</span>;
    }
  }

  customRowFormat(cell, row) {
    const { t, popoverOpenIndex } = this.state;
    let edit = (
      <div className="text-center">
        <Link to={`/privilege/EmployeeDetial/${cell}`}>
          <i className="fa fa-info" /> {t("common:more")}
        </Link>
        &nbsp;&nbsp;&nbsp;
        <a
          onClick={() => this.PopOvertoggle("delete" + cell + popoverOpenIndex)}
          id={"delete" + cell + popoverOpenIndex}
          className="btn button delete-button"
        >
          <i className="fa fa-trash" /> {t("common:delete")}
        </a>
        <Popover
          isOpen={this.state.popoverOpen["delete" + cell + popoverOpenIndex]}
          target={"delete" + cell + popoverOpenIndex}
          toggle={() => this.toggleTooltip("delete" + cell + popoverOpenIndex)}
        >
          <PopoverHeader>
            {row.Fullname + " " + t("common:delete")}
          </PopoverHeader>
          <PopoverBody>
            {t("common:confirmdelete")}
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => {
                this.onConfirmDelete(cell);
              }}
            >
              {t("common:delete")}
            </Button>
          </PopoverBody>
        </Popover>
      </div>
    );
    return edit;
  }

  imgRowFormat(cell, row) {
    return (
      <img
        src={CDN_URL + "/file/img/" + cell}
        width={60}
        style={{ borderRadius: "50%" }}
        onError={(e) => (e.target.src = require("../../../img/no-image.png"))}
      />
    );
  }

  PopOvertoggle(id) {
    var NewObject = Object.assign([], this.state.popoverOpen);
    NewObject[id] = !NewObject[id];
    this.setState({
      popoverOpen: NewObject,
    });
  }

  AddMember() {
    const { t } = this.state;
    var title = t("employee.add");
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <AddMerchantMember
            t={t}
            success={this.addSuccess}
            onFailed={this.onFailed}
          />
        }
      />
    );
  }

  submit(row) {
    const { t } = this.state;
    var title = t("common:changestatus");
    var message = t("common:confirmchangestatus");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.UpdateStatus(row);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  statusRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          style={{ marginLeft: 10 }}
          className={`button delete-button ${row.status == magicnum.Status.Active
            ? "btn-success"
            : "btn-danger"
            }`}
          onClick={() => {
            this.submit(row);
          }}
        >
          {row.status == magicnum.Status.Active
            ? t("common:magicnum.Status.Active")
            : t("common:magicnum.Status.InActive")}
        </Button>
      </div>
    );
    return edit;
  };

  UpdateStatus = (row) => {
    var req = EmployeeChangeStatusReq;
    req.employeeid = row.id
    req.status = row.status  == magicnum.Status.InActive ? magicnum.Status.Active : magicnum.Status.InActive
    EmployeeChangeStatus(req, this.onUpdateStatusSuccess, this.onFailed, "POST");
  }

  onUpdateStatusSuccess = () => {
    this.getList();
    return toast.success("Амжилттай төлөв солигдлоо");
  }


  render() {
    const { loading, t, apierror, apierrormsg } = this.state;

    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: "EmployeeID", // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true, // haruulah eseh zaana,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      },
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: "",
          dataField: "photo",
          dataSort: false,
          dataFormat: this.imgRowFormat,
          hidden: false,
          width: "120px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("userName"),
          dataField: "firstname",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("userName"),
          dataField: "lastname",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:type"),
          dataField: "typeid",
          dataSort: false,
          dataFormat: this.TypeRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("status"),
          dataField: "status",
          dataSort: false,
          dataFormat: this.statusRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "",
          dataField: "id",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          width: "190px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <ToastContainer position="top-right" autoClose={3000} />
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle">
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.getList}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">
              <Row>
                <Col>
                  <div className="item"></div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
        <Card className="nopadding">
          <CardHeader>
            <i className="fa fa-align-justify" />
            {t("employeeList")}
            <div className="float-right">
              <span
                onClick={() => {
                  this.AddMember();
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </span>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <CustomBootsrapTable
              data={this.state.data} // serverees irsen datag ogno
              table={table} // tabliin tohirgoog ogno
              PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
              pageChange={this.onChangePage} // huudas soligdohod duudah function bn
              PID={this.state.PID} // huudaslatiin hed deh huudas ve
              loading={loading} // end true false damjuulj data avch ireh uid loading haruulna
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("employee")(Employee);
