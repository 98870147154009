import React, { Component } from "react";
import {
  Card,
  Button,
  Table,
  UncontrolledAlert,
  FormGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import {
  DataAsuulgaList,
  ConnectList,
  ConnectAdd,
  ConnectDelete,
  ConnectUpdate,
} from "../../../services/main";
import {
  DataAsuulgaListReq,
  ConnectListReq,
  ConnectAddReq,
  BaseRequest,
  ConnectUpdateReq,
  magicnum,
} from "../../../services/Request";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
import {
  isNullOrEmpty,
  LastAndFirstName,
  getUser,
  dateFormat,
} from "../../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import AsuulgaAdd from "../AsuulgaAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
const renderSuggestion = (suggestion) => (
  <div style={{ cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    {suggestion.name}
  </div>
);
class ConnectAsuulgaFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      loading: false,
      apierrormsg: "",
      apierror: false,
      value: "",
      suggestions: [],
      selectedProd: null,
      sizePerPage: 1000,
      PID: 1,
      dataa: [],
      dataUser: [],
    };
    this.onFailed = this.onFailed.bind(this);
    this.acpn = this.acpn.bind(this);
    this.onAcpnSuccess = this.onAcpnSuccess.bind(this);
    this.onConnectSuccess = this.onConnectSuccess.bind(this);
    this.onConnectAddSuccess = this.onConnectAddSuccess.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  Update(item, type) {
    var req = ConnectUpdateReq;
    req.asuudal = item;
    if (type == "confirm") {
      req.asuudal.is_confirm = magicnum.Status.Active;
    }
    req.type = type;
    ConnectUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.success(t("common:SuccessEdit"));
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }
    const { PID, sizePerPage, item } = this.state;
    var req = ConnectListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.type = "asuulga";
    req.filter.asuudal_id = item.id;
    console.log(item.id);
    ConnectList(req, this.onConnectSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onConnectSuccess(response) {
    console.log("asuulga");
    console.log(response.data);
    this.setState({
      loading: false,
      dataa: response.data,
      userData: response.users,
    });
  }

  add(selected) {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { item } = this.state;
    if (!isNullOrEmpty(selected)) {
      var req = ConnectAddReq;
      req.connect.type = "asuulga";
      req.connect.asuudal_id = item.id;
      req.connect.type_id = selected.id;
      ConnectAdd(req, this.onConnectAddSuccess, this.onFailed, "POST");
    }
  }

  onConnectAddSuccess(response) {
    const { t } = this.state;
    this.setState(
      {
        loading: false,
      },
      function () {
        this.getList();
      }
    );
    return toast.success(t("common:SuccessSave"));
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  acpn(value) {
    var req = DataAsuulgaListReq;
    req.filter.name = value;
    if (value.length > 2) {
      DataAsuulgaList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess(response) {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedProd: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected(e, val) {
    this.add(val.suggestion);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedProd: null,
      apierror: false,
    });
  };

  submit(row, type) {
    const { t } = this.state;
    var title = row.dname + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.dname + " " + t("Data.Asuudal.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.dname + " " + t("Data.Asuudal.delete");
      var message = t("common:confirm");
    }
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: t("common:yes"),
          onClick: () =>
            type == "deleteMain" ? this.delete(row) : this.Update(row, type),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }

  delete(row) {
    ConnectDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  asadd(type, row) {
    const { t } = this.state;
    var title = t("Data.Asuulga.Add");
    if (type == "update") {
      title = t("Data.Asuulga.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <AsuulgaAdd
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess = (text, response) => {
    var obj = { id: response.insertId };
    if (!isNullOrEmpty(obj)) {
      this.add(obj);
    }
    return toast.success(text);
  };

  render() {
    const { t, apierror, apierrormsg, value, suggestions, loading, dataa } =
      this.state;

    const inputProps = {
      placeholder: t("Data.Asuulga.Search"),
      value,
      onChange: this.onChange,
    };

    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    return (
      <div className="animated fadeIn getorder-container">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={containerStyle}
        />
        <Row>
          <Col>
            <FormGroup style={{ position: "relative" }}>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={(e, val) =>
                  this.onSuggestionSelected(e, val)
                }
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
              <Button
                className="button delete-button"
                onClick={() => {
                  this.asadd("new", null);
                }}
                style={{ position: "absolute", top: 9, right: 10 }}
              >
                {t("common:add")}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Spinner />
            ) : (
              <Table>
                <tbody>
                  {isNullOrEmpty(dataa)
                    ? null
                    : dataa.map((item, index) => {
                        var user = getUser(
                          this.state.userData,
                          item.connect_by
                        );
                        return (
                          <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.dname}</td>
                            <td>
                              {!isNullOrEmpty(user)
                                ? LastAndFirstName(
                                    user.lastname,
                                    user.firstname
                                  )
                                : null}
                            </td>
                            <td>{dateFormat(item.connect_at)}</td>
                            <td>
                              <div className="text-center">
                                <Button
                                  className="button delete-button"
                                  onClick={() => {
                                    this.submit(item, "deleteMain");
                                  }}
                                  title={t("common:delete")}
                                >
                                  <i className="fa fa-trash" />
                                </Button>

                                <Button
                                  style={{ marginLeft: 10 }}
                                  className="button delete-button btn-info"
                                  onClick={() => {
                                    this.submit(item, "confirm");
                                  }}
                                  title={t("Data.Asuudal.confirm")}
                                >
                                  <i className="fa fa-check-circle" />
                                </Button>

                                <Button
                                  className="button delete-button btn-danger"
                                  onClick={() => {
                                    this.submit(item, "delete");
                                  }}
                                  title={t("Data.Asuudal.delete")}
                                >
                                  <i className="fa fa-trash" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConnectAsuulgaFunc;
