import i18next from "i18next";
import common_mn from "./translations/mn/common.json";
import common_en from "./translations/en/common.json";

import login_mn from "./translations/mn/login.json";
import login_en from "./translations/en/login.json";

import menu_mn from "./translations/mn/menu.json";
import menu_en from "./translations/en/menu.json";

import priv_mn from "./translations/mn/priv.json";
import priv_en from "./translations/en/priv.json";


import employee_mn from "./translations/mn/employee.json";
import employee_en from "./translations/en/employee.json";

import log_mn from "./translations/mn/log.json";
import log_en from "./translations/en/log.json";

import main_mn from "./translations/mn/main.json";
import main_en from "./translations/en/main.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng:"mn",
  resources: {
    en: {
      common: common_en,
      login: login_en,     
      menu: menu_en,    
      priv: priv_en,
      employee: employee_en,
      log: log_en,     
      main: main_en     
    },
    mn: {
      common: common_mn,
      login: login_mn,     
      menu: menu_mn,     
      priv: priv_mn,
      employee: employee_mn,
      log: log_mn,
      main: main_mn  
    }
  }
});