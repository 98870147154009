import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  setPageTitle,
  ProjectAuditEmailList,
  CDN_URL,
} from "../../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { ProjectAuditEmailListReq } from "../../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import CustomBootsrapTable from "../../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san

import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { dateFormat } from "../../../components/Utils/Utils";
class CustomerListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno   
    this.onChangePage = this.onChangePage.bind(this);
    //end heregleh talbar, huvsagchiin bgaa ahnii utguudiig zaaj ogno

    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
      register_number: "",
      name: "",
      director_name: "",
      accountant_name: "",
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("Customer.Title"));
    this.getList();
  }

  //buh jagsaaltiig serverees avch ireh function ehlel
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const {
      PID,
      sizePerPage,
      name,
      register_number,
      director_name,
      accountant_name,
    } = this.state;
    var req = ProjectAuditEmailListReq
    req.project.customer_id = ""
    req.project.project_id = ""
    req.pid = PID;
    req.pSize = sizePerPage;
    ProjectAuditEmailList(
      req,
      this.onSuccess,
      this.onFailed,
      "POST"
    );
  }
  //buh jagsaaltiig serverees avch ireh function haalt

  //serveriin huselt amjilttai uid iishee orno
  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data, //!!!!!
    });
  }

  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }
  //serveriin huselt amjiltgui uid haalt
  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
      $(".app-header").height() -
      otherHeight -
      filterHeight
    );
  }


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  zahidalRowFormat = (cell, row) => {
    return <div style={{ display: "flex", gap: 20 }}>
      <a className="btn btn-primary" href={CDN_URL + row.attach1} target="_blank">Захидал харах</a>
      <a className="btn btn-primary" href={CDN_URL + row.attach2} target="_blank">Хавсралт-1 харах</a>
    </div>
  }

  render() {
    const {
      t,
      apierror,
      apierrormsg
    } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("id"), // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("Customer.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "Имэйл",
          dataField: "email",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "Огноо",
          dataField: "created_at",
          dataSort: false,
          dataFormat: dateFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "Захидал",
          dataField: "attach1",
          dataSort: false,
          dataFormat: this.zahidalRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        }
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">

            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("Customer.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(CustomerListFunc);
