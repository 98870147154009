import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  DataAsuulgaAddReq,
  DataAsuulgaUpdateReq,
} from "../../services/Request";
import {
  DataAsuulgaUpdate,
  DataAsuulgaAdd,socket
} from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isValidEmail, isValidPhone } from "../../components/Utils/Utils.js";
//import {ExcelRenderer, OutTable } from "react-excel-renderer";


class DataAsuulgaAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      name: "",
      company_size: "",
      is_confirm: "",
      confirm_at: "",
      confirm_by: "",
      cancel_at: "",
      cancel_type: "",
      cancel_by: "",
      deleted_at: "",
      deleted_by: "",
      id: "",
      validate: [],
      item: props.item,
      isSave: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        name: item.name,
        company_size: item.company_size,
        is_confirm: item.is_confirm,
        confirm_at: item.confirm_at,
        confirm_by: item.confirm_by,
        cancel_at: item.cancel_at,
        cancel_type: item.cancel_type,
        cancel_by: item.cancel_by,
        deleted_at: item.deleted_at,
        deleted_by: item.deleted_by,
        id: item.id,
      });
    }
  }



  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    const {
      t,
      name,
      company_size,
      is_confirm,
      confirm_at,
      confirm_by,
      cancel_at,
      cancel_type,
      cancel_by,
      deleted_at,
      deleted_by,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = DataAsuulgaAddReq;
      req.asuulga.name = name;
      req.asuulga.company_size = company_size;
      req.asuulga.is_confirm = is_confirm;
      req.asuulga.confirm_at = confirm_at;
      req.asuulga.confirm_by = confirm_by;
      req.asuulga.cancel_at = cancel_at;
      req.asuulga.cancel_type = cancel_type;
      req.asuulga.cancel_by = cancel_by;
      req.asuulga.deleted_at = deleted_at;
      req.asuulga.deleted_by = deleted_by;      
      DataAsuulgaAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    socket.emit("dataasuulga", "add");
    this.props.addSuccess(t("common:SuccessSave"),response);
    ModalManager.close();
  }

  Update() {
    const {
      t,
      name,
      company_size,
      is_confirm,
      confirm_at,
      confirm_by,
      cancel_at,
      cancel_type,
      cancel_by,
      deleted_at,
      deleted_by,
      id,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = DataAsuulgaUpdateReq;
      req.asuulga.name = name;
      req.asuulga.company_size = company_size;
      req.asuulga.is_confirm = is_confirm;
      req.asuulga.confirm_at = confirm_at;
      req.asuulga.confirm_by = confirm_by;
      req.asuulga.cancel_at = cancel_at;
      req.asuulga.cancel_type = cancel_type;
      req.asuulga.cancel_by = cancel_by;
      req.asuulga.deleted_at = deleted_at;
      req.asuulga.deleted_by = deleted_by;
      req.asuulga.id = id;
      DataAsuulgaUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    socket.emit("dataasuulga", "update");
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const {
      validate,
      name,
    } = this.state;
    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }
    this.setState({ validate });
    return res;
  }

  draw(data, left) {
    const { t } = this.state;
    
    return data.map((item, index) => {
      var array = [];
      array.push(
        <option value={item.key} key={index}>
          {item.label}
        </option>
      );
      if (item.child_count > 0) {
        item.children.map((citem, cindex) => {
          array.push(
            <option value={citem.key} key={cindex}>
              ---{citem.label}
            </option>
          );
        });
      }

      return array;
    });
  }



  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      name,
      company_size,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuulga.name")}</Label>
                <Input
                  type="textarea"
                  name="name"
                  value={name}
                  rows={5}
                  cols={5}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuulga.company_size")}</Label>
                <Input
                  type="select"
                  name="company_size"
                  value={company_size}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("company_size", event);
                  }}
                  invalid={validate["company_size"]}
                >
                <option value="">{t("common:choose")}</option>
                  {magicnum.Company.Size.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                  </Input>
                  <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row> */}

          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
      
        </div>
      </div>
    );
  }
}

export default DataAsuulgaAddFunc;