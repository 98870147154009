import React, { useCallback, useEffect, useRef, useState } from "react";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import { Sheet } from "@fortune-sheet/core";
import "@fortune-sheet/react/dist/index.css";
export const ExcelWorkBook = (props: any) => {
  const arr = props.data;
  const count = props.count;
  const ref = useRef<WorkbookInstance>(null);
  const [data, setData] = useState<Sheet[]>([]);

  useEffect(() => {
    var arr2 = [];
    setData([]);
    arr.map((item: any, index: any) => {
      var arr3 = [];
      var obj0 = {
        r: 0,
        c: 1,
        v: {
          v: "№",
          bl: 1,
        },
      };
      var obj1 = {
        r: 0,
        c: 2,
        v: {
          v: item?.code?.name,
        },
      };
      arr3.push(obj0);
      arr3.push(obj1);
      var mor = 0;
      item?.data?.map((item2: any, index2: any) => {
        if (item2[2]) {
          mor++;
          var obj2 = {
            r: mor,
            c: 1,
            v: {
              v: item2[0].value,
              bl: 1,
            },
          };
          var obj3 = {
            r: mor,
            c: 2,
            v: {
              v: item2[1]?.toString(),
            },
          };
          arr3.push(obj2);
          arr3.push(obj3);
        }
      });
      var obj = {
        name: item.code.code,
        celldata: arr3,
        order: 0,
        row: arr.length + 1,
        column: item?.data?.length + 1,
        config: {
          toolbar: false,
          columnlen: {
            "1": 150,
            "2": 250,
          },
        },
      };
      arr2.push(obj);
    });
    setTimeout(() => {
      setData(arr2);
    }, 1000);
  }, [count]);

  const onChange = useCallback((d: Sheet[]) => {
    setData(d);
  }, []);

  const settings = {
    showFormulaBar: false, //is included header
    showSheetTabs: true,
    showToolbar: false,   
    data: data, // sheet data
  };

  return (
    <div>
      <div style={{ width: "100%", height: "300px" }}>
        {data.length == 0 ? (
          <div>Боловсруулж байна...</div>
        ) : (
          <Workbook {...settings} ref={ref} data={data} onChange={onChange} />
        )}
      </div>
    </div>
  );
};
