import React, { Component } from "react";
import {
  Card,
  Button,
  Table,
  UncontrolledAlert,
  FormGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import {
  DataZuvlumjList,
  ConnectList,
  ConnectAdd,
  ConnectDelete,
  ConnectUpdate,
} from "../../../services/main";
import {
  DataZuvlumjListReq,
  ConnectListReq,
  ConnectAddReq,
  BaseRequest,
  ConnectUpdateReq,
  magicnum,
} from "../../../services/Request";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
import {
  isNullOrEmpty,
  LastAndFirstName,
  getUser,
  dateFormat,
} from "../../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import ZuvlumjAdd from "../ZuvlumjAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
const renderSuggestion = (suggestion) => (
  <div style={suggestion.cancel_by > 0 && !isNullOrEmpty(suggestion.cancel_type) ? { color: "red", backgroundColor: "yellow", padding: 15, cursor: "not-allowed" } : { cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    <span>{suggestion.name}</span>
    {suggestion.cancel_by > 0 && !isNullOrEmpty(suggestion.cancel_type) ? <span style={{ fontSize: 10, display: "block", marginTop: 5, color: "#151b1e" }}>{suggestion.cancel_type} - ({dateFormat(suggestion.cancel_at)})</span> : null}
  </div>
);
class ConnectZuvlumjFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      loading: false,
      apierrormsg: "",
      apierror: false,
      value: "",
      suggestions: [],
      selectedProd: null,
      sizePerPage: 1000,
      PID: 1,
      data: [],
      dataUser: [],
    };
    this.onFailed = this.onFailed.bind(this);
    this.acpn = this.acpn.bind(this);
    this.onAcpnSuccess = this.onAcpnSuccess.bind(this);
    this.onConnectSuccess = this.onConnectSuccess.bind(this);
    this.onConnectAddSuccess = this.onConnectAddSuccess.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  Update(item, type) {
    var req = ConnectUpdateReq;
    req.asuudal = item;
    if (type == "confirm") {
      req.asuudal.is_confirm = magicnum.Status.Active;
    }
    req.type = type;
    ConnectUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.success(t("common:SuccessEdit"));
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }
    const { PID, sizePerPage, item } = this.state;
    var req = ConnectListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.type = "zuvlumj";
    req.filter.asuudal_id = item.id;
    ConnectList(req, this.onConnectSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onConnectSuccess(response) {
    this.setState({
      loading: false,
      data: response.data,
      userData: response.users,
    });
  }

  add(selected) {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { item } = this.state;
    if (!isNullOrEmpty(selected)) {
      var req = ConnectAddReq;
      req.connect.type = "zuvlumj";
      req.connect.asuudal_id = item.id;
      req.connect.type_id = selected.id;
      ConnectAdd(req, this.onConnectAddSuccess, this.onFailed, "POST");
    }
  }

  onConnectAddSuccess(response) {
    const { t } = this.state;
    this.setState(
      {
        loading: false,
      },
      function () {
        this.getList();
      }
    );
    return toast.success(t("common:SuccessSave"));
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  acpn(value) {
    var req = DataZuvlumjListReq;
    req.pid = 1;
    req.filter.name = value;
    if (value.length > 2) {
      DataZuvlumjList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess(response) {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedProd: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected(e, val) {
    var ff = val.suggestion;
    if (ff.cancel_by > 0 && !isNullOrEmpty(ff.cancel_type)) {
      return toast.error(this.state.t("main:Data.Zuvlumj.disabledAsuudal"));
    } else {
      this.add(ff);
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedProd: null,
      apierror: false,
    });
  };

  submit(row, type) {
    const { t } = this.state;
    var title = row.dname + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.dname + " " + t("Data.Asuudal.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.dname + " " + t("Data.Asuudal.delete");
      var message = t("common:confirm");
    }
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: t("common:yes"),
          onClick: () =>
            type == "deleteMain" ? this.delete(row) : this.Update(row, type),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }

  delete(row) {
    ConnectDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  zadd(type, row) {
    const { t } = this.state;
    var title = t("Data.Zuvlumj.Add");
    if (type == "update") {
      title = t("Data.Zuvlumj.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ZuvlumjAdd
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess = (text, response) => {
    var obj = { id: response.insertId };
    if (!isNullOrEmpty(obj)) {
      this.add(obj);
    }
    return toast.success(text);
  };

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      value,
      suggestions,
      loading,
      data,
    } = this.state;

    const inputProps = {
      placeholder: t("Data.Zuvlumj.Search"),
      value,
      onChange: this.onChange,
    };

    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };
    var parentid = "";
    return (
      <div className="animated fadeIn getorder-container">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={containerStyle}
        />
        <Row>
          <Col>
            <FormGroup style={{ position: "relative" }}>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={(e, val) =>
                  this.onSuggestionSelected(e, val)
                }
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
              <Button
                className="button delete-button"
                onClick={() => {
                  this.zadd("new", null);
                }}
                style={{ position: "absolute", top: 9, right: 10 }}
              >
                {t("common:add")}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Spinner />
            ) : (
              <Table>
                <tbody>
                  {isNullOrEmpty(data)
                    ? null
                    : data.map((item, index) => {
                      var user = getUser(
                        this.state.userData,
                        item.connect_by
                      );
                      if (!isNullOrEmpty(item.cancel_parent)) { parentid = item.cancel_parent; }
                      return (
                        <tr key={index} style={item.cancel_torol > 0 && !isNullOrEmpty(item.cancel_type) ? { color: "red", backgroundColor: "yellow" } : { backgroundColor: "#fff" }}>
                          <td><div>
                            {!isNullOrEmpty(parentid) && item.type_id == parentid ? <div style={{ fontWeight: "bold" }}>{t("Data.Ersdel.newversion")} :</div> : null}
                            <span>{item.dname}</span>
                            {item.cancel_torol > 0 && !isNullOrEmpty(item.cancel_type) ? <span style={{ fontSize: 10, display: "block", marginTop: 5, color: "#151b1e" }}>{item.cancel_type + " шалтгаанаар " + dateFormat(item.cancel_at) + " -нд" + " (" + t(`common:magicnum.Data.CancelType.${item.cancel_torol}`) + ")"}</span> : null}
                          </div></td>
                          <td>
                            {!isNullOrEmpty(user)
                              ? LastAndFirstName(
                                user.lastname,
                                user.firstname
                              )
                              : null}
                          </td>
                          <td>{dateFormat(item.connect_at)}</td>
                          <td>
                            <div className="text-center">
                              <Button
                                className="button delete-button"
                                onClick={() => {
                                  this.submit(item, "deleteMain");
                                }}
                                title={t("common:delete")}
                              >
                                <i className="fa fa-trash" />
                              </Button>

                              <Button
                                style={{ marginTop: 5, marginBottom: 5 }}
                                className="button delete-button btn-info"
                                onClick={() => {
                                  this.submit(item, "confirm");
                                }}
                                title={t("Data.Asuudal.confirm")}
                              >
                                <i className="fa fa-check-circle" />
                              </Button>

                              <Button
                                className="button delete-button btn-danger"
                                onClick={() => {
                                  this.submit(item, "delete");
                                }}
                                title={t("Data.Asuudal.delete")}
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConnectZuvlumjFunc;
