import React, { useEffect, useState } from "react";
import { Button, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import { isNullOrEmpty } from "../../../../components/Utils/Utils";
import { ModalManager } from "react-dynamic-modal/lib/Modal";
import { Multiselect } from "multiselect-react-dropdown";
import { magicnum } from "../../../../services/Request";
import { ProjectFilesSaveMultiFile } from "../../../../services/main";
const MultiFilesAdd = (props) => {
    const t = props.t
    const data = props.data
    const item = props.item
    const [loading, setLoading] = useState(false);
    const [validate, setValidate] = useState([])
    const [count, setCount] = useState(0)
    const [dataFiles, setDataFiles] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [file, setFile] = useState('')
    useEffect(() => {
        var tempArray = []
        data.filter((fitem) => fitem.parentcat_id == null).map((fitem, findex) => {
            data.filter((sfitem) => sfitem.parentcat_id == fitem.cat_id).map((fsitem, fsindex) => {
                if (fsitem.status === 0) {
                    var obj = {
                        cat: findex + 1 + ". " + t("common:magicnum.filesInfo." + fitem.cat_id),
                        key: findex + 1 + "." + parseInt(fsindex + 1) + ". " + t("common:magicnum.filesInfoSub." + fsitem.parentcat_id + "-" + fsitem.cat_id),
                        cat_id: fsitem.cat_id,
                        parentcat_id: fsitem.parentcat_id,
                    }
                    tempArray.push(obj)
                }
            })
        })
        setDataFiles(tempArray)
    }, []);

    const save = () => {
        var res = validateFunc()
        if (res.retType === 0) {
            setLoading(true)
            var tempArray = []
            selectedList.map(uitem => {
                var gg = {
                    cat_id: uitem.cat_id,
                    parentcat_id: uitem.parentcat_id,
                    status: magicnum.Status.Active
                }
                tempArray.push(gg)
            })
            var data = {
                customer_id: item.customer_id,
                project_id: item.id,
                created_by: localStorage.getItem("EmployeeID"),
                files: tempArray
            }
            var formdata = new FormData()
            formdata.append('file', file)
            formdata.append('data', JSON.stringify(data))
            ProjectFilesSaveMultiFile(formdata, onSuccess, onFailed, 'POST', 'file')
        }
        else {
            return toast.warning(t("common:SelectValid"))
        }
    }

    const onSuccess = (response) => {
        setLoading(false)
        props.addSuccess(t("common:Success"));
        ModalManager.close();
    }

    const onFailed = (error) => {
        setLoading(false)
        return toast.error(error)
    }

    const validateFunc = () => {
        var res = { retType: 0 }
        if (selectedList.length == 0) {
            res.retType = 1
            validate['files'] = true
        }
        if (isNullOrEmpty(file)) {
            res.retType = 1
            validate['file'] = true
        }
        setValidate(validate)
        setCount(count + 1)
        return res
    }

    const onSelect = (selectedList) => {
        validate["files"] = false;
        setValidate(validate)
        setSelectedList(selectedList)
    }

    const onRemove = (selectedList) => {
        validate["files"] = false;
        setValidate(validate)
        setSelectedList(selectedList)
    }

    const handleSelectFile = (event) => {
        const files = event.target.files
        if (files && files.length > 0) {
            setFile(files[0])
        }
        validate["file"] = false
        setValidate(validate)
    }

    return (
        <div className="customer-project-files" style={{ minHeight: 300, padding: "20px 40px" }}>
            <FormGroup>
                {dataFiles.length == 0 ? null : (
                    <Multiselect
                        displayValue="key"
                        groupBy="cat"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={onRemove}
                        onSearch={function noRefCheck() { }}
                        onSelect={onSelect}
                        options={dataFiles}
                        showCheckbox
                        placeholder={t("main:Project.files.chooseMulti")}
                    />
                )}
                {validate["files"] ? (
                    <div className="invalid-feedback show">
                        {t("common:SelectValid")}
                    </div>
                ) : null}
            </FormGroup>
            <FormGroup>
                <input
                    type="file"
                    className="input-field"
                    accept=".csv, .xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                        handleSelectFile(e)
                    }}
                    invalid={validate["file"]}
                />
                {validate["file"] ? (
                    <div className="invalid-feedback show">
                        {t("common:SelectValid")}
                    </div>
                ) : null}
            </FormGroup>
            <div className="action text-rigth" style={{ marginTop: 40 }}>
                <Button onClick={save} disabled={loading}>{loading ? t("common:loading") : t("common:save")}</Button>
            </div>
            <span style={{ visibility: "hidden", fontSize: 1 }}>{count}</span>
        </div>
    );
};

export default MultiFilesAdd;
