import { getRealData, isNullOrEmpty } from "../components/Utils/Utils";
import config from "./config.json";
import { io } from "socket.io-client";
var CryptoJS = require("crypto-js");

var varBASE_URL = "";
var varCDN_URL = "";
var debugMode = config.debug;

if (debugMode === "test") {
    varBASE_URL = config.BASE_URL;
    varCDN_URL = config.CDN_URL;
} else {
    varBASE_URL = config.RealBASE_URL;
    varCDN_URL = config.RealCDN_URL;
}

export const socket = io(varBASE_URL);

export const BASE_URL = varBASE_URL;
export const CDN_URL = varCDN_URL;

export function setPageTitle(text) {
    document.title = text + " | " + " мэжик аудит систем";
}

function isPriv(url, method, failCallback) {
    var isprivNo = false; //false bn shuu
    var realprivNoArray = url.split("/"); //api/Library/LibraryList
    var realprivNo = ""; //Employee-Post

    if (realprivNoArray[0] === "api") {
        for (var i = 0; i <= realprivNoArray.length - 1; i++) {
            if (realprivNoArray[i] !== "api" && realprivNoArray[i] !== "" && i <= 2) {
                realprivNo += realprivNoArray[i];
                realprivNo += "-";
            }
        }
        realprivNo = realprivNo + method;
        var userPriv = !isNullOrEmpty(getRealData("data1")) ?
            JSON.parse(getRealData("data1")) : []; //localstroge iin data1 ni tuhain hereglegchiin privNo nuud yum
        userPriv.map((i, index) => {
            if (i.PrivNo === realprivNo) {
                isprivNo = true;
            }
        });
    } else if (realprivNoArray[0] === "publicapi") {
        isprivNo = true;
    }

    return isprivNo;
} //ene code client der erh shalgaj baina ta harj bnu?ok

function baseFetch(
    url,
    request,
    successCallback,
    failCallback,
    rMethod,
    rHeader
) {
    if (isPriv(url, rMethod, failCallback)) {
        var statusCode = "";
        var baseHeader = {
            "x-system-id":"mc",
            "x-auth-id": localStorage.getItem("EmployeeID"),
            "x-auth-token": localStorage.getItem("SystemToken"),
            "x-api-key": CryptoJS.AES.encrypt(
                config.keyAPI,
                config.engryptPass
            ).toString(),
            "Content-Type": "application/json",
            lang: "mn",
        };
        var baseHeaderFileUpload = {
            "x-system-id":"mc",
            "x-auth-id": localStorage.getItem("EmployeeID"),
            "x-auth-token": localStorage.getItem("SystemToken"),
            "x-api-key": CryptoJS.AES.encrypt(
                config.keyAPI,
                config.engryptPass
            ).toString(),
            lang: "mn",
        };
        fetch(BASE_URL + url, {
            crossDomain: false,
            //mode: "cors",
            method: rMethod,
            headers: rHeader == "file" ? baseHeaderFileUpload : baseHeader,
            body: rHeader == "file" ?
                request : rMethod !== "GET" ?
                    JSON.stringify(request) : null,
        })
            .then((res) => {
                statusCode = res.status;
                return res.json();
            })
            .then(function (data) {
                if (statusCode === 401) {
                    localStorage.removeItem("SystemToken")
                    localStorage.removeItem("data1")
                    localStorage.removeItem("data2")
                    localStorage.removeItem("EmployeeID")
                    localStorage.removeItem("EmployeeTypeID")
                    setTimeout(() => {
                        window.location.replace("/logout");
                    });
                } else if (statusCode === 200) {
                    TokenHandler(url, data, successCallback);
                } else {
                    errorJob(url, data.statusText, failCallback);
                }
            })
            .catch(function (error) {
                errorJob(url, "Алдаа гарсан тул, шинээр нэвтэрнэ үү", failCallback);
                //window.location.replace("/logout");
            });
    } else {
        errorJob(url, "Хандах эрх байхгүй байна.", failCallback);
    }
} //baseFetch gedeg api duuddag yum bgaa js ter ni neg api duudhad iim coed bichigddeg teriig ni bagasgatsan bgaa  TypeZurchilList(req, this.onSuccess, this.onFailed, "POST");

function TokenHandler(url, response, successCallback) {
    var bytes = CryptoJS.AES.decrypt(response, config.engryptPass);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    successCallback(decryptedData);
}

function errorJob(url, error, failCallback) {
    failCallback(error);
    //return toast.error(error);
}

function errorFetch(url, request) {
    var baseHeader = {
        Authorization: "Bearer " + getRealData("SystemToken"),
        "x-api-key": config.keyAPI,
        "Content-Type": "application/json",
        Language: localStorage.getItem("Lang"),
    };
    fetch(BASE_URL + url, {
        method: "POST",
        headers: baseHeader,
        body: JSON.stringify(request),
    }).catch((error) => console.error("Error:", error));
}

//#region [ AuthCreate & Verify ]

// Нэвтрэх үед token авах
export function loginGetToken(request, successCallback, failCallback, method) {
    baseFetch(
        "publicapi/auth/signin",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function signOut(request, successCallback, failCallback, method) {
    baseFetch(
        "publicapi/auth/signout",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}
//#endregion

//#region [ Ажилчдын жагсаалт ]
export function listEmployee(request, successCallback, failCallback, method) {
    baseFetch(
        "api/employee/getallemployee",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function EmployeeChangeInfo(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/employee/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function EmployeeChangePhoto(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/employee/changephoto",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function EmployeeAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/employee/addemployee",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function EmployeeChangeStatus(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/employee/changestatus",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ChangePass(request, successCallback, failCallback, method) {
    baseFetch(
        "api/employee/changepassword",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function GetEmployee(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/employee/getemployee/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function EmployeeDelete(
    employeeID,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/employee/deleteemployee/" + employeeID,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function EmployeeSendOTP(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "publicapi/auth/sendOTP",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function EmployeeCheckOTP(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "publicapi/auth/checkOTP",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//#endregion

//#region [Privilege]
// EmployeeList =>request(GetEmployeeByIdReq)
export function UserPrivList(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/priv/userprivlist/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivGroupList List =>request(BaseRequest)
export function PrivilegeGroupList(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/listmpriv_group",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivGroupAdd Add =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupAdd(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/addmpriv_group",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivGroupDelete Delete =>request(PrivilegeGroupDeleteReq)
export function PrivilegeGroupDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/deletempriv_group/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/getmpriv_group/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupUpdate(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/updatempriv_group",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList List =>request(BaseRequest)
export function PrivilegeList(request, successCallback, failCallback, method) {
    baseFetch(
        "api/priv/listpriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList Add =>request(PrivilegeAddReq)
export function PrivilegeAdd(request, successCallback, failCallback, method) {
    baseFetch(
        "api/priv/addmpriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList Delete =>request(PrivilegeDeleteReq)
export function PrivilegeDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/priv/deletempriv/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function GetUSerPrivile(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/priv/getmpriv/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function UpdateMPriv(request, successCallback, failCallback, method) {
    baseFetch(
        "api/priv/updatempriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//AddMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function AddMPriv_GroupUser(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroupuser/addmpriv_groupuser",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//UpdateMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function UpdateMPriv_GroupUser(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroupuser/updatempriv_groupuser",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//AddMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function AddMPriv_GroupPriv(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgrouppriv/addmpriv_grouppriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//GetMPriv_GroupPriv =>request(GetMPriv_GroupPrivReq)
export function GetMPriv_GroupPriv(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/addmpriv_grouppriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

export function GetMPriv_GetAllMPGroupPriv(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroup/getallmpriv_grouppriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//ListMPriv_GroupPriv =>request(ListMPriv_GroupPrivReq)
export function ListMPriv_GroupPriv(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgrouppriv/listmpriv_grouppriv/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//UpdateMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function UpdateMPriv_GroupPriv(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgrouppriv/updatempriv_grouppriv",
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}

//UpdateMPriv_GroupPriv =>request(GetEmployeeByIdReq)
export function ListMPriv_GroupsForUser(
    userkey,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/privgroupuser/getmpriv_groupuser/" + userkey,
        request,
        successCallback,
        failCallback,
        method
    );
    return "";
}
//#endregion

//#region [Log]
export function LogAction(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/log/action",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function LogError(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/log/error",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [TypeAsuudal]
export function TypeAsuudalList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typeasuudal/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeAsuudalAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typeasuudal/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeAsuudalUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typeasuudal/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeAsuudalGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typeasuudal/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function TypeAsuudalDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typeasuudal/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [TypeZurchil]
export function TypeZurchilList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typezurchil/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeZurchilAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typezurchil/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeZurchilUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typezurchil/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeZurchilGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typezurchil/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function TypeZurchilDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typezurchil/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [TypeNotolgoo]
export function TypeNotolgooList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typenotolgoo/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeNotolgooAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typenotolgoo/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeNotolgooUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typenotolgoo/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeNotolgooGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typenotolgoo/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function TypeNotolgooDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typenotolgoo/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [TypeDans]
export function TypeDansList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typedans/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeDansAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typedans/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeDansUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/typedans/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function TypeDansGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typedans/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function TypeDansDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/typedans/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [Customer]
export function CustomerList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customer/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CustomerAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customer/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CustomerUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customer/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CustomerGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/customer/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}

export function CustomerDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/customer/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}

export function CustomerCheckRD(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/customer/checkRD/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}

export function CustomerCheckRDtoName(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/customer/checkRDtoName/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [DataAsuudal]
export function DataAsuudalConnCountList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/connectioncount",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalExcelAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/exceladd",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalRating(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/setrating",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalCancel(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuudal/cancel",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuudalGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/dataasuudal/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function DataAsuudalDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/dataasuudal/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [DataAsuulga]
export function DataAsuulgaList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuulga/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuulgaAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuulga/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuulgaUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataasuulga/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataAsuulgaGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/dataasuulga/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function DataAsuulgaDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/dataasuulga/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [DataZuvlumj]
export function DataZuvlumjConnectNewVersion(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/connectnewversion",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjConnectedAsuudalList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/connectedasuudal",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjCancel(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/cancel",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjDuplicate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/duplicate",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/datazuvlumj/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataZuvlumjGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/datazuvlumj/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function DataZuvlumjDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/datazuvlumj/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [DataErsdel]
export function DataErsdelConnectNewVersion(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataersdel/connectnewversion",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataErsdelConnectedAsuudalList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataersdel/connectedasuudal",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataErsdelCancel(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataersdel/cancel",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataErsdelList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataersdel/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataErsdelAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataersdel/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataErsdelUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dataersdel/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DataErsdelGet(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/dataersdel/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function DataErsdelDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/dataersdel/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [Connect]
export function ConnectList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connect/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connect/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connect/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectGet(request, successCallback, failCallback, method) {
    baseFetch("api/connect/get", request, successCallback, failCallback, method);
}
export function ConnectDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/connect/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [ConnectCustomer]
export function AsuudalConnectProjects(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connectcustomer/asuudalbyconnrojects",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectCustomerProjectCountList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connectcustomer/listbyconnect",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectCustomerList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connectcustomer/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectCustomerByYear(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connectcustomer/byyear",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectCustomerAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connectcustomer/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectCustomerUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/connectcustomer/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ConnectCustomerGet(
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/connectcustomer/get",
        request,
        successCallback,
        failCallback,
        method
    );
}
export function ConnectCustomerDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/connectcustomer/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [CustomerProject]
export function ProjectReport(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/project/report",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ProjectList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/project/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ProjectAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/project/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ProjectUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/project/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ProjectGet(id, request, successCallback, failCallback, method) {
    baseFetch(
        "api/project/get/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
export function ProjectDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/project/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}

export function ProjectSendEmail(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/project/sendemail",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [Comment]
export function CommentList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/comment/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CommentAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/comment/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CommentUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/comment/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CommentDelete(
    id,
    request,
    successCallback,
    failCallback,
    method
) {
    baseFetch(
        "api/comment/delete/" + id,
        request,
        successCallback,
        failCallback,
        method
    );
}
//#endregion

//#region [Dashboard]
export function DashboardNotifiWorkProject(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dashboard/workproject",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DashboardTopAsuudal(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dashboard/topasuudal",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DashboardUserBy(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dashboard/byuser",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DashboardAsuudalBatalgaajaagui(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dashboard/asuudalBatalgaajaagui",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DashboardUserbyMounth(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dashboard/bymounth",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function DashboardNewAndUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/dashboard/lastedited",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [Rate]
export function RateAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/rate/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function RateList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/rate/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function RateDelete(
    id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/rate/delete/" + id,
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [Chat]
export function ChatAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/chat/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ChatUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/chat/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function ChatDelete(
    id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/chat/delete/" + id,
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [Industry]
export function IndustryList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/industry/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function IndustryAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/industry/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function IndustryUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/industry/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function IndustryDelete(
    id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/industry/delete/" + id,
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [Bodit]
export function BoditList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/bodit/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function BoditAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/bodit/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function BoditUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/bodit/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function BoditDelete(
    id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/bodit/delete/" + id,
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

//#region [CustomerUser]
export function CustomerUserList(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customeruser/list",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CustomerUserAdd(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customeruser/add",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CustomerUserUpdate(
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customeruser/update",
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}

export function CustomerUserDelete(
    id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
) {
    baseFetch(
        "api/customeruser/delete/" + id,
        request,
        successCallback,
        failCallback,
        method,
        rHeader
    );
    return "";
}
//#endregion

export function ProjectFilesAdd(request, successCallback, failCallback, method) {
    baseFetch(
        "api/projectfiles/add",
        request,
        successCallback,
        failCallback,
        method
    );
}

export function ProjectFilesList(request, successCallback, failCallback, method) {
    baseFetch(
        "api/projectfiles/list",
        request,
        successCallback,
        failCallback,
        method
    );
}

export function ProjectAuditEmailList(request, successCallback, failCallback, method) {
    baseFetch(
        "api/project/emaillist",
        request,
        successCallback,
        failCallback,
        method
    );
}

export function ProjectFilesSaveFile(request, successCallback, failCallback, method, rheader) {
    baseFetch(
        "api/projectfiles/filesave",
        request,
        successCallback,
        failCallback,
        method,
        rheader
    );
}

export function ProjectFilesDeleteFile(request, successCallback, failCallback, method) {
    baseFetch(
        "api/projectfiles/filedelete",
        request,
        successCallback,
        failCallback,
        method
    );
}

export function ProjectFilesSaveMultiFile(request, successCallback, failCallback, method, rheader) {
    baseFetch(
        "api/projectfiles/multifileadd",
        request,
        successCallback,
        failCallback,
        method,
        rheader
    );
}

export function ProjectFilesChangeStatus(request, successCallback, failCallback, method) {
    baseFetch(
        "api/projectfiles/changeStatus",
        request,
        successCallback,
        failCallback,
        method
    );
}

export function ProjectFilesMultiChangeStatus(request, successCallback, failCallback, method) {
    baseFetch(
        "api/projectfiles/multichangeStatus",
        request,
        successCallback,
        failCallback,
        method
    );
}