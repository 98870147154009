import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Button,
  Input,
  FormFeedback,
  Spinner,
  Label,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { ProjectFilesAddReq, ProjectFilesDeleteFileReq, ProjectFilesListReq, magicnum } from "../../../../services/Request";
import { CDN_URL, ProjectFilesAdd, ProjectFilesDeleteFile, ProjectFilesList } from "../../../../services/main";
import { isNullOrEmpty } from "../../../../components/Utils/Utils";
import FilesAdd from "./filesadd";
import MultiFilesAdd from "./multi";
import ChangeStatus from "./changeStatus";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../../components/Modal/MainModal/MainModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SendEmail from "./sendEmail";
const Login = (props) => {
  const t = props.t
  const item = props.item
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [data, setData] = useState([]);
  const [dataStored, setDataStored] = useState([]);
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item]);

  useEffect(() => {
    if (dataStored.length > 0) {
      var array = buildFiles('stored')
      setData(array)
    } else {
      var array = buildFiles('')
      setData(array)
    }
  }, [dataStored]);

  const buildFiles = (type) => {
    var array = []
    magicnum.filesInfo.map(fitem => {
      var aitem = { id: fitem, cat_id: fitem, parentcat_id: null, status: false }
      array.push(aitem)
      magicnum.filesInfoSub.filter((sfitem) => sfitem.parent == fitem).map(fsitem => {
        var status = false, storedid = "", checked = false, filepath = "", action_desc = "", mcupload = 0, filepath_mc = "";
        if (type == 'stored') {
          var storeditem = checkStoredItem(fsitem)
          if (!isNullOrEmpty(storeditem)) {
            checked = true
            status = storeditem?.status
            filepath = storeditem?.filepath
            mcupload = storeditem?.mcupload
            filepath_mc = storeditem?.filepath_mc
            storedid = storeditem?.id
            action_desc = storeditem?.action_desc
          }
        }
        var aitem = { id: fitem + "-" + fsitem.id, cat_id: fsitem.id, parentcat_id: fsitem.parent, status: status, storedid: storedid, checked: checked, filepath: filepath, action_desc: action_desc, filepath_mc: filepath_mc, mcupload: mcupload }
        array.push(aitem)
      })
    })
    return array
  }

  const checkFiles = (subcat) => {
    var arr = []
    data.map(negfile => {
      if (negfile.id == subcat.id) {
        var bb = negfile
        bb.checked = !subcat.checked;
        arr.push(bb)
      }
      else {
        arr.push(negfile)
      }
    })
    setData(arr)
  }

  const list = () => {
    setLoadingList(true)
    var req = ProjectFilesListReq
    req.filter.customer_id = item?.customer_id
    req.filter.project_id = item?.id
    ProjectFilesList(
      req,
      onListSuccess,
      onFailed,
      "POST"
    );
  }

  const onListSuccess = (response) => {
    setDataStored(response.data)
    setLoadingList(false)
  }

  const save = () => {
    if (data.filter(uu => uu.checked && isNullOrEmpty(uu.storedid)).length > 0 && !isNullOrEmpty(item)) {
      var req = ProjectFilesAddReq
      var tempArray = []
      data.filter(uu => uu.checked && isNullOrEmpty(uu.storedid)).map(uitem => {
        var gg = {
          cat_id: uitem.cat_id,
          parentcat_id: uitem.parentcat_id,
          status: 0
        }
        tempArray.push(gg)
      })
      req.customer_id = item.customer_id;
      req.project_id = item.id;
      req.created_by = localStorage.getItem("EmployeeID")
      req.files = tempArray
      setLoading(true)
      ProjectFilesAdd(req, onSuccess, onFailed, "POST");
    }
    else {
      return toast.warning(t("common:reqallfield"))
    }
  }

  const onSuccess = (response) => {
    setTimeout(() => {
      setLoading(false)
      setData([])
      list()
    }, 3000);
  }

  const onFailed = (error) => {
    setLoading(false)
    setLoadingList(false)
    return toast.error(error)
  }

  const checkStoredItem = (subcat) => {
    var stcount = dataStored.filter(dsitem => dsitem.cat_id == subcat.id && dsitem.parentcat_id == subcat.parent)[0]
    return stcount;
  }

  const show = (selectedfile) => {
    var title = t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id);
    if (selectedfile.status == magicnum.Status.InActive) {
      title += " - " + t("main:Project.files.add")
    }
    else {
      title += " - " + t("main:Project.files.update")
    }
    var system = 'customer'
    if (selectedfile?.mcupload == 1) {
      system = 'mc'
    }
    ModalManager.open(
      <MainModal
        small
        title={title}
        onRequestClose={() => true}
        component={
          <FilesAdd
            t={t}
            system={system}
            item={item}
            selectedfile={selectedfile}
            addSuccess={addSuccess}
          />
        }
      />
    );
  };

  const addSuccess = (text) => {
    setDataStored([])
    list();
    return toast.success(text);
  }

  const showMulti = () => {
    var title = t("main:Project.files.add")
    ModalManager.open(
      <MainModal
        small
        title={title}
        onRequestClose={() => true}
        component={
          <MultiFilesAdd
            t={t}
            item={item}
            data={data}
            addSuccess={addMultiSuccess}
          />
        }
      />
    );
  };

  const addMultiSuccess = (text) => {
    setDataStored([])
    list();
    return toast.success(text);
  }

  const showEmail = () => {
    var title = t("main:Project.files.sendEmail")
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <SendEmail
            t={t}
            item={item}
            data={dataStored}
            workuser={props.workuser}
            addSuccess={addEmailSuccess}
          />
        }
      />
    );
  };

  const addEmailSuccess = (text) => {
    setDataStored([])
    list();
    return toast.success(text);
  }

  const submit = (selectedfile) => {
    var title = t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id);
    var message = t("common:confirmdelete");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                deleteFunc(selectedfile);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  const deleteFunc = (selectedfile) => {
    setLoading(true)
    var req = ProjectFilesDeleteFileReq
    req.file.filepath = selectedfile?.filepath
    req.file.id = selectedfile?.storedid
    ProjectFilesDeleteFile(
      req,
      onDeleteSuccess,
      onFailed,
      "PUT"
    );
  }

  const onDeleteSuccess = (response) => {
    setLoading(false)
    list()
    return toast.success(t("main:Project.files.deleteSuccess"))
  }

  const changeStatus = (selectedfile, type) => {
    var title = "";
    if (type == "one") {
      title += t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id)
      title += " - " + t("main:Project.files.changestatus")
    } else {
      title += t("main:Project.files.multiStatusChange")
    }
    ModalManager.open(
      <MainModal
        small
        title={title}
        onRequestClose={() => true}
        component={
          <ChangeStatus
            t={t}
            data={data}
            type={type}
            selectedfile={selectedfile}
            onChangeSuccess={onChangeSuccess}
          />
        }
      />
    );
  };

  const onChangeSuccess = (text) => {
    setDataStored([])
    list();
    return toast.success(text);
  }

  return (
    <div className="customer-project-files">
      {dataStored.length > 0 ? <div style={{ display: "flex", gap: 20, marginBottom: 20 }}>
        <>
          <Button color="success" onClick={() => showMulti()}>{t("main:Project.files.multiAdd")}</Button>
          <Button color="success" onClick={() => changeStatus(null, "multi")}>{t("main:Project.files.multiStatusChange")}</Button>
          <Button color="info" onClick={() => showEmail()}>{t("main:Project.files.sendEmail")}</Button>
        </>
      </div> : null}
      {loadingList ? <div><Spinner />{t("common:loading")}</div> : <div>
        {data.filter((fitem) => fitem.parentcat_id == null).map((fitem, findex) => {
          return <div key={findex}>
            <div>{findex + 1}.{t("common:magicnum.filesInfo." + fitem.cat_id)}
              {data.filter((sfitem) => sfitem.parentcat_id == fitem.cat_id).map((fsitem, fsindex) => {
                return <div key={fsindex} style={{ paddingLeft: 20, display: "flex", justifyContent: "space-between" }}>
                  <FormGroup check>
                    <Label check style={{ cursor: "pointer" }}>
                      <Input
                        type="checkbox"
                        checked={fsitem.checked}
                        onChange={() => checkFiles(fsitem)}
                      />
                      <span>{findex + 1}.{fsindex + 1}.{t("common:magicnum.filesInfoSub." + fsitem.parentcat_id + "-" + fsitem.cat_id)}</span>
                    </Label>
                  </FormGroup>
                  {!isNullOrEmpty(fsitem.storedid) ?
                    <div className="action" style={{ padding: 5, display: "flex", gap: 5 }}>
                      <Button
                        color={fsitem.status == 1 ? "info" : fsitem.status == 2 ? "warning" : fsitem.status == 3 ? "success" : "danger"}
                        style={{ display: "flex", gap: 5, alignItems: "center" }}
                        onClick={() => { !isNullOrEmpty(fsitem.filepath) ? changeStatus(fsitem, "one") : toast.info(t("main:Project.files.nofileupload")) }}
                      >
                        {t("main:Project.files.status.title")} : {t("main:Project.files.status." + fsitem.status)}
                      </Button>
                      {fsitem.status !== 3 ?
                        <>
                          <Button style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => show(fsitem)}>
                            <i className="fa fa-plus" />
                            {!fsitem.status ? t("main:Project.files.add") : t("main:Project.files.update")}
                          </Button>
                          {fsitem.mcupload == 1 ? <Button color="success" style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => show(fsitem)}>
                            <i className="fa fa-plus" />
                            {isNullOrEmpty(fsitem.filepath_mc) ? t("main:Project.files.copy") : t("main:Project.files.mcupdate")}
                          </Button> : null}
                        </> : null}
                      {fsitem.status !== 3 ? <Button style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => submit(fsitem)}>
                        <i className="fa fa-trash" />
                        {t("main:Project.files.delete")}
                      </Button> : null}
                      {!isNullOrEmpty(fsitem.filepath) ?
                        <a className="btn btn-secondary" style={{ display: "flex", gap: 5, alignItems: "center" }} href={CDN_URL + fsitem.filepath} target="_blank">
                          <i className="fa fa-download" />
                          {t("main:Project.files.download")}
                        </a>
                        : null}
                      {fsitem.mcupload == 1 && !isNullOrEmpty(fsitem.filepath_mc) ?
                        <a className="btn btn-primary" style={{ display: "flex", gap: 5, alignItems: "center" }} href={CDN_URL + fsitem.filepath_mc} target="_blank">
                          <i className="fa fa-download" />
                          {t("main:Project.files.downloadmc")}
                        </a>
                        : null}
                    </div> : null}
                </div>
              }
              )}
            </div>
          </div>
        })}
        <div className="action text-rigth" style={{ marginTop: 20 }}>
          <Button onClick={save} disabled={loading}>{loading ? t("common:loading") : t("main:Project.files.resave")}</Button>
        </div>
      </div>}
    </div>
  );
};

export default Login;
