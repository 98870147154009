import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  IndustryAddReq,
  IndustryUpdateReq,
} from "../../services/Request";
import { IndustryUpdate, IndustryAdd } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
class IndustryAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      Name: "",
      validate: [],
      item: props.item,
      actionType: props.type,
      isSave: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {
    const { item, actionType } = this.state;
    if (item !== null && actionType == "update") {
      this.setState({
        ID: item.id,
        Name: item.name,
      });
    }
   
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    const { Name } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = IndustryAddReq;
      req.name = Name;
      IndustryAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    this.setState({ isSave: false });
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const { Name, ID } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      var req = IndustryUpdateReq;
      req.name = Name;
      req.id = ID;
      IndustryUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const { Name,  validate } = this.state;
    if (Name == "") {
      res.retType = 1;
      validate["Name"] = true;
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      Name,
      item,
      actionType,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>            
            <Col>
              <FormGroup>
                <Label>{t("Type.Industry.Name")}</Label>
                <Input
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Name", event);
                  }}
                  invalid={validate["Name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={
                  item != null && actionType == "update"
                    ? this.Update
                    : this.save
                }
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default IndustryAddFunc;
