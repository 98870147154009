import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import { LogError, setPageTitle } from "../../services/main"; //serverluu handah huseltiin ner san
import { LogErrorReq } from "../../services/Request"; //serverluu ilgeeh huselt san
import { Card, CardHeader, CardBody } from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import { ToastContainer } from "react-toastify"; //aldaa garah uid garah aldaanii msg haruulagch san
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san
import { dateTimeFormat } from "../../components/Utils/Utils";
class logError extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onChangePage = this.onChangePage.bind(this);   
    
    this.state = {
      sizePerPage: 30, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false, //serverees aldaa garsan eseh iltgegch
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("ErrorTitle"));
    this.getList(); 
  }
  //hamgiin ehend ajillah function haalt

  //buh jagsaaltiig serverees avch ireh function ehlel
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = LogErrorReq;
    req.pid = this.state.PID; //huseltuud der utga onooj bn
    req.pSize = this.state.sizePerPage; //huseltuud der utga onooj bn
    LogError(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn
  }
  //buh jagsaaltiig serverees avch ireh function haalt

  //serveriin huselt amjilttai uid iishee orno
  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.mApi_error,
    });
  }
  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  render() {
    const { t, apierror, apierrormsg } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, 
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("ErrorID"), // Garchig der haragdana
        dataField: "ErrorID", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
    
        {
          title: t("ControllerName"),
          dataField: "ControllerName",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("ActionName"),
          dataField: "ActionName",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CreatedAt"),
          dataField: "CreatedAt",
          dataSort: false,
          dataFormat: dateTimeFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("ErrorType"),
          dataField: "ErrorType",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("ErrorDesc"),
          dataField: "ErrorDesc",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="nopadding">
          {
            //ene deed garchig heseg ehlel
          }

          <CardHeader>{t("errorTitle")}</CardHeader>

          <CardBody className="nobg">
            <div>
              {
                apierror && (
                  <div className="help-block text-danger">{apierrormsg}</div>
                ) // serverees aldaa garval aldaanii medeelel haragdana
              }

              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />

                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("log")(logError); //(logError) gedeg classiig (log) gedeg helnii filer orchuulj bn gesen utgatai code
