import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Button,
  Input,
  Spinner,
  Label,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import { ProjectFilesDeleteFileReq, ProjectFilesListReq, magicnum } from "../../../../services/Request";
import { CDN_URL, ProjectFilesDeleteFile, ProjectFilesList } from "../../../../services/main";
import { dateFormat, isNullOrEmpty } from "../../../../components/Utils/Utils";
import FilesAdd from "./filesadd";
import ChangeStatus from "./changeStatus";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../../components/Modal/MainModal/MainModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const FilesSend = (props) => {
  const t = props.t
  const item = props.item
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [data, setData] = useState([]);
  const [dataStored, setDataStored] = useState([]);
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item]);

  const list = () => {
    setLoadingList(true)
    var req = ProjectFilesListReq
    req.filter.customer_id = item?.customer_id
    req.filter.project_id = item?.id
    ProjectFilesList(
      req,
      onListSuccess,
      onFailed,
      "POST"
    );
  }

  const onListSuccess = (response) => {
    setDataStored(response.data)
    setLoadingList(false)
  }

  const onFailed = (error) => {
    setLoading(false)
    setLoadingList(false)
    return toast.error(error)
  }


  const show = (selectedfile) => {
    var title = t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id);
    if (selectedfile.status == magicnum.Status.InActive) {
      title += " - " + t("main:Project.files.add")
    }
    else {
      title += " - " + t("main:Project.files.update")
    }
    var temItem = selectedfile;
    temItem.storedid = selectedfile.id
    var system = 'customer'
    if (selectedfile?.mcupload == 1) {
      system = 'mc'
    }
    ModalManager.open(
      <MainModal
        small
        title={title}
        onRequestClose={() => true}
        component={
          <FilesAdd
            t={t}
            item={item}
            system={system}
            selectedfile={temItem}
            addSuccess={addSuccess}
          />
        }
      />
    );
  };

  const addSuccess = (text) => {
    setDataStored([])
    list();
    return toast.success(text);
  }


  const submit = (selectedfile) => {
    var title = t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id);
    var message = t("common:confirmdelete");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                deleteFunc(selectedfile);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  const deleteFunc = (selectedfile) => {
    setLoading(true)
    var req = ProjectFilesDeleteFileReq
    req.file.filepath = selectedfile?.filepath
    req.file.id = selectedfile?.storedid
    ProjectFilesDeleteFile(
      req,
      onDeleteSuccess,
      onFailed,
      "PUT"
    );
  }

  const onDeleteSuccess = (response) => {
    setLoading(false)
    list()
    return toast.success(t("main:Project.files.deleteSuccess"))
  }

  const changeStatus = (selectedfile) => {
    var title = t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id);
    var tempselectedfile = selectedfile;
    tempselectedfile.storedid = selectedfile.id
    title += " - " + t("main:Project.files.changestatus")
    ModalManager.open(
      <MainModal
        small
        title={title}
        onRequestClose={() => true}
        component={
          <ChangeStatus
            t={t}
            selectedfile={tempselectedfile}
            onChangeSuccess={onChangeSuccess}
          />
        }
      />
    );
  };

  const onChangeSuccess = (text) => {
    setDataStored([])
    list();
    return toast.success(text);
  }


  return (
    <div className="customer-project-files">
      <Table>
        <thead>
          <th>№</th>
          <th>{t("main:Project.files.sendfiles")}</th>
          <th>{t("main:Project.files.status.title")}</th>
          <th>{t("common:CreatedAt")}</th>
          <th>{t("common:action")}</th>
        </thead>
        <tbody>
          {loadingList ? <tr><td colSpan={5} className="text-center">{t("common:loading")}</td></tr>
            : dataStored.length == 0 ? <tr><td colSpan={5} className="text-center">{t("common:emptyList")}</td></tr> : dataStored.map((ditem, dindex) => {
              return <tr key={dindex}>
                <td>{dindex + 1}</td>
                <td>{t("common:magicnum.filesInfoSub." + ditem.parentcat_id + "-" + ditem.cat_id)}</td>
                <td>
                  <div className="action" style={{ padding: 5, display: "flex", gap: 5 }}>
                    <Button
                      color={ditem.status == 1 ? "info" : ditem.status == 2 ? "warning" : ditem.status == 3 ? "success" : "danger"}
                      style={{ display: "flex", gap: 5, alignItems: "center" }}
                      onClick={() => { !isNullOrEmpty(ditem.filepath) ? changeStatus(ditem) : toast.info(t("main:Project.files.nofileupload")) }}
                    >
                      {t("main:Project.files.status." + ditem.status)}
                    </Button>
                  </div>
                </td>
                <td>{dateFormat(ditem.created_at)}</td>
                <td>
                  <div className="action" style={{ padding: 5, display: "flex", gap: 5 }}>
                    {ditem.status !== 3 ?
                      <Button style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => show(ditem)}>
                        <i className="fa fa-plus" />
                        {!ditem.status ? t("main:Project.files.add") : t("main:Project.files.update")}
                      </Button> : null}
                    {ditem.status !== 3 ? <Button style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => submit(ditem)}>
                      <i className="fa fa-trash" />
                      {t("main:Project.files.delete")}
                    </Button> : null}
                    {!isNullOrEmpty(ditem.filepath) ?
                      <a className="btn btn-secondary" style={{ display: "flex", gap: 5, alignItems: "center" }} href={CDN_URL + ditem.filepath} target="_blank">
                        <i className="fa fa-download" />
                        {t("main:Project.files.download")}
                      </a>
                      : null}
                    {ditem.mcupload == 1 ? <Button color="success" style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => show(ditem)}>
                      <i className="fa fa-plus" />
                      {isNullOrEmpty(ditem.filepath_mc) ? t("main:Project.files.copy") : t("main:Project.files.mcupdate")}
                    </Button> : null}
                    {ditem.mcupload == 1 && !isNullOrEmpty(ditem.filepath_mc) ?
                      <a className="btn btn-primary" style={{ display: "flex", gap: 5, alignItems: "center" }} href={CDN_URL + ditem.filepath_mc} target="_blank">
                        <i className="fa fa-download" />
                        {t("main:Project.files.downloadmc")}
                      </a>
                      : null}
                  </div>
                </td>
              </tr>
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default FilesSend;
