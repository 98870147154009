import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../components/Utils/Utils";
import { ProjectReport } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { ProjectReportReq } from "../../services/Request";
import logoDefault from "./logo-default.png";
import MagicCloud from "./MagicCloud_monS.png";
class AsuudalGetReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: props.item,
      userData: [],
      connection: [],
      loading: false,
      apierrormsg: "",
      apierror: false,
    };
  }

  componentDidMount() {
    this.getMore();
  }

  getMore = () => {
    this.setState({ loading: true });
    var req = ProjectReportReq;
    req.filter.id = this.state.item.id;
    ProjectReport(req, this.onGetSuccess, this.onFailed, "POST");
  };

  onGetSuccess = (response) => {
    this.setState({
      loading: false,
      item: response.asuudal,
      connection: response.connection,
      userData: response.users,
    });
  };

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  render() {
    const { t, connection, item, apierrormsg, apierror, loading } = this.state;
    var ersdelCount = 0,
      zuvlumjCount = 0;
    return (
      <div>
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div style={{ marginTop: 60, verticalAlign: "center", height: 34 }}>
              <div style={{ width: "65%", float: "left", paddingLeft: 60 }}>
                <span>
                  Байгууллагад гарч буй асуудал ба түүнд өгөх эрсдэл, зөвлөмж
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "#0d9749",
                  textAlign: "center",
                  width: "35%",
                  float: "left",
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
              >
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "white",
                    textTransform: "uppercase",
                  }}
                >
                  {item.dpname} , {item.dname}
                </span>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#f6f299",
                height: 120,
                borderBottom: "3px solid #0d9749",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 30,
                  left: 60,
                  backgroundColor: "#0d9749",
                  borderRadius: "30px",
                  padding: "2px 10px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white", paddingRight: 20 }}>
                  АСУУДАЛ :
                </span>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 60,
                  left: 60,
                  width: "70%",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 14,
                    paddingBottom: 10,
                  }}
                >
                  {item.name}
                </span>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: 20,
                  right: 60,
                  backgroundColor: "#0d9749",
                  borderRadius: "50%",
                  padding: "10px 23px",
                }}
              >
                <i
                  className="fa fa-question"
                  style={{
                    fontSize: 60,
                    color: "white",
                    transform: "rotate(20deg)",
                  }}
                />
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src={MagicCloud}
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: -20,
                  opacity: "0.15",
                  zIndex: 0,
                  width: "60%",
                }}
              />
              <div
                style={{
                  paddingLeft: 60,
                  paddingRight: 60,
                  paddingTop: 40,
                }}
              >
                {connection.map((item, index) => {
                  if (item.type == "ersdel") {
                    ersdelCount++;
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "block",
                          marginBottom: 20,
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <div
                            style={{
                              //position: "absolute",
                              //top: 20,
                              //left: 60,
                              backgroundColor: "#0d9749",
                              borderRadius: "50%",
                              padding: "5px 8px",
                              height: 40,
                              width: 40,
                              float: "left",
                            }}
                          >
                            <i
                              className="fa fa-close"
                              style={{
                                fontSize: 30,
                                color: "white",
                                float: "left",
                              }}
                            />
                          </div>
                          <span
                            style={{
                              color: "#0d9749",
                              fontWeight: "bold",
                              fontSize: 16,
                              paddingLeft: 10,
                              paddingTop: 8,
                              display: "flex",
                            }}
                          >
                            ЭРСДЭЛ-{ersdelCount}
                          </span>
                        </div>
                        <span
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: 20,
                          }}
                        >
                          {item.ersdel}
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
              <div
                style={{
                  marginLeft: 60,
                  marginRight: 60,
                  marginTop: 40,
                  borderTop: "3px dashed #0d9749",
                }}
              />
              <div
                style={{
                  paddingLeft: 60,
                  paddingRight: 60,
                  paddingTop: 40,
                }}
              >
                {connection.map((item, index) => {
                  if (item.type == "zuvlumj") {
                    zuvlumjCount++;
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "block",
                          marginBottom: 20,
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <div
                            style={{
                              backgroundColor: "#0d9749",
                              borderRadius: "50%",
                              padding: "5px 5px",
                              height: 40,
                              width: 40,
                              float: "left",
                            }}
                          >
                            <i
                              className="fa fa-check"
                              style={{
                                fontSize: 30,
                                color: "white",
                                float: "left",
                              }}
                            />
                          </div>
                          <span
                            style={{
                              color: "#0d9749",
                              fontWeight: "bold",
                              fontSize: 16,
                              paddingLeft: 10,
                              paddingTop: 8,
                              display: "flex",
                            }}
                          >
                            ЗӨВЛӨМЖ-{zuvlumjCount}
                          </span>
                        </div>
                        <span
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: 20,
                          }}
                        >
                          {item.zuvlumj}
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div
              style={{
                height: 100,
                paddingLeft: 60,
                paddingRight: 60,
                marginTop: 20,
              }}
            >
              <img src={logoDefault} style={{ float: "left", height: 25 }} />
              <div
                style={{
                  borderBottom: "1px solid #0d9749",
                  display: "flex",
                  width: "70%",
                  marginRight: "15%",
                  display: "inline-block",
                }}
              />
              <a
                href="http://magicgroup.mn/mn"
                target="_blank"
                style={{ float: "right", marginTop: -15 }}
              >
                www.magicgroup.mn
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AsuudalGetReport;
