import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  ProjectList,
  ProjectDelete,
  setPageTitle,
  ProjectUpdate,
  CustomerList,
  socket,
} from "../../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  ProjectListReq,
  magicnum,
  BaseRequest,
  ProjectUpdateReq,
  CustomerListReq,
} from "../../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import CustomBootsrapTable from "../../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san

import $ from "jquery";
import ProjectAdd from "./ProjectAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  dateFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
} from "../../../components/Utils/Utils";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
const renderSuggestion = (suggestion) => (
  <div style={{ cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    {suggestion.name}
  </div>
);
class ProjectListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.addSuccess = this.addSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      cancel_type: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
      userData: [],
      value: "",
      type: "",
      audit_no: "",
      suggestions: [],
      customer_id: !isNullOrEmpty(this.props.customer_id) ? this.props.customer_id : "",
      selectedCustomer: null,
      selectedValue: null,
      EmployeeTypeID: localStorage.getItem("EmployeeTypeID"),
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("Project.Title"));
    this.getList();
  }

  Update(item, type) {
    var req = ProjectUpdateReq;
    req.project = item;
    req.type = type;
    ProjectUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    if (type == "confirm") {
      socket.emit("project", "changestatus");
    }
  }

  Confirm(item, type) {
    var req = ProjectUpdateReq;
    req.project = item;
    req.type = type;
    ProjectUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    if (type == "confirm") {
      socket.emit("project", "changestatus");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.success(t("common:SuccessEdit"));
  }

  delete(row) {
    ProjectDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }
  //buh jagsaaltiig serverees avch ireh function ehlel
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const {
      PID,
      sizePerPage,
      report_year,
      selectedCustomer,
      EmployeeTypeID, customer_id, type, audit_no
    } = this.state;
    var req = ProjectListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.report_year = report_year;
    req.filter.customer_id = isNullOrEmpty(selectedCustomer) ? "" : selectedCustomer.id;
    if (!isNullOrEmpty(customer_id)) {
      req.filter.customer_id = customer_id
    }
    req.filter.EmployeeTypeID = EmployeeTypeID;
    req.filter.type = type;
    req.filter.audit_no = audit_no;
    req.filter.mode = "all"
    ProjectList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }
  //buh jagsaaltiig serverees avch ireh function haalt

  //serveriin huselt amjilttai uid iishee orno
  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
      userData: response.users,
    });
  }

  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }
  //serveriin huselt amjiltgui uid haalt
  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
      $(".app-header").height() -
      otherHeight -
      filterHeight
    );
  }

  add(type, row) {
    const { t, EmployeeTypeID, customer_id } = this.state;
    var title = t("Project.Add");
    if (type == "update") {
      title = t("Project.Update");
    }
    if (
      EmployeeTypeID == "employee" &&
      type == "update" &&
      row.confirm_by > 0
    ) {
      var title = t("common:Warning");
      var message = t("common:nfPrivAccess");

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-ui">
              <h1>{title.substr(0, 30)}</h1>
              <p>{message}</p>
              <Button outline onClick={onClose}>
                {t("common:ok")}
              </Button>
            </div>
          );
        },
      });
    } else {
      ModalManager.open(
        <MainModal
          responsive
          title={title}
          onRequestClose={() => true}
          component={
            <ProjectAdd
              t={this.state.t}
              item={row}
              customer_id={customer_id}
              addSuccess={this.addSuccess}
            />
          }
        />
      );
    }
  }

  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }

  submit(row, type) {
    const { t } = this.state;

    var title = row.dname + " " + t("common:delete");
    var message = t("common:confirmdelete");

    if (type == "confirm") {
      var title = t("Project.confirm");
      var message =
        row.dname +
        " Нэртэй төслийг баталгаажуулах гэж байна." +
        t("common:confirm");
    } else if (type == "delete") {
      var title = row.dname + " " + t("Project.delete");
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain"
                  ? this.delete(row)
                  : this.Update(row, type);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  statusRowFormat = (cell, row) => {
    const { t, EmployeeTypeID } = this.state;
    let edit = (
      <div className="text-center">
        {EmployeeTypeID == "admin" ||
          (EmployeeTypeID == "manager" &&
            row.check_user == localStorage.getItem("EmployeeID")) ? (
          <Button
            style={{ marginLeft: 10 }}
            className={`button delete-button ${row.confirm_by > 0 ? "btn-success" : ""
              }`}
            onClick={() => {
              this.submit(row, "confirm");
            }}
            title={t("Project.confirm")}
          >
            <i className="fa fa-check-circle" />
          </Button>
        ) : (
          <Button
            style={{ marginLeft: 10 }}
            className={`button delete-button ${row.confirm_by > 0 ? "btn-success" : ""
              }`}
            title={t("Project.confirm")}
          >
            <i className="fa fa-check-circle" />
          </Button>
        )}
      </div>
    );
    return edit;
  };

  actionRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Link to={`/project/${cell}`}>
          <Button
            className="button more-button"
            color="success"
            outline
            title={t("common:more")}
          >
            <i className="fa fa-info" />
          </Button>
        </Link>

        <Button
          onClick={() => {
            this.add("update", row);
          }}
          className="button edit-button"
          title={t("common:edit")}
          color="success"
          outline
        >
          <i className="fa fa-edit" />
        </Button>

        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  nameRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-left">
        <Link to={`/project/${row.customer_id}`}>{row.dname}</Link>
      </div>
    );
    return edit;
  };

  userRowFormat = (cell, row) => {
    var user = getUser(this.state.userData, cell);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  };

  workUserRowFormat = (cell, row) => {
    var userArray = [],
      names = "";
    userArray = cell.split(":");
    userArray.map((item) => {
      var user = getUser(this.state.userData, item);
      if (isNullOrEmpty(user)) {
        names += "";
      } else {
        names += LastAndFirstName(user.lastname, user.firstname) + " , ";
      }
    });
    return names;
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  acpn = (value) => {
    var req = CustomerListReq;
    req.filter.name = value;
    req.pid = 1;
    req.pSize = 1000;
    if (value.length > 2) {
      CustomerList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  };

  onAcpnSuccess = (response) => {
    this.setState({ suggestions: response.data });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedCustomer: suggestion });
    return suggestion.name;
  };


  onSuggestionSelected = (e, val) => {
    this.setState({ selectedCustomer: val.suggestion });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedCustomer: null,
      apierror: false,
    });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  auditRowFormat = (cell, row) => {
    return <span>{cell}{isNullOrEmpty(row.audit_date) ? null : " , " + dateFormat(row.audit_date)}</span>
  }

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      report_year,
      suggestions,
      value, type, customer_id, audit_no
    } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("id"), // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("Project.customer_id"),
          dataField: "dname",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },

        {
          title: t("Project.type"),
          dataField: "type",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Project.report_year"),
          dataField: "report_year",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          width: "90px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Project.audit"),
          dataField: "audit_no",
          dataSort: false,
          dataFormat: this.auditRowFormat,
          width: "130px",
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Project.check_user"),
          dataField: "check_user",
          dataSort: false,
          dataFormat: this.userRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Project.work_user"),
          dataField: "work_user",
          dataSort: false,
          dataFormat: this.workUserRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Data.created_by"),
          dataField: "created_by",
          dataSort: false,
          dataFormat: this.userRowFormat,
          width: "130px",
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:date"),
          dataField: "created_at",
          dataSort: false,
          dataFormat: (d) => dateFormat(d),
          hidden: false,
          width: "80px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:status"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.statusRowFormat,
          hidden: false,
          width: "80px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:action"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "130px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    const inputProps = {
      placeholder: t("Customer.Search"),
      value,
      onChange: this.onChange,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">
              <Row>
                {isNullOrEmpty(customer_id) ?
                  <Col>
                    <FormGroup>
                      <Label>{t("Project.customer_id")}</Label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={this.getSuggestionValue}
                        onSuggestionSelected={(e, val) =>
                          this.onSuggestionSelected(e, val)
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                    </FormGroup>
                  </Col> : null}
                <Col>
                  <FormGroup>
                    <Label>{t("Project.type")}</Label>
                    <Input
                      type="select"
                      name="type"
                      value={type}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {t("common:choose")}
                      </option>
                      <option value="XBA1">
                        XBA1
                      </option>
                      <option value="XBA2">
                        XBA2
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Project.report_year")}</Label>
                    <Input
                      type="number"
                      name="report_year"
                      value={report_year}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Project.audit_no")}</Label>
                    <Input
                      type="text"
                      name="audit_no"
                      value={audit_no}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("Project.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(ProjectListFunc);
