import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container } from "reactstrap";
import Header from "../../components/Header/";
import Sidebar from "../../components/Sidebar/";
import Aside from "../../components/Aside/";
import Logout from "../../views/Login/Logout";

import PrivList from "../../views/Privilege/Priv/PrivList";
import Employee from "../../views/Privilege/Employee/Employee";
import PrivGroupList from "../../views/Privilege/PrivGroup/PrivGroupList";
import MorePrivGroup from "../../views/Privilege/PrivGroup/MorePrivGroup";
import EmployeeDetail from "../../views/Privilege/Employee/EmployeeDetail";

import TypeAsuudalList from "../../views/Type/AsuudalList";
import TypeZurchilList from "../../views/Type/ZurchilList";
import TypeNotolgooList from "../../views/Type/NotolgooList";
import DansList from "../../views/Type/DansList";

import CustomerList from "../../views/Customer/CustomerList";
import CustomerGet from "../../views/Customer/CustomerGet";
import CustomerUsers from "../../views/Customer/CustomerUsers";

import DataAsuudalList from "../../views/Data/AsuudalList";
import DataZuvlumjList from "../../views/Data/ZuvlumjList";
import AsuudalGet from "../../views/Data/AsuudalGet";
import AsuudalConnCountList from "../../views/Data/AsuudalConnCountList";
import AsuudalConnProjectCountList from "../../views/Data/AsuudalConnProjectCountList";

import DataAsuulgaList from "../../views/Data/AsuulgaList";
import DataErsdelList from "../../views/Data/ErsdelList";

import ProjectList from "../../views/Customer/Project/ProjectList";
import ProjectGet from "../../views/Customer/Project/ProjectGet";
import ProjectEmails from "../../views/Customer/Project/ProjectEmails";
import ProjectAsuudal from "../../views/Customer/Project/ProjectAsuudal";
import ProjectInfo from "../../views/Customer/Project/ProjectInfo";

import IndustryList from "../../views/Type/IndustryList";
import IndustryGet from "../../views/Type/IndustryGet";

import Action from "../../views/Log/action";
import Error from "../../views/Log/error";

import Dashboard from "../../views/Dashboard/dashboard";

import UserDetail from "../../views/Privilege/Employee/UserDetail";


class Full extends Component {
  componentDidMount() {
    var getToken = localStorage.getItem("SystemToken");
    if (getToken === null) {
      localStorage.removeItem("SystemToken")
      localStorage.removeItem("data1")
      localStorage.removeItem("data2")
      localStorage.removeItem("EmployeeID")
      localStorage.removeItem("EmployeeTypeID")
      return <Navigate to="/login" />;
    }
  }

  render() {
    return (
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar {...this.props} />
          <main className="main">
            {/* <Breadcrumb /> */}
            <Container fluid>
              <Routes>
                <Route exact path="/Logout" name="Logout" element={<Logout />} />
                <Route
                  exact
                  path="/industry"
                  name="IndustryList"
                  element={<IndustryList />}
                />
                <Route
                  exact
                  path="/projectemail"
                  name="ProjectEmails"
                  element={<ProjectEmails />}
                />
                <Route
                  exact
                  path="/industry/:id/:pname"
                  name="IndustryGet"
                  element={<IndustryGet />}
                />
                <Route
                  exact
                  path="/userdetail"
                  name="UserDetail"
                  element={<UserDetail />}
                />
                <Route
                  exact
                  path="/privilege/privgrouplist"
                  name="PrivGroupList"
                  element={<PrivGroupList />}
                />
                <Route
                  exact
                  path="/privilege/privlist"
                  name="PrivList"
                  element={<PrivList />}
                />
                <Route
                  exact
                  path="/privilege/employeelist"
                  name="Employee"
                  element={<Employee />}
                />
                <Route
                  path="/privilege/privgroupmore/:id"
                  name="Privilege More And Relation"
                  element={<MorePrivGroup />}
                />
                <Route
                  path="/privilege/EmployeeDetial/:id"
                  name="Employee More And Relation"
                  element={<EmployeeDetail />}
                />

                <Route
                  exact
                  path="/log/success"
                  name="Action"
                  element={<Action />}
                />
                <Route exact path="/log/error" name="Error" element={<Error />} />

                <Route
                  exact
                  path="/type/asuudal"
                  name="TypeAsuudalList"
                  element={<TypeAsuudalList />}
                />
                <Route
                  exact
                  path="/type/zurchil"
                  name="TypeZurchilList"
                  element={<TypeZurchilList />}
                />
                <Route
                  exact
                  path="/type/notolgoo"
                  name="TypeNotolgooList"
                  element={<TypeNotolgooList />}
                />
                <Route
                  exact
                  path="/type/dans"
                  name="DansList"
                  element={<DansList />}
                />
                <Route
                  exact
                  path="/customer"
                  name="CustomerList"
                  element={<CustomerList />}
                />
                <Route
                  exact
                  path="/asuudal/:is_confirm"
                  name="DataAsuudalList"
                  element={<DataAsuudalList />}
                />
                <Route
                  exact
                  path="/asuudalcount"
                  name="AsuudalConnCountList"
                  element={<AsuudalConnCountList />}
                />
                <Route
                  exact
                  path="/asuudalprojectcount"
                  name="AsuudalConnProjectCountList"
                  element={<AsuudalConnProjectCountList />}
                />
                <Route
                  exact
                  path="/data/zuvlumj"
                  name="DataZuvlumjList"
                  element={<DataZuvlumjList />}
                />
                <Route
                  exact
                  path="/data/ersdel"
                  name="DataErsdelList"
                  element={<DataErsdelList />}
                />
                <Route
                  exact
                  path="/data/asuulga"
                  name="DataAsuulgaList"
                  element={<DataAsuulgaList />}
                />
                <Route
                  path="/data/asuudal/:id"
                  name="AsuudalGet More"
                  element={<AsuudalGet />}
                />
                <Route
                  path="/customer/:id"
                  name="CustomerGet More"
                  element={<CustomerGet />}
                />

                <Route
                  exact
                  path="/project"
                  name="ProjectList"
                  element={<ProjectList />}
                />
                <Route
                  path="/project/:id"
                  name="ProjectGet More"
                  element={<ProjectGet />}
                />
                <Route
                  path="/project/info/:id"
                  name="ProjectInfo"
                  element={<ProjectInfo />}
                />
                <Route
                  path="/projectasuudal/:id/:project_id"
                  name="Project Asuudal"
                  element={<ProjectAsuudal />}
                />
                <Route
                  exact
                  path="/customeruser"
                  name="CustomerUsers"
                  element={<CustomerUsers />}
                />
                <Route path="/" name="dashboard" element={<Dashboard />} />
                <Route path="/dashboard" name="dashboard" element={<Dashboard />} />
              </Routes>
            </Container>
          </main>
          <Aside />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Full;
