import React, { Component } from "react";
import {
  DataAsuudalList,
  setPageTitle,
  TypeDansList,
  IndustryList
} from "../../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  DataAsuudalListReq,
  magicnum,
  TypeDansListReq, IndustryListReq
} from "../../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import {
  dateFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
} from "../../../components/Utils/Utils";
class AsuudalFilterFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno    
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      sizePerPage: 1000, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      code: "",
      cancel_type: "",
      cancel_at: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
      userData: [],
      dansData: [],
      loadingTypeDans: false,
      dataTypeDans: [],
      industryLoading: false,
      industryData: [],
      ua_chiglel: "", tusgai_zovshoorol: "",
      parent_dans_id: "",
      EmployeeTypeID: localStorage.getItem("EmployeeTypeID"),
      EmployeeID: localStorage.getItem("EmployeeID"),
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("Data.Asuudal.Title"));
    this.geTypeDanstList();
    this.getList();
    this.getIndustyList();
  }

  getIndustyList = () => {
    if (!this.state.industryLoading) {
      this.setState({ industryLoading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = IndustryListReq; // !!!!!
    req.pid = 1;
    req.pSize = 1000;
    req.filter.parent_id = -1;
    IndustryList(req, this.onIndustySuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }


  onIndustySuccess = (response) => {
    this.setState({
      industryLoading: false,
      industryData: response.data,
    });
  }


  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const {
      PID,
      sizePerPage,
      risk_type,
      dans_id,
      parent_dans_id,
      name,
      code,
      ua_chiglel, tusgai_zovshoorol
    } = this.state;
    var req = DataAsuudalListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.risk_type = risk_type;
    req.filter.dans_id = dans_id;
    req.filter.parent_dans_id = parent_dans_id;
    req.filter.created_by = "";
    req.filter.status = "";
    req.filter.name = name;
    req.filter.code = parseInt(code);
    req.filter.ua_chiglel = ua_chiglel;
    req.filter.priv_level = "";
    req.filter.tusgai_zovshoorol = tusgai_zovshoorol;
    req.filter.is_confirm = "1";
    DataAsuudalList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
      userData: response.users,
      dansData: response.dans,
    });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "ua_chiglel") {
        this.setState({ tusgai_zovshoorol: "" })
      }
      if (name == "parent_dans_id") {
        this.setState({ dans_id: "" })
      }
      if (name !== "code" && name !== "name") {
        this.getList()
      } else if ((name == "code" || name == "name") && value.length > 3) {
        this.getList()
      }

    });
  }

  geTypeDanstList = () => {
    if (!this.state.loadingTypeDans) {
      this.setState({ loadingTypeDans: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = TypeDansListReq;
    req.pid = 1;
    req.pSize = 1000;
    TypeDansList(req, this.onTypeDansSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  };

  onTypeDansSuccess = (response) => {
    this.setState({
      loadingTypeDans: false,
      dataTypeDans: response.data,
    });
  };

  getDansName = (id, type) => {
    var dname = "";
    const { dansData } = this.state
    dansData?.map(ditem => {
      if (ditem.id == id && type == 'parent') {
        dname = ditem?.parent
      }
      if (ditem.id == id && type == 'name') {
        dname = ditem?.name
      }
    })
    return dname
  }

  dansParentRowFormat = (cell, row) => {
    const ff = cell?.split(":")
    return <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
      {ff?.map((hh, hindex) => {
        return isNullOrEmpty(hh) ? null : <span key={hindex}>{this.getDansName(hh, "parent")}</span>
      })}
    </div>
  }

  dansRowFormat = (cell, row) => {
    const ff = cell?.split(":")
    return <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
      {ff?.map((hh, hindex) => {
        return isNullOrEmpty(hh) ? null : <span key={hindex}>{this.getDansName(hh, "name")}</span>
      })}
    </div>
  }

  industryRowFormat = (cell, row) => {
    var name = "";
    if (!isNullOrEmpty(this.state.industryData)) {
      var UA = getUser(this.state.industryData, cell);
      name = isNullOrEmpty(UA) ? "a" : UA.name
    }

    return <span title={name}>{name}</span>;
  }

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      risk_type,
      dans_id,
      loadingTypeDans,
      dataTypeDans,
      parent_dans_id,
      name,
      code, industryData, ua_chiglel, tusgai_zovshoorol, data, loading
    } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>{t("Data.Asuudal.name")}</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{t("Data.Asuudal.code")}</Label>
                  <Input
                    type="number"
                    name="code"
                    value={code}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{t("Data.Asuudal.risk_type")}</Label>
                  <Input
                    type="select"
                    value={risk_type}
                    name="risk_type"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  >
                    <option value="">{t("common:all")}</option>
                    {magicnum.Data.RiskType.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{t("Data.Asuudal.ua_chiglel")}</Label>
                  <Input
                    type="select"
                    name="ua_chiglel"
                    value={ua_chiglel}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  >
                    <option value="">
                      {t("common:all")}
                    </option>
                    {industryData.map((itt, iindex) => {
                      return itt.parent_id == 0 ? <option key={iindex} value={itt.id}>{itt.name}</option> : null
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{t("Data.Asuudal.tusgai_zovshoorol")}</Label>
                  <Input
                    type="select"
                    name="tusgai_zovshoorol"
                    value={tusgai_zovshoorol}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  >
                    <option value="">
                      {t("common:all")}
                    </option>
                    {industryData.map((itt, iindex) => {
                      return itt.parent_id == ua_chiglel && !isNullOrEmpty(ua_chiglel) ? <option key={iindex} value={itt.id}>{itt.name}</option> : null
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{t("common:type1")}</Label>
                  <Input
                    type="select"
                    value={parent_dans_id}
                    name="parent_dans_id"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  >
                    <option value="">{t("common:all")}</option>
                    {magicnum.Type.DansParent.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{t("common:type2")}</Label>
                  <Input
                    type="select"
                    name="dans_id"
                    value={dans_id}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                  >
                    <option value="">
                      {loadingTypeDans
                        ? t("common:loading")
                        : t("common:all")}
                    </option>
                    {loadingTypeDans
                      ? null
                      : dataTypeDans.map((item, index) => {
                        return parent_dans_id == item.parent ? (
                          <option value={item.id} key={index}>
                            {item.name} - ({item.parent})
                          </option>
                        ) : null;
                      })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="nopadding">
          {loading ? null : <CardHeader>Нийт : {data?.length} асуудал</CardHeader>}
          <CardBody className="nobg">
            <div className="asuudalfilter">
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              {loading ? <div className="loading"><Spinner /></div> :
                <div style={{ overflowY: "scroll", height: 300 }}>
                  {data?.map((item, index) => {
                    return <div key={index} className="item" onClick={() => this.props.selectAsuudal({ suggestion: item })}>
                      <span>{item?.name}</span>
                      <div className="other">
                        <span>{t("Data.Asuudal.code")}: {item?.code}</span>
                        <span>{t("Data.Asuudal.risk_type")}: {item?.risk_type}</span>
                        <div style={{ display: "flex", gap: 5 }}>{t("Data.Asuudal.dans_id")}: {this.dansRowFormat(item.dans_id)} {this.dansParentRowFormat(item.dans_id)}</div>
                        <div>{t("Data.Asuudal.ua_chiglel")}: {this.industryRowFormat(item.ua_chiglel)}</div>
                      </div>
                    </div>
                  })}
                </div>}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default AsuudalFilterFunc;
