import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../../components/Utils/Utils";
// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
];

class ProjectAsuudalEXCELFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: props.item,
      userData: props.userData,
      connection: props.connection,
      dataSet1: [],
      dataSet2: [],
      dataSet3: [],
    };
  }
  componentDidMount() {
    const { t, connection, item } = this.state;
    var user,
      userC = "";
    if (!isNullOrEmpty(item)) {
      user = getUser(this.state.userData, item.created_by);
      userC = getUser(this.state.userData, item.confirm_by);
    }

    const dataSet1 = [
      {
        name: item.name,
        code: item.code,
        dpname: item.dpname,
        dname: item.dname,
        risk_type: item.risk_type,
        created_by: !isNullOrEmpty(user)
          ? LastAndFirstName(user.lastname, user.firstname)
          : null,
        created_at: dateFormat(item.created_at),
        confirm_by: !isNullOrEmpty(userC)
          ? LastAndFirstName(userC.lastname, userC.firstname)
          : null,
        confirm_at: dateFormat(item.confirm_at),
      },
    ];

    var dataSet2 = [],
      dataSet3 = [];
    connection.map((item, index) => {
      if (item.type == "ersdel") {
        dataSet2.push({ name: item.ersdel });
      }
    });

    connection.map((item, index) => {
      if (item.type == "zuvlumj") {
        dataSet3.push({ name: item.zuvlumj });
      }
    });

    this.setState({ dataSet1, dataSet2, dataSet3 });
  }

  render() {
    const { dataSet1, dataSet2, dataSet3 } = this.state;
    return (
      <div>
        {/* <ExcelFile element={<button>Татах</button>}>
          <ExcelSheet data={dataSet1} name="Асуудал">
            <ExcelColumn label="Асуудал" value="name" />
            <ExcelColumn label="Код" value="code" />
            <ExcelColumn label="Данс" value="dname" />
            <ExcelColumn label="Данс" value="dpname" />
            <ExcelColumn label="Эрсдэлийн төрөл" value="risk_type" />
            <ExcelColumn label="Үүсгэсэн хэрэглэгч" value="created_by" />
            <ExcelColumn label="Үүсгэсэн огноо" value="created_at" />
            <ExcelColumn label="Баталгаажуулсан хэрэглэгч" value="confirm_bt" />
            <ExcelColumn label="Баталгаажуулсан огноо" value="confirm_at" />
          </ExcelSheet>
          <ExcelSheet data={dataSet2} name="Эрсдэл">
            <ExcelColumn label="Эрсдэл" value="name" />
          </ExcelSheet>
          <ExcelSheet data={dataSet3} name="Зөвлөмж">
            <ExcelColumn label="Зөвлөмж" value="name" />
          </ExcelSheet>
        </ExcelFile> */}
      </div>
    );
  }
}

export default ProjectAsuudalEXCELFunc;
