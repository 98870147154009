import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  setPageTitle,
  DashboardAsuudalBatalgaajaagui,
  DashboardTopAsuudal,
  DashboardNewAndUpdate,
} from "../../services/main";
import {
  BaseRequest,
  DashboardTopAsuudalReq,
} from "../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import {
  LastAndFirstName,
  dateFormat,
  isNullOrEmpty,
} from "../../components/Utils/Utils";
import { CountUp } from "use-count-up";
import { Link } from "react-router-dom";
class DashboardFunction extends Component {
  constructor(props) {
    super(props);
    this.onFailed = this.onFailed.bind(this);

    this.state = {
      loading: false,
      loadingNewAndUpdate: false,
      t: this.props.t,
      apierrormsg: "",
      apierror: false,
      year: new Date().getFullYear(),
      years: [],
      loadingBtalgaajaagui: false,
      topData: null,
      newAsuudalData: [],
      updateAsuudalData: [],
      loadingTop: false,
      sumErsdel: 0,
      sumZuvlumj: 0,
      sumAsuulga: 0,
      sumAsuudal: 0,
      Ersdel: 0,
      Zuvlumj: 0,
      Asuulga: 0,
      Asuudal: 0,
    };
  }
  componentDidMount() {
    setPageTitle(this.props.t("common:dashboard"));
    const years = [];
    for (let i = 2019; i <= this.state.year + 1; i += 1) {
      years.push(i);
    }
    this.setState({ years });
    this.getBatalgaajaagui();
    this.getTop();
    this.getNewAndUpdate();
  }

  getTop = () => {
    if (!this.state.loadingTop) {
      this.setState({ loadingTop: true });
    }
    var req = DashboardTopAsuudalReq;
    req.filter.limit = 5;
    DashboardTopAsuudal(req, this.onTopSuccess, this.onFailed, "POST");
  };

  onTopSuccess = (response) => {
    this.setState({
      loadingTop: false,
      topData: response.data,
    });
  };

  getBatalgaajaagui = () => {
    if (!this.state.loadingBtalgaajaagui) {
      this.setState({ loadingBtalgaajaagui: true });
    }
    DashboardAsuudalBatalgaajaagui(
      BaseRequest,
      this.onBatalgaajaaguiSuccess,
      this.onFailed,
      "POST"
    );
  };

  onBatalgaajaaguiSuccess = (response) => {
    this.setState({
      loadingBtalgaajaagui: false,
      Asuudal: response.asuudal[0].too,
      Ersdel: response.ersdel[0].too,
      Zuvlumj: response.zuvlumj[0].too,
      Asuulga: response.asuulga[0].too,
      sumAsuudal: response.asuudal[0].too + response.asuudal[1].too,
      sumErsdel: response.ersdel[0].too + response.ersdel[1].too,
      sumZuvlumj: response.zuvlumj[0].too + response.zuvlumj[1].too,
      sumAsuulga: response.asuulga[0].too + response.asuulga[1].too,
    });
  };

  getNewAndUpdate = () => {
    if (!this.state.loadingTop) {
      this.setState({ loadingTop: true });
    }
    var req = DashboardTopAsuudalReq;
    DashboardNewAndUpdate(req, this.onNewAndUpdateSuccess, this.onFailed, "POST");
  };

  onNewAndUpdateSuccess = (response) => {
    this.setState({
      loadingNewAndUpdate: false,
      newData: response.new,
      updateData: response.update,
    });
  };

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      loadingNewAndUpdate: false,
      apierrormsg: error,
    });
  }


  render() {
    const {
      t,
      apierror,
      apierrormsg,
      loadingBtalgaajaagui,
      sumErsdel,
      sumZuvlumj,
      sumAsuulga,
      sumAsuudal,
      Ersdel,
      Zuvlumj,
      Asuulga,
      Asuudal,
      loading,
      loadingTop,
      topData,
      newData,
      updateData,
      loadingNewAndUpdate
    } = this.state;

    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };
    return (
      <div className="animated fadeIn">
        <div>
          {apierror && (
            <div className="help-block text-danger">{apierrormsg}</div>
          )}
          <div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={containerStyle}
            />
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="callout callout-warning">
                      <small className="text-muted">
                        {t("Data.Asuudal.batalgaajagui")} {t("common:asuudal")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loadingBtalgaajaagui ? (
                          ".."
                        ) : (
                          <CountUp
                            isCounting
                            end={Asuudal}
                            duration={1}
                          />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-warning">
                      <small className="text-muted">
                        {t("Data.Asuudal.batalgaajagui")} {t("common:ersdel")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loadingBtalgaajaagui ? (
                          ".."
                        ) : (
                          <CountUp
                            isCounting
                            end={Ersdel}
                            duration={1}
                          />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-warning">
                      <small className="text-muted">
                        {t("Data.Asuudal.batalgaajagui")} {t("common:zuvlumj")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loadingBtalgaajaagui ? (
                          ".."
                        ) : (
                          <CountUp
                            isCounting
                            end={Zuvlumj}
                            duration={1}
                          />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-warning">
                      <small className="text-muted">
                        {t("Data.Asuudal.batalgaajagui")} {t("common:asuulga")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loadingBtalgaajaagui ? (
                          ".."
                        ) : (
                          <CountUp
                            isCounting
                            end={Asuulga}
                            duration={1}
                          />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-success">
                      <small className="text-muted">
                        {t("common:pagination.Total")} {t("common:asuudal")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loading ? (
                          ".."
                        ) : (
                          <CountUp isCounting end={sumAsuudal} duration={1} />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-success">
                      <small className="text-muted">
                        {t("common:pagination.Total")} {t("common:ersdel")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loading ? (
                          ".."
                        ) : (
                          <CountUp isCounting end={sumErsdel} duration={1} />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-success">
                      <small className="text-muted">
                        {t("common:pagination.Total")} {t("common:zuvlumj")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loading ? (
                          ".."
                        ) : (
                          <CountUp isCounting end={sumZuvlumj} duration={1} />
                        )}
                      </strong>
                    </div>
                  </Col>
                  <Col>
                    <div className="callout callout-success">
                      <small className="text-muted">
                        {t("common:pagination.Total")} {t("common:asuulga")}
                      </small>
                      <br />
                      <strong className="h4">
                        {loading ? (
                          ".."
                        ) : (
                          <CountUp isCounting end={sumAsuulga} duration={1} />
                        )}
                      </strong>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>{t("Dashboard.topasuudal")}</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 55 }}>№</td>
                      <td>{t("Data.Asuudal.name")}</td>
                      <td style={{ width: 55 }}>{t("Dashboard.total")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingTop ? (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          <Spinner />
                        </td>
                      </tr>
                    ) : !isNullOrEmpty(topData) ? (
                      topData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td><Link to={`/data/asuudal/${item.asuudal_id}`}>{item.name}</Link></td>
                            <td>{item.niit}</td>
                          </tr>
                        );
                      })
                    ) : null}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Row>
              <Col>
                <Card>
                  <CardHeader>{t("Dashboard.new")}</CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <td style={{ width: 55 }}>№</td>
                          <td>{t("Data.Asuudal.name")}</td>
                          <td style={{ width: 55 }}>{t("Dashboard.user")}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingNewAndUpdate ? (
                          <tr>
                            <td colSpan="3" style={{ textAlign: "center" }}>
                              <Spinner />
                            </td>
                          </tr>
                        ) : newData?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><Link to={`/data/asuudal/${item.id}`}>{item.name}</Link></td>
                              <td style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <span>{LastAndFirstName(item.lastname, item.firstname)}</span>
                                <span style={{ fontSize: 10 }}>{dateFormat(item.created_at)}</span>
                              </td>
                            </tr>
                          );
                        })
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader>{t("Dashboard.update")}</CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <td style={{ width: 55 }}>№</td>
                          <td>{t("Data.Asuudal.name")}</td>
                          <td style={{ width: 55 }}>{t("Dashboard.user")}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingNewAndUpdate ? (
                          <tr>
                            <td colSpan="3" style={{ textAlign: "center" }}>
                              <Spinner />
                            </td>
                          </tr>
                        ) : updateData?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><Link to={`/data/asuudal/${item.id}`}>{item.name}</Link></td>
                              <td style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <span>{LastAndFirstName(item.lastname, item.firstname)}</span>
                                <span style={{ fontSize: 10 }}>{dateFormat(item.updated_at)}</span>
                              </td>
                            </tr>
                          );
                        })
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("main")(DashboardFunction);
