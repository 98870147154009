import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  setPageTitle,
  CustomerUserList,
  CustomerUserDelete,
  CustomerList,
} from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  magicnum,
  BaseRequest,
  CustomerUserListReq,
  CustomerListReq,
} from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san

import $ from "jquery";
import ZuvlumjAdd from "./CustomerUsersAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Autosuggest from "react-autosuggest";
import "./Project/CustomAutoSuggest.scss";
import {
  dateFormat, isNullOrEmpty,
} from "../../components/Utils/Utils";
const renderSuggestion = (suggestion) => (
  <div style={{ cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    {suggestion.name}
  </div>
);
class DataZuvlumjListFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      customer_id: !isNullOrEmpty(this.props.customer_id) ? this.props.customer_id : "",
      customer_rd: !isNullOrEmpty(this.props.customer_rd) ? this.props.customer_rd : "",
      firstname: "",
      lastname: "",
      type: "",
      phone: "",
      status: "",
      rd: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
      userData: [],
      value: "",
      suggestions: [],
      selectedCustomer: null,
      selectedValue: null,
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("CustomerUser.Title"));
    this.getList();
    $("#filterTogleCuUs").toggle();
  }

  delete = (row) => {
    CustomerUserDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    );
  }

  onDeleteSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }
  //buh jagsaaltiig serverees avch ireh function ehlel
  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { PID, sizePerPage, firstname, lastname, phone, type, rd, status, selectedCustomer, customer_id } = this.state;
    var req = CustomerUserListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.firstname = firstname;
    req.filter.lastname = lastname;
    req.filter.phone = phone;
    req.filter.type = type;
    req.filter.rd = rd;
    req.filter.status = status;
    req.filter.customer_id = !isNullOrEmpty(customer_id) ? customer_id : selectedCustomer?.id
    CustomerUserList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }
  //buh jagsaaltiig serverees avch ireh function haalt

  //serveriin huselt amjilttai uid iishee orno
  onSuccess = (response) => {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
    });
  }

  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }
  //serveriin huselt amjiltgui uid haalt
  onChangePage = (pid) => {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  filterToggle = () => {
    $("#filterTogleCuUs").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
      $(".app-header").height() -
      otherHeight -
      filterHeight
    );
  }

  add = (type, row) => {
    const { t, customer_id, customer_rd } = this.state;
    var title = t("CustomerUser.Add");
    if (type == "update") {
      title = t("CustomerUser.Update");
    }
    ModalManager.open(
      <MainModal
        small
        title={title}
        onRequestClose={() => true}
        component={
          <ZuvlumjAdd
            t={this.state.t}
            item={row}
            customer_id={customer_id}
            customer_rd={customer_rd}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess = (text) => {
    this.getList();
    return toast.success(text);
  }

  submit = (row, type) => {
    const { t } = this.state;
    var title = row.firstname + " - " + t("common:delete");
    var message = t("common:confirmdelete");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.delete(row);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  customRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          onClick={() => {
            this.add("update", row);
          }}
          className="button edit-button"
          title={t("common:edit")}
        >
          <i className="fa fa-edit" />
        </Button>

        <Button
          className="button delete-button btn-danger"
          onClick={() => {
            this.submit(row, "delete");
          }}
          title={t("CustomerUser.delete")}
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  nameRowFormat = (cell, row) => {
    return <span title={cell}>{cell}</span>
  };

  typeRowFormat = (cell, row) => {
    const { t } = this.state
    return <span>{cell == 'zahiral' ? t("CustomerUser.zahiral") : t("CustomerUser.nyagtlan")}</span>
  };

  statusRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          style={{ marginLeft: 10 }}
          className={`button delete-button ${cell == magicnum.Status.Active
            ? "btn-success"
            : "btn-danger"
            }`}
          onClick={() => {
            this.add("update", row);
          }}
        >
          {cell == magicnum.Status.Active
            ? t("common:magicnum.Status.Active")
            : t("common:magicnum.Status.InActive")}
        </Button>

      </div>
    );
    return edit;
  };

  acpn(value) {
    var req = CustomerListReq;
    req.filter.name = value;
    req.pid = 1;
    req.pSize = 1000;
    if (value.length > 2) {
      CustomerList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess = (response) => {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedCustomer: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected = (e, val) => {
    this.setState({ selectedCustomer: val.suggestion });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedCustomer: null,
      apierror: false,
    });
  };

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      firstname, lastname, type, phone, rd, status, suggestions, value, customer_id
    } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("id"), // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("CustomerUser.cname"),
          dataField: "cname",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CustomerUser.crd"),
          dataField: "crd",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CustomerUser.lastname"),
          dataField: "lastname",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CustomerUser.firstname"),
          dataField: "firstname",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CustomerUser.rd"),
          dataField: "rd",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CustomerUser.phone"),
          dataField: "phone",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },

        {
          title: t("common:type"),
          dataField: "type",
          dataSort: false,
          dataFormat: this.typeRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "150px",
        },

        {
          title: t("common:date"),
          dataField: "created_at",
          dataSort: false,
          dataFormat: (d) => dateFormat(d),
          hidden: false,
          width: "80px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },

        {
          title: t("common:status"),
          dataField: "status",
          dataSort: false,
          dataFormat: this.statusRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "150px",
        },


        {
          title: null,
          dataField: "id",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          width: "150px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    const inputProps = {
      placeholder: t("Customer.Search"),
      value,
      onChange: this.onChange,
    };
    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.getList}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogleCuUs">
            <div className="filter">
              <Row>
                {isNullOrEmpty(customer_id) ?
                  <Col>
                    <FormGroup>
                      <Label>{t("Project.customer_id")}</Label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        onSuggestionSelected={(e, val) =>
                          this.onSuggestionSelected(e, val)
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                      />
                    </FormGroup>
                  </Col> : null}
                <Col>
                  <FormGroup>
                    <Label>{t("CustomerUser.lastname")}</Label>
                    <Input
                      type="text"
                      name="lastname"
                      value={lastname}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("CustomerUser.firstname")}</Label>
                    <Input
                      type="text"
                      name="firstname"
                      value={firstname}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("CustomerUser.phone")}</Label>
                    <Input
                      type="number"
                      name="phone"
                      value={phone}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("CustomerUser.rd")}</Label>
                    <Input
                      type="text"
                      name="rd"
                      value={rd}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:status")}</Label>
                    <Input
                      type="select"
                      name="status"
                      value={status}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {t("common:choose")}
                      </option>
                      <option value={magicnum.Status.Active}>
                        {t("common:magicnum.Status.Active")}
                      </option>
                      <option value={magicnum.Status.InActive}>
                        {t("common:magicnum.Status.InActive")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:type")}</Label>
                    <Input
                      type="select"
                      name="type"
                      value={type}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {t("common:choose")}
                      </option>
                      <option value="zahiral">
                        {t("CustomerUser.zahiral")}
                      </option>
                      <option value="nyagtlan">
                        {t("CustomerUser.nyagtlan")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("CustomerUser.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(DataZuvlumjListFunc);
