import React, { Component } from 'react'
import Login from './views/Login/Login.jsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Full from './containers/Full/';
class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  render() {
    return (
      <Router>
        <div className='App'>
          <Routes>
            <Route path='/login' name='Login' element={<Login />} />
            <Route path="/*" exact element={<Full />} />
          </Routes>
        </div>
      </Router>
    )
  }
}
export default App
