import React, { Component, useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import parser from "html-react-parser";
import {
  CommentAdd,
  CommentUpdate,
  CommentList,
  CommentDelete,
  setPageTitle,
  socket,
  ChatAdd,
} from "../../../services/main";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Button,
  ListGroup,
  Table,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
  FormGroup,
  Label,
  Input,
  Form,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  timeAgo,
  getRealData,
} from "../../../components/Utils/Utils";
import {
  CommentAddReq,
  CommentUpdateReq,
  CommentListReq,
  BaseRequest,
  magicnum,
} from "../../../services/Request";

import ChatEdit from "./ChatEdit";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

class DataAsuudalChatFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      type: this.props.type,
      torol: "",
      filtertorol: "",
      reply: "",
      apierrormsg: "",
      apierror: false,
      userData: [],
      data: [],
      message: "",
      isSave: false,
      loading: false,
      sizePerPage: 50,
      PID: 1,
      PTotal: 0,
      refreshType: "direct",
      editorData: "",
      commentID: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.getList = this.getList.bind(this);
    this.onListSuccess = this.onListSuccess.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInEditor = this.onChangeInEditor.bind(this);

    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    var aa = getRealData("data2");
    var id = "";
    if (!isNullOrEmpty(aa)) {
      id = JSON.parse(aa).id;
    }
    this.setState({ loading: true, id }, function () {
      this.getList();
    });
    socket.on("comment", (msg) => {
      this.getList();
    });
  }

  getList() {
    const { item, type, PID, sizePerPage, filtertorol } = this.state;

    this.setState({ loading: true });
    var req = CommentListReq;
    req.filter.comment_id = item.id;
    req.filter.type = type;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.torol = filtertorol
    if (type == 'project') {
      req.filter.customer_id = item.comment_id
    }
    CommentList(req, this.onListSuccess, this.onFailed, "POST");
  }

  onListSuccess(response) {
    const { refreshType, data,type } = this.state;
    var array = data;
    if (refreshType == "more") {
      response.data.map((item) => {
        array.push(item);
      });
    }

    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: refreshType == "more" ? array : response.data,
      userData: type =='project' ? response.customerusers : response.users,
      isSave: false,
    });
  }

  save() {
    const { message, item, type, torol, reply, t } = this.state;
    if (!isNullOrEmpty(message) && !isNullOrEmpty(torol)) {
      this.setState({ message: "" });
      var req = CommentAddReq;
      req.comment.comment = message;
      req.comment.comment_id = item.id;
      req.comment.customer_id = null;
      req.comment.haana = type;
      req.comment.torol = torol;
      req.comment.reply_id = isNullOrEmpty(reply) ? null : reply?.id;
      socket.emit("comment", "send");
      if (type == 'project') {
        req.comment.customer_id = item.customer_id
      }
      CommentAdd(req, this.onSuccess, this.onFailed, "POST");
    }
    else {
      return toast.warning(t("common:reqallfield"))
    }
  }

  update() {
    const { message, item, commentID, type, torol } = this.state;
    if (!isNullOrEmpty(message)) {
      this.setState({ message: "" });
      var req = CommentUpdateReq;
      req.comment.comment = message;
      req.comment.comment_id = item.id;
      req.comment.haana = type;
      req.comment.torol = torol;
      req.comment.id = commentID;
      socket.emit("comment", "send");
      CommentUpdate(req, this.onSuccess, this.onFailed, "PUT");
    }
  }

  onSuccess(response) {
    this.setState({ refreshType: "direct" }, function () {

      this.getList();
      this.setState({
        editorData: "",
        commentID: "",
        torol: "",
        reply: ""
      });
    });
  }

  onFailed(error) {
    //console.log(error);
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    if (e.keyCode == 13) {
      this.save();
    }
  };

  onSubmit(e) {
    e.preventDefault();
    this.save();
  }

  onChangeInEditor = (e) => {
    const { value } = e.target;
    this.setState({ message: value });
  };

  userRowFormat = (cell, row) => {
    var user = getUser(this.state.userData, cell);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  };

  more = () => {
    this.setState(
      { PID: this.state.PID + 1, refreshType: "more", isSave: true },
      function () {
        this.getList();
      }
    );
  };

  submit(row, type) {
    const { t } = this.state;
    var title = " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = " " + t("Data.Asuudal.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = " dd" + t("Data.Asuudal.delete");
      var message = t("common:confirm");
    }
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: t("common:yes"),
          onClick: () => (type == "deleteMain" ? this.delete(row) : ""),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }


  editChat(row) {
    this.setState({
      message: row.comment,
      commentID: row.id,
      torol: row.torol
    });

  }

  editReply = (row) => {
    this.setState({
      reply: row,
      torol: row.torol
    });

  }

  delete(row) {
    CommentDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  itemBody = (item, userD) => {
    const { t, data } = this.state;
    return <div>
      <div style={{ marginRight: 50 }}>
        {
          data.map(item2 => {
            return item2?.reply_id == item?.id ? this.itemBody(item2, userD) : null;
          })
        }
      </div>
      <div
        className="timeline timeline-3"
        key={item?.id}
        style={{ transform: "rotate(180deg)", direction: "ltr", borderBottom: "1px solid #ededed", marginTop: 10, paddingBottom: 10 }}
      >
        <div className="timeline-items">
          <div className="timeline-content">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="mr-2">
                <Button
                  className="button delete-button"
                  onClick={() => {
                    this.submit(item, "deleteMain");
                  }}
                  title={t("common:delete")}
                >
                  <i className="fa fa-trash" />
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  className="button edit-button"
                  onClick={() => {
                    this.editChat(item);
                  }}
                  title={t("common:edit")}
                >
                  <i className="fa fa-edit" />
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  className="button edit-button"
                  onClick={() => {
                    this.editReply(item);
                  }}
                  title={t("common:reply")}
                >
                  <i className="fa fa-reply" />
                </Button>
                {isNullOrEmpty(item?.torol) ? null : <>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() => { this.setState({ filtertorol: item?.torol }, function () { this.getList() }); }}
                    className="button edit-button" style={item?.torol == 1 ? { backgroundColor: "red", color: "white" } : item?.torol == 2 ? { backgroundColor: "blue", color: "white" } : { backgroundColor: "green", color: "white" }}>{t(`common:magicnum.HeleltsuulegTorol.${item?.torol}`)}</Button>
                </>}
                &nbsp;&nbsp;&nbsp;
                <a className="text-dark-75 text-hover-primary font-weight-bold">
                  {userD}
                </a>
                &nbsp;&nbsp;&nbsp;
                <span className="text-muted ml-2" style={{ fontSize: 10 }}>
                  {timeAgo(item.created_at)}
                </span>
              </div>
              <div
                className="dropdown ml-2"
                data-toggle="tooltip"
                title=""
                data-placement="left"
                data-original-title="Quick actions"
              >
                <a
                  href="#"
                  className="btn btn-hover-light-primary btn-sm btn-icon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="ki ki-more-hor icon-md"></i>
                </a>
              </div>
            </div>
            <div className="p-0">{parser(item.comment)}</div>
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    const { t, message, data, commentID, loading, PTotal, sizePerPage, PID, reply, torol, filtertorol } = this.state;

    return (
      <div>
        <Input type="select" style={{ marginBottom: 10, height: 37 }} value={filtertorol} onChange={(e) => { this.setState({ filtertorol: e.target.value }, function () { this.getList() }) }}>
          <option value="">{t(`common:choose`)}</option>
          {magicnum.HeleltsuulegTorol.map((hitem, hindex) => {
            return <option key={hindex} value={hitem}>{t(`common:magicnum.HeleltsuulegTorol.${hitem}`)}</option>
          })}
        </Input>
        <div
          className="separator separator-dashed my-10"
          style={{
            maxHeight: 450,
            overflowX: "auto",
            transform: "rotate(180deg)",
            direction: "rtl",
            scrollBehavior: "smooth",
          }}
        >
          {loading ? (
            <Spinner />
          ) : data.length == 0 ? null : (
            data.map(item => {
              var userD = this.userRowFormat(item.created_by);
              return isNullOrEmpty(item?.reply_id) ? this.itemBody(item, userD) : null;
            })
          )}
          <div
            style={{
              textAlign: "center",
              margin: 20,
              transform: "rotate(180deg)",
              direction: "ltr",
            }}
          >
            {(data.length > 0 && PTotal / sizePerPage < PID) ||
              data.length == 0 ? null : (
              <Button
                color="primary"
                onClick={this.more}
                disabled={this.state.isSave}
              >
                {t("common:tsaashid")}
              </Button>
            )}
          </div>
        </div>
        <div className="form-group" style={{ marginTop: 40 }}>
          <FormGroup>
            <Row>
              {isNullOrEmpty(reply) ? null :
                <Col>
                  <InputGroup>
                    <Input value={reply?.comment} disabled={true} />
                    <InputGroupText>
                      <button
                        onClick={() => { this.setState({ reply: "" }) }}

                        className="btn-sm btn btn-primary"
                      >
                        Х
                      </button>
                    </InputGroupText>
                  </InputGroup>
                </Col>}
              <Col>
                <Input type="select" style={{ marginBottom: 10, height: 37 }} value={torol} name="torol" onChange={this.handleChange}>
                  <option value="">{t(`common:choose`)}</option>
                  {magicnum.HeleltsuulegTorol.map((hitem, hindex) => {
                    return <option key={hindex} value={hitem}>{t(`common:magicnum.HeleltsuulegTorol.${hitem}`)}</option>
                  })}
                </Input>
              </Col>
            </Row>
            <textarea
              onChange={(event) => this.onChangeInEditor(event)}
              rows={4} cols={4}
              value={message}
              className="form-control"
              onKeyDown={(e) => this.handleSubmit(e)}
              placeholder={t("common:message")}
            ></textarea>
            {isNullOrEmpty(commentID) ? (
              <button
                onClick={this.onSubmit}
                className="btn-sm btn btn-primary"
                style={{ marginTop: 20, width: "100%" }}
              >
                Нэмэх
              </button>
            ) : (
              <div>
                <Input
                  style={{ marginTop: 10, visibility: "hidden" }}
                  type="text"
                  name="commentID"
                  value={this.state.commentID}
                  disabled={true}
                />
                <button
                  onClick={this.update}

                  className="btn-sm btn btn-primary"
                  style={{ marginTop: 20, width: "100%" }}
                >
                  Хадгалах
                </button></div>
            )}

          </FormGroup>
        </div>
      </div>
    );
  }
}

export default DataAsuudalChatFunc;
