import React, { Component } from "react";
import config from "../../services/config.json";
var CryptoJS = require("crypto-js");

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function isNullOrEmpty(text) {
  var retVal = false;
  if (text === "" || text === null || text === undefined) {
    retVal = true;
  }
  return retVal;
}

export function moneyFormat(amount, currency) {
  return formatMoney(amount, 0, ".", ",") + currencySymbol(currency); //mongon dun, .-s hoish heden oron, myangad,sayd
}

function currencySymbol(currency) {
  var symbol = "₮";
  switch (currency) {
    case "MNT":
      symbol = "₮";
      break;
    case "USD":
      symbol = "$";
      break;
    default:
      symbol = "%";
  }
  return symbol;
}

export function getRealData(name) {
  var retVal = "";
  var getValue = localStorage.getItem(name);
  if (getValue !== null && getValue !== undefined && getValue !== "") {
    retVal = CryptoJS.AES.decrypt(
      getValue.toString(),
      config.engryptPass
    ).toString(CryptoJS.enc.Utf8);
  }
  return retVal;
}

export function IsPrivilege(privNo) {
  var retVal = false;
  var stringData = getRealData("data1");
  if (stringData !== "") {
    const UserPrivs = JSON.parse(stringData);
    if (UserPrivs !== null && privNo !== undefined) {
      UserPrivs.map((i, index) => {
        if (i.PrivNo == privNo) {
          retVal = true;
        }
      });
    }
  }
  return retVal;
}

export function dateFormat(date) {
  var retVal = "";
  !isNullOrEmpty(date) ? (retVal = date.substring(0, 10)) : (retVal = "");
  return retVal.replace(/-/g, ".");
}

export function dateTimeFormat(date) {
  var retVal = "";
  !isNullOrEmpty(date)
    ? (retVal =
      date.substring(0, 10) +
      " " +
      (parseInt(date.substring(11, 13)) + 8) +
      date.substring(13, 16))
    : (retVal = "");
  return retVal.replace(/-/g, ".");
}

export function TimeFormat(date) {
  var retVal = "";
  !isNullOrEmpty(date)
    ? retVal = date.substring(11, 13)
    : (retVal = "");
  return retVal;
}

export function customNumberRight(number) {
  return <div style={{ textAlign: "right" }}>{number}</div>;
}

export function exportPng(svg, width, height) {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");

    // Set background to white
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, width, height);

    let xml = new XMLSerializer().serializeToString(svg);
    let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
    let img = new Image(width, height);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      let imageData = canvas.toDataURL("image/png", 1.0);
      resolve(imageData);
    };

    img.onerror = () => reject();

    img.src = dataUrl;
  });
}

export function dateTimeOnlyFormat(date) {
  var retVal = "";
  !isNullOrEmpty(date)
    ? (retVal =
      date.substring(0, 10) +
      " " +
      (parseInt(date.substring(11, 13)) + 8) +
      date.substring(13, 16))
    : (retVal = "");
  return retVal.replace(/-/g, ".");
}

export function LastAndFirstName(lastname, firstname) {
  var P = "";
  if (isNullOrEmpty(lastname) && isNullOrEmpty(firstname)) {
    P = "";
  } else if (!isNullOrEmpty(lastname)) {
    P = lastname.substring(0, 1) + "." + firstname;
  } else {
    P = firstname;
  }
  return P;
}

export function isValidEmail(email) {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
}

export function isValidPhone(phone) {
  return isNullOrEmpty(phone) || phone.length != 8 ? false : true;
}

export function getUser(data, id) {
  var i = null;
  !isNullOrEmpty(data)
    ? data.map((item) => {
      if (item.id == id) {
        i = item;
      }
    })
    : (i = null);
  return i;
}

export function removeTags(str) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}

export function timeAgo(prevDate) {
  const diff = Number(new Date()) - Date.parse(prevDate);
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000);
      return `${seconds} ${seconds > 1 ? "секундын" : "секунд"} өмнө`;
    case diff < hour:
      return Math.round(diff / minute) + " минутын өмнө";
    case diff < day:
      return Math.round(diff / hour) + " цагийн өмнө";
    case diff < month:
      return Math.round(diff / day) + " өдрийн өмнө";
    case diff < year:
      return Math.round(diff / month) + " сарын өмнө";
    case diff > year:
      return Math.round(diff / year) + " жилийн өмнө";
    default:
      return "nu";
  }
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('.');
}
