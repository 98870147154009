import React, { Component } from "react";
import { AsuudalConnectProjects, setPageTitle } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { AsuudalConnectProjectsReq } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Table,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import { Link } from "react-router-dom";
class AsuudalConnectProjectsFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      asuudal_id: this.props.asuudal_id,
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { asuudal_id } = this.state;
    var req = AsuudalConnectProjectsReq;
    req.filter.asuudal_id = asuudal_id;
    AsuudalConnectProjects(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onSuccess(response) {
    console.log(response);
    this.setState({
      loading: false,
      data: response.data,
    });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  render() {
    const { t, loading, data } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    return (
      <div className="animated fadeIn">
        <Card className="nopadding">
          <CardHeader>{t("Data.Asuudal.Title")}</CardHeader>
          <CardBody className="nobg">
            <div>
              <Table>
                <thead>
                  <tr>
                    <td>{t("Project.customer_id")}</td>
                    <td>{t("Project.report_year")}</td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? <tr><td colSpan={2}><Spinner /></td></tr> : data.map((item, index) => {
                    return <tr key={index}>
                      <td><a href={`/project/${item.id}`} target="_blank">{item.name}</a></td>
                      <td>{item.report_year}</td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default AsuudalConnectProjectsFunc;
