import React, { Component } from "react";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import { Button, Row } from "reactstrap";
import "./MainModal.scss";
import $ from "jquery";
class MainModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 0
    };
    this.generateComponent = this.generateComponent.bind(this);
    // this.onPositiveClicked = this.onPositiveClicked.bind(this);
    // this.onNegativeClicked = this.onNegativeClicked.bind(this);
    // this.generateBody = this.generateBody.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  generateComponent() {
    return <div>{this.props.component}</div>;
  }

  // onPositiveClicked() {
  //   this.props.onPositiveButtonClicked();
  //   ModalManager.close();
  // }

  // onNegativeClicked() {
  //   this.props.onNegativeButtonClicked();
  //   ModalManager.close();
  // }

  // generateBody() {
  //   return (
  //     <div>
  //       <h5>{this.props.message === undefined ? "" : this.props.message}</h5>
  //       <Row className="action-row">
  //         {this.props.isNeutral === undefined ||
  //           this.props.isNeutral === false ? (
  //           ""
  //         ) : (
  //           <Button
  //             className="btn btn-primary action-btn"
  //             onClick={ModalManager.close}
  //           >
  //             Close
  //           </Button>
  //         )}
  //         {this.props.positiveText !== undefined ? (
  //           <Button
  //             className="btn btn-success action-btn"
  //             onClick={this.onPositiveClicked}
  //           >
  //             {this.props.positiveText}
  //           </Button>
  //         ) : (
  //           ""
  //         )}
  //         {this.props.negativeText !== undefined ? (
  //           <Button
  //             className="btn btn-danger action-btn"
  //             onClick={this.onNegativeClicked}
  //           >
  //             {this.props.negativeText}
  //           </Button>
  //         ) : (
  //           ""
  //         )}
  //       </Row>
  //     </div>
  //   );
  // }

  closeModal() {
    ModalManager.close();
    if (this.props.onModalClose != undefined) {
      this.props.onModalClose();
    }
  }

  render() {
    const { title, onRequestClose, component } = this.props;
    var modalStyles = { overlay: { zIndex: 10 } };
    const style = {
      content: {
        margin: "2% auto",
        width: "1200px",
        height: "90%",
        maxHeight: "90%"
      }
    };
    //var setheight1=($(window).height()-$('.modal-body').height())
    const setheight = "5%";
    const small = {
      content: {
        margin: setheight + " auto",
        width: "740px",
        height: $(".modal-body").height(),
        maxHeight: "80%"
      }
    };
    const responsive = {
      content: {
        margin: setheight + " auto",
        height: $(".modal-body").height(),
        maxHeight: "80%",
        width: "95%"
      }
    };
    return (
      <Modal
        style={modalStyles}
        onRequestClose={onRequestClose !== undefined ? onRequestClose : () => false}
        //onRequestClose={true}
        effect={Effect.SlideFromBottom}
        style={this.props.big ? style : this.props.small ? small : responsive}
      >
        <div className="main-modal-container" ref="content">
          <div className="modal-header">
            <h3>{title !== undefined ? title : ""}</h3>
            <div className="actions">
              <Button className="btn btn-outline" onClick={this.closeModal}>
                X
              </Button>
            </div>
          </div>
          <div className="modal-body">
            {/* {component !== undefined
              ? this.generateComponent()
              : this.generateBody()} */
              this.generateComponent()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default MainModal;
