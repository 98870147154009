import React, { Component, useRef } from "react";
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
  moneyFormat,
  removeTags,
} from "../../../components/Utils/Utils";
// import ReactExport from "react-export-excel";
import { ProjectReport, CommentList, BoditList } from "../../../services/main";
import { BoditListReq, CommentListReq, ProjectReportReq } from "../../../services/Request";
import {
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from "reactstrap";
import classNames from "classnames";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import { ExcelWorkBook } from "./excelworkbook.tsx";
import { ExcelWorkBook2 } from "./excelworkbook2.tsx";
class ProjectAsuudalEXCELFunc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      t: this.props.t,
      item: props.item,
      userData: props.userData,
      data: props.data,
      loading: true,
      dataSet1: [],
      reports: [],
      array: [],
      activeTab: 0,
      maxZuvlumj: 0,
      comments: [],
      bodits: [],
      maxErsdel: 0,
      activemaintab: "1",
      report2: null,
      changecount: 0
    };
  }
  componentDidMount() {
    const { t, data, item } = this.state;
    var user,
      userC = "";
    if (!isNullOrEmpty(item)) {
      user = getUser(this.state.userData, item.created_by);
      userC = getUser(this.state.userData, item.confirm_by);
    }

    // const dataSet1 = [
    //   {
    //     name: item.name,
    //     code: item.code,
    //     dpname: item.dpname,
    //     dname: item.dname,
    //     risk_type: item.risk_type,
    //     created_by: !isNullOrEmpty(user)
    //       ? LastAndFirstName(user.lastname, user.firstname)
    //       : null,
    //     created_at: dateFormat(item.created_at),
    //     confirm_by: !isNullOrEmpty(userC)
    //       ? LastAndFirstName(userC.lastname, userC.firstname)
    //       : null,
    //     confirm_at: dateFormat(item.confirm_at),
    //   },
    // ];

    data.map(async (item, index) => {
      await this.getAll(item)
    })


    // this.setState({ dataSet1 });
  }

  getAll = async (item) => {
    await this.getMore(item.asuudal_id);
    await this.getBodit(item.asuudal_id);
    await this.forumListGet(item.asuudal_id, "asuudal");
  }

  forumListGet = async (id, type) => {
    this.setState({ loading: true });
    var req = CommentListReq;
    req.filter.comment_id = id;
    req.filter.type = type;
    req.pid = 1;
    req.pSize = 1000;
    CommentList(req, this.onForumSuccess, this.onForumFailed, "POST");
  }

  onForumSuccess = (response) => {
    const { comments } = this.state;

    response.data.map((item) => {
      comments.push(item);
    });

    this.setState({ comments }, function () {
      this.setDataSets();
    });

  };

  onForumFailed(error) {
    //console.log(error);
  }


  setDataSets = () => {
    const { reports, comments, userData, item, maxZuvlumj, maxErsdel, bodits, changecount, t } = this.state;
    var maxZuvlumj2 = 0,
      maxErsdel2 = 0;
    var array = [];
    reports.map(async (it, jindex) => {
      var text = "";
      var maxZuvlumj3 = 0,
        maxErsdel3 = 0;
      it.notolgooData.map((ig) => {
        var notolgooArray = it.asuudal.notolgoo_id.split(":");
        notolgooArray.map((im) => {
          if (ig.id == im) {
            if (!isNullOrEmpty(text)) {
              text += ":";
            }
            text += ig.name;
          }
        });
      });


      var itemArray = [];
      itemArray.push([
        { value: "Код", style: { font: { sz: "14", bold: true } } },
        it.asuudal.code,
        true,
      ]);
      itemArray.push([
        { value: "Төрөл-2", style: { font: { sz: "14", bold: true } } },
        it.asuudal.dname,
        true,
      ]);
      itemArray.push([
        { value: "Асуудал", style: { font: { sz: "14", bold: true } } },
        it.asuudal.name,
        true,
      ]);
      itemArray.push([
        { value: "Бодит байдал", style: { font: { sz: "14", bold: true } } },
        it.asuudal.bodit,
        true,
      ]);
      bodits.map((bitem, bindex) => {
        if (bitem.asuudal_id == it.asuudal.id) {
          itemArray.push([
            { value: "Бодит байдал", style: { font: { sz: "14", bold: true } } },
            bitem.bodit,
            true,
          ]);
        }
      })


      itemArray.push([
        {
          value: "Нотолгоо",
          style: { font: { sz: "14", bold: true } },
        },
        text,
        true,
      ]);
      itemArray.push([
        {
          value: "Батламж төрөл",
          style: { font: { sz: "14", bold: true } },
        },
        it.asuudal.batlamj_type,
        true,
      ]);
      itemArray.push([
        {
          value: "Батламж мэдэгдэл",
          style: { font: { sz: "14", bold: true } },
        },
        it.asuudal.batlamj,
        true,
      ]);
      itemArray.push([
        { value: "Асуулт/Горим", style: { font: { sz: "14", bold: true } } },
        it.asuudal.gorim_tailbar,
        true,
      ]);



      it.connection.map((co, index) => {
        if (co.type == "ersdel") {
          maxErsdel3++;
          itemArray.push([
            {
              value: "Эрсдэл-",
              style: {
                font: { sz: "14", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
              },
            },
            co.ersdel,
            true,
          ]);
        }
        if (co.type == "zuvlumj") {
          maxZuvlumj3++;
          itemArray.push([
            {
              value: "Зөвлөмж-",
              style: {
                font: { sz: "14", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
              },
            },
            co.zuvlumj,
            true,
          ]);
        }
      });
      itemArray.push([
        {
          value: "Хэрэгжүүлэх горим",
          style: { font: { sz: "14", bold: true } },
        },
        it.asuudal.gorim_sheet,
        true,
      ]);

      comments.map((item) => {
        if (item.comment_id == it.asuudal.id) {

          var stripedHtml = item.comment.replace(/<[^>]+>/g, '');
          var user = getUser(userData, item.created_by);
          var uName = user.lastname.substr(0, 1) + ". " + user.firstname;
          var uu = ""
          if (!isNullOrEmpty(item.torol)) {
            uu = "(" + t(`common:magicnum.HeleltsuulegTorol.${item.torol}`) + ") " + stripedHtml
          } else {
            uu = stripedHtml
          }
          itemArray.push([
            {
              value: "Хэлэлцүүлэг (" + uName + ")",
              style: { font: { sz: "14", bold: true } },
            },
            uu,
            item.created_at
          ]);
        }
      });

      array.push({
        code: it.asuudal,
        data: itemArray,
      });


      if (maxErsdel3 > maxErsdel2) {
        maxErsdel2 = maxErsdel3;
      }
      if (maxZuvlumj3 > maxZuvlumj2) {
        maxZuvlumj2 = maxZuvlumj3;
      }
    });
    if (array.length == reports.length) {
      if (maxErsdel > maxErsdel2) {
        maxErsdel2 = maxErsdel;
      }
      if (maxZuvlumj > maxZuvlumj2) {
        maxZuvlumj2 = maxZuvlumj;
      }
      this.setState({
        array,
        loading: false,
        maxErsdel: maxErsdel2,
        maxZuvlumj: maxZuvlumj2,
        changecount: changecount + 1
      });
    }
  };


  getAsuudalRel(data, code) {
    var selected = null;
    data.map((item) => {
      if (item.asuudal.code == code) {
        selected = item.asuudal;
      }
    });
    return selected;
  }

  getAsuudalRel(data, code) {
    var selected = null;
    data.map((item) => {
      if (item.asuudal.code == code) {
        selected = item.asuudal;
      }
    });
    return selected;
  }

  getErsdelZuvlumjRel(data, id, type) {
    var selected = [],
      count = 0;
    data.map((item) => {
      if (item.asuudal.id == id) {
        item.connection.map((ide) => {
          if (ide.type == type && type == "ersdel") {
            selected.push(ide.ersdel);
          }
          if (ide.type == type && type == "zuvlumj") {
            selected.push(ide.zuvlumj);
          }
        });
      }
    });
    return selected;
  }

  setAuditReport = () => {
    const { maxErsdel, maxZuvlumj, data, reports, comments } = this.state;
    var array = [];
    data.map((item, index) => {
      var asuudalGet = this.getAsuudalRel(reports, item.acode);
      var ersdelGet = this.getErsdelZuvlumjRel(
        reports,
        item.asuudal_id,
        "ersdel"
      );
      var zuvlumjGet = this.getErsdelZuvlumjRel(
        reports,
        item.asuudal_id,
        "zuvlumj"
      );


      var obj = {
        code: item.acode,
        asuudalid: item.asuudal_id,
        asuudal: item.aname,
        dun: item.dun,
        batlamj_type: isNullOrEmpty(asuudalGet) ? "" : asuudalGet.batlamj_type,
        dname: isNullOrEmpty(asuudalGet) ? "" : asuudalGet.dname,
        dpname: isNullOrEmpty(asuudalGet) ? "" : asuudalGet.risk_type,
        bodit: isNullOrEmpty(asuudalGet) ? "" : asuudalGet.bodit,

      };

      for (let index = 1; index <= maxErsdel; index++) {
        obj["ersdel" + index] = isNullOrEmpty(ersdelGet[index - 1])
          ? ""
          : ersdelGet[index - 1];
      }

      for (let index = 1; index <= maxZuvlumj; index++) {
        obj["zuvlumj" + index] = isNullOrEmpty(zuvlumjGet[index - 1])
          ? ""
          : zuvlumjGet[index - 1];
      }


      array.push(obj);
    });

    this.setState({ report2: array });
  };

  getMore = async (id) => {
    var req = ProjectReportReq;
    req.filter.id = id;
    req.pid = 1;
    req.pSize = 1000;
    ProjectReport(req, this.onGetSuccess, this.onFailed, "POST");
  };

  onGetSuccess = (response) => {
    const { reports } = this.state;
    var arr = {
      asuudal: response.asuudal,
      connection: response.connection,
      notolgooData: response.notolgooData,
    };
    reports.push(arr);

    this.setState({ reports }, function () {
      // if (this.state.data.length == reports.length) {
      //   this.setDataSets();
      // }
    });
  };

  getBodit = async (id) => {
    var req = BoditListReq;
    req.filter.asuudal_id = id;
    req.pid = 1;
    req.pSize = 1000;
    BoditList(req, this.onGetBoditSuccess, this.onFailed, "POST");
  };

  onGetBoditSuccess = (response) => {
    const { bodits } = this.state;
    response.data.map((item) => {
      bodits.push(item);
    });

    this.setState({ bodits });
  };

  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  };

  toggle = (id) => {
    this.setState({ activeTab: id });
  };

  toggleMain = (id) => {
    this.setState({ activemaintab: id });
  };

  change = (index, jindex) => {
    const { array, changecount } = this.state;
    var arr = array;
    arr[index].data[jindex][2] = !arr[index].data[jindex][2];
    this.setState({ array: arr, changecount: changecount + 1 });
  };

  buildERow(numrows) {
    var rows = [];
    for (var i = 0; i < numrows; i++) {
      rows.push(<th key={i}>Эрсдэл - {i + 1}</th>);
    }
    return rows;
  }

  buildZRow(numrows) {
    var rows = [];
    for (var i = 0; i < numrows; i++) {
      rows.push(<th key={i}>Зөвлөмж - {i + 1}</th>);
    }
    return rows;
  }

  buildERowD(numrows, item) {
    var rows = [];
    for (var i = 0; i < numrows; i++) {
      var fieldName = "ersdel" + (i + 1);
      rows.push(
        <td key={i}>
          <span style={{ width: 350, display: "block" }}>
            {item[fieldName]}
          </span>
        </td>
      );
    }
    return rows;
  }

  buildZRowD(numrows, item) {
    var rows = [];
    for (var i = 0; i < numrows; i++) {
      var fieldName = "zuvlumj" + (i + 1);
      rows.push(
        <td key={i}>
          <span style={{ width: 350, display: "block" }}>
            {item[fieldName]}
          </span>
        </td>
      );
    }
    return rows;
  }

  buildForum(asuudalid) {
    var rows = [];
    rows.push(
      <td key="999">
        <span style={{ width: 350 }}>
          Forum {asuudalid}
        </span>
      </td>
    );
    return rows;
  }

  buildForumM() {
    var rows = [];
    rows.push(<ExcelColumn key="999" label="Хэлэлцүүлэг" value="Forum" />);
    return rows;
  }

  buildERowM(numrows) {
    var rows = [];
    for (var i = 0; i < numrows; i++) {
      var fieldName = "ersdel" + (i + 1);
      var colName = "Эрсдэл-" + (i + 1);
      rows.push(<ExcelColumn key={i} label={colName} value={fieldName} />);
    }
    return rows;
  }

  buildZRowM(numrows) {
    var rows = [];
    for (var i = 0; i < numrows; i++) {
      var fieldName = "zuvlumj" + (i + 1);
      var colName = "Зөвлөмж-" + (i + 1);
      rows.push(<ExcelColumn key={i} label={colName} value={fieldName} />);
    }
    return rows;
  }

  render() {
    const {
      loading,
      array,
      activeTab,
      activemaintab,
      report2,
      maxErsdel,
      maxZuvlumj,
      changecount, bodits
    } = this.state;


    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({
                active: activemaintab === "1",
              })}
              style={activemaintab === "1" ? { borderColor: "#009549", borderBottomColor: "white" } : {}}
              onClick={() => {
                this.toggleMain("1");
              }}
            >
              Тайлан-1
            </NavLink>
          </NavItem>
          {array.length > 0 ? (
            <NavItem>
              <NavLink
                className={classNames({
                  active: activemaintab === "2",
                })}
                style={activemaintab === "2" ? { borderColor: "#009549", borderBottomColor: "white" } : {}}
                onClick={() => {
                  this.toggleMain("2");
                  this.setAuditReport();
                }}
              >
                Тайлан-2
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent
          activeTab={activemaintab}
          style={{ padding: 20, border: "1px solid #009549" }}
        >
          <TabPane tabId="1">
            <div>
              {loading ? (
                <Spinner />
              ) : isNullOrEmpty(array) ? null : (
                <div>
                  <div>
                    <Nav tabs>
                      {array.map((item, index) => {
                        return (
                          <NavItem key={index}>
                            <NavLink
                              className={classNames({
                                active: activeTab === index,
                              })}
                              onClick={() => {
                                this.toggle(index);
                              }}
                            >
                              {item.code.code}
                            </NavLink>
                          </NavItem>
                        );
                      })}

                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {array.map((item, index) => {
                        return (
                          <TabPane tabId={index} key={index}>
                            {item.data.map((itee, jindex) => {
                              return (
                                <div key={jindex}>
                                  <FormGroup check style={{ marginBottom: 10 }}>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        checked={itee[2]}
                                        onChange={(e) =>
                                          this.change(index, jindex)
                                        }
                                      />{" "}
                                      <span>{itee[0].value}</span> :
                                    </Label>
                                    <span style={{ paddingLeft: 20 }}>
                                      {itee[1]}
                                    </span>
                                  </FormGroup>
                                </div>
                              );
                            })}
                          </TabPane>
                        );
                      })}
                    </TabContent>
                  </div>
                  <div style={{
                    width: "100%",
                  }}>
                    {activemaintab == 1 ? <div>{array.length > 0 ? <ExcelWorkBook data={array} count={changecount} /> : null}</div> : null}
                  </div>
                </div>
              )}
            </div>

          </TabPane>
          <TabPane tabId="2">
            {isNullOrEmpty(report2) ? null : activemaintab == 2 ? <ExcelWorkBook2 data={report2} count={changecount} bodits={bodits} maxErsdel={maxErsdel} maxZuvlumj={maxZuvlumj} /> : null}
            {/* <div className="table-responsive">
              <div className="scroll">
                <Table>
                  <thead>
                    <tr>
                      <th>Код</th>
                      <th>Асуудал</th>
                      <th>Эрсдэл</th>
                      <th>Данс</th>
                      <th>Алдааны дүн</th>
                      <th>Бодит байдал</th>
                      <th>Батламж төрөл</th>
                      {this.buildERow(maxErsdel)}
                      {this.buildZRow(maxZuvlumj)}
                      <th>Хэлэлцүүлэг</th>
                    </tr>
                  </thead>
                  <tbody>

                    {isNullOrEmpty(report2)
                      ? null
                      : report2.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.code}</td>
                            <td>
                              <span style={{ width: 350, display: "block" }}>
                                {item.asuudal}
                              </span>
                            </td>
                            <td>{item.dpname}</td>
                            <td>
                              <span style={{ width: 150, display: "block" }}>
                                {item.dname}
                              </span>
                            </td>
                            <td>{moneyFormat(item.dun, "MNT")}</td>
                            <td>
                              <span style={{ width: 350, display: "block" }}>
                                {item.bodit}
                              </span>
                            </td>
                            <td>
                              <span style={{ width: 150, display: "block" }}>
                                {item.batlamj_type}
                              </span>
                            </td>
                            {this.buildERowD(maxErsdel, item)}
                            {this.buildZRowD(maxZuvlumj, item)}
                            <td>
                              <span style={{ width: 350, display: "block" }}>
                                {item.forum}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div> */}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default ProjectAsuudalEXCELFunc;
