import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  IndustryList,
  IndustryDelete,
  setPageTitle, IndustryAdd
} from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { IndustryListReq, IndustryAddReq, BaseRequest } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Spinner, Row, Col, Input, Label, FormGroup, Table, FormFeedback
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { isNullOrEmpty } from "../../components/Utils/Utils";
class IndustryListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.addSuccess = this.addSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0,
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      isSave: false,
      validate: [],

      parent_id: isNullOrEmpty(this.props.match.params.id) ? "" : this.props.match.params.id,
      pname: isNullOrEmpty(this.props.match.params.pname) ? "" : this.props.match.params.pname,
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false, //serverees aldaa garsan eseh iltgegch
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("Type.Industry.Title"));
    this.getList();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  delete(row) {
    IndustryDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }
  //buh jagsaaltiig serverees avch ireh function ehlel
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { name, sizePerPage, PID, parent_id } = this.state;
    var req = IndustryListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.name = name;
    req.filter.parent_id = parent_id;
    IndustryList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  onSuccess(response) {
    this.setState({
      loading: false,
      data: response.data, //!!!!!
      PTotal: response.pagination.ptotal,
    });
  }
  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error, isSave: false }); //amjiltgui uid aldaanii msg-g onooj bn.
  }
  //serveriin huselt amjiltgui uid haalt

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
      $(".app-header").height() -
      otherHeight -
      filterHeight
    );
  }

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate = () => {
    var res = { retType: 0 };
    const { name, validate } = this.state;
    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }
    this.setState({ validate });
    return res;
  }

  save = () => {
    const { name, parent_id } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = IndustryAddReq;
      req.name = name;
      req.parent_id = parent_id;
      IndustryAdd(req, this.addSuccess, this.onFailed, "POST");
    }
  }

  addSuccess = (text) => {
    this.setState({ isSave: false, name: "" })
    this.getList();
    return toast.success(text);
  }

  submit(row) {
    const { t } = this.state;
    confirmAlert({
      title: row.name + " " + t("common:delete"),
      message: t("common:confirmdelete"),
      buttons: [
        {
          label: t("common:yes"),
          onClick: () => this.delete(row),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }

  customRowFormat = (row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row);
          }}
        >
          <i className="fa fa-trash" /> {t("common:delete")}
        </Button>
      </div>
    );
    return edit;
  };

  render() {
    const { t, apierror, apierrormsg, data, loading, name, pname, validate } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            {pname}
            <div className="float-right">
              <Link to={`/industry`}>
                <button
                  className="btn btn-sm btn-secondary"
                >
                  {t("common:back")}
                </button></Link>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <div style={{ marginBottom: 20 }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Type.Industry.AddTusgai")}</Label>
                        <Input
                          type="text"
                          name="name"
                          value={name}
                          onChange={(event) => {
                            this.handleChange(event);
                            this.setValidate("name", event);
                          }}
                          invalid={validate["name"]}
                        />
                        <FormFeedback>{t("common:InputValid")}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col style={{ marginTop: 25 }}>
                      <Button
                        color="primary"
                        onClick={this.save}
                        disabled={this.state.isSave}
                      >
                        {t("common:save")}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <Table>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>{t("Type.Industry.AddTusgai")}</th>
                      <th style={{width:150}}></th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody> <tr><td colSpan="3"><Spinner /></td></tr></tbody>
                  ) : (<tbody>{data.map((item, index) => {
                    return <tr>
                      <td>{index+1}</td>
                      <td>{item.name}</td>
                      <td>{this.customRowFormat(item)}</td>
                    </tr>
                  })} </tbody>
                  )}</Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(IndustryListFunc);
