import React, { Component } from 'react'
import { PrivilegeAdd, GetUSerPrivile, UpdateMPriv} from '../../../services/main'
import { PrivilegeAddReq, BaseRequest } from '../../../services/Request'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  InputGroup
} from 'reactstrap'
import { ModalManager } from 'react-dynamic-modal'
class AddPriv extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.save = this.save.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onFailed = this.onFailed.bind(this)
    this.isValidate = this.isValidate.bind(this)
    this.fetchPriv = this.fetchPriv.bind(this)
    this.onFetchSuccess = this.onFetchSuccess.bind(this)
    this.onFetchFailed = this.onFetchFailed.bind(this)
    this.state = {
      loading: true,
      apierror: false,
      apierrormsg: '',
      t: this.props.t,
      privNo: '',
      privName: ''
    }
  }

  componentDidMount(){
    if (this.props.type != 'new') this.fetchPriv()
  }

  fetchPriv(){
    GetUSerPrivile(this.props.type.PrivNo, BaseRequest, this.onFetchSuccess, this.onFetchFailed,"GET");
  }

  onFetchSuccess(response){
    this.setState({
      privNo: response.mPriv.PrivNo,
      privName : response.mPriv.PrivName
    })
  }

  onFetchFailed(error){
    this.setState({ loading: false, apierror: true, apierrormsg: error })
  }

  isValidate () {
    var validate = false
    const { privNo,privName } = this.state
    if (privNo == '' || privName == '') {
      validate = true
      this.setState({
        apierrormsg: 'Please insert * required fields',
        apierror: true
      })
    } else {
      this.setState({ apierror: false })
    }

    return validate
  }
  handleChange (e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  save () {
    if (!this.isValidate()) {
      const { privName, privNo } = this.state
      var reqq=PrivilegeAddReq;
      var mPriv=reqq.mPriv;
      mPriv.privName=privName;


      if(this.props.type == "new"){
        mPriv.privNo=privNo;
        reqq.mPriv=mPriv;
        PrivilegeAdd(reqq, this.onSuccess, this.onFailed,"POST")
      }

      else{
        mPriv.privNo=this.props.type.PrivNo;
        reqq.mPriv=mPriv;
        UpdateMPriv(reqq, this.onSuccess, this.onFailed,"PUT")
      }
    }
  }
  onSuccess (response) {
    const { t } = this.state;
    ModalManager.close();
    if(this.props.type != "new"){
      this.props.success(t('common:SuccessEdit'));
    }
    else{
      this.props.success(t('common:SuccessSave'));
    }
  }
  onFailed (error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error })
  }
  render () {
    const { privNo, privName, t } = this.state
    var checkType = true

    if(this.props.type == "new"){
      checkType = false
    }
    return (
      <div className='animated fadeIn'>
        <Form action='' method='post' className='form-horizontal'>
          <FormGroup row>
            <Col md='12'>
              <Label htmlFor='privNo'>
              {t("privNo")} <span className='reqSymbol'>*</span>{' '}
              </Label>
                <Input
                  type='text'
                  name='privNo'
                  id='privNo'
                  value={privNo}
                  disabled={checkType}
                  onChange={this.handleChange}
                />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md='12'>
              <Label htmlFor='privName'>
              {t("privName")} <span className='reqSymbol'>*</span>
              </Label>
                <Input
                  name='privName'
                  id='privName'
                  value={privName}
                  onChange={this.handleChange}
                />
            </Col>
          </FormGroup>

          <Row className='row-container'>
            <Col md={6} className='req'>
              (<span className='reqSymbol'>*</span>) Required fields
            </Col>
            <Col md={6} className='text-right'>
              <Button
                className='searchBtn custom'
                type='button'
                color='danger'
                onClick={this.save}
              >
                <i className='fa fa-check' /> {t("common:save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default AddPriv
