import React, { useEffect, useRef, useState } from "react";
import {
  Button, Col, FormFeedback, FormGroup, Input, Label, Row, Table,
} from "reactstrap";
import { toast } from "react-toastify";
import { ProjectSendEmail } from "../../../../services/main";
import { dateFormat, formatDate, isNullOrEmpty } from "../../../../components/Utils/Utils";
import { ModalManager } from "react-dynamic-modal/lib/Modal";
import { ProjectSendEmailReq } from "../../../../services/Request";
import { toPng } from "html-to-image";
const SendEmail = (props) => {
  const t = props.t
  const data = props.data
  const selectedfile = props.selectedfile
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(props?.item);
  const [sender, setSender] = useState('')
  const [validate, setValidate] = useState([])
  const [count, setCount] = useState(0)
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  var date = new Date()
  useEffect(() => {
    if (!isNullOrEmpty(props?.item)) {
      setItem(props?.item)
    }

  }, [props?.item]);

  console.log(item)

  const save = (image, image2) => {
    var res = validateFunc()
    if (res.retType === 0) {
      var req = ProjectSendEmailReq
      req.project.email = sender
      req.project.customer_id = item?.customer_id
      req.project.project_id = item?.id
      req.project.title = "Magic Аудитын захидал"
      req.project.message = 'Аудитын захидал хүлээн авч байгаа харилцагч танд энэ өдрийн мэндийг хүргэе. Аттач хасвалтаар илгээж байна.'
      req.project.attach = image
      req.project.attach2 = image2
      ProjectSendEmail(req, onSuccess, onFailed, 'POST')
    }
    else {
      setLoading(false)
      return toast.warning(t("common:InputValid"))
    }
  }

  const onSuccess = (response) => {
    setLoading(false)
    props.addSuccess(t("common:Success"));
    ModalManager.close();
  }

  const onFailed = (error) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateFunc = () => {
    var res = { retType: 0 }
    if (isNullOrEmpty(sender)) {
      res.retType = 1
      validate['sender'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const htmlToImageConvert = () => {
    setLoading(true)
    toPng(elementRef.current, { cacheBust: false })
      .then((data) => {
        toPng(elementRef2.current, { cacheBust: false })
          .then((data2) => {
            save(data, data2)
          })
          .catch((err) => {
            setLoading(false)
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  };

  return (
    <div className="customer-project-files">
      <Row style={{ width: 400 }}>
        <Col>
          <FormGroup>
            <Label>{t("main:Project.files.sender")}</Label>
            <Input
              type="text"
              className="input-field"
              onChange={(e) => {
                setSender(e.target.value)
              }}
              value={sender}
              invalid={validate["sender"]}
            />
            <FormFeedback>{t("common:SelectValid")}</FormFeedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup style={{ paddingTop: 26 }}>
            <Button type="button" onClick={htmlToImageConvert} color="info" disabled={loading}>{loading ? t("common:loading") : t("main:Project.files.sendEmail")}</Button>
          </FormGroup>
        </Col>
      </Row>
      <div style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center", justifyContent: "center", borderTop: "1px solid #e3e3e3",fontSize:13 }}>
        <div ref={elementRef} style={{ width: "960px", padding: 40, backgroundColor: "white" }} >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/audit_logo.png" style={{ width: "160px", height: "40px", marginLeft: 45 }} />
              <div>
                <p style={{ textAlign: "end", lineHeight: "16px" }}>{item?.dname}-ИЙН</p>
                <p style={{ marginTop: "-10px" }}>ХУВЬЦАА ЭЗЭМШИГЧДЭД</p>
              </div>
            </div>
            <div style={{ width: "260px", marginTop: "-10px" }}>
              <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: 12 }}>МЭЖИК КОНСАЛТИНГ АУДИТ ХХК </h1>
              <p style={{
                textAlign: 'center',
                paddingInline: "18px",
                fontSize: "x-small",
                lineHeight: "12px"
              }}>
                Улаанбаатар хот, Баянзүрх дүүрэг, 25-р хороо, Манлайбаатар
                Дамдинсүрэнгийн гудамж, UB tower, 15 давхар, 1502 тоот, Утас:
                72887288, Мэйл: info@magicgroup.mn, Вэб: www.magicgroup.mn
              </p>

              <div style={{ marginTop: "12px", borderTop: "1px solid #808080", width: "100%", backgroundColor: "rgb(242, 242, 242)" }}>
                <p style={{
                  textAlign: 'center',
                  fontSize: "11px",
                  paddingTop: "4px",
                  display: "flex",
                  justifyContent: "center"
                }}>{dateFormat(item?.audit_date)} <div style={{ borderLeft: "1px solid #808080", paddingLeft: 10, borderRight: "1px solid #808080", paddingRight: 10, marginRight: 10, marginLeft: 10 }}>дугаар</div> {item?.audit_no}	</p>
              </div>
            </div>
          </div>
          <h1 style={{ fontSize: 12, fontWeight: 600, marginBottom: 30, marginLeft: 20 }}>АУДИТЫН ГЭРЭЭТ АЖЛЫН ЗАХИДАЛ</h1>
          <div style={{ display: "flex", flexDirection: "column", gap: 5, textAlign: "justify" }}>
            <p>Та бүхний энэ өдрийн амар амгаланг айлтгая.
            </p><p>Аудитын тухай хууль болон танай байгууллагатай байгуулсан аудитын үйлчилгээ үзүүлэх {formatDate(date)}-ний өдрийн {item?.audit_no} тоот гэрээний дагуу ААА ХХК-ийн {item?.report_year} оны 12 сарын 31-ний өдрөөрх санхүүгийн байдал, орлогын дэлгэрэнгүй тайлан, эздийн өмчийн өөрчлөлт, мөнгөн гүйлгээний тайлан, тодруулгад аудит хийж гүйцэтгэх болсныг үүгээр уламжилж байна.
            </p><p>Үйлчлүүлэгч нь үр ашиг бүхий нягтлан бодох бүртгэл, дотоод хяналтыг зохион байгуулж, {item?.report_year} оны 12 сарын 31-ний өдрөөрх жилийн санхүүгийн тайлангуудыг материаллаг алдаагүй, Нягтлан бодох бүртгэлийн тухай хууль, Санхүүгийн тайлагналын олон улсын стандарт, Сангийн сайдын баталсан журам, зааврын дагуу үнэн зөв, шударга толилуулах, үүрэгтэй.
            </p><p> Санхүүгийн тайлан нь Монгол улсын Нягтлан бодох бүртгэлийн тухай хууль, Санхүүгийн тайлагналын олон улсын стандарт /СТОУС/ түүнд нийцүүлэн эрх бүхий байгууллагаас баталсан нягтлан бодох бүртгэлийн бодлого, заавар, журмын дагуу илэрхийлэгдсэн буюу залилан эсвэл алдаанаас үүссэн материаллаг буруу илэрхийллээс бүхэлдээ ангид эсэх талаарх үндэслэлтэй баталгаажуулалт олж авах, аудиторын дүгнэлт бүхий тайлан гаргах нь энэхүү аудитын зорилго юм.
            </p><p>Бид аудитаа АОУС-ын зарчимд нийцүүлэн, урьдчилсан төлөвлөгөө хөтөлбөрийн дагуу явуулна. Уг стандартад зааснаар байгууллагын дотоод хяналтын системийг судалж, нягтлан бодох бүртгэл хөтлөх, санхүүгийн тайланг бэлтгэхэд баримталсан бодлого зарчмуудыг үнэлэж, санхүүгийн тайлан тодруулгад тусгагдсан мэдээллийн талаар хангалттай ба зохистой нотлох зүйлүүдийг олж авахын тулд сонголтот түүвэр шалгалт ба аудитын сорил, горимуудыг хэрэгжүүлж, удирдлагын шийдвэр, үлэмж их хэмжээний дүн бүхий тооцоолол, онцлог үйл явдлуудын бүртгэл ба тайлагналыг тусгайлан авч үзэх болно.
            </p><p>Бид аудитыг АОУС-ын дагуу төлөвлөж, гүйцэтгэсэн ч гэсэн аудитын эрсдэл, түүнчлэн дотоод хяналтын системийн уламжлалт хязгаарлалтуудаас шалтгаалж, зарим материаллаг зөрчил, залилангийн асуудлыг илрүүлэхгүй байх эрсдэл байхыг үгүйсгэхгүй. Бид ийм алдаа гаргахгүй байхыг эрхэмлэн, тодруулах шаардлагатай болон материаллаг алдаатай мэдээлэл, бусад зүй бус илрүүлэлтийн хувьд холбогдох тайлбар танилцуулгыг та бүхнээс тухай бүрд нь авах болно.
            </p><p>Санхүүгийн тайланд хийсэн аудитаар илэрсэн алдаа, зөрчил, аудиторын гаргасан шийдлийг асуудлын бүртгэлээр танилцуулж, аудитын тайлан, дүгнэлт, түүнчлэн удирдлагын анхааралд хүргэвэл зохих асуудлыг багтаасан зөвлөмжийг хүргүүлнэ.
            </p><p>Аудитын ажлын явцад бидний бэлтгэсэн ажлын болон нотлох баримтууд нь манай өмч бөгөөд шалгагдагч байгууллага, хувь хүний нууцад хамаарах мэдээлэл баримт бичигтэй танилцсан манай компанийн аудиторууд уг мэдээллийн нууцыг чандлан хадгалах болно.
            </p><p>Аудитыг тогтоосон хугацаанд чанартай хийж гүйцэтгэхэд харилцан хүлээсэн үүргээ биелүүлж, аудитаар шаардсан баримт материал, бусад мэдээллийг цаг хугацаа алдахгүй шуурхай гарган өгч, хамтран ажиллана гэдэгт итгэлтэй байна.
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 60, marginLeft: 100 }}>
            <p>С.МӨНХБАЯР</p>
            <div style={{ borderTop: "1px solid green", width: 250, marginTop: "-15px" }}>
              <p>ГҮЙЦЭТГЭХ ЗАХИРАЛ, ПАРТНЕР	</p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", marginTop: "10px", alignItems: "flex-end" }}>
            <p>{item?.audit_no}</p>
          </div>
        </div>
        <div style={{ borderTop: "1px solid #e3e3e3", marginBottom: 20, width: "100%" }} />
        <div ref={elementRef2} style={{ display: "flex", flexDirection: "column", width: "960px", padding: 40, backgroundColor: "white" }}>
          <p style={{ textAlign: "end", width: "100%" }}>Хавсралт-1</p>
          <div style={{
            marginTop: "20px",
            display: "flex"
          }}>
            <div style={{ width: "350px" }}>
              <tr>
                <span
                  style={{
                    color: "rgb(192, 0, 0)",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Үйлчлүүлэгч:
                </span>
                <span>{item?.dname}</span>
              </tr>
              <tr>
                <span
                  style={{
                    color: "rgb(192, 0, 0)",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Тайлант хугацаа:
                </span>
                <span>{dateFormat(item?.audit_date)}</span>
                {/* <span>{item?.report_year} оны 12 сарын 31</span> */}
              </tr>
              <tr>
                <span
                  style={{
                    color: "rgb(192, 0, 0)",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Аудитын чиглэл:
                </span>
                <span>{item?.type}</span>
              </tr>
            </div>
            <div style={{ width: "400px" }}>
              <tr>
                <span
                  style={{
                    color: "rgb(192, 0, 0)",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Код:
                </span>
                <span>{item?.audit_no}</span>
              </tr>
              <tr>
                <span
                  style={{
                    color: "rgb(192, 0, 0)",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Бэлтгэсэн:
                </span>
                <span>{props.workuser}</span>
              </tr>
              <tr>
                <span
                  style={{
                    color: "rgb(192, 0, 0)",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Огноо:
                </span>
                <span>{formatDate(date)}</span>
              </tr>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 5, marginTop: 20, textAlign: "justify" }}>
            <p>
              Аудитын тухай хуулийн 3 дугаар зүйлийн 3.1.14-т “ажлын баримт” гэж аудитын үйл ажиллагаа явуулах явцад аудитор өөрөө бэлтгэсэн, цуглуулсан санхүүгийн баримт, бүртгэл, тооцоо, тайлан, судалгаа шинжилгээний материал, уулзалтын тэмдэглэл, холбогдох этгээдээс авсан мэдээлэл зэрэг аудитын болон чанарын хяналтын олон улсын стандартын дагуу бүрдүүлсэн баримт бичгийг” хэлнэ гэж заасан байдаг.
            </p>
            <p style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <b>Мөн хуулийн 14 дүгээр зүйлд:</b>
              <span>14.2.1.холбогдох олон улсын стандартад нийцүүлэн гаргасан санхүүгийн тайланг аудиторт шалгуулахаар хүлээлгэн өгөх;
              </span><span>14.2.2.аудитын үйл ажиллагаа явуулах нөхцөл бололцоогоор хангах;
              </span><span>14.2.3.аудиторын шаардсан баримт, мэдээллийг хугацаанд нь гаргаж өгөх;
              </span><span>14.2.4.байгууллагын аж ахуй, санхүүгийн үйл ажиллагааг нягтлан бодох бүртгэл, тайланд стандартын дагуу зөв тусгасан тухай, мөн эдгээртэй холбогдох баримт, материал, тайлбарыг бүрэн танилцуулсан, энэ талаар үүсч болох хариуцлагыг хүлээх тухайгаа нотолсон тодорхойлолтыг аудиторт гаргаж өгөх;
              </span><span>14.2.5.шалгалтын явцад илэрсэн хүлээн зөвшөөрсөн алдаа, зөрчлийг шуурхай залруулах;
              </span><span>14.2.6.аудитын үйл ажиллагаанд саад учруулахгүй, хөндлөнгөөс оролцохгүй, дарамт шахалт үзүүлэхгүй байх;
              </span><span> 14.2.7.аудиторын хүссэн шаардлагатай нотлох баримтыг гуравдагч этгээдээс гаргуулж өгөх;
                гэж тус тус заасны дагуу аудиторын зүгээс харилцагчаас доорх мэдээллийг бэлтгэж ирүүлэх хүсэлт гаргаж байна.
              </span>
            </p>
          </div>

          <div style={{ backgroundColor: "white", marginTop: 10, marginBottom: 30 }}>
            <Table style={{ fontSize: 13 }}>
              <thead>
                <th style={{ width: 30 }}>№</th>
                <th>{t("main:Project.files.sendfiles")}</th>
                <th>{t("main:Project.files.status.title")}</th>
              </thead>
              <tbody>
                {data.map((ditem, dindex) => {
                  return <tr key={dindex}>
                    <td style={{ width: 30 }}>{dindex + 1}</td>
                    <td>{t("common:magicnum.filesInfoSub." + ditem.parentcat_id + "-" + ditem.cat_id)}</td>
                    <td>
                      <div className="action" style={{ padding: 5, display: "flex", gap: 5 }}>
                        <Button
                          color={ditem.status == 1 ? "info" : ditem.status == 2 ? "warning" : ditem.status == 3 ? "success" : "danger"}
                          style={{ display: "flex", gap: 5, alignItems: "center", fontSize: 13, padding: "1px 5px", cursor: "default" }}
                        >
                          {t("main:Project.files.status." + ditem.status)}
                        </Button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
          </div>
        </div >
      </div>
    </div >
  );
};

export default SendEmail;
