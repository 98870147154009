import React, { Component } from "react";
import {
  PrivilegeGroupAdd,
  PrivilegeGroupGet,
  PrivilegeGroupUpdate
} from "../../../services/main";
import { PrivilegeGroupAddReq, BaseRequest } from "../../../services/Request";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  InputGroup
} from "reactstrap";
import { ModalManager } from "react-dynamic-modal";
class AddPrivGroup extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.isValidate = this.isValidate.bind(this);
    this.fetchPrivGroup = this.fetchPrivGroup.bind(this);
    this.fetchPrivGroupSuccess = this.fetchPrivGroupSuccess.bind(this);
    this.state = {
      loading: true,
      privGroupId: "",
      privGroupName: "",
      IsUserGroup: "N",
      t: this.props.t
    };
  }

  componentDidMount() {
    if (this.props.type != "new") this.fetchPrivGroup();
  }

  fetchPrivGroup() {
    PrivilegeGroupGet(
      this.props.type,
      BaseRequest,
      this.fetchPrivGroupSuccess,
      this.onFailed,
      "GET"
    );
  }

  fetchPrivGroupSuccess(response) {
    this.setState({
      privGroupId: response.mPriv_Group.PrivGroupID,
      privGroupName: response.mPriv_Group.PrivGroupName,
      IsUserGroup: response.mPriv_Group.IsUserGroup
    });
  }

  isValidate() {
    var validate = false;
    const { privGroupName, privGroupId } = this.state;
    if (privGroupName == "" || privGroupId == "") {
      validate = true;
      this.setState({
        apierrormsg: "Please insert * required fields",
        apierror: true
      });
    } else {
      this.setState({ apierror: false });
    }

    return validate;
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    if (!this.isValidate()) {
      const { IsUserGroup, privGroupName, privGroupId } = this.state;
      var reqq = PrivilegeGroupAddReq;
      var mPriv_Group = reqq.mPriv_Group;
      mPriv_Group.isUserGroup = IsUserGroup;
      mPriv_Group.privGroupName = privGroupName;

      if (this.props.type == "new") {
        mPriv_Group.privGroupId = privGroupId;
        reqq.mPriv_Group = mPriv_Group;
        PrivilegeGroupAdd(reqq, this.onSuccess, this.onFailed, "POST");
      } else {
        mPriv_Group.privGroupId = this.props.type;
        reqq.mPriv_Group = mPriv_Group;
        PrivilegeGroupUpdate(reqq, this.onSuccess, this.onFailed, "PUT");
      }
    }
  }
  onSuccess(response) {
    ModalManager.close();
    if (this.props.type == "new") {
      this.props.success("Privilege Group added");
    } else {
      this.props.success("Privilege Group edited");
    }
  }
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }
  render() {
    const { privGroupId, privGroupName, IsUserGroup, t } = this.state;
    var checkType = true;

    if (this.props.type == "new") {
      checkType = false;
    }

    return (
      <div className="animated fadeIn">
        <Form action="" method="post" className="form-horizontal">
          <Row>
            <Col>
              <Label htmlFor="privGroupId">
                {this.state.t("privNo")} <span className="reqSymbol">*</span>{" "}
              </Label>
              <Input
                name="privGroupId"
                id="privGroupId"
                value={privGroupId}
                onChange={this.handleChange}
                disabled={checkType}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label htmlFor="privGroupName">
                {this.state.t("privName")} <span className="reqSymbol">*</span>
              </Label>
              <Input
                type="text"
                name="privGroupName"
                id="privGroupName"
                value={privGroupName}
                onChange={this.handleChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label htmlFor="IsUserGroup">
                Is User Group <span className="reqSymbol">*</span>
              </Label>
              <Input
                type="select"
                name="IsUserGroup"
                id="IsUserGroup"
                value={IsUserGroup}
                onChange={this.handleChange}
              >
                <option value="N">{t("common:no")}</option>
                <option value="Y">{t("common:yes")}</option>
              </Input>
            </Col>
          </Row>
          <br />
          <br />
          <Row className="row-container">
            <Col md={6} className="req">
              (<span className="reqSymbol">*</span>) Required fields
            </Col>
            <Col md={6} className="text-right">
              <Button
                className="searchBtn custom"
                type="button"
                color="danger"
                onClick={this.save}
              >
                <i className="fa fa-check" /> {t("common:save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default AddPrivGroup;
