import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  DataAsuudalAddReq,
  DataAsuudalUpdateReq,
  TypeDansListReq,
  TypeNotolgooListReq,
  IndustryListReq,
} from "../../services/Request";
import {
  DataAsuudalUpdate,
  DataAsuudalAdd,
  TypeDansList,
  TypeNotolgooList,
  socket,
  IndustryList,
} from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { Multiselect } from "multiselect-react-dropdown";
import { isNullOrEmpty } from "../../components/Utils/Utils";

class DataAsuudalAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      industryData: props.industryData,
      name: "",
      dans_id: "",
      batlamj: "",
      is_confirm: 0,
      confirm_at: null,
      confirm_by: "",
      cancel_at: "",
      cancel_type: "",
      cancel_by: "",
      risk_type: "",
      notolgoo_id: "",
      batlamj_type: "",
      deleted_at: "",
      deleted_by: "",
      id: "",
      bodit: "",
      status: magicnum.Status.Active,
      gorim_sheet: "",
      gorim_tailbar: "",
      validate: [],
      item: props.item,
      isSave: false,
      loadingTypeDans: false,
      dataTypeDans: [],
      loadingTypeNotolgoo: false,
      dataTypeNotolgoo: [],
      batlamjArray: null,
      plcBatlamjType: this.props.t("common:choose"),
      plcNotolgoo: this.props.t("common:choose"),
      plcDans: this.props.t("common:choose"),
      tusgai_zovshoorol: "",
      ua_chiglel: "",
      priv_level: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.geTypeDanstList = this.geTypeDanstList.bind(this);
    this.onTypeDansSuccess = this.onTypeDansSuccess.bind(this);
    this.geTypeNotolgooList = this.geTypeNotolgooList.bind(this);
    this.onTypeNotolgooSuccess = this.onTypeNotolgooSuccess.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    this.geTypeDanstList();
    this.geTypeNotolgooList();
    this.setBatLamjType();
    //this.getIndustyList();
    if (item !== null) {
      this.setState({
        name: item.name,
        dans_id: item.dans_id,
        batlamj: item.batlamj,
        is_confirm: item.is_confirm,
        confirm_at: item.confirm_at,
        confirm_by: item.confirm_by,
        cancel_at: item.cancel_at,
        cancel_type: item.cancel_type,
        cancel_by: item.cancel_by,
        risk_type: item.risk_type,
        bodit: item.bodit,
        notolgoo_id: item.notolgoo_id,
        deleted_at: item.deleted_at,
        deleted_by: item.deleted_by,
        status: item.status,
        gorim_sheet: item.gorim_sheet,
        gorim_tailbar: item.gorim_tailbar,
        id: item.id,
        batlamj_type: item.batlamj_type,
        plcBatlamjType: item.batlamj_type,
        ua_chiglel: item.ua_chiglel,
        tusgai_zovshoorol: item.tusgai_zovshoorol,
        priv_level: item.priv_level,
      });
    }
  }

  getIndustyList = () => {
    if (!this.state.industryLoading) {
      this.setState({ industryLoading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { name, sizePerPage, PID } = this.state;
    var req = IndustryListReq; // !!!!!
    req.pid = 1;
    req.pSize = 1000;
    IndustryList(req, this.onIndustySuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  };

  onIndustySuccess = (response) => {
    this.setState({
      industryLoading: false,
      industryData: response.data,
    });
  };

  setBatLamjType = () => {
    var arry = [];
    magicnum.Batlamj.map((item) => {
      arry.push({ name: item });
    });
    this.setState({ batlamjArray: arry });
  };

  geTypeDanstList() {
    if (!this.state.loadingTypeDans) {
      this.setState({ loadingTypeDans: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = TypeDansListReq;
    req.pid = 1;
    req.pSize = 1000;
    TypeDansList(req, this.onTypeDansSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onTypeDansSuccess(response) {
    const { item } = this.state;
    var dd = []
    var text = this.state.plcDans;
    response?.data?.map(((ditem, dindex) => {
      if (!isNullOrEmpty(item)) {
        if (dindex == 0) { text = ""; }
        var dansArray = item.dans_id.toString().split(":");
        dansArray.map((im) => {
          if (ditem.id == im) {
            if (!isNullOrEmpty(text)) {
              text += ":";
            }
            text += ditem.name;
          }
        });
      }
      dd.push({ ...ditem, name: ditem.name + " -(" + ditem.parent + ")" })
    }))
    this.setState({
      loadingTypeDans: false,
      dataTypeDans: dd,
      plcDans: text
    });
  }

  geTypeNotolgooList() {
    if (!this.state.loadingTypeNotolgoo) {
      this.setState({ loadingTypeNotolgoo: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = TypeNotolgooListReq;
    req.pid = 1;
    req.pSize = 1000;
    TypeNotolgooList(req, this.onTypeNotolgooSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onTypeNotolgooSuccess(response) {
    if (this.state.item != null) {
      var text = "";
      response.data.map((ig) => {
        var notolgooArray = this.state.item.notolgoo_id.split(":");
        notolgooArray.map((im) => {
          if (ig.id == im) {
            if (!isNullOrEmpty(text)) {
              text += ":";
            }
            text += ig.name;
          }
        });
      });
      this.setState({
        loadingTypeNotolgoo: false,
        dataTypeNotolgoo: response.data,
        plcNotolgoo: text,
      });
    } else {
      this.setState({
        loadingTypeNotolgoo: false,
        dataTypeNotolgoo: response.data,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "ua_chiglel") {
        this.setState({ tusgai_zovshoorol: "" });
      }
    });
  }

  save(version) {
    const {
      t,
      name,
      dans_id,
      is_confirm,
      confirm_at,
      confirm_by,
      cancel_at,
      cancel_type,
      cancel_by,
      risk_type,
      notolgoo_id,
      batlamj_type,
      deleted_at,
      deleted_by,
      bodit,
      status,
      gorim_sheet,
      gorim_tailbar,
      ua_chiglel,
      tusgai_zovshoorol,
      priv_level,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = DataAsuudalAddReq;
      req.asuudal.name = name;
      req.asuudal.is_confirm = is_confirm;
      req.asuudal.confirm_at = confirm_at;
      req.asuudal.confirm_by = confirm_by;
      req.asuudal.cancel_at = cancel_at;
      req.asuudal.cancel_type = cancel_type;
      req.asuudal.cancel_by = cancel_by;
      req.asuudal.risk_type = risk_type;
      req.asuudal.bodit = bodit;
      req.asuudal.deleted_at = deleted_at;
      req.asuudal.deleted_by = deleted_by;
      req.asuudal.status = status;
      req.asuudal.gorim_sheet = gorim_sheet;
      req.asuudal.gorim_tailbar = gorim_tailbar;
      req.asuudal.ua_chiglel = ua_chiglel;
      req.asuudal.tusgai_zovshoorol = isNullOrEmpty(tusgai_zovshoorol) ? null : tusgai_zovshoorol;
      req.asuudal.priv_level = priv_level;
      if (!isNullOrEmpty(version)) {
        if (!isNullOrEmpty(version.version_root)) {
          req.asuudal.version_root = version.version_root;
        } else {
          req.asuudal.version_root = version.id;
        }
        req.asuudal.version_parent = version.id;
      }
      
      if (Array.isArray(batlamj_type)) {
        var ws = "";
        batlamj_type?.map((item) => {
          if (isNullOrEmpty(ws)) {
            ws += ":";
          }
          ws += item.name + ":";
          req.asuudal.batlamj_type = ws;
        });
      } else {
        req.asuudal.batlamj_type = batlamj_type;
      }

      if (Array.isArray(notolgoo_id)) {
        var ws2 = "";
        notolgoo_id?.map((item) => {
          if (isNullOrEmpty(ws2)) {
            ws2 += ":";
          }
          ws2 += item.id + ":";
          req.asuudal.notolgoo_id = ws2;
        });
      } else {
        req.asuudal.notolgoo_id = notolgoo_id;
      }

      if (Array.isArray(dans_id)) {
        var ws3 = "";
        dans_id?.map((item) => {
          if (isNullOrEmpty(ws3)) {
            ws3 += ":";
          }
          ws3 += item.id + ":";
          req.asuudal.dans_id = ws3;
        });
      } else {
        req.asuudal.dans_id = dans_id;
      }

      DataAsuudalAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    socket.emit("dataasuudal", "add");
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const {
      t,
      name,
      dans_id,
      is_confirm,
      confirm_at,
      confirm_by,
      cancel_at,
      cancel_type,
      cancel_by,
      risk_type,
      notolgoo_id,
      batlamj_type,
      deleted_at,
      deleted_by,
      id,
      bodit,
      gorim_sheet,
      gorim_tailbar,
      ua_chiglel,
      tusgai_zovshoorol,
      priv_level,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = DataAsuudalUpdateReq;
      req.asuudal.name = name;
      req.asuudal.dans_id = dans_id;
      req.asuudal.is_confirm = is_confirm;
      req.asuudal.confirm_at = confirm_at;
      req.asuudal.confirm_by = confirm_by;
      req.asuudal.cancel_at = cancel_at;
      req.asuudal.cancel_type = cancel_type;
      req.asuudal.cancel_by = cancel_by;
      req.asuudal.risk_type = risk_type;
      req.asuudal.notolgoo_id = notolgoo_id;
      req.asuudal.batlamj_type = batlamj_type;
      req.asuudal.bodit = bodit;
      req.asuudal.deleted_at = deleted_at;
      req.asuudal.deleted_by = deleted_by;
      req.asuudal.gorim_sheet = gorim_sheet;
      req.asuudal.gorim_tailbar = gorim_tailbar;
      req.asuudal.ua_chiglel = ua_chiglel;
      req.asuudal.tusgai_zovshoorol = tusgai_zovshoorol;
      req.asuudal.priv_level = priv_level;
      req.asuudal.id = id;
      req.type = "update";
      DataAsuudalUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    socket.emit("dataasuudal", "update");
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
      loadingTypeDans: false,
      loadingTypeNotolgoo: false,
    });
  }

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate() {
    var res = { retType: 0 };
    const {
      validate,
      name,
      dans_id,
      risk_type,
      notolgoo_id,
      batlamj_type,
      bodit,
      gorim_sheet,
      gorim_tailbar,
      ua_chiglel,
      tusgai_zovshoorol,
      priv_level,
    } = this.state;
    if (isNullOrEmpty(ua_chiglel)) {
      res.retType = 1;
      validate["ua_chiglel"] = true;
    }
    // if (isNullOrEmpty(tusgai_zovshoorol)) {
    //   res.retType = 1;
    //   validate["tusgai_zovshoorol"] = true;
    // }
    if (isNullOrEmpty(priv_level)) {
      res.retType = 1;
      validate["priv_level"] = true;
    }
    if (isNullOrEmpty(batlamj_type)) {
      res.retType = 1;
      validate["batlamj_type"] = true;
    }
    if (gorim_sheet == "") {
      res.retType = 1;
      validate["gorim_sheet"] = true;
    }
    if (gorim_tailbar == "") {
      res.retType = 1;
      validate["gorim_tailbar"] = true;
    }
    if (dans_id == "") {
      res.retType = 1;
      validate["dans_id"] = true;
    }
    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }
    if (risk_type == "") {
      res.retType = 1;
      validate["risk_type"] = true;
    }
    if (isNullOrEmpty(notolgoo_id)) {
      res.retType = 1;
      validate["notolgoo_id"] = true;
    }
    if (isNullOrEmpty(batlamj_type)) {
      res.retType = 1;
      validate["batlamj_type"] = true;
    }
    // if (bodit == "") {
    //   res.retType = 1;
    //   validate["bodit"] = true;
    // }

    this.setState({ validate });
    return res;
  }

  onSelectBatlamjType = (selectedList, selectedItem) => {
    const { validate } = this.state;
    validate["batlamj_type"] = false;
    this.setState({ batlamj_type: selectedList, validate });
  };

  onRemoveBatlamjType = (selectedList, removedItem) => {
    const { validate } = this.state;
    validate["batlamj_type"] = false;
    this.setState({ batlamj_type: selectedList, validate });
  };

  onSelectNotolgoo = (selectedList, selectedItem) => {
    const { validate } = this.state;
    validate["notolgoo_id"] = false;
    this.setState({ notolgoo_id: selectedList, validate });
  };

  onRemoveNotolgoo = (selectedList, removedItem) => {
    const { validate } = this.state;
    validate["notolgoo_id"] = false;
    this.setState({ notolgoo_id: selectedList, validate });
  };

  onSelectDans = (selectedList, selectedItem) => {
    const { validate } = this.state;
    validate["dans_id"] = false;
    this.setState({ dans_id: selectedList, validate });
  };

  onRemoveDans = (selectedList, removedItem) => {
    const { validate } = this.state;
    validate["dans_id"] = false;
    this.setState({ dans_id: selectedList, validate });
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      name,
      risk_type,
      dataTypeDans,
      dataTypeNotolgoo,
      bodit,
      batlamjArray,
      plcBatlamjType,
      plcNotolgoo,
      plcDans,
      status,
      gorim_tailbar,
      gorim_sheet,
      industryData,
      tusgai_zovshoorol,
      ua_chiglel,
      priv_level,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.name")}</Label>
                <Input
                  type="textarea"
                  name="name"
                  value={name}
                  rows={5}
                  cols={5}
                  onChange={(event) => {
                    this.handleChange(event, "text");
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.dans_id")}</Label>
                {isNullOrEmpty(dataTypeDans) ? (
                  t("common:loading")
                ) : (
                  <Multiselect
                    options={dataTypeDans}
                    onSelect={this.onSelectDans}
                    onRemove={this.onRemoveDans}
                    displayValue="name"
                    placeholder={plcDans}
                  />
                )}
                {validate["dans_id"] ? (
                  <div className="invalid-feedback show">
                    {t("common:SelectValid")}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.risk_type")}</Label>
                <Input
                  type="select"
                  value={risk_type}
                  name="risk_type"
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("risk_type", event);
                  }}
                  invalid={validate["risk_type"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.Data.RiskType.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("common:status")}</Label>
                <Input
                  type="select"
                  name="status"
                  value={status}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("status", event);
                  }}
                  invalid={validate["status"]}
                >
                  <option value={magicnum.Status.Active}>
                    {t("common:magicnum.Status.Active")}
                  </option>
                  <option value={magicnum.Status.InActive}>
                    {t("common:magicnum.Status.InActive")}
                  </option>
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.gorim_sheet")}</Label>
                <Input
                  type="text"
                  name="gorim_sheet"
                  value={gorim_sheet}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("gorim_sheet", event);
                  }}
                  invalid={validate["gorim_sheet"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.ua_chiglel")}</Label>
                <Input
                  type="select"
                  name="ua_chiglel"
                  value={ua_chiglel}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("ua_chiglel", event);
                  }}
                  invalid={validate["ua_chiglel"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {industryData.map((itt, iindex) => {
                    return itt.parent_id == 0 ? (
                      <option key={iindex} value={itt.id}>
                        {itt.name}
                      </option>
                    ) : null;
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.tusgai_zovshoorol")}</Label>
                <Input
                  type="select"
                  name="tusgai_zovshoorol"
                  value={tusgai_zovshoorol}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("tusgai_zovshoorol", event);
                  }}
                  invalid={validate["tusgai_zovshoorol"]}
                >
                  <option value={0}>{t("common:choose")}</option>
                  {industryData.map((itt, iindex) => {
                    return itt.parent_id == ua_chiglel &&
                      !isNullOrEmpty(ua_chiglel) ? (
                      <option key={iindex} value={itt.id}>
                        {itt.name}
                      </option>
                    ) : null;
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.priv_level")}</Label>
                <Input
                  type="select"
                  name="priv_level"
                  value={priv_level}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("priv_level", event);
                  }}
                  invalid={validate["priv_level"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.PrivLevel.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.PrivLevel.${item}`)}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.gorim_tailbar")}</Label>
                <Input
                  type="textarea"
                  name="gorim_tailbar"
                  value={gorim_tailbar}
                  rows={5}
                  cols={5}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("gorim_tailbar", event);
                  }}
                  invalid={validate["gorim_tailbar"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.notolgoo_id")}</Label>
                {isNullOrEmpty(dataTypeNotolgoo) ? (
                  t("common:loading")
                ) : (
                  <Multiselect
                    options={dataTypeNotolgoo}
                    onSelect={this.onSelectNotolgoo}
                    onRemove={this.onRemoveNotolgoo}
                    displayValue="name"
                    placeholder={plcNotolgoo}
                  />
                )}
                {validate["notolgoo_id"] ? (
                  <div className="invalid-feedback show">
                    {t("common:SelectValid")}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.batlamj_type")}</Label>
                {isNullOrEmpty(batlamjArray) ? null : (
                  <Multiselect
                    options={batlamjArray}
                    onSelect={this.onSelectBatlamjType}
                    onRemove={this.onRemoveBatlamjType}
                    displayValue="name"
                    placeholder={plcBatlamjType}
                  />
                )}
                {validate["batlamj_type"] ? (
                  <div className="invalid-feedback show">
                    {t("common:SelectValid")}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Data.Asuudal.bodit")}</Label>
                <Input
                  type="textarea"
                  name="bodit"
                  value={bodit}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("bodit", event);
                  }}
                  invalid={validate["bodit"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              {item != null ?
                <Button
                  style={{ marginRight: 20 }}
                  color="info"
                  outline
                  onClick={() => this.save(item)}
                  disabled={this.state.isSave}
                >
                  {t("common:versoinadd")}
                </Button> : null}
              <Button
                color="primary"
                onClick={() => item != null ? this.Update : this.save(null)}
                disabled={this.state.isSave}
              >
                {item != null ? t("common:update") : t("common:add")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default DataAsuudalAddFunc;
