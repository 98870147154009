export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-bar-chart",
      PrivNo: "menu-dashboard",
    },
    {
      name: "Log",
      url: "/log",
      icon: "fa fa-h-square",
      PrivNo: "menu-log",
      children: [
        {
          name: "LogList",
          url: "/log/success",
          PrivNo: "log-action-POST",
        },
        {
          name: "ErrorLogList",
          url: "/log/error",
          PrivNo: "log-error-POST",
        },
      ],
    },
    {
      name: "Privilege",
      url: "/privilege",
      icon: "fa fa-user",
      PrivNo: "menu-priv",
      children: [
        {
          name: "Employee-List",
          url: "/privilege/employeelist",
          icon: "fa fa-code",
          PrivNo: "menu-priv",
        },
        {
          name: "Privilege-Group",
          icon: "fa fa-code",
          url: "/privilege/privgrouplist",
          PrivNo: "menu-priv",
        },
        {
          name: "Privilege",
          url: "/privilege/privlist",
          icon: "fa fa-code",
          PrivNo: "menu-priv",
        },
      ],
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-gear ",
      PrivNo: "menu-settings",
      children: [
        {
          name: "TypeZurchilList",
          url: "/type/zurchil",
          PrivNo: "typezurchil-list-POST",
        },
        {
          name: "TypeNotolgooList",
          url: "/type/notolgoo",
          PrivNo: "typenotolgoo-list-POST",
        },
        {
          name: "TypeDansList",
          url: "/type/dans",
          PrivNo: "typedans-list-POST",
        },
        {
          name: "IndustryList",
          url: "/industry",
          PrivNo: "industry-list-POST",
        },
      ],
    },
    {
      icon: "fa fa-users",
      name: "CustomerList",
      url: "/customer",
      PrivNo: "customer-list-POST",
      children: [
        {
          name: "CustomerList",
          url: "/customer",
          PrivNo: "customer-list-POST",
        },        
        {
          name: "CustomerUserList",
          url: "/customeruser",
          PrivNo: "customeruser-list-POST",
        },
        {
          name: "Project",
          url: "/project",
          PrivNo: "project-list-POST",
        },
        {
          name: "ProjectEmail",
          url: "/projectemail",
          PrivNo: "project-emaillist-POST",
        },
      ],
    },
    {
      icon: "fa fa-database",
      name: "Data",
      url: "/data",
      PrivNo: "menu-data",
      children: [
        {
          name: "DataAsuudalList",
          url: "/asuudal/1",
          PrivNo: "dataasuudal-list-POST",
        },
        {
          name: "DataZuvlumjList",
          url: "/data/zuvlumj",
          PrivNo: "datazuvlumj-list-POST",
        },
        {
          name: "DataErsdelList",
          url: "/data/ersdel",
          PrivNo: "dataersdel-list-POST",
        },
        {
          name: "DataAsuulgaList",
          url: "/data/asuulga",
          PrivNo: "dataasuulga-list-POST",
        },
        {
          name: "DataAsuudalConnCountList",
          url: "/asuudalcount",
          PrivNo: "dataasuudal-connectioncount-POST",
        },
        {
          name: "DataAsuudalConnProjectCountList",
          url: "/asuudalprojectcount",
          PrivNo: "connectcustomer-listbyconnect-POST",
        },
      ],
    },
  ],
};
