import React, { useCallback, useEffect, useRef, useState } from "react";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import { Sheet } from "@fortune-sheet/core";
import "@fortune-sheet/react/dist/index.css";
import { isNullOrEmpty, moneyFormat } from "../../../components/Utils/Utils";
export const ExcelWorkBook2 = (props: any) => {
  const arr = props.data;
  const bodits = props.bodits;
  const maxErsdel = props.maxErsdel;
  const maxZuvlumj = props.maxZuvlumj;
  const ref = useRef<WorkbookInstance>(null);
  const [data, setData] = useState<Sheet[]>([]);
  console.log(arr);
  useEffect(() => {
    if (arr.length > 0) {
      var arr2 = [];
      setData([]);
      var arr3 = [];
      var obj0 = {
        r: 0,
        c: 1,
        v: {
          v: "Код",
          bl: 1,
        },
      };
      var obj1 = {
        r: 0,
        c: 2,
        v: {
          v: "Асуудал",
          bl: 1,
        },
      };
      var obj2 = {
        r: 0,
        c: 3,
        v: {
          v: "Эрсдэл",
          bl: 1,
        },
      };
      var obj3 = {
        r: 0,
        c: 4,
        v: {
          v: "Данс",
          bl: 1,
        },
      };
      var obj4 = {
        r: 0,
        c: 5,
        v: {
          v: "Алдааны дүн",
          bl: 1,
        },
      };
      var obj5 = {
        r: 0,
        c: 6,
        v: {
          v: "Бодит байдал",
          bl: 1,
        },
      };
      var obj6 = {
        r: 0,
        c: 7,
        v: {
          v: "Батламж төрөл",
          bl: 1,
        },
      };
      var obj7 = {
        r: 0,
        c: 8,
        v: {
          v: "Хэлэлцүүлэг",
          bl: 1,
        },
      };

      arr3.push(obj0);
      arr3.push(obj1);
      arr3.push(obj2);
      arr3.push(obj3);
      arr3.push(obj4);
      arr3.push(obj5);
      arr3.push(obj6);
      arr3.push(obj7);
      var row = 8;
      for (var i = 0; i < maxErsdel; i++) {
        var colName = "Эрсдэл-" + (i + 1);

        var obj8 = {
          r: 0,
          c: row++,
          v: {
            v: colName,
            bl: 1,
          },
        };

        arr3.push(obj8);
      }

      for (var i = 0; i < maxZuvlumj; i++) {
        var colName = "Зөвлөмж-" + (i + 1);
        var obj8 = {
          r: 0,
          c: row++,
          v: {
            v: colName,
            bl: 1,
          },
        };

        arr3.push(obj8);
      }

      var mor = 0;
      arr?.map((item2: any, index2: any) => {
        if (!isNullOrEmpty(item2.code)) {
          mor++;
          var obj2 = {
            r: mor,
            c: 1,
            v: {
              v: item2.code.toString(),
              m: item2.code.toString(),
            },
            ct: {
              fa: "General",
              t: "g",
            },
            ht: 1,
            vt: 1,
          };
          var obj3 = {
            r: mor,
            c: 2,
            v: {
              v: item2.asuudal,
            },
          };
          var obj4 = {
            r: mor,
            c: 3,
            v: {
              v: item2.dpname,
            },
          };
          var obj5 = {
            r: mor,
            c: 4,
            v: {
              v: item2.dname,
            },
          };
          var obj6 = {
            r: mor,
            c: 5,
            v: {
              v: moneyFormat(item2.dun, "MNT"),
            },
          };
          var obj7 = {
            r: mor,
            c: 6,
            v: {
              v: item2.bodit,
            },
          };
          var obj8 = {
            r: mor,
            c: 7,
            v: {
              v: item2.batlamj_type,
            },
          };
          arr3.push(obj2);
          arr3.push(obj3);
          arr3.push(obj4);
          arr3.push(obj5);
          arr3.push(obj6);
          arr3.push(obj7);
          arr3.push(obj8);
          var row = 8;
          for (var i = 0; i < maxErsdel; i++) {
            var fieldName = "ersdel" + (i + 1);
            var obj9 = {
              r: mor,
              c: row++,
              v: {
                v: item2[fieldName],
              },
            };
            arr3.push(obj9);
          }

          for (var i = 0; i < maxZuvlumj; i++) {
            var fieldName = "zuvlumj" + (i + 1);
            var obj9 = {
              r: mor,
              c: row++,
              v: {
                v: item2[fieldName],
              },
            };

            arr3.push(obj9);
          }

          bodits
            ?.filter((ff) => ff.asuudal_id == item2.asuudalid)
            .map((bitem, bindex) => {
              var colName = bitem.bodit;
              var obj10 = {
                r: mor,
                c: row++,
                v: {
                  v: colName,
                },
              };

              arr3.push(obj10);

              var colName2 = "Бодит-" + (bindex + 1);
              var obj11 = {
                r: 0,
                c: row-1,
                v: {
                  v: colName2,
                  bl: 1,
                },
              };

              arr3.push(obj11);
            });
        }
      });
      var obj = {
        name: "report2",
        celldata: arr3,
        order: 0,
        row: arr.length + 1,
        column: data?.length + 1,
        config: {
          toolbar: false,
          columnlen: {
            "1": 100,
            "2": 250,
            "3": 100,
            "4": 200,
            "5": 100,
            "6": 250,
            "7": 250,
            "8": 350,
            "9": 350,
            "10": 350,
            "11": 350,
            "12": 350,
            "13": 350,
            "14": 350,
            "15": 350,
            "16": 350,
            "17": 350,
            "18": 350,
          },
        },
        status: 1,
      };
      arr2.push(obj);
      setData(arr2);
    }
  }, [arr]);

  const onChange = useCallback((d: Sheet[]) => {
    setData(d);
  }, []);

  const settings = {
    showFormulaBar: false, //is included header
    showSheetTabs: false,
    showToolbar: false,
    data: data, // sheet data
  };

  return (
    <div>
      <div style={{ width: "100%", height: "300px" }}>
        {data.length == 0 ? (
          <div>Боловсруулж байна...</div>
        ) : (
          <Workbook {...settings} ref={ref} data={data} onChange={onChange} />
        )}
      </div>
    </div>
  );
};
