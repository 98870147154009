import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  DataErsdelAddReq,
  DataErsdelUpdateReq,
  TypeZurchilListReq, DataZuvlumjDuplicateReq
} from "../../services/Request";
import {
  DataErsdelUpdate,
  DataErsdelAdd,
  TypeZurchilList,
  socket, DataZuvlumjDuplicate
} from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
class DataErsdelAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      name: "",
      type_id: "",
      risk_type: "",
      is_confirm: magicnum.Status.InActive,
      validate: [],
      item: props.item,
      isSave: false,
      loadingZurchil: false,
      dataZurchil: [],
      loadingDuplicate: false,
      duplicateData: [],
      checkDuplicate: false,
      type: "text",
      whatLat: "",
      whatLat2: "",
      lawZaalt: "",
      lawHeseg: "",
      zuilNo: "",
      zaaltNo: "",
      lawZaalt2: "",
      lawHeseg2: "",
      zuilNo2: "",
      zaaltNo2: "",
      oguulber: "",

      JuramWhatTushaal: "",
      JuramBulegNo: "",
      JuramZuilNo: "",
      JuramBulegHeseg: "",
      JuramZaalt: "",
      JuramTushaalZaalt: "",
      disabledJuramZuilNo: false,
      disabledJuramBulegNo: false,

      StandartWhatStandart: "",
      StandartHedDehi: "",
      StandartHeseg: "",
      StandartParagrap: "",

      TogtoolWhatTogtool: "",
      TogtoolNo: "",
      TogtoolName: "",
      TogtoolZaalt: "",
      TogtoolZaaltiinUtga: "",
      TogtoolOdor: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.getZurchilList = this.getZurchilList.bind(this);
    this.onZurchilSuccess = this.onZurchilSuccess.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    this.getZurchilList();
    if (item !== null) {
      this.setState({
        name: item.name,
        type_id: item.type_id,
        //risk_type: item.risk_type,
        is_confirm: item.is_confirm,
        confirm_at: item.confirm_at,
        confirm_by: item.confirm_by,
        cancel_at: item.cancel_at,
        cancel_type: item.cancel_type,
        cancel_by: item.cancel_by,
        deleted_at: item.deleted_at,
        deleted_by: item.deleted_by,
        id: item.id,
      });
    }
  }

  getDuplicate = () => {
    const { oguulberPrev } = this.state;
    if (!isNullOrEmpty(oguulberPrev)) {
      if (!this.state.loadingDuplicate) {
        this.setState({ loadingDuplicate: true });
      }

      var req = DataZuvlumjDuplicateReq;
      req.name = oguulberPrev;
      DataZuvlumjDuplicate(req, this.onDuplicateSuccess, this.onFailed, "POST");
    }
  }

  onDuplicateSuccess = (response) => {
    this.setState({ loadingDuplicate: false, duplicateData: response.data, checkDuplicate: true })
  }

  getZurchilList() {
    if (!this.state.loadingZurchil) {
      this.setState({ loadingZurchil: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { name } = this.state;
    var req = TypeZurchilListReq; // !!!!!
    req.filter.name = name;
    TypeZurchilList(req, this.onZurchilSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onZurchilSuccess(response) {
    this.setState({ dataZurchil: response.data, loadingZurchil: false });
  }

  // handleChange(e) {
  //   const { name, value } = e.target;
  //   var { disabledJuramZuilNo, disabledJuramBulegNo } = false;
  //   if (name == "JuramBulegNo" && !isNullOrEmpty(value)) {
  //     disabledJuramZuilNo = true;
  //   } else if (name == "JuramZuilNo" && !isNullOrEmpty(value)) {
  //     disabledJuramBulegNo = true;
  //   }

  //   if (name == "JuramBulegNo" || name == "JuramZuilNo") {
  //     this.setState({ disabledJuramBulegNo, disabledJuramZuilNo });
  //   }

  //   this.setState({ [name]: value }, function () {
  //     const { type } = this.state;
  //     var oguulber = "";
  //     if (type !== "text") {
  //       const {
  //         whatLat,
  //         whatLat2,
  //         lawZaalt,
  //         lawHeseg,
  //         zuilNo,
  //         zaaltNo,
  //         lawZaalt2,
  //         lawHeseg2,
  //         zuilNo2,
  //         zaaltNo2,
  //         type,

  //         JuramWhatTushaal,
  //         JuramBulegNo,
  //         JuramZuilNo,
  //         JuramBulegHeseg,
  //         JuramZaalt,
  //         JuramTushaalZaalt,

  //         StandartWhatStandart,
  //         StandartHedDehi,
  //         StandartHeseg,
  //         StandartParagrap,

  //         TogtoolWhatTogtool,
  //         TogtoolNo,
  //         TogtoolName,
  //         TogtoolZaalt,
  //         TogtoolZaaltiinUtga,
  //         TogtoolOdor,
  //       } = this.state;
  //       if (type === "law") {
  //         oguulber =
  //           '"' +
  //           whatLat +
  //           '"-ийн ' +
  //           zuilNo +
  //           "-р зүйл. " +
  //           lawHeseg +
  //           " " +
  //           zaaltNo +
  //           '-д "' +
  //           lawZaalt +
  //           '"';
  //         if (!isNullOrEmpty(zuilNo2)) {
  //           if (whatLat2 != whatLat) {
  //             oguulber += "," + '"' + whatLat2 + '"-ийн ';
  //           } else {
  //             oguulber += ", ";
  //           }
  //           oguulber +=
  //             zuilNo2 +
  //             "-р зүйл. " +
  //             lawHeseg2 +
  //             " " +
  //             zaaltNo2 +
  //             '-д "' +
  //             lawZaalt2 +
  //             '"' +
  //             " гэж тус тус заасан байна.";
  //         } else {
  //           oguulber += " гэж заасан байна.";
  //         }
  //       }

  //       if (type === "juram") {
  //         oguulber = '"' + JuramWhatTushaal + '"-ийн ';
  //         if (!isNullOrEmpty(JuramBulegNo) && isNullOrEmpty(JuramZuilNo)) {
  //           oguulber += JuramBulegNo + "-р бүлэг. ";
  //         }
  //         if (isNullOrEmpty(JuramBulegNo) && !isNullOrEmpty(JuramZuilNo)) {
  //           oguulber += JuramBulegNo + "-р зүйл. ";
  //         }
  //         oguulber +=
  //           JuramBulegHeseg +
  //           " " +
  //           JuramZaalt +
  //           '-д "' +
  //           JuramTushaalZaalt +
  //           '" гэж заасан байна.';
  //       }

  //       if (type === "standart") {
  //         oguulber =
  //           StandartWhatStandart +
  //           " " +
  //           StandartHedDehi +
  //           " -ийн " +
  //           StandartHeseg +
  //           "-р хэсэгт " +
  //           StandartParagrap +
  //           " гэж заасан байна.";
  //       }

  //       if (type === "togtool") {
  //         oguulber =
  //           '"' +
  //           TogtoolWhatTogtool +
  //           '" -ийн ' +
  //           TogtoolOdor +
  //           " -ний өдрийн " +
  //           TogtoolNo +
  //           ' тоот: "' +
  //           TogtoolName +
  //           '" тогтоолын ' +
  //           TogtoolZaalt +
  //           ' -хэсэгт "' +
  //           TogtoolZaaltiinUtga +
  //           '" гэж заасан байна.';
  //       }

  //       this.setState({ oguulber });
  //     }
  //   });
  // }

  handleChange(e) {
    const { name, value } = e.target;
    var { disabledJuramZuilNo, disabledJuramBulegNo } = false;
    if (name == "JuramBulegNo" && !isNullOrEmpty(value)) {
      disabledJuramZuilNo = true;
    } else if (name == "JuramZuilNo" && !isNullOrEmpty(value)) {
      disabledJuramBulegNo = true;
    }

    if (name == "JuramBulegNo" || name == "JuramZuilNo") {
      this.setState({ disabledJuramBulegNo, disabledJuramZuilNo });
    }

    this.setState({ [name]: value }, function () {
      const { type } = this.state;
      var oguulber = "", oguulberPrev = "";
      if (type !== "text") {
        const {
          whatLat,
          whatLat2,
          lawZaalt,
          lawHeseg,
          zuilNo,
          zaaltNo,
          lawZaalt2,
          lawHeseg2,
          zuilNo2,
          zaaltNo2,
          type,

          JuramWhatTushaal,
          JuramBulegNo,
          JuramZuilNo,
          JuramBulegHeseg,
          JuramZaalt,
          JuramTushaalZaalt,

          StandartWhatStandart,
          StandartHedDehi,
          StandartHeseg,
          StandartParagrap,

          TogtoolWhatTogtool,
          TogtoolNo,
          TogtoolName,
          TogtoolZaalt,
          TogtoolZaaltiinUtga,
          TogtoolOdor,
        } = this.state;
        if (type === "law") {
          if (!isNullOrEmpty(whatLat)) {
            oguulber = '"' + whatLat + '"-ийн ';
          }
          if (!isNullOrEmpty(zuilNo)) {
            oguulber += zuilNo + "-р зүйл. ";
          }
          if (!isNullOrEmpty(lawHeseg)) {
            oguulber += lawHeseg + " ";
          }
          if (!isNullOrEmpty(zaaltNo)) {
            oguulber += zaaltNo + '-д ';
          }
          if (!isNullOrEmpty(lawZaalt)) {
            oguulber += '"' + lawZaalt + '"';
          }
          oguulberPrev = oguulber;

          if (!isNullOrEmpty(zuilNo2)) {
            if (whatLat2 != whatLat) {
              oguulber += "," + '"' + whatLat2 + '"-ийн ';
            } else {
              oguulber += ", ";
            }
            if (!isNullOrEmpty(zuilNo2)) {
              oguulber += zuilNo2 + "-р зүйл. ";
            }
            if (!isNullOrEmpty(lawHeseg2)) {
              oguulber += lawHeseg2 + " ";
            }
            if (!isNullOrEmpty(zaaltNo2)) {
              oguulber += zaaltNo2 + '-д ';
            }
            if (!isNullOrEmpty(lawZaalt2)) {
              oguulber += '"' + lawZaalt2 + '"';
            }
            oguulberPrev = oguulber;
            oguulber += " гэж тус тус заасан байна.";
          } else {
            oguulber += " гэж заасан байна.";
          }
        }

        if (type === "juram") {
          oguulber = '"' + JuramWhatTushaal + '"-ийн ';
          if (!isNullOrEmpty(JuramBulegNo) && isNullOrEmpty(JuramZuilNo)) {
            oguulber += JuramBulegNo + "-р бүлэг. ";
          }
          if (isNullOrEmpty(JuramBulegNo) && !isNullOrEmpty(JuramZuilNo)) {
            oguulber += JuramBulegNo + "-р зүйл. ";
          }
          if (!isNullOrEmpty(JuramBulegHeseg)) {
            oguulber += JuramBulegHeseg + " ";
          }
          if (!isNullOrEmpty(JuramZaalt)) {
            oguulber += JuramZaalt + '-д ';
          }
          if (!isNullOrEmpty(JuramTushaalZaalt)) {
            oguulber += '"' + JuramTushaalZaalt + '" гэж заасан байна.';
          }
          oguulberPrev = oguulber;
        }

        if (type === "standart") {
          if (!isNullOrEmpty(StandartWhatStandart)) {
            oguulber = StandartWhatStandart + " ";
          }
          if (!isNullOrEmpty()) {
            oguulber += StandartHedDehi + " -ийн ";
          }
          if (!isNullOrEmpty()) {
            oguulber += StandartHeseg + "-р хэсэгт ";
          }
          if (!isNullOrEmpty()) {
            oguulber += StandartParagrap + " гэж заасан байна.";
          }
          oguulberPrev = oguulber;
        }

        if (type === "togtool") {
          if (!isNullOrEmpty(TogtoolWhatTogtool)) {
            oguulber = '"' + TogtoolWhatTogtool + '" -ийн ';
          }
          if (!isNullOrEmpty(TogtoolOdor)) {
            oguulber += TogtoolOdor + " -ний өдрийн ";
          }
          if (!isNullOrEmpty(TogtoolNo)) {
            oguulber += TogtoolNo + ' тоот: ';
          }
          if (!isNullOrEmpty(TogtoolName)) {
            oguulber += '"' + TogtoolName + '" тогтоолын ';
          }
          if (!isNullOrEmpty(TogtoolZaalt)) {
            oguulber += TogtoolZaalt + ' -хэсэгт ';
          }
          if (!isNullOrEmpty(TogtoolZaaltiinUtga)) {
            oguulber += '"' + TogtoolZaaltiinUtga + '" гэж заасан байна.';
          }
          oguulberPrev = oguulber;
        }

        this.setState({ oguulber, oguulberPrev, checkDuplicate: false });
      }
    });
  }

  save() {
    const {
      t,
      name,
      type_id,
      risk_type,
      is_confirm,
      confirm_at,
      confirm_by,
      cancel_at,
      cancel_type,
      cancel_by,
      deleted_at,
      deleted_by,
      type,
      oguulber,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = DataErsdelAddReq;
      req.ersdel.name = type == "text" ? name : oguulber;
      req.ersdel.type_id = type_id;
      req.ersdel.is_confirm = is_confirm;
      req.ersdel.confirm_at = confirm_at;
      req.ersdel.confirm_by = confirm_by;
      req.ersdel.cancel_at = cancel_at;
      req.ersdel.cancel_type = cancel_type;
      req.ersdel.cancel_by = cancel_by;
      req.ersdel.deleted_at = deleted_at;
      req.ersdel.deleted_by = deleted_by;
      DataErsdelAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    socket.emit("dataersdel", "add");
    this.props.addSuccess(t("common:SuccessSave"), response);
    ModalManager.close();
  }

  Update() {
    const {
      t,
      name,
      is_confirm,
      confirm_at,
      confirm_by,
      cancel_at,
      cancel_type,
      cancel_by,
      deleted_at,
      deleted_by,
      type_id,
      risk_type,
      id,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = DataErsdelUpdateReq;
      req.ersdel.name = name;
      req.ersdel.type_id = type_id;
      //req.ersdel.risk_type = risk_type;
      req.ersdel.is_confirm = is_confirm;
      req.ersdel.confirm_at = confirm_at;
      req.ersdel.confirm_by = confirm_by;
      req.ersdel.cancel_at = cancel_at;
      req.ersdel.cancel_type = cancel_type;
      req.ersdel.cancel_by = cancel_by;
      req.ersdel.deleted_at = deleted_at;
      req.ersdel.deleted_by = deleted_by;
      req.ersdel.id = id;
      req.type = "update";
      DataErsdelUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    socket.emit("dataersdel", "update");
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
      loadingZurchil: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const {
      validate,
      name,
      type_id,
      type,
      whatLat,
      lawZaalt,
      lawHeseg,
      zuilNo,
      zaaltNo,
      lawZaalt2,
      lawHeseg2,
      zuilNo2,
      zaaltNo2,

      JuramWhatTushaal,
      JuramBulegNo,
      JuramZuilNo,
      JuramBulegHeseg,
      JuramZaalt,
      JuramTushaalZaalt,

      StandartWhatStandart,
      StandartHedDehi,
      StandartHeseg,
      StandartParagrap,

      TogtoolWhatTogtool,
      TogtoolNo,
      TogtoolName,
      TogtoolZaalt,
      TogtoolZaaltiinUtga,
      TogtoolOdor,
      whatLat2,
    } = this.state;
    if (type_id == "") {
      res.retType = 1;
      validate["type_id"] = true;
    }

    if (type == "text" && name == "") {
      res.retType = 1;
      validate["name"] = true;
    }

    if (type == "law") {
      if (whatLat == "") {
        res.retType = 1;
        validate["whatLat"] = true;
      }
      if (lawZaalt == "") {
        res.retType = 1;
        validate["lawZaalt"] = true;
      }
      if (lawHeseg == "") {
        res.retType = 1;
        validate["lawHeseg"] = true;
      }
      if (zuilNo == "") {
        res.retType = 1;
        validate["zuilNo"] = true;
      }
      if (zaaltNo == "") {
        res.retType = 1;
        validate["zaaltNo"] = true;
      }
      if (
        lawZaalt2 !== "" ||
        lawHeseg2 !== "" ||
        zuilNo2 !== "" ||
        zaaltNo2 !== ""
      ) {
        if (whatLat2 == "") {
          res.retType = 1;
          validate["whatLat2"] = true;
        }
        if (lawZaalt2 == "") {
          res.retType = 1;
          validate["lawZaalt2"] = true;
        }
        if (lawHeseg2 == "") {
          res.retType = 1;
          validate["lawHeseg2"] = true;
        }
        if (zuilNo2 == "") {
          res.retType = 1;
          validate["zuilNo2"] = true;
        }
        if (zaaltNo2 == "") {
          res.retType = 1;
          validate["zaaltNo2"] = true;
        }
      }
    }

    if (type == "juram") {
      if (JuramWhatTushaal == "") {
        res.retType = 1;
        validate["JuramWhatTushaal"] = true;
      }
      if (JuramZaalt == "") {
        res.retType = 1;
        validate["JuramZaalt"] = true;
      }
      if (JuramTushaalZaalt == "") {
        res.retType = 1;
        validate["JuramTushaalZaalt"] = true;
      }
      if (JuramBulegHeseg == "") {
        res.retType = 1;
        validate["JuramBulegHeseg"] = true;
      }
    }

    if (type == "standart") {
      if (StandartWhatStandart == "") {
        res.retType = 1;
        validate["StandartWhatStandart"] = true;
      }
      if (StandartHedDehi == "") {
        res.retType = 1;
        validate["StandartHedDehi"] = true;
      }
      if (StandartHeseg == "") {
        res.retType = 1;
        validate["StandartHeseg"] = true;
      }
      if (StandartParagrap == "") {
        res.retType = 1;
        validate["StandartParagrap"] = true;
      }
    }

    if (type == "togtool") {
      if (TogtoolWhatTogtool == "") {
        res.retType = 1;
        validate["TogtoolWhatTogtool"] = true;
      }
      if (TogtoolNo == "") {
        res.retType = 1;
        validate["TogtoolNo"] = true;
      }
      if (TogtoolName == "") {
        res.retType = 1;
        validate["TogtoolName"] = true;
      }
      if (TogtoolZaalt == "") {
        res.retType = 1;
        validate["TogtoolZaalt"] = true;
      }
      if (TogtoolZaaltiinUtga == "") {
        res.retType = 1;
        validate["TogtoolZaaltiinUtga"] = true;
      }
      if (TogtoolOdor == "") {
        res.retType = 1;
        validate["TogtoolOdor"] = true;
      }
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      code,
      name,
      type_id,
      risk_type,
      dataZurchil,
      loadingZurchil,
      type,
      whatLat,
      lawZaalt,
      lawHeseg,
      zuilNo,
      zaaltNo,
      lawZaalt2,
      lawHeseg2,
      zuilNo2,
      zaaltNo2,
      oguulber,
      JuramWhatTushaal,
      JuramBulegNo,
      JuramZuilNo,
      JuramBulegHeseg,
      JuramZaalt,
      JuramTushaalZaalt,
      disabledJuramZuilNo,
      disabledJuramBulegNo,
      StandartWhatStandart,
      StandartHedDehi,
      StandartHeseg,
      StandartParagrap,

      TogtoolWhatTogtool,
      TogtoolNo,
      TogtoolName,
      TogtoolZaalt,
      TogtoolZaaltiinUtga,
      TogtoolOdor,
      whatLat2, checkDuplicate, loadingDuplicate, duplicateData
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <div
                className="animated fadeIn"
                style={{ borderBottom: "1px solid #0d9749", marginBottom: 15 }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{t("common:type")}</Label>
                      <Input
                        type="select"
                        value={type}
                        name="type"
                        onChange={(event) => {
                          this.handleChange(event, "type");
                        }}
                      >
                        <option value="text">
                          {t("common:magicnum.Type.Zurchil.text")}
                        </option>
                        <option value="law">
                          {t("common:magicnum.Type.Zurchil.law")}
                        </option>
                        <option value="juram">
                          {t("common:magicnum.Type.Zurchil.juram")}
                        </option>
                        <option value="standart">
                          {t("common:magicnum.Type.Zurchil.standart")}
                        </option>
                        <option value="togtool">
                          {t("common:magicnum.Type.Zurchil.togtool")}
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                {type == "law" ? (
                  <div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>1-{t("Data.Oguulber.law.whatLat")}</Label>
                          <Input
                            type="select"
                            name="whatLat"
                            value={whatLat}
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("whatLat", event);
                            }}
                            invalid={validate["whatLat"]}
                          >
                            <option value="">
                              {loadingZurchil
                                ? t("common:loading")
                                : t("common:choose")}
                            </option>
                            {loadingZurchil
                              ? null
                              : dataZurchil.map((item, index) => {
                                return (
                                  <option
                                    value={item.description}
                                    key={index}
                                  >
                                    {item.description}
                                  </option>
                                );
                              })}
                          </Input>
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>1-{t("Data.Oguulber.law.zuilNo")}</Label>
                          <Input
                            type="text"
                            name="zuilNo"
                            value={zuilNo}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("zuilNo", event);
                            }}
                            invalid={validate["zuilNo"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>1-{t("Data.Oguulber.law.lawHeseg")}</Label>
                          <Input
                            type="text"
                            name="lawHeseg"
                            value={lawHeseg}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("lawHeseg", event);
                            }}
                            invalid={validate["lawHeseg"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>1-{t("Data.Oguulber.law.zaaltNo")}</Label>
                          <Input
                            type="text"
                            name="zaaltNo"
                            value={zaaltNo}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("zaaltNo", event);
                            }}
                            invalid={validate["zaaltNo"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>1-{t("Data.Oguulber.law.lawZaalt")}</Label>
                          <Input
                            type="text"
                            name="lawZaalt"
                            value={lawZaalt}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("lawZaalt", event);
                            }}
                            invalid={validate["lawZaalt"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>2-{t("Data.Oguulber.law.whatLat")}</Label>
                          <Input
                            type="select"
                            name="whatLat2"
                            value={whatLat2}
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("whatLat2", event);
                            }}
                            invalid={validate["whatLat2"]}
                          >
                            <option value="">
                              {loadingZurchil
                                ? t("common:loading")
                                : t("common:choose")}
                            </option>
                            {loadingZurchil
                              ? null
                              : dataZurchil.map((item, index) => {
                                return (
                                  <option
                                    value={item.description}
                                    key={index}
                                  >
                                    {item.description}
                                  </option>
                                );
                              })}
                          </Input>
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>2-{t("Data.Oguulber.law.zuilNo")}</Label>
                          <Input
                            type="text"
                            name="zuilNo2"
                            value={zuilNo2}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("zuilNo2", event);
                            }}
                            invalid={validate["zuilNo2"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>2-{t("Data.Oguulber.law.lawHeseg")}</Label>
                          <Input
                            type="text"
                            name="lawHeseg2"
                            value={lawHeseg2}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("lawHeseg2", event);
                            }}
                            invalid={validate["lawHeseg2"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>2-{t("Data.Oguulber.law.zaaltNo")}</Label>
                          <Input
                            type="text"
                            name="zaaltNo2"
                            value={zaaltNo2}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("zaaltNo2", event);
                            }}
                            invalid={validate["zaaltNo2"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>2-{t("Data.Oguulber.law.lawZaalt")}</Label>
                          <Input
                            type="text"
                            name="lawZaalt2"
                            value={lawZaalt2}
                            onChange={(event) => {
                              this.handleChange(event, "law");
                              this.setValidate("lawZaalt2", event);
                            }}
                            invalid={validate["lawZaalt2"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {oguulber}
                  </div>
                ) : null}
                {type == "text" ? (
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Data.Zuvlumj.name")}</Label>
                        <Input
                          type="textarea"
                          name="name"
                          value={name}
                          rows={5}
                          cols={5}
                          onChange={(event) => {
                            this.handleChange(event, "text");
                            this.setValidate("name", event);
                          }}
                          invalid={validate["name"]}
                        />
                        <FormFeedback>{t("common:InputValid")}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {type == "juram" ? (
                  <div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.juram.JuramWhatTushaal")}
                          </Label>
                          <Input
                            type="text"
                            name="JuramWhatTushaal"
                            value={JuramWhatTushaal}
                            onChange={(event) => {
                              this.handleChange(event, "juram");
                              this.setValidate("JuramWhatTushaal", event);
                            }}
                            invalid={validate["JuramWhatTushaal"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{t("Data.Oguulber.juram.JuramBulegNo")}</Label>
                          <Input
                            type="text"
                            name="JuramBulegNo"
                            value={JuramBulegNo}
                            onChange={(event) => {
                              this.handleChange(event, "juram");
                              this.setValidate("JuramBulegNo", event);
                            }}
                            disabled={disabledJuramBulegNo ? true : false}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{t("Data.Oguulber.juram.JuramZuilNo")}</Label>
                          <Input
                            type="text"
                            name="JuramZuilNo"
                            value={JuramZuilNo}
                            onChange={(event) => {
                              this.handleChange(event, "juram");
                              this.setValidate("JuramZuilNo", event);
                            }}
                            disabled={disabledJuramZuilNo ? true : false}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.juram.JuramBulegHeseg")}
                          </Label>
                          <Input
                            type="text"
                            name="JuramBulegHeseg"
                            value={JuramBulegHeseg}
                            onChange={(event) => {
                              this.handleChange(event, "juram");
                              this.setValidate("JuramBulegHeseg", event);
                            }}
                            invalid={validate["JuramBulegHeseg"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{t("Data.Oguulber.juram.JuramZaalt")}</Label>
                          <Input
                            type="text"
                            name="JuramZaalt"
                            value={JuramZaalt}
                            onChange={(event) => {
                              this.handleChange(event, "juram");
                              this.setValidate("JuramZaalt", event);
                            }}
                            invalid={validate["JuramZaalt"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.juram.JuramTushaalZaalt")}
                          </Label>
                          <Input
                            type="text"
                            name="JuramTushaalZaalt"
                            value={JuramTushaalZaalt}
                            onChange={(event) => {
                              this.handleChange(event, "juram");
                              this.setValidate("JuramTushaalZaalt", event);
                            }}
                            invalid={validate["JuramTushaalZaalt"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {oguulber}
                  </div>
                ) : null}

                {type == "standart" ? (
                  <div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.standart.StandartWhatStandart")}
                          </Label>
                          <Input
                            type="text"
                            name="StandartWhatStandart"
                            value={StandartWhatStandart}
                            onChange={(event) => {
                              this.handleChange(event, "standart");
                              this.setValidate("StandartWhatStandart", event);
                            }}
                            invalid={validate["StandartWhatStandart"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.standart.StandartHedDehi")}
                          </Label>
                          <Input
                            type="text"
                            name="StandartHedDehi"
                            value={StandartHedDehi}
                            onChange={(event) => {
                              this.handleChange(event, "standart");
                              this.setValidate("StandartHedDehi", event);
                            }}
                            invalid={validate["StandartHedDehi"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.standart.StandartHeseg")}
                          </Label>
                          <Input
                            type="text"
                            name="StandartHeseg"
                            value={StandartHeseg}
                            onChange={(event) => {
                              this.handleChange(event, "standart");
                              this.setValidate("StandartHeseg", event);
                            }}
                            invalid={validate["StandartHeseg"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.standart.StandartParagrap")}
                          </Label>
                          <Input
                            type="text"
                            name="StandartParagrap"
                            value={StandartParagrap}
                            onChange={(event) => {
                              this.handleChange(event, "standart");
                              this.setValidate("StandartParagrap", event);
                            }}
                            invalid={validate["StandartParagrap"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {oguulber}
                  </div>
                ) : null}

                {type == "togtool" ? (
                  <div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.togtool.TogtoolWhatTogtool")}
                          </Label>
                          <Input
                            type="text"
                            name="TogtoolWhatTogtool"
                            value={TogtoolWhatTogtool}
                            onChange={(event) => {
                              this.handleChange(event, "togtool");
                              this.setValidate("TogtoolWhatTogtool", event);
                            }}
                            invalid={validate["TogtoolWhatTogtool"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{t("Data.Oguulber.togtool.TogtoolNo")}</Label>
                          <Input
                            type="text"
                            name="TogtoolNo"
                            value={TogtoolNo}
                            onChange={(event) => {
                              this.handleChange(event, "togtool");
                              this.setValidate("TogtoolNo", event);
                            }}
                            invalid={validate["TogtoolNo"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.togtool.TogtoolName")}
                          </Label>
                          <Input
                            type="text"
                            name="TogtoolName"
                            value={TogtoolName}
                            onChange={(event) => {
                              this.handleChange(event, "togtool");
                              this.setValidate("TogtoolName", event);
                            }}
                            invalid={validate["TogtoolName"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.togtool.TogtoolZaalt")}
                          </Label>
                          <Input
                            type="text"
                            name="TogtoolZaalt"
                            value={TogtoolZaalt}
                            onChange={(event) => {
                              this.handleChange(event, "togtool");
                              this.setValidate("TogtoolZaalt", event);
                            }}
                            invalid={validate["TogtoolZaalt"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.togtool.TogtoolZaaltiinUtga")}
                          </Label>
                          <Input
                            type="text"
                            name="TogtoolZaaltiinUtga"
                            value={TogtoolZaaltiinUtga}
                            onChange={(event) => {
                              this.handleChange(event, "togtool");
                              this.setValidate("TogtoolZaaltiinUtga", event);
                            }}
                            invalid={validate["TogtoolZaaltiinUtga"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("Data.Oguulber.togtool.TogtoolOdor")}
                          </Label>
                          <Input
                            type="text"
                            name="TogtoolOdor"
                            value={TogtoolOdor}
                            onChange={(event) => {
                              this.handleChange(event, "togtool");
                              this.setValidate("TogtoolOdor", event);
                            }}
                            invalid={validate["TogtoolOdor"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {oguulber}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("common:type")}</Label>
                <Input
                  type="select"
                  name="type_id"
                  value={type_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("type_id", event);
                  }}
                  invalid={validate["type_id"]}
                >
                  <option value="">
                    {loadingZurchil ? t("common:loading") : t("common:choose")}
                  </option>
                  {dataZurchil.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              {/* <FormGroup>
                <Label>{t("Data.Asuudal.risk_type")}</Label>
                <Input
                  type="select"
                  value={risk_type}
                  name="risk_type"
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("risk_type", event);
                  }}
                  invalid={validate["risk_type"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.Data.RiskType.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup> */}
            </Col>
          </Row>
          <Row><Col>
            <div style={{ maxHeight: "150px", overflowY: "auto", backgroundColor: "#ffde7a" }}>
              {duplicateData.length > 0 ? <div style={{ textAlign: "center", marginTop: 10 }}><span style={{ fontWeight: "bold" }}>Төстөй мэдээлэл : {duplicateData.length}</span></div> : null}
              {duplicateData.map((iteem, indeex) => {
                return <div key={indeex} style={{ padding: "10px 15px" }}>{indeex + 1}. {iteem.name}</div>
              })}</div>
          </Col></Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="secondary"
                onClick={this.getDuplicate}
                disabled={loadingDuplicate}
                style={{ marginRight: 10 }}
              >
                {t("common:check")}
              </Button>
            </FormGroup>

            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default DataErsdelAddFunc;
