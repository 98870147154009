import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  CustomerAddReq,
  CustomerUpdateReq,
  IndustryListReq,
  BaseRequest,
} from "../../services/Request";
import { CustomerUpdate, CustomerAdd, IndustryList, CustomerCheckRDtoName, CustomerCheckRD } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import {
  isNullOrEmpty,
  isValidEmail,
  isValidPhone,
} from "../../components/Utils/Utils.js";
import { toast } from "react-toastify";
class CustomerAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      register_number: "",
      name: "",
      email: "",
      mobile: "",
      telephone: "",
      director_name: "",
      accountant_name: "",
      company_type: "",
      company_size: "",
      industry_id: "",
      city_id: "",
      description: "",
      address: "",
      is_active: "",
      is_isset: false,
      is_isset_loading: false,
      id: "",
      validate: [],
      item: props.item,
      Status: magicnum.Status.Active,
      isSave: false,
      Industryloading: false,
      industryData: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        register_number: item.register_number,
        name: item.name,
        email: item.email,
        mobile: item.mobile,
        telephone: item.telephone,
        director_name: item.director_name,
        accountant_name: item.accountant_name,
        company_type: item.company_type,
        company_size: item.company_size,
        industry_id: item.industry_id,
        city_id: item.city_id,
        description: item.description,
        address: item.address,
        is_active: item.is_active,
        id: item.id,
      });
    }
    this.getIndustryList();
  }

  checkRD = () => {
    const { register_number, t } = this.state
    if (!isNullOrEmpty(register_number)) {
      if (!this.state.is_isset_loading) {
        this.setState({ is_isset_loading: true });
      }

      CustomerCheckRD(register_number, BaseRequest, this.onCheckRDSuccess, this.onFailed, "GET");
    } else {
      return toast.warn(t("common:validRD"))
    }
  };

  onCheckRDSuccess = (response) => {
    this.setState({ is_isset_loading: false, name: response?.data?.name });
    if (isNullOrEmpty(response?.data)) {
      this.checkRDtoName()
      return toast.warn("Урд нь манайд бүртгэлгүй тул улсын бүртгэлээс нэрийг оллоо")
    }
  }

  checkRDtoName = () => {
    const { register_number, t } = this.state
    if (!isNullOrEmpty(register_number)) {
      if (!this.state.is_isset_loading) {
        this.setState({ is_isset_loading: true });
      }

      CustomerCheckRDtoName(register_number, BaseRequest, this.onCheckRDtoNameSuccess, this.onFailed, "GET");
    } else {
      return toast.warn(t("common:validRD"))
    }
  };

  onCheckRDtoNameSuccess = (response) => {
    this.setState({ is_isset_loading: false, name: response?.data?.name });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  getIndustryList = () => {
    if (!this.state.Industryloading) {
      this.setState({ loading: true });
    }

    var req = IndustryListReq;
    req.pid = 1;
    req.pSize = 1000;
    IndustryList(req, this.onIndustrySuccess, this.onFailed, "POST");
  };

  onIndustrySuccess = (response) => {
    this.setState({
      Industryloading: false,
      industryData: response.data,
    });
  };

  save() {
    const {
      t,
      register_number,
      name,
      email,
      mobile,
      telephone,
      director_name,
      accountant_name,
      company_type,
      company_size,
      industry_id,
      city_id,
      description,
      address,
      is_active,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = CustomerAddReq;
      req.customer.register_number = register_number;
      req.customer.name = name;
      req.customer.email = email;
      req.customer.mobile = mobile;
      req.customer.telephone = telephone;
      req.customer.director_name = director_name;
      req.customer.accountant_name = accountant_name;
      req.customer.company_type = company_type;
      req.customer.company_size = company_size;
      req.customer.industry_id = industry_id;
      req.customer.city_id = city_id;
      req.customer.description = description;
      req.customer.address = address;
      req.customer.is_active = is_active;
      CustomerAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const {
      t,
      register_number,
      name,
      email,
      mobile,
      telephone,
      director_name,
      accountant_name,
      company_type,
      company_size,
      industry_id,
      city_id,
      description,
      address,
      is_active,
      id,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = CustomerUpdateReq;
      req.customer.register_number = register_number;
      req.customer.name = name;
      req.customer.email = email;
      req.customer.mobile = mobile;
      req.customer.telephone = telephone;
      req.customer.director_name = director_name;
      req.customer.accountant_name = accountant_name;
      req.customer.company_type = company_type;
      req.customer.company_size = company_size;
      req.customer.industry_id = industry_id;
      req.customer.city_id = city_id;
      req.customer.description = description;
      req.customer.address = address;
      req.customer.is_active = is_active;
      req.customer.id = id;
      CustomerUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      is_isset_loading: false,
      apierrormsg: error,
      isSave: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const {
      validate,
      register_number,
      name,
      email,
      mobile,
      telephone,
      director_name,
      accountant_name,
      company_type,
      company_size,
      industry_id,
      city_id,
      address,
      is_active,
    } = this.state;
    if (register_number == "") {
      res.retType = 1;
      validate["register_number"] = true;
    }
    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }
    if (!isValidPhone(mobile)) {
      res.retType = 1;
      validate["mobile"] = true;
    }
    if (director_name == "") {
      res.retType = 1;
      validate["director_name"] = true;
    }
    if (accountant_name == "") {
      res.retType = 1;
      validate["accountant_name"] = true;
    }
    if (company_type == "") {
      res.retType = 1;
      validate["company_type"] = true;
    }
    if (company_size == "") {
      res.retType = 1;
      validate["company_size"] = true;
    }
    if (industry_id == "") {
      res.retType = 1;
      validate["industry_id"] = true;
    }
    if (city_id == "") {
      res.retType = 1;
      validate["city_id"] = true;
    }
    if (address == "") {
      res.retType = 1;
      validate["address"] = true;
    }
    if (is_active == "") {
      res.retType = 1;
      validate["is_active"] = true;
    }

    if (!isValidEmail(email)) {
      res.retType = 1;
      validate["email"] = true;
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      register_number,
      name,
      email,
      mobile,
      telephone,
      director_name,
      accountant_name,
      company_type,
      company_size,
      industry_id,
      city_id,
      description,
      address,
      is_active,
      id,
      industryData,
      Industryloading,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Customer.register_number")}</Label>
                <div style={{ display: "flex" }}>
                  <Input
                    type="text"
                    name="register_number"
                    value={register_number}
                    onChange={(event) => {
                      this.handleChange(event);
                      this.setValidate("register_number", event);
                    }}
                    invalid={validate["register_number"]}
                  />
                  <Button onClick={this.checkRD} disabled={this.state.is_isset_loading}>{t("common:checkDuplicate")}</Button>
                  <div style={{ borderLeft: "1x solid white", width: "2px" }} />
                  <Button onClick={this.checkRDtoName} disabled={this.state.is_isset_loading}>{t("common:checkRD")}</Button>
                </div>
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.name")}</Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.director_name")}</Label>
                <Input
                  type="text"
                  name="director_name"
                  value={director_name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("director_name", event);
                  }}
                  invalid={validate["director_name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.accountant_name")}</Label>
                <Input
                  type="text"
                  name="accountant_name"
                  value={accountant_name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("accountant_name", event);
                  }}
                  invalid={validate["accountant_name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Customer.company_type")}</Label>
                <Input
                  type="select"
                  name="company_type"
                  value={company_type}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("company_type", event);
                  }}
                  invalid={validate["company_type"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.Company.Type.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.company_size")}</Label>
                <Input
                  type="select"
                  name="company_size"
                  value={company_size}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("company_size", event);
                  }}
                  invalid={validate["company_size"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.Company.Size.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.industry_id")}</Label>
                <Input
                  type="select"
                  name="industry_id"
                  value={industry_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("industry_id", event);
                  }}
                  invalid={validate["industry_id"]}
                >
                  <option value="">
                    {Industryloading ? t("common:loading") : t("common:choose")}
                  </option>
                  {isNullOrEmpty(industryData)
                    ? null
                    : industryData.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                </Input>
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.city_id")}</Label>
                <Input
                  type="select"
                  name="city_id"
                  value={city_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("city_id", event);
                  }}
                  invalid={validate["city_id"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.City.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.City.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Customer.telephone")}</Label>
                <Input
                  type="number"
                  name="telephone"
                  value={telephone}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("telephone", event);
                  }}
                  invalid={validate["telephone"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("Customer.email")}</Label>
                <Input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("email", event);
                  }}
                  invalid={validate["email"]}
                />
                <FormFeedback>{t("common:InputValidEmail")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.mobile")}</Label>
                <Input
                  type="number"
                  name="mobile"
                  value={mobile}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("mobile", event);
                  }}
                  invalid={validate["mobile"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("common:status")}</Label>
                <Input
                  type="select"
                  name="is_active"
                  value={is_active}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("is_active", event);
                  }}
                  invalid={validate["is_active"]}
                >
                  <option value="">{t("common:choose")}</option>
                  <option value={magicnum.Status.Active}>
                    {t("common:magicnum.Status.Active")}
                  </option>
                  <option value={magicnum.Status.InActive}>
                    {t("common:magicnum.Status.InActive")}
                  </option>
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Customer.description")}</Label>
                <Input
                  type="textarea"
                  name="description"
                  value={description}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("description", event);
                  }}
                  invalid={validate["description"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.address")}</Label>
                <Input
                  type="textarea"
                  name="address"
                  value={address}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("address", event);
                  }}
                  invalid={validate["address"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default CustomerAddFunc;
