import React, { useEffect, useState } from "react";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { ProjectFilesChangeStatus, ProjectFilesMultiChangeStatus } from "../../../../services/main";
import { isNullOrEmpty } from "../../../../components/Utils/Utils";
import { ModalManager } from "react-dynamic-modal/lib/Modal";
import { ProjectFilesChangeStatusReq, magicnum } from "../../../../services/Request";
import { Multiselect } from "multiselect-react-dropdown";
const ChangeStatus = (props) => {
  const t = props.t
  const data = props.data
  const type = props.type
  const selectedfile = props.selectedfile
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('')
  const [action_desc, setAction_desc] = useState('')
  const [validate, setValidate] = useState([])
  const [count, setCount] = useState(0)

  const [dataFiles, setDataFiles] = useState([])
  const [selectedList, setSelectedList] = useState([])

  useEffect(() => {
    if (type == "multi") {
      var tempArray = []
      data.filter((fitem) => fitem.parentcat_id == null).map((fitem, findex) => {
        data.filter((sfitem) => sfitem.parentcat_id == fitem.cat_id).map((fsitem, fsindex) => {
          if (fsitem.status > 0) {
            var obj = {
              cat: findex + 1 + ". " + t("common:magicnum.filesInfo." + fitem.cat_id),
              key: findex + 1 + "." + parseInt(fsindex + 1) + ". " + t("common:magicnum.filesInfoSub." + fsitem.parentcat_id + "-" + fsitem.cat_id),
              cat_id: fsitem.cat_id,
              parentcat_id: fsitem.parentcat_id,
              storedid: fsitem.storedid,
            }
            tempArray.push(obj)
          }
        })
      })
      setDataFiles(tempArray)
    }
  }, [type]);

  useEffect(() => {
    if (!isNullOrEmpty(props?.selectedfile)) {
      setStatus(props?.selectedfile.status)
      setAction_desc(props?.selectedfile.action_desc)
    }
  }, [props?.selectedfile]);

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = ProjectFilesChangeStatusReq
      req.file.status = status
      req.file.action_desc = action_desc
      req.file.id = selectedfile?.storedid
      if (type == "one") {
        ProjectFilesChangeStatus(req, onSuccess, onFailed, 'PUT')
      }
      else {
        var tempArray = []
        selectedList.map(uitem => {
          var gg = {
            id: uitem.storedid
          }
          tempArray.push(gg)
        })
        req.file.id = null
        req.multi = tempArray
        ProjectFilesMultiChangeStatus(req, onSuccess, onFailed, 'PUT')
      }
    }
    else {
      return toast.warning(t("common:SelectValid"))
    }
  }

  const onSuccess = (response) => {
    setLoading(false)
    props.onChangeSuccess(t("common:Success"));
    ModalManager.close();
  }

  const onFailed = (error) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateFunc = () => {
    var res = { retType: 0 }

    if (selectedList.length == 0 && type == "multi") {
      res.retType = 1
      validate['files'] = true
    }

    if (isNullOrEmpty(status)) {
      res.retType = 1
      validate['status'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const onSelect = (selectedList) => {
    validate["files"] = false;
    setValidate(validate)
    setSelectedList(selectedList)
  }

  const onRemove = (selectedList) => {
    validate["files"] = false;
    setValidate(validate)
    setSelectedList(selectedList)
  }

  return (
    <div className="customer-project-files">
      {type == "multi" ?
        <FormGroup>
          {dataFiles.length == 0 ? null : (
            <Multiselect
              displayValue="key"
              groupBy="cat"
              onKeyPressFn={function noRefCheck() { }}
              onRemove={onRemove}
              onSearch={function noRefCheck() { }}
              onSelect={onSelect}
              options={dataFiles}
              showCheckbox
              placeholder={t("main:Project.files.chooseMulti")}
            />
          )}
          {validate["files"] ? (
            <div className="invalid-feedback show">
              {t("common:SelectValid")}
            </div>
          ) : null}
        </FormGroup> : null}
      <FormGroup>
        <Label>{t("main:Project.files.status.title")}</Label>
        <Input
          type="select"
          className="input-field"
          invalid={validate["status"]}
          rows={3}
          cols={100}
          value={status}
          disabled={status === 3}
          onChange={(e) => { setStatus(e.target.value) }}
        >
          <option value={""}>{t("common:choose")}</option>
          <option value={0}>{t("main:Project.files.status." + 0)}</option>
          <option value={1}>{t("main:Project.files.status." + 1)}</option>
          <option value={2}>{t("main:Project.files.status." + 2)}</option>
          <option value={3}>{t("main:Project.files.status." + 3)}</option>
        </Input>
        <FormFeedback>{t("common:SelectValid")}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>{t("main:Project.files.status.desc")}</Label>
        <Input
          type="textarea"
          className="input-field"
          rows={3}
          cols={100}
          value={action_desc}
          disabled={status === 3}
          onChange={(e) => { setAction_desc(e.target.value) }}
        />
      </FormGroup>
      <div className="action text-rigth" style={{ marginTop: 20 }}>
        <Button onClick={save} disabled={loading}>{loading ? t("common:loading") : t("common:save")}</Button>
      </div>
      <span style={{ visibility: "hidden", fontSize: 1 }}>{count}</span>
    </div>
  );
};

export default ChangeStatus;
