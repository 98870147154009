import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  TabContent,
  Button,
  Label,
  FormGroup,
  Input,
  Spinner,
  UncontrolledAlert,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import {
  setPageTitle,
  ProjectReport,
  ConnectCustomerGet,
} from "../../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  magicnum,
  BaseRequest,
  ProjectReportReq,
  ConnectCustomerGetReq,
} from "../../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../../components/Utils/Utils";
import ProjectAsuudalPDF from "./ProjectAsuudalPDF";
import ProjectAsuudalEXCEL from "./ProjectAsuudalEXCEL";
import { useParams } from "react-router-dom";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class ProjectAsuudalFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      connection: null,
      item: null,
      userData: [],
      CustomerConnectData: null,
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.getCustomerConnect = this.getCustomerConnect.bind(this);
    this.onGetCustomerConnectSuccess = this.onGetCustomerConnectSuccess.bind(
      this
    );
  }
  componentDidMount() {
    setPageTitle(this.state.t("Project.More"));
    this.getCustomerConnect();
    this.getMore();
  }

  getCustomerConnect() {
    var req = ConnectCustomerGetReq;
    req.filter.project_id = this.props.params.project_id;
    req.filter.asuudal_id = this.props.params.id;
    ConnectCustomerGet(
      req,
      this.onGetCustomerConnectSuccess,
      this.onFailed,
      "POST"
    );
  }

  onGetCustomerConnectSuccess(response) {
    this.setState({
      CustomerConnectData: response.data,
    });
  }

  getMore() {
    this.setState({ loading: true });
    var req = ProjectReportReq;
    req.filter.id = this.props.params.id;
    ProjectReport(req, this.onGetSuccess, this.onFailed, "POST");
  }

  renderUsername(user_id) {
    var user = getUser(this.state.userData, user_id);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  }

  onGetSuccess(response) {
    this.setState(
      {
        loading: false,
        item: response.asuudal,
        connection: response.connection,
        userData: response.users,
      },
      function () {
        if (!isNullOrEmpty(this.state.item)) {
          setPageTitle(this.state.item.name);
        }
      }
    );
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  isChecked(data, id, type) {
    var ret = false;
    if (!isNullOrEmpty(data)) {
      if (type === "asuulga" && data.asuulga.includes(":" + id + ":")) {
        ret = true;
      }
      if (type === "zuvlumj" && data.zuvlumj.includes(":" + id + ":")) {
        ret = true;
      }
      if (type === "ersdel" && data.ersdel.includes(":" + id + ":")) {
        ret = true;
      }
    }

    return ret;
  }

  showPDF = (row) => {
    const { t } = this.state;
    var title = t("common:pdf") + " - " + row.name;
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ProjectAsuudalPDF
            t={this.state.t}
            item={row}
            userData={this.state.userData}
            connection={this.state.connection}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  };

  showEXCEL = (row) => {
    const { t } = this.state;
    var title = t("common:excel") + " - " + row.name;
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ProjectAsuudalEXCEL
            t={this.state.t}
            item={row}
            userData={this.state.userData}
            connection={this.state.connection}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  };

  render() {
    const {
      t,
      loading,
      connection,
      item,
      apierror,
      apierrormsg,
      CustomerConnectData,
    } = this.state;
    var user,
      userC = "";
    if (!isNullOrEmpty(item)) {
      user = getUser(this.state.userData, item.created_by);
      userC = getUser(this.state.userData, item.confirm_by);
    }
    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {isNullOrEmpty(item) ? null : item.name}

            <div className="float-right">
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  this.showPDF(item);
                }}
                color="primary"
                disabled={loading}
              >
                {t("common:pdf")}
              </Button>

              <Button
                onClick={() => {
                  this.showEXCEL(item);
                }}
                color="primary"
                disabled={loading}
              >
                {t("common:excel")}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}

            <Table borderless>
              <thead>
                <tr>
                  <th>{t("Data.Asuudal.code")}</th>
                  <th> {t("Data.Asuudal.dans_id")} </th>                  
                  <th> {t("Data.Asuudal.risk_type")} </th>
                  <th> {t("common:CreatedBy")}</th>
                  <th> {t("common:ConfirmBy")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Spinner />
                ) : isNullOrEmpty(item) ? (
                  <div>{t("common:NotFound")}</div>
                ) : (
                  <tr>
                    <td>{item.code}</td>
                    <td>{item.dname}</td>                    
                    <td>{item.risk_type}</td>
                    <td>
                      {!isNullOrEmpty(user)
                        ? LastAndFirstName(user.lastname, user.firstname)
                        : null}{" "}
                      - {dateFormat(item.created_at)}
                    </td>
                    <td>
                      {!isNullOrEmpty(userC)
                        ? LastAndFirstName(userC.lastname, userC.firstname)
                        : null}{" "}
                      - {dateFormat(item.confirm_at)}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* <Card body style={{ marginTop: 40 }}>
              <CardTitle className="label-title">
                <label>{t("common:asuulga")}</label>
              </CardTitle>
              <div className="detail">
                {isNullOrEmpty(connection)
                  ? null
                  : connection.map((item, index) => {
                      if (item.type == "asuulga") {
                        return (
                          <div key={index}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={this.isChecked(
                                    CustomerConnectData,
                                    item.id,
                                    "asuulga"
                                  )}
                                />{" "}
                                {item.asuulga}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      }
                    })}
              </div>
            </Card> */}
            <Card body style={{ marginTop: 40 }}>
              <CardTitle className="label-title">
                <label>{t("common:ersdel")}</label>
              </CardTitle>
              <div className="detail">
                {isNullOrEmpty(connection)
                  ? null
                  : connection.map((item, index) => {
                      if (item.type == "ersdel") {
                        return (
                          <div key={index}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={this.isChecked(
                                    CustomerConnectData,
                                    item.id,
                                    "ersdel"
                                  )}
                                />{" "}
                                {item.ersdel}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      }
                    })}
              </div>
            </Card>
            <Card body>
              <CardTitle className="label-title">
                <label>{t("common:zuvlumj")}</label>
              </CardTitle>
              <div className="detail">
                {isNullOrEmpty(connection)
                  ? null
                  : connection.map((item, index) => {
                      if (item.type == "zuvlumj") {
                        return (
                          <div key={index}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={this.isChecked(
                                    CustomerConnectData,
                                    item.id,
                                    "zuvlumj"
                                  )}
                                />{" "}
                                {item.zuvlumj}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      }
                    })}
              </div>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(withParams(ProjectAsuudalFunc));
