import React, { Component, useState, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Button,
  ListGroup,
  Table,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
  FormGroup,
  Label,
  Input,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import Rating from "react-rating";
import {
  DataAsuudalGet,
  setPageTitle,
  DataAsuudalUpdate,
  DataAsuudalRating,
  RateAdd,
  RateList,
} from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  magicnum,
  BaseRequest,
  DataAsuudalUpdateReq,
  DataAsuudalRatingReq,
  RateAddReq,
  RateListReq,
} from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../components/Utils/Utils";

import ConnectZuvlumj from "./Connect/ConnectZuvlumj";
import ConnectAsuulga from "./Connect/ConnectAsuulga";
import ConnectErsdel from "./Connect/ConnectErsdel";
import ConnectMention from "./Connect/ConnectMention";
import VersionAsuudal from "./Connect/VersionAsuudal";

import ChatAsuudal from "./Chat/ChatAsuudal";
import AsuudalGetReport from "./AsuudalGetReport";
import Bodit from "./Bodit/Bodit";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useParams } from "react-router-dom";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class DataAsuudalGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      activeTab: "1",
      item: null,
      cancel_type: "",
      dansData: [],
      userData: [],
      industryData: [],
      notolgooData: null,
      isOpenRate: false,
      loadingRate: false,
      RateData: null,
      notolgooText: "",
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setRating = this.setRating.bind(this);
  }
  componentDidMount() {
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    DataAsuudalGet(
      this.props.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }



  onGetSuccess(response) {

    var text = "";
    response.notolgooData.map((ig) => {
      var notolgooArray = response.data.notolgoo_id.split(":");
      notolgooArray.map((im) => {
        if (ig.id == im) {
          if (!isNullOrEmpty(text)) {
            text += ":";
          }
          text += ig.name;
        }
      });
    });
    var UA = getUser(response.industry, response.data.ua_chiglel);
    var TZ = getUser(response.industry, response.data.tusgai_zovshoorol);

    this.setState({
      loading: false,
      notolgooData: response.notolgooData,
      item: { ...response.data, UA: UA, TZ: TZ },
      userData: response.users,
      dansData: response.dans,
      industryData: response.industry,
      notolgooText: text,
    });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  renderUsername(user_id) {
    var user = getUser(this.state.userData, user_id);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  }

  submit(row, type) {
    const { t, cancel_type } = this.state;
    var title = row.name + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.name + " " + t("Data.Asuudal.confirm");
      var message = t("common:confirm");
    } else if (type == "cancel") {
      var title = row.name + " " + t("Data.Asuudal.cancel");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.name + " " + t("Data.Asuudal.delete");
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            {type == "cancel" ? (
              <Input
                type="select"
                name="cancel_type"
                onChange={(event) => {
                  this.handleChange(event);
                }}
                style={{ marginTop: 20, marginBottom: 40 }}
              >
                <option value="">{t("common:choose")}</option>
                {magicnum.Data.CancelType.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
              </Input>
            ) : null}
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain"
                  ? this.delete(row)
                  : this.Update(row, type);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  Update(item, type) {
    const { t, cancel_type } = this.state;
    var isValidate = true;
    var req = DataAsuudalUpdateReq;
    req.asuudal = item;
    if (type == "cancel") {
      if (isNullOrEmpty(cancel_type)) {
        isValidate = false;
        return toast.error(t("Data.Asuudal.SelectCancelType"));
      } else {
        req.asuudal.cancel_type = cancel_type;
      }
    }
    if (type == "confirm") {
      req.asuudal.is_confirm = magicnum.Status.Active;
    }
    req.type = type;
    if (isValidate) {
      DataAsuudalUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getMore();
    return toast.success(t("common:SuccessEdit"));
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  setRating(rate) {
    var req = RateAddReq;
    req.rate.relation_id = this.state.item.id;
    req.rate.rate = rate;
    req.rate.type = "data_asuudal";
    if (rate > 0) {
      RateAdd(req, this.setRateSuccess, this.onFailed, "POST");
    }
  }

  setRateSuccess = () => {
    return toast.success(this.state.t("common:SuccessRating"));
  };

  Rates = () => {
    this.setState({ loadingRate: true });
    var req = RateListReq;
    req.filter.relation_id = this.state.item.id;
    req.filter.type = "data_asuudal";
    RateList(req, this.RatesSuccess, this.onFailed, "POST");
  };

  RatesSuccess = (response) => {
    this.setState({
      loadingRate: false,
      RateData: response.data,
      isOpenRate: !this.state.isOpenRate,
    });
  };

  rateToggle = () => {
    if (!this.state.isOpenRate) {
      this.Rates();
    } else {
      this.setState({ isOpenRate: !this.state.isOpenRate });
    }
  };

  getDansName = (id, type) => {
    var dname = "";
    const { dansData } = this.state
    dansData?.map(ditem => {
      if (ditem.id == id && type == 'parent') {
        dname = ditem?.parent
      }
      if (ditem.id == id && type == 'name') {
        dname = ditem?.name
      }
    })
    return dname
  }

  render() {
    const {
      t,
      loading,
      activeTab,
      item,
      apierror,
      apierrormsg,
      isOpenRate,
      loadingRate,
      RateData,
      notolgooText,
      industryData,
    } = this.state;
    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {t("common:asuudal")} {t("common:description")}
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}
            {loading ? (
              <Spinner />
            ) : isNullOrEmpty(item) ? (
              <div>{t("common:NotFound")}</div>
            ) : (
              <Row>
                <Col xs="4">
                  <div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header h-auto py-3 border-0">
                        <div className="card-toolbar">
                          <Rating
                            emptySymbol="fa fa-star-o fa-2x"
                            fullSymbol="fa fa-star fa-2x"
                            step="1"
                            initialRating={item.rating}
                            onClick={this.setRating}
                          />
                          <Button
                            color="primary"
                            onClick={this.rateToggle}
                            style={{ float: "right", marginTop: 0 }}
                            className="btn-sm"
                          >
                            {t("common:more")}
                          </Button>
                          <Collapse
                            isOpen={isOpenRate}
                            style={{ marginTop: 20 }}
                          >
                            <hr />
                            {loadingRate ? (
                              <Spinner />
                            ) : isNullOrEmpty(RateData) ? null : (
                              RateData.map((itee, jindex) => {
                                return (
                                  <div key={jindex}>
                                    <Rating
                                      emptySymbol="fa fa-star-o fa-2x"
                                      fullSymbol="fa fa-star fa-2x"
                                      initialRating={itee.rate}
                                      readonly={true}
                                    />
                                    <span
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {" "}
                                      : {itee.niit}
                                    </span>
                                  </div>
                                );
                              })
                            )}
                          </Collapse>
                        </div>
                      </div>
                    </div>
                    <div className="card card-custom gutter-b">
                      <div className="card-header h-auto py-3 border-0">
                        <div className="card-toolbar">
                          <span
                            className="label font-weight-bold label label-inline label-light-danger"
                            style={{ display: "block" }}
                          >
                            {t("common:asuudal")}:
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <p className="text-dark-50">{item.name}</p>
                      </div>
                    </div>
                    {/* <div className="card card-custom gutter-b">
                      <div className="card-header h-auto py-3 border-0">
                        <div className="card-toolbar">
                          <span className="label font-weight-bold label label-inline label-light-danger">
                            {t("Data.Asuudal.bodit")}:
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <p className="text-dark-50">{item.bodit}</p>
                      </div>
                    </div> */}
                    <div className="card card-custom">
                      <div className="card-body py-4">
                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {t("Data.Asuudal.code")} :
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              {item.code}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {t("Data.Asuudal.ua_chiglel")} :
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              {item.UA.name}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {t("Data.Asuudal.tusgai_zovshoorol")} :
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              {item.TZ.name}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {t("Data.Asuudal.priv_level")} :
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              {t(
                                `common:magicnum.PrivLevel.${item.priv_level}`
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {t("common:type1")} :
                          </label>
                          <div className="col-8">
                            <div className="form-control-plaintext" style={{ display: "flex", columnGap: 5 }}>
                              {item.dans_id.toString().split(":").map((hj, hindex) => {
                                return isNullOrEmpty(hj) ? null : <div key={hindex}>{this.getDansName(hj, "parent")}{hindex > 0 ? <span>,</span> : null}</div>
                              }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {t("common:type2")} :
                          </label>
                          <div className="col-8">
                            <div className="form-control-plaintext" style={{ display: "flex", columnGap: 5 }}>
                              {item.dans_id.toString().split(":").map((hj, hindex) => {
                                return isNullOrEmpty(hj) ? null : <div key={hindex}>{this.getDansName(hj, "name")}{hindex > 0 ? <span>,</span> : null}</div>
                              }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            Эрсдлийн төрөл:
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              {item.risk_type}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            Батламж төрөл:
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.batlamj_type}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            Нотолгоо:
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {notolgooText}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Table>
                      <tbody>
                        <tr>
                          <th>Нэмсэн:</th>
                          <td>
                            {this.renderUsername(item.created_by)}
                            <span style={{ display: "block", fontSize: 9 }}>
                              {dateFormat(item.created_at)}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <th>Баталгаажуулсан:</th>
                          <td>
                            {item.confirm_by ? (
                              <div>
                                {this.renderUsername(item.confirm_by)}
                                <span style={{ display: "block", fontSize: 9 }}>
                                  {dateFormat(item.confirm_at)}
                                </span>
                              </div>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.submit(item, "confirm");
                                }}
                              >
                                {t("Data.Asuudal.confirm")}
                              </Button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Хүчингүй болгосон:</th>
                          <td>
                            {item.cancel_at ? (
                              this.renderUsername(item.cancel_by)
                            ) : (
                              <Button
                                color="warning"
                                onClick={() => {
                                  this.submit(item, "cancel");
                                }}
                              >
                                {t("Data.Asuudal.cancel")}
                              </Button>
                            )}
                            <span style={{ display: "block", fontSize: 9 }}>
                              {dateFormat(item.cancel_at)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            {item.cancel_type
                              ? "Хүчингүй болгосон шалтгаан: " +
                              item.cancel_type
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col xs="8">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        {t("common:heleltsuuleg")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        {t("common:asuulga")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        {t("common:ersdel")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          this.toggle("4");
                        }}
                      >
                        {t("common:zuvlumj")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          this.toggle("5");
                        }}
                      >
                        {t("common:report")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" })}
                        onClick={() => {
                          this.toggle("6");
                        }}
                      >
                        {t("common:mention")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "7" })}
                        onClick={() => {
                          this.toggle("7");
                        }}
                      >
                        {t("common:bodit.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "8" })}
                        onClick={() => {
                          this.toggle("8");
                        }}
                      >
                        {t("common:version")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {/* <div className="container">
                        <form className="form">
                          <div className="form-group">
                            <textarea
                              className="form-control form-control-lg form-control-solid"
                              id="exampleTextarea"
                              rows="3"
                              placeholder="Сэтгэгдэл бичих"
                            ></textarea>
                          </div>
                          <div className="row">
                            <div className="col">
                              <a
                                href="#"
                                className="btn btn-primary font-weight-bold mr-2"
                              >
                                нэмэх
                              </a>
                            </div>
                          </div>
                        </form>
                        <br />
                        <br />                        
                      </div>
                   */}
                      <ChatAsuudal t={t} item={item} type="asuudal" />
                    </TabPane>
                    <TabPane tabId="2">
                      {activeTab == "2" ? (
                        <ConnectAsuulga t={t} item={item} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="3">
                      {activeTab == "3" ? (
                        <ConnectErsdel t={t} item={item} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="4">
                      {activeTab == "4" ? (
                        <ConnectZuvlumj t={t} item={item} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="5">
                      {activeTab == "5" ? (
                        <AsuudalGetReport t={t} item={item} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="6">
                      {activeTab == "6" ? (
                        <ConnectMention t={t} item={item} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="7">
                      {activeTab == "7" ? (
                        <Bodit t={t} item={item} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="8">
                      {activeTab == "8" ? (
                        <VersionAsuudal t={t} item={item} />
                      ) : null}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(withParams(DataAsuudalGetFunc));
