import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
  Button,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import { ModalManager } from "react-dynamic-modal";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { setPageTitle, ProjectGet, ConnectCustomerByYear, ProjectList } from "../../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { magicnum, BaseRequest, ConnectCustomerByYearReq, ProjectListReq } from "../../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../../components/Utils/Utils";
import ConnectAsuudal from "../Connect/ConnectAsuudal";
import ConnectAsuudalAdd from "../Connect/ConnectAsuudalAdd";
import ChatAsuudal from "../../Data/Chat/ChatAsuudal";
import { useParams } from "react-router-dom";
import Files from "./Files/files";
import FilesSend from "./Files/filessend";
import FilesEmail from "./Files/filesemail";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class ProjectGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      loadingYear: false,
      loadingYearProject: false,
      apierrormsg: "",
      apierror: false,
      activeTab: "1",
      item: null,
      itemData: [],
      userData: [],
      yearsData: [],
      concCount: 0,
      activemaintab: "",
      niitJileer: 1,
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addSuccess = this.addSuccess.bind(this);
  }
  componentDidMount() {
    setPageTitle(this.state.t("Project.More"));
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    ProjectGet(
      this.props.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }

  add(yitem) {
    const { t } = this.state;
    var title = t("Data.Asuudal.create_title");
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ConnectAsuudalAdd
            t={this.state.t}
            item={yitem}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  renderUsername(user_id) {
    var user = getUser(this.state.userData, user_id);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  }

  onGetSuccess(response) {
    this.setState({
      loading: false,
      item: response.data,
      userData: response.users,
      concCount: response.data.conn_limit,
      activemaintab: response.data.report_year
    });
    this.getYears();
  }

  getYears = () => {
    this.setState({ loadingYear: true });
    var req = ConnectCustomerByYearReq
    req.filter.customer_id = this.props.params.id
    ConnectCustomerByYear(
      req,
      this.onGetYearSuccess,
      this.onFailed,
      "POST"
    );
  }

  onGetYearSuccess = (response) => {
    var niitJileer = 0;
    response.data.map(hh => {
      niitJileer += hh.count
    })
    if (niitJileer > 1) {
      this.setState({
        loadingYear: false,
        yearsData: response.data,
        niitJileer: niitJileer
      });
      this.getYearProjects()
    }
    else {
      this.setState({
        loadingYear: false,
        yearsData: [{ report_year: this.state.item.report_year, count: 1 }],
        itemData: [this.state.item]
      });
    }
  }

  getYearProjects = () => {
    this.setState({ loadingYearProject: true });
    var req = ProjectListReq
    req.filter.customer_id = this.props.params.id
    req.filter.report_year = ""
    req.filter.mode = "customer"
    req.pid = 1
    ProjectList(
      req,
      this.onGetYearProjectsSuccess,
      this.onFailed,
      "POST"
    );
  }

  onGetYearProjectsSuccess = (response) => {
    this.setState({ loadingYearProject: false, itemData: response.data });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error, loadingYearProject: false, loadingYear: false }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  toggleMain = (id) => {
    this.setState({ activemaintab: id });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  workUserRowFormat = (cell, row) => {
    var userArray = [],
      names = "";
    userArray = cell?.split(":");
    userArray?.map((item) => {
      var user = getUser(this.state.userData, item);
      if (isNullOrEmpty(user)) {
        names += "";
      } else {
        names += LastAndFirstName(user.lastname, user.firstname) + " , ";
      }
    });
    return names;
  };

  addSuccess(text) {
    this.getMore();
    return toast.success(text);
  }

  setConnCount = (niit) => {
    this.setState({ concCount: niit });
  };

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  body = (yitem) => {
    const { t, loading, concCount, activemaintab, activeTab, item } = this.state;
    return activemaintab == yitem?.report_year ? <Card key={yitem.id}>
      <CardHeader style={{ padding: "10px 20px" }}>
        <i className="fa fa-align-justify" />
        {t("Project.More")}
        <div className="float-right">
          {isNullOrEmpty(yitem) ? null : (
            <button
              onClick={() => {
                this.add(yitem);
              }}
              className="btn btn-sm btn-primary"
              disabled={concCount >= yitem.conn_limit}
            >
              {concCount >= yitem.conn_limit
                ? t("common:limitDavsan")
                : t("Data.Asuudal.create_title")}
            </button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : isNullOrEmpty(yitem) ? (
          <div>{t("common:NotFound")}</div>
        ) : (
          <div>
            <Row>
              <Col>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("common:CreatedAt")} :
                  </label>
                  <span>
                    {this.renderUsername(yitem.created_by)} -{" "}
                    {dateFormat(yitem.created_at)}
                  </span>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("Project.check_user")} :
                  </label>
                  <span>{this.renderUsername(yitem.check_user)}</span>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("Project.customer_id")} :
                  </label>
                  <span>{yitem.dname}</span>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("Project.report_year")} :
                  </label>
                  <span>{yitem.report_year}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("Project.conn_limit")} :
                  </label>
                  <span>{yitem.conn_limit}</span>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("common:status")} :
                  </label>
                  <span>
                    {!isNullOrEmpty(yitem.confirm_by)
                      ? t("common:active")
                      : t("common:deactive")}
                  </span>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="col-form-label">
                    {t("Project.work_user")} :
                  </label>
                  <span>
                    {this.workUserRowFormat(yitem.work_user, null)}
                  </span>
                </div>
              </Col>
            </Row>
            <Nav tabs style={{ marginTop: 20 }}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  {t("Data.Asuudal.Title")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  {t("common:heleltsuuleg")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  {t("main:Project.files.title")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  {t("main:Project.files.sendfiles")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggle("5");
                  }}
                >
                  {t("main:Project.files.sendemails")}
                </NavLink>
              </NavItem>
            </Nav>
            <Row>
              <TabPane tabId="1">
                {activeTab == "1" ?
                  <Col>
                    <ConnectAsuudal
                      item={yitem}
                      t={t}
                      setConnCount={this.setConnCount}
                    />
                  </Col> : null}
              </TabPane>

              <TabPane tabId="2">
                {activeTab == "2" ?
                  <div style={{ marginTop: "-20px" }}>
                    <ChatAsuudal t={t} item={yitem} type="project" />
                  </div> : null}
              </TabPane>

              <TabPane tabId="3">
                {activeTab == "3" ? <div style={{ marginTop: "-60px" }}><Files t={t} item={yitem} workuser={this.workUserRowFormat(yitem.work_user, null)} /></div> : null}
              </TabPane>

              <TabPane tabId="4">
                {activeTab == "4" ? <div style={{ marginTop: "-80px" }}><FilesSend t={t} item={yitem} /></div> : null}
              </TabPane>

              <TabPane tabId="5">
                {activeTab == "5" ? <div style={{ marginTop: "-110px" }}><FilesEmail t={t} item={item} yitem={yitem} /></div> : null}
              </TabPane>
            </Row>
          </div>
        )}
      </CardBody>
    </Card> : <></>
  }

  render() {
    const {
      t,
      loading,
      apierror,
      apierrormsg,
      yearsData,
      activemaintab, itemData, niitJileer, item, loadingYear
    } = this.state;
    return (
      <div className="animated fadeIn getorder-container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1999, top: 40 }}
        />
        {apierror && (
          <UncontrolledAlert color="danger">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        {loadingYear ? <div className="text-center"><Spinner></Spinner></div> : <Nav tabs>
          {yearsData.map((yitem, yindex) => {
            return <NavItem key={yindex}>
              <NavLink
                className={classnames({
                  active: activemaintab === yitem.report_year,
                })}
                style={activemaintab === yitem.report_year ? { borderColor: "#009549", borderBottomColor: "white" } : {}}
                onClick={() => {
                  this.toggleMain(yitem.report_year);
                }}
              >
                <div style={{ position: "relative" }}>
                  {yitem.report_year}
                  <div style={{ position: "absolute", backgroundColor: "green", borderRadius: "50%", padding: "1px 7px", color: "white", top: "-18px", right: "-15px" }}>
                    {yitem.count}
                  </div>
                </div>
              </NavLink>
            </NavItem>
          })}
        </Nav>}

        <TabContent
          activeTab={activemaintab}
          style={{ padding: 20, border: "1px solid #009549" }}
        >
          {yearsData.map((yitem, yindex) => {
            return activemaintab === yitem.report_year ? <TabPane tabId={yitem.report_year} key={yindex}>
              {itemData.map(ditem => { return this.body(ditem) })}
            </TabPane> : null
          })}
        </TabContent>
      </div>
    );
  }
}

export default withTranslation("main")(withParams(ProjectGetFunc));
