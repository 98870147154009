import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import { DataAsuudalConnCountList, setPageTitle } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { DataAsuudalConnCountListReq } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san

import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  dateFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
} from "../../components/Utils/Utils";

class DataAsuudalConnListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onChangePage = this.onChangePage.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      type: "ersdel",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("Data.Asuudal.Title"));
    this.getList();
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { PID, type, sizePerPage } = this.state;
    var req = DataAsuudalConnCountListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.type = type;
    DataAsuudalConnCountList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onSuccess(response) {
    console.log(response);
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
    });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
        $(".app-header").height() -
        otherHeight -
        filterHeight
    );
  }

  actionRowFormat = (cell, row) => {
    const { t, EmployeeTypeID } = this.state;
    let edit = (
      <div className="text-center">
        <Link to={`/data/asuudal/${cell}`}>
          <Button
            className="button more-button"
            color="success"
            outline
            title={t("common:more")}
          >
            <i className="fa fa-info" />
          </Button>
        </Link>
      </div>
    );
    return edit;
  };

  nameRowFormat = (cell, row) => {
    let edit = (
      <div className="text-left">
        <Link to={`/data/asuudal/${row.id}`}>
          {cell}
        </Link>
      </div>
    );
    return edit;
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  render() {
    const { t, apierror, apierrormsg, loading, type } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("id"), // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono

        {
          title: t("Data.Asuudal.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Data.Asuudal.niit"),
          dataField: "niit",
          dataSort: false,
          dataFormat: this.niitRowFormat,
          hidden: false,
          width: "60px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:action"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "130px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("common:type")}</Label>
                    <Input
                      type="select"
                      name="type"
                      value={type}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="ersdel">{t("common:ersdel")}</option>
                      <option value="zuvlumj">{t("common:zuvlumj")}</option>
                      <option value="asuulga">{t("common:asuulga")}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>{t("Data.Asuudal.Title")}</CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(DataAsuudalConnListFunc);
