import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  DataZuvlumjList,
  DataZuvlumjDelete,
  setPageTitle,
  DataZuvlumjUpdate,
  TypeZurchilList,
  socket,
  DataZuvlumjCancel,
} from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  DataZuvlumjListReq,
  magicnum,
  BaseRequest,
  DataZuvlumjUpdateReq,
  TypeZurchilListReq,
  DataZuvlumjCancelReq,
} from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san

import $ from "jquery";
import ZuvlumjAdd from "./ZuvlumjAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ConnectedAsuudal from "./Connect/ConnectedAsuudal";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  dateFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
} from "../../components/Utils/Utils";
class DataZuvlumjListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.addSuccess = this.addSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);

    this.getZurchilList = this.getZurchilList.bind(this);
    this.onZurchilSuccess = this.onZurchilSuccess.bind(this);

    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      type_id: "",
      type: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
      userData: [],
      loadingZurchil: false,
      dataZurchil: [],
      cancel_type: "",
      cancel_at: "",
      cancel_torol: ""
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("Data.Zuvlumj.Title"));
    this.getList();
  }

  cancel = (item) => {
    const { t, cancel_type, cancel_at, cancel_torol } = this.state;
    var isValidate = true;
    var req = DataZuvlumjCancelReq;
    if (isNullOrEmpty(cancel_torol)) {
      isValidate = false;
      return toast.error(t("Data.Zuvlumj.SelectCancelTorol"));
    }
    if (isNullOrEmpty(cancel_at)) {
      isValidate = false;
      return toast.error(t("Data.Zuvlumj.SelectCancelAt"));
    }
    if (isNullOrEmpty(cancel_type)) {
      isValidate = false;
      return toast.error(t("Data.Zuvlumj.SelectCancelType"));
    }


    req.cancel_type = cancel_type;
    req.cancel_at = cancel_at;
    req.cancel_torol = cancel_torol;
    req.id = item.id;
    if (isValidate) {
      DataZuvlumjCancel(req, this.onCancelSuccess, this.onFailed, "POST");
    }
  }

  onCancelSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    socket.emit("datazuvlumj", "changestatus");
    return toast.success(t("common:SuccessEdit"));
  }


  getZurchilList() {
    if (!this.state.loadingZurchil) {
      this.setState({ loadingZurchil: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { name } = this.state;
    var req = TypeZurchilListReq; // !!!!!
    req.filter.name = name;
    TypeZurchilList(req, this.onZurchilSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onZurchilSuccess(response) {
    this.setState({ dataZurchil: response.data, loadingZurchil: false });
  }

  Update(item, type) {
    var req = DataZuvlumjUpdateReq;
    req.zuvlumj = item;
    if (type == "confirm") {
      req.zuvlumj.is_confirm = magicnum.Status.Active;
    }
    req.type = type;
    DataZuvlumjUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getList();
    socket.emit("datazuvlumj", "changestatus");
    return toast.success(t("common:SuccessEdit"));
  }

  delete(row) {
    DataZuvlumjDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    socket.emit("datazuvlumj", "delete");
    return toast.error(t("common:SuccessDelete"));
  }
  //buh jagsaaltiig serverees avch ireh function ehlel
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { PID, sizePerPage, name, type_id, type } = this.state;
    var req = DataZuvlumjListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.name = name;
    req.filter.type_id = type_id;
    req.filter.type = type;
    DataZuvlumjList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }
  //buh jagsaaltiig serverees avch ireh function haalt

  //serveriin huselt amjilttai uid iishee orno
  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
      userData: response.users,
    });
    this.getZurchilList();
  }

  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }
  //serveriin huselt amjiltgui uid haalt
  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
      $(".app-header").height() -
      otherHeight -
      filterHeight
    );
  }

  add(type, row) {
    const { t } = this.state;
    var title = t("Data.Zuvlumj.Add");
    if (type == "update") {
      title = t("Data.Zuvlumj.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ZuvlumjAdd
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }

  submit(row, type) {
    const { t } = this.state;
    var title = row.name + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.name + " " + t("Data.Zuvlumj.confirm");
      var message = t("common:confirm");
    } else if (type == "cancel") {
      var title = row.name + " " + t("Data.Zuvlumj.cancel");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.name + " " + t("Data.Zuvlumj.delete");
      var message = t("common:confirm");
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            {type == "cancel" ? (
              <div style={{ textAlign: "left", marginTop: 20 }}>
                <FormGroup>
                  <Label>{t("Data.Asuudal.cancel_torol")} : </Label>
                  <Input
                    type="select"
                    name="cancel_torol"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    style={{ marginBottom: 40 }}
                  >
                    <option value="">{t("common:choose")}</option>
                    {magicnum.Data.CancelType.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {t(`common:magicnum.Data.CancelType.${item}`)}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>{t("Data.Asuudal.cancel_at")} : </Label>
                  <Input type="date" name="cancel_at"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    style={{ marginBottom: 30 }}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label>{t("Data.Asuudal.cancel_type")} : </Label>
                  <Input
                    type="textarea"
                    name="cancel_type"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    style={{ marginBottom: 40 }}
                  >
                  </Input>
                </FormGroup>
              </div>
            ) : null}
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain"
                  ? this.delete(row) : type == "cancel" ? this.cancel(row)
                    : this.Update(row, type);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  ersdelRowFormat = (cell, row) => {
    const { t } = this.state;
    return (
      <div>
        <span
          style={{
            backgroundColor: "#ffc107",
            color: "black",
            marginRight: 3,
            padding: "2px 6px",
            cursor: "pointer"
          }}
          title={t("common:connectasuudal")}
          onClick={() => row.connectcount ? this.connect(row) : null}
        >
          {row.connectcount}
          {!isNullOrEmpty(row.cancel_torol) && row.connectcount > 0 && isNullOrEmpty(row.cancel_parent) && row.cancel_torol == 4 ? <i className="fa fa-refresh" style={{ marginLeft: 10 }} /> : null}
        </span>
      </div>
    );
  };

  customRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          onClick={() => {
            this.add("update", row);
          }}
          className="button edit-button"
          title={t("common:edit")}
        >
          <i className="fa fa-edit" />
        </Button>

        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
        >
          <i className="fa fa-trash" />
        </Button>

        <Button
          style={{ marginLeft: 10 }}
          className="button delete-button btn-info"
          onClick={() => {
            this.submit(row, "confirm");
          }}
          title={t("Data.Zuvlumj.confirm")}
        >
          <i className="fa fa-check-circle" />
        </Button>
        <Button
          className={`button delete-button ${row.cancel_by > 0 && !isNullOrEmpty(row.cancel_type) ? "btn-warning" : ""
            }`}
          onClick={() => {
            this.submit(row, "cancel");
          }}
          title={row.cancel_by > 0 && !isNullOrEmpty(row.cancel_type) ? row.cancel_type + " (" + dateFormat(row.cancel_at) + ")" : t("Data.Ersdel.cancel")}
        >
          <i className="fa fa-close" style={row.status == 0 && row.cancel_by > 0 ? { color: "red" } : {}} />
        </Button>
        <Button
          className="button delete-button btn-danger"
          onClick={() => {
            this.submit(row, "delete");
          }}
          title={t("Data.Zuvlumj.delete")}
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  userRowFormat = (cell, row) => {
    var user = getUser(this.state.userData, cell);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  nameRowFormat = (cell, row) => {
    return <span style={row.cancel_by > 0 && !isNullOrEmpty(row.cancel_type) ? { color: "red" } : {}} title={cell}>{cell}</span>
  };

  typeRowFormat = (cell, row) => {
    const { t } = this.state
    return <span>{t("common:magicnum.Type.Zurchil." + cell)}</span>
  };

  connect = (row) => {
    const { t } = this.state;
    var title = t("common:connectasuudal");
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ConnectedAsuudal t={this.state.t} item={row} type="zuvlumj" addSuccess={this.connectSuccess} />
        }
      />
    );
  }

  connectSuccess = (text, id) => {
    this.getList();
    return toast.success(text);
  }

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      name,
      type_id,
      dataZurchil,
      loadingZurchil, type
    } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("id"), // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("Data.Zuvlumj.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Data.Asuudal.holbolt"),
          dataField: "connectcount",
          dataSort: false,
          dataFormat: this.ersdelRowFormat,
          hidden: false,
          width: "60px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:type"),
          dataField: "type",
          dataSort: false,
          dataFormat: this.typeRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "150px",
        },
        {
          title: t("common:type"),
          dataField: "zname",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "150px",
        },
        {
          title: t("Data.Zuvlumj.created_by"),
          dataField: "created_by",
          dataSort: false,
          dataFormat: this.userRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "120px",
        },
        {
          title: t("common:date"),
          dataField: "created_at",
          dataSort: false,
          dataFormat: (d) => dateFormat(d),
          hidden: false,
          width: "80px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: null,
          dataField: "id",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          width: "250px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.getList}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Zuvlumj.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:type")}</Label>
                    <Input
                      type="select"
                      value={type}
                      name="type"
                      onChange={(event) => {
                        this.handleChange(event, "type");
                      }}
                    >
                      <option value="">
                        {t("common:all")}
                      </option>
                      <option value="text">
                        {t("common:magicnum.Type.Zurchil.text")}
                      </option>
                      <option value="law">
                        {t("common:magicnum.Type.Zurchil.law")}
                      </option>
                      <option value="juram">
                        {t("common:magicnum.Type.Zurchil.juram")}
                      </option>
                      <option value="standart">
                        {t("common:magicnum.Type.Zurchil.standart")}
                      </option>
                      <option value="togtool">
                        {t("common:magicnum.Type.Zurchil.togtool")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:type")}</Label>
                    <Input
                      type="select"
                      name="type_id"
                      value={type_id}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {loadingZurchil
                          ? t("common:loading")
                          : t("common:choose")}
                      </option>
                      {dataZurchil.map((item, index) => {
                        return item.type == type ? (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        ) : null;
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("Data.Zuvlumj.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(DataZuvlumjListFunc);
