import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  ProjectAddReq,
  ProjectUpdateReq,
  listEmployeeReq,
  CustomerListReq,
} from "../../../services/Request";
import {
  ProjectUpdate,
  ProjectAdd,
  listEmployee,
  CustomerList,
} from "../../../services/main";
import { ModalManager } from "react-dynamic-modal";
import {
  isNullOrEmpty,
  isValidEmail,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../../components/Utils/Utils.js";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
import { Multiselect } from "multiselect-react-dropdown";
const renderSuggestion = (suggestion) => (
  <div style={{ cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    {suggestion.name}
  </div>
);
class ProjectAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      customer_id: isNullOrEmpty(props.customer_id) ? "" : props.customer_id,
      report_year: "",
      check_user: "",
      work_users: [],
      tailbar: "",
      audit_no: "",
      audit_date: "",
      id: "",
      type: "",
      validate: [],
      item: props.item,
      isSave: false,
      loadingUser: false,
      dataUser: null,
      loadingCustomer: false,
      dataCustomer: null,
      value: "",
      suggestions: [],
      selectedProd: null,
      selectedValue: null,
      names: this.props.t("common:choose"),
      conn_limit: 10,
      EmployeeTypeID: localStorage.getItem("EmployeeTypeID"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.geUserList = this.geUserList.bind(this);
    this.onUserListSuccess = this.onUserListSuccess.bind(this);
    this.acpn = this.acpn.bind(this);
    this.onAcpnSuccess = this.onAcpnSuccess.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    this.geUserList();
    if (item !== null) {
      this.setState({
        customer_id: item.customer_id,
        type: item.type,
        report_year: item.report_year,
        check_user: item.check_user,
        work_user: item.work_user,
        tailbar: item.tailbar,
        conn_limit: item.conn_limit,
        confirm_by: item.confirm_by,
        confirm_at: dateFormat(item.confirm_at),
        audit_no: item.audit_no,
        audit_date: item.audit_date?.substring(0, 10),
        id: item.id,
        selectedProd: { name: item.dname, id: item.did },
        work_user: item.work_user,
      });
    }
  }

  geUserList() {
    if (!this.state.loadingUser) {
      this.setState({ loadingUser: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = listEmployeeReq;
    req.pid = 1;
    req.pSize = 1000;
    req.filter.Status = magicnum.Status.Active
    listEmployee(req, this.onUserListSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onUserListSuccess(response) {
    this.setState(
      {
        loadingUser: false,
        dataUser: response.mEmployees,
      },
      function () {
        if (!isNullOrEmpty(this.state.item)) {
          this.workUserRowFormat();
        }
      }
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  acpn(value) {
    var req = CustomerListReq;
    req.filter.name = value;
    req.pid = 1;
    req.pSize = 1000;
    if (value.length > 2) {
      CustomerList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess(response) {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedProd: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected = (e, val) => {
    //this.add(val.suggestion);
    this.setState({ selectedProd: val.suggestion });
  };

  onChange = (event, { newValue }) => {
    const { validate } = this.state;
    validate["customer_id"] = false;
    this.setState({
      value: newValue,
      selectedProd: null,
      apierror: false,
      validate,
    });
  };

  save() {
    const {
      t,
      customer_id,
      check_user,
      work_user,
      tailbar,
      report_year,
      selectedProd,
      work_users,
      conn_limit, type, audit_date, audit_no
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProjectAddReq;
      req.project.customer_id = !isNullOrEmpty(customer_id) ? customer_id : selectedProd.id;
      req.project.check_user = check_user;

      req.project.type = type;
      req.project.audit_date = audit_date;
      req.project.audit_no = audit_no;
      req.project.tailbar = tailbar;
      req.project.report_year = report_year;
      req.project.conn_limit = conn_limit;
      var wusers = "";
      work_users.map((item) => {
        if (isNullOrEmpty(wusers)) {
          wusers += ":";
        }
        wusers += item.id + ":";
        req.project.work_user = wusers;
      });

      ProjectAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const {
      t,
      customer_id,
      check_user,
      work_user,
      tailbar,
      report_year,
      conn_limit,
      work_users, audit_date, audit_no, confirm_at, confirm_by,
      id, type
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = ProjectUpdateReq;
      req.project.customer_id = customer_id;
      req.project.check_user = check_user;
      req.project.audit_date = audit_date;
      req.project.audit_no = audit_no;
      req.project.work_user = work_user;
      req.project.type = type;
      req.project.tailbar = tailbar;
      req.project.confirm_at = confirm_at;
      req.project.confirm_by = confirm_by;
      req.project.report_year = report_year;
      req.project.conn_limit = conn_limit;
      var wusers = "";
      work_users?.map((item) => {
        if (isNullOrEmpty(wusers)) {
          wusers += ":";
        }
        wusers += item.id + ":";
        req.project.work_user = wusers;
      });
      req.project.id = id;
      req.type = "update";
      ProjectUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
      loadingUser: false,
      loadingCustomer: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const {
      validate,
      check_user,
      report_year,
      selectedProd,
      work_users, customer_id, type, work_user
    } = this.state;
    if (selectedProd == null && isNullOrEmpty(customer_id)) {
      res.retType = 1;
      validate["customer_id"] = true;
    }
    if (check_user == "") {
      res.retType = 1;
      validate["check_user"] = true;
    }
    if (type == "") {
      res.retType = 1;
      validate["type"] = true;
    }
    if (work_users.length == 0 && isNullOrEmpty(work_user)) {
      res.retType = 1;
      validate["work_user"] = true;
    }
    if (report_year == "") {
      res.retType = 1;
      validate["report_year"] = true;
    }
    this.setState({ validate });
    return res;
  }

  onSelect(selectedList, selectedItem) {
    const { validate } = this.state;
    validate["work_user"] = false;
    this.setState({ work_users: selectedList, validate });
  }

  onRemove(selectedList, removedItem) {
    const { validate } = this.state;
    validate["work_user"] = false;
    this.setState({ work_users: selectedList, validate });
  }

  workUserRowFormat = () => {
    var userArray = [],
      names = "";
    userArray = this.state.item.work_user.split(":");
    userArray.map((item) => {
      var user = getUser(this.state.dataUser, item);
      if (isNullOrEmpty(user)) {
        names += "";
      } else {
        names += LastAndFirstName(user.lastname, user.firstname) + " , ";
      }
    });
    this.setState({ names });
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      loadingUser,
      dataUser,
      customer_id,
      check_user,
      work_user,
      tailbar,
      report_year,
      value,
      suggestions,
      selectedList,
      names,
      conn_limit,
      EmployeeTypeID, type, audit_date, audit_no
    } = this.state;

    const inputProps = {
      placeholder: isNullOrEmpty(item) ? t("Customer.Search") : item.dname,
      value,
      onChange: this.onChange,
    };
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            {isNullOrEmpty(customer_id) ?
              <Col>
                <FormGroup>
                  <Label>{t("Project.customer_id")}</Label>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    onSuggestionSelected={(e, val) =>
                      this.onSuggestionSelected(e, val)
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                  />
                  {validate["customer_id"] ? (
                    <div className="invalid-feedback show">
                      {t("common:SelectValid")}
                    </div>
                  ) : null}
                </FormGroup>
              </Col> : null}

            <Col>
              <FormGroup>
                <Label>{t("Project.type")}</Label>
                <Input
                  type="select"
                  name="type"
                  value={type}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("type", event);
                  }}
                  invalid={validate["type"]}
                >
                  <option value="">
                    {t("common:choose")}
                  </option>
                  <option value="XBA1">
                    XBA1
                  </option>
                  <option value="XBA2">
                    XBA2
                  </option>
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("Project.check_user")}</Label>
                <Input
                  type="select"
                  name="check_user"
                  value={check_user}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("check_user", event);
                  }}
                  invalid={validate["check_user"]}
                >
                  <option value="">
                    {loadingUser ? t("common:loading") : t("common:choose")}
                  </option>
                  {isNullOrEmpty(dataUser)
                    ? null
                    : dataUser.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {LastAndFirstName(item.lastname, item.firstname)}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Project.work_user")}</Label>
                {isNullOrEmpty(dataUser) ? null : (
                  <Multiselect
                    options={dataUser}
                    selectedValues={selectedList}
                    onSelect={this.onSelect}
                    onRemove={this.onRemove}
                    displayValue="name"
                    placeholder={names}
                  />
                )}
                {validate["work_user"] ? (
                  <div className="invalid-feedback show">
                    {t("common:SelectValid")}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Project.report_year")}</Label>
                <Input
                  type="number"
                  name="report_year"
                  value={report_year}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("report_year", event);
                  }}
                  invalid={validate["report_year"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Project.audit_no")}</Label>
                <Input
                  type="text"
                  name="audit_no"
                  value={audit_no}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("audit_no", event);
                  }}
                  invalid={validate["audit_no"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Project.audit_date")}</Label>
                <Input
                  type="date"
                  name="audit_date"
                  value={audit_date}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("audit_date", event);
                  }}
                  invalid={validate["audit_date"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Project.conn_limit")}</Label>
                <Input
                  type="number"
                  name="conn_limit"
                  value={conn_limit}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("conn_limit", event);
                  }}
                  invalid={validate["conn_limit"]}
                  disabled={
                    EmployeeTypeID == "admin" || EmployeeTypeID == "director"
                      ? false
                      : true
                  }
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Project.tailbar")}</Label>
                <Input
                  type="textarea"
                  name="tailbar"
                  value={tailbar}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("tailbar", event);
                  }}
                  invalid={validate["tailbar"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default ProjectAddFunc;
