import React, { Component } from "react";
import { Row, Col, Button, FormGroup, UncontrolledAlert } from "reactstrap";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { ToastContainer, toast } from "react-toastify";
import { isNullOrEmpty } from "../../components/Utils/Utils";
import { DataAsuudalExcelAddReq } from "../../services/Request";
import { DataAsuudalExcelAdd } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
class DataAsuudalAddExcelFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      rows: [],
      cols: [],
      isSave: false,
    };
  }

  componentDidMount() {}

  fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  save = () => {
    const { cols, rows, t } = this.state;
    var array = [];
    rows.map((item, index) => {
      var ite = item;
      if (
        index > 0 &&
        !isNullOrEmpty(ite[0]) &&
        !isNullOrEmpty(ite[1]) &&
        !isNullOrEmpty(ite[2]) &&
        !isNullOrEmpty(ite[3]) &&
        !isNullOrEmpty(ite[4]) &&
        !isNullOrEmpty(ite[5]) &&
        !isNullOrEmpty(ite[6]) &&
        !isNullOrEmpty(ite[7]) &&
        !isNullOrEmpty(ite[8]) &&
        !isNullOrEmpty(ite[12]) &&
        !isNullOrEmpty(ite[13]) &&
        !isNullOrEmpty(ite[14]) &&
        !isNullOrEmpty(ite[15]) &&
        Number.isInteger(ite[2]) &&
        Number.isInteger(ite[5]) &&
        Number.isInteger(ite[6]) &&
        Number.isInteger(ite[8])
      ) {
        array.push(ite);
      }
    });
    if (array.length > 0) {
      this.setState({ isSave: true });
      var req = DataAsuudalExcelAddReq;
      req.datas = array;
      DataAsuudalExcelAdd(req, this.onSuccess, this.onFailed, "POST");
    } else {
      return toast.error(t("Data.Asuudal.invalidExcel"));
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  };

  render() {
    const { t, apierror, apierrormsg, cols, rows } = this.state;
    return (
      <div className="animated fadeIn">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1999, top: 40 }}
        />
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col md={2}>
              <input
                type="file"
                onChange={this.fileHandler.bind(this)}
                style={{ padding: "10px", paddingLeft: 0 }}
              />
              <Button
                color="primary"
                onClick={this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </Col>
            <Col>
              <div style={{ maxHeight: 300, overflow: "auto" }}>
                {cols.length > 0 && rows.length > 0 ? (
                  <OutTable
                    data={this.state.rows}
                    columns={this.state.cols}
                    tableClassName="ExcelTable2007"
                    tableHeaderRowClass="heading"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DataAsuudalAddExcelFunc;
