import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import ChatAsuudal from "../Data/Chat/ChatAsuudal";
import { setPageTitle, CustomerGet } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { magicnum, BaseRequest } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../components/Utils/Utils";
import ConnectAsuudal from "./Connect/ConnectAsuudal";
import ProjectList from "./Project/ProjectList";
import CustomerUsers from "./CustomerUsers";
import { useParams } from "react-router-dom";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class CustomerGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      activeTab: "1",
      item: null,
      userData: [],
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    CustomerGet(
      this.props.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }

  renderUsername(user_id) {
    var user = getUser(this.state.userData, user_id);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  }

  onGetSuccess(response) {
    this.setState(
      {
        loading: false,
        item: response.data,
        userData: response.users,
      },
      function () {
        setPageTitle(this.state.item.name);
      }
    );
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { t, loading, activeTab, item, apierror, apierrormsg } = this.state;

    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {t("common:more")}
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}
            {loading ? (
              <Spinner />
            ) : isNullOrEmpty(item) ? (
              <div>{t("common:NotFound")}</div>
            ) : (
              <Row>
                <Col xs="3">
                  <div>
                    <div className="card card-custom">
                      <div className="card-body py-4">
                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("common:CreatedAt")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              {this.renderUsername(item.created_by)}
                              <span style={{ display: "block", fontSize: 9 }}>
                                {dateFormat(item.created_at)}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.name")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              {item.name} {item.company_type}{" "}
                              {item.company_size}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.register_number")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              {item.register_number}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.email")} :
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext ">
                              {item.email}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.mobile")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.mobile}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.telephone")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.telephone}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.director_name")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.director_name}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.accountant_name")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.accountant_name}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.industry_id")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {t(
                                  `common:magicnum.Industry.${item.industry_id}`
                                )}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.city_id")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {t(`common:magicnum.City.${item.city_id}`)}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.address")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.address}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("Customer.description")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.description}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="9">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        {t("main:Customer.more.tabproject")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        {t("main:Customer.more.tabusers")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        {t("common:heleltsuuleg")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <ProjectList customer_id={this.props.params.id} />
                    </TabPane>
                    <TabPane tabId="2">
                      {activeTab == "2" ? <CustomerUsers customer_id={this.props.params.id} customer_rd={item.register_number} /> : null}
                    </TabPane>
                    <TabPane tabId="3">
                      {activeTab == "3" ? <ChatAsuudal t={t} item={item} type="project" /> : null}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(withParams(CustomerGetFunc));
