import React, { Component } from "react";
import { Row, Col, Button, FormGroup, UncontrolledAlert } from "reactstrap";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
class DataAsuudalAddExcelFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      rows: [],
      cols: [],
    };
  }

  componentDidMount() {}

  fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  render() {
    const { t, apierror, apierrormsg, cols, rows } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <input
              type="file"
              onChange={this.fileHandler.bind(this)}
              style={{ padding: "10px" }}
            />
            {cols.length > 0 && rows.length > 0 ? (
              <OutTable
                data={this.state.rows}
                columns={this.state.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading"
              />
            ) : null}
          </Row>
        </div>
      </div>
    );
  }
}

export default DataAsuudalAddExcelFunc;
