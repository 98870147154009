import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  TypeZurchilList,
  TypeZurchilDelete,
  setPageTitle,
} from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  TypeZurchilListReq,
  magicnum,
  BaseRequest,
} from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Spinner,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san

import $ from "jquery";
import ZurchilAdd from "./ZurchilAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable";

class TypeZurchilListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.addSuccess = this.addSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0,
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false, //serverees aldaa garsan eseh iltgegch
    };
  }
  //bagiiulagch function haalt

  //hamgiin ehend ajillah function
  componentDidMount() {
    setPageTitle(this.props.t("Type.Zurchil.Title"));
    this.getList();
  }

  delete(row) {
    TypeZurchilDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }
  //buh jagsaaltiig serverees avch ireh function ehlel
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { name,sizePerPage,PID } = this.state;
    var req = TypeZurchilListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.name = name;
    TypeZurchilList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!

  }
  //buh jagsaaltiig serverees avch ireh function haalt


  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  //serveriin huselt amjilttai uid iishee orno
  onSuccess(response) {    
     this.setState({
      loading: false,
      data: response.data,
      PTotal: response.pagination.ptotal,
    });
  }
  //serveriin huselt amjilttai uid iishee orno haalt

  //serveriin huselt amjiltgui uid iishee orno
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }
  //serveriin huselt amjiltgui uid haalt

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
        $(".app-header").height() -
        otherHeight -
        filterHeight
    );
  }

  add(type, row) {
    const { t } = this.state;
    var title = t("Type.Zurchil.Add");
    if (type == "update") {
      title = t("Type.Zurchil.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ZurchilAdd
            t={this.state.t}
            item={row}
            type={type}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }

  submit(row) {
    const { t } = this.state;
    confirmAlert({
      title: row.name + " " + t("common:delete"),
      message: t("common:confirmdelete"),
      buttons: [
        {
          label: t("common:yes"),
          onClick: () => this.delete(row),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }

  customRowFormat = (cell,row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          onClick={() => {
            this.add("new", row);
          }}
          className="button edit-button"
        >
          <i className="fa fa-plus" /> {t("common:add")}
        </Button>

        <Button
          onClick={() => {
            this.add("update", row);
          }}
          className="button edit-button"
        >
          <i className="fa fa-info" /> {t("common:edit")}
        </Button>

        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row);
          }}
        >
          <i className="fa fa-trash" /> {t("common:delete")}
        </Button>
      </div>
    );
    return edit;
  };


  render() {
    const { t, apierror, apierrormsg, data, loading } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: "", // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("Type.Zurchil.Code"),
          dataField: "code",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width:"80px"
        },
        {
          title: t("Type.Zurchil.Name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Type.Zurchil.Description"),
          dataField: "description",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: null,
          dataField: "id",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width:"220px"
        }
      ],
    };
    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.getList}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">
              <div className="item"></div>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("Type.Zurchil.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                  <CustomBootsrapTable
                    data={this.state.data} // serverees irsen datag ogno
                    table={table} // tabliin tohirgoog ogno
                    PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                    pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                    PID={this.state.PID} // huudaslatiin hed deh huudas ve
                    loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                  />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(TypeZurchilListFunc);
