import React, { Component } from "react";
import { withTranslation } from "react-i18next"; //helnii filetai holbootoi san
import {
  DataAsuudalList,
  DataAsuudalDelete,
  setPageTitle,
  DataAsuudalUpdate,
  TypeDansList,
  socket, IndustryList, DataAsuudalCancel
} from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import {
  DataAsuudalListReq,
  magicnum,
  BaseRequest,
  DataAsuudalUpdateReq,
  TypeDansListReq, IndustryListReq, DataAsuudalCancelReq
} from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"; //zuragdaj bgaa componentuud tovch geh met san
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable"; //husnegtiig zurj haruulagch san

import $ from "jquery";
import AsuudalAdd from "./AsuudalAdd";
import VersionAsuudal from "./Connect/VersionAsuudal";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import classnames from "classnames";

import "react-confirm-alert/src/react-confirm-alert.css";
import {
  dateFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
} from "../../components/Utils/Utils";
import AsuudalAddExcel from "./AsuudalAddExcel";
import { useParams } from "react-router-dom";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class DataAsuudalListFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onSuccess = this.onSuccess.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.onFailed = this.onFailed.bind(this); //door ashiglah functionuudiig end zaaj ogno
    this.addSuccess = this.addSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    var today = new Date();
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1, //idevhitei bgaa huudas
      PTotal: 0, //niit heden huudas bolhiig iltgegch
      loading: false, //serverees medee ireh hurtel unshij bgaa eseh
      data: [], //serverees irsen jagsaalt bn
      t: this.props.t, //helnii file
      name: "",
      code: "",
      status: "",
      cancel_type: "",
      cancel_at: "",
      apierrormsg: "", //serverees aldaa garsan uid aldaanii text
      apierror: false,
      userData: [],
      dansData: [],
      loadingTypeDans: false,
      dataTypeDans: [],
      industryLoading: false,
      industryData: [],
      ua_chiglel: "", tusgai_zovshoorol: "", priv_level: "",
      parent_dans_id: "",
      is_confirm: this.props.params.is_confirm,
      EmployeeTypeID: localStorage.getItem("EmployeeTypeID"),
      EmployeeID: localStorage.getItem("EmployeeID"),
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("Data.Asuudal.Title"));
    this.geTypeDanstList();
    this.getList();
    this.getIndustyList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.params.is_confirm != this.props.params.is_confirm
    ) {
      this.setState(
        { is_confirm: this.props.params.is_confirm, PID: 1 },
        function () {
          this.getList();
        }
      );
    }
  }

  getIndustyList = () => {
    if (!this.state.industryLoading) {
      this.setState({ industryLoading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = IndustryListReq; // !!!!!
    req.pid = 1;
    req.pSize = 1000;
    req.filter.parent_id = -1;
    IndustryList(req, this.onIndustySuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }


  onIndustySuccess = (response) => {
    this.setState({
      industryLoading: false,
      industryData: response.data,
    });
  }

  Update(item, type) {
    const { t, cancel_type } = this.state;
    var isValidate = true;
    var req = DataAsuudalUpdateReq;
    req.asuudal = item;
    if (type == "cancel") {
      if (isNullOrEmpty(cancel_type)) {
        isValidate = false;
        return toast.error(t("Data.Asuudal.SelectCancelType"));
      } else {
        req.asuudal.cancel_type = cancel_type;
      }
    }
    if (type == "confirm") {
      req.asuudal.is_confirm = magicnum.Status.Active;
    }
    if (type == "status") {
      req.asuudal.status =
        item.status == magicnum.Status.Active
          ? magicnum.Status.InActive
          : magicnum.Status.Active;
    }
    req.type = type;
    if (isValidate) {
      DataAsuudalUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getList();
    socket.emit("dataasuudal", "changestatus");
    return toast.success(t("common:SuccessEdit"));
  }

  cancel = (item) => {
    const { t, cancel_type, cancel_at } = this.state;
    var isValidate = true;
    var req = DataAsuudalCancelReq;
    if (isNullOrEmpty(cancel_at)) {
      isValidate = false;
      return toast.error(t("Data.Asuudal.SelectCancelAt"));
    }
    if (isNullOrEmpty(cancel_type)) {
      isValidate = false;
      return toast.error(t("Data.Asuudal.SelectCancelType"));
    }

    req.cancel_type = cancel_type;
    req.cancel_at = cancel_at;
    req.id = item.id;
    if (isValidate) {
      DataAsuudalCancel(req, this.onCancelSuccess, this.onFailed, "POST");
    }
  }

  onCancelSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    socket.emit("dataasuudal", "changestatus");
    return toast.success(t("common:SuccessEdit"));
  }

  delete(row) {
    DataAsuudalDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    socket.emit("dataasuudal", "delete");
    return toast.error(t("common:SuccessDelete"));
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const {
      PID,
      sizePerPage,
      risk_type,
      dans_id,
      parent_dans_id,
      created_by,
      status,
      name,
      code,
      is_confirm, ua_chiglel, priv_level, tusgai_zovshoorol
    } = this.state;
    var req = DataAsuudalListReq; // !!!!!
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.risk_type = risk_type;
    req.filter.dans_id = dans_id;
    req.filter.parent_dans_id = parent_dans_id;
    req.filter.created_by = created_by;
    req.filter.status = status;
    req.filter.name = name;
    req.filter.code = code;
    req.filter.ua_chiglel = ua_chiglel;
    req.filter.priv_level = priv_level;
    req.filter.tusgai_zovshoorol = tusgai_zovshoorol;
    req.filter.is_confirm = is_confirm;
    req.filter.version_parent = "";
    req.filter.version_root = "";
    DataAsuudalList(req, this.onSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
      userData: response.users,
      dansData: response.dans,
    });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
      $(".app-header").height() -
      otherHeight -
      filterHeight
    );
  }

  add(type, row) {
    const { t, industryData } = this.state;
    var title = t("Data.Asuudal.Add");
    if (type == "update") {
      title = t("Data.Asuudal.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <AsuudalAdd
            t={this.state.t}
            item={row}
            industryData={industryData}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }

  submit(row, type) {
    const { t, cancel_type, onoodor } = this.state;
    var title = t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = t("Data.Asuudal.confirm") + " - " + row.name;
      var message = t("common:confirm");
    } else if (type == "cancel") {
      var title = t("Data.Asuudal.cancel") + " - " + row.name;
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = t("Data.Asuudal.delete") + " - " + row.name;
      var message = t("common:confirm");
    } else if (type == "status") {
      var title = t("Data.Asuudal.status") + " - " + row.name;
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            {type == "cancel" ? (
              <div style={{ textAlign: "left", marginTop: 20 }}>
                <FormGroup>
                  <Label>{t("Data.Asuudal.cancel_at")} : </Label>
                  <Input type="date" name="cancel_at"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    style={{ marginBottom: 30 }}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label>{t("Data.Asuudal.cancel_type")} : </Label>
                  <Input
                    type="textarea"
                    name="cancel_type"
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    style={{ marginBottom: 40 }}
                  >
                    {/* <option value="">{t("common:choose")}</option>
                  {magicnum.Data.CancelType.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })} */}
                  </Input>
                </FormGroup>
              </div>
            ) : null}
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain"
                  ? this.delete(row) : type == "cancel" ? this.cancel(row)
                    : this.Update(row, type);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  statusRowFormat = (cell, row) => {
    const { t, EmployeeTypeID, EmployeeID } = this.state;
    let edit = (
      <div className="text-center">
        {EmployeeTypeID == "admin" ? (
          <Button
            style={{ marginLeft: 10 }}
            className={`button delete-button ${row.status == magicnum.Status.Active
              ? "btn-success"
              : "btn-danger"
              }`}
            onClick={() => {
              this.submit(row, "status");
            }}
          >
            {row.status == magicnum.Status.Active
              ? t("common:magicnum.Status.Active")
              : t("common:magicnum.Status.InActive")}
          </Button>
        ) : null}

        {(EmployeeTypeID == "manager" && row.is_confirm == magicnum.Status.InActive) || EmployeeID == row.created_by ? (
          <Button
            style={{ marginLeft: 10 }}
            className={`button delete-button ${row.is_confirm == magicnum.Status.Active ? "btn-success" : ""
              }`}
            onClick={() => {
              this.submit(row, "confirm");
            }}
            title={t("Data.Asuudal.confirm")}
          >
            <i className="fa fa-check-circle" />
          </Button>
        ) : <Button
          style={{ marginLeft: 10, cursor: "not-allowed" }}
          className={`button delete-button ${row.is_confirm == magicnum.Status.Active ? "btn-success" : ""
            }`}
          title={t("Data.Asuudal.confirm")}
        >
          <i className="fa fa-check-circle" />
        </Button>}
        <Button
          className={`button delete-button ${!isNullOrEmpty(row.cancel_by) ? "btn-warning" : ""
            }`}
          onClick={() => {
            this.submit(row, "cancel");
          }}
          title={isNullOrEmpty(row.cancel_by) ? t("Data.Asuudal.cancel") : row.cancel_type + " (" + dateFormat(row.cancel_at) + ")"}
        >
          <i className="fa fa-close" style={row.status == 0 && row.cancel_by > 0 ? { color: "red" } : {}} />
        </Button>
        <Button
          className={`button delete-button ${!isNullOrEmpty(row.deleted_by) ? "btn-danger" : ""
            }`}
          onClick={() => {
            this.submit(row, "delete");
          }}
          title={t("Data.Asuudal.delete")}
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  actionRowFormat = (cell, row) => {
    const { t, EmployeeTypeID } = this.state;
    let edit = (
      <div className="text-center">
        <Link to={`/data/asuudal/${cell}`}>
          <Button
            className="button more-button"
            color="success"
            outline
            title={t("common:more")}
          >
            <i className="fa fa-info" />
          </Button>
        </Link>
        {row.is_confirm == magicnum.Status.InActive ? (
          <Button
            onClick={() => {
              this.add("update", row);
            }}
            className="button edit-button"
            title={t("common:edit")}
            color="success"
            outline
          >
            <i className="fa fa-edit" />
          </Button>
        ) : null}
        {row.is_confirm == magicnum.Status.InActive ?
          <Button
            className="button delete-button"
            onClick={() => {
              this.submit(row, "deleteMain");
            }}
            title={t("common:delete")}
            color="success"
            outline
          >
            <i className="fa fa-trash" />
          </Button> : null}
      </div>
    );
    return edit;
  };

  nameRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-left">
        <Link to={`/data/asuudal/${row.id}`}><span style={row.status == 0 && row.cancel_by > 0 ? { color: "red" } : {}} title={cell}>{row.name}</span></Link>
      </div>
    );
    return edit;
  };

  userRowFormat = (cell, row) => {
    var user = getUser(this.state.userData, cell);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  };

  codeRowFormat = (cell, row) => {
    return <span style={row.status == 0 && row.cancel_by > 0 ? { color: "red" } : {}} >{cell}</span>
  };

  versionShow = (item) => {
    const { t } = this.state;
    var title = t("common:version");
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <VersionAsuudal item={item} t={t} />
        }
      />
    );
  }

  ersdelRowFormat = (cell, row) => {
    const { t } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 10, alignItems: "center" }}>
        <div>
          <span
            style={{
              backgroundColor: "#ffc107",
              color: "white",
              marginRight: 3,
              padding: "2px 6px",
            }}
            title={t("common:ersdel")}
          >
            {row.ersdel}
          </span>
          <span
            style={{
              backgroundColor: "#4dbd74",
              color: "white",
              padding: "2px 6px",
            }}
            title={t("common:zuvlumj")}
          >
            {row.zuvlumj}
          </span>
        </div>
        {isNullOrEmpty(row.version_root) ? null :
          <div onClick={() => this.versionShow(row)}>
            <span
              style={{
                backgroundColor: "#ffc107",
                color: "white",
                marginRight: 3,
                padding: "2px 6px",
                cursor: "pointer"
              }}
            >
              {t("common:version")}
            </span>
          </div>
        }
      </div>
    );
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  geTypeDanstList = () => {
    if (!this.state.loadingTypeDans) {
      this.setState({ loadingTypeDans: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    var req = TypeDansListReq;
    req.pid = 1;
    req.pSize = 1000;
    TypeDansList(req, this.onTypeDansSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  };

  onTypeDansSuccess = (response) => {
    this.setState({
      loadingTypeDans: false,
      dataTypeDans: response.data,
    });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  addExcel = (type, row) => {
    const { t } = this.state;
    var title = t("Data.Asuudal.Add");
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <AsuudalAddExcel
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  };

  industryRowFormat = (cell, row) => {
    var name = "";
    if (!isNullOrEmpty(this.state.industryData)) {
      var UA = getUser(this.state.industryData, cell);
      //console.log(cell, UA)
      name = isNullOrEmpty(UA) ? "a" : UA.name
    }

    return <span title={name}>{name}</span>;
  }

  privLevelRowFormat = (cell, row) => {
    const { t } = this.state
    var n = t(`common:magicnum.PrivLevel.${cell}`)
    return <span title={n}>{n}</span>;
  }

  getDansName = (id, type) => {
    var dname = "";
    const { dansData } = this.state
    dansData?.map(ditem => {
      if (ditem.id == id && type == 'parent') {
        dname = ditem?.parent
      }
      if (ditem.id == id && type == 'name') {
        dname = ditem?.name
      }
    })
    return dname
  }

  dansParentRowFormat = (cell, row) => {
    if (!isNullOrEmpty(cell)) {
      const ff = cell.toString()?.split(":")
      return <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
        {ff?.map((hh, hindex) => {
          return isNullOrEmpty(hh) ? null : <span key={hindex}>{this.getDansName(hh, "parent")}</span>
        })}
      </div>
    }
  }

  dansRowFormat = (cell, row) => {
    if (!isNullOrEmpty(cell)) {
      const ff = cell.toString()?.split(":")
      return <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
        {ff?.map((hh, hindex) => {
          return isNullOrEmpty(hh) ? null : <span key={hindex}>{this.getDansName(hh, "name")}</span>
        })}
      </div>
    }
  }

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      risk_type,
      dans_id,
      loadingTypeDans,
      dataTypeDans,
      parent_dans_id,
      userData,
      loading,
      created_by,
      status,
      is_confirm,
      name,
      code, industryData, ua_chiglel, tusgai_zovshoorol, priv_level
    } = this.state; //deer state deer bgaa utguudiig haruulj ashiglahiin tuld oruulj irj bn

    //husnegtiin medeelliig todorhoil bgaa ehlel
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too

      //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("id"), // Garchig der haragdana
        dataField: "id", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      }, //ene hesegt davtagdahgui hesgiig zaaj ogno ihevchlen ID talbar bn haalt

      //ene hesegt busad baganii talbariig zooj ogno haragdah
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("Data.Asuudal.code"),
          dataField: "code",
          dataSort: false,
          dataFormat: this.codeRowFormat,
          hidden: false,
          width: "70px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },

        {
          title: t("Data.Asuudal.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Data.Asuudal.holbolt"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.ersdelRowFormat,
          hidden: false,
          width: "60px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },

        {
          title: t("common:type1"),
          dataField: "dans_id",
          dataSort: false,
          dataFormat: this.dansParentRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "80px",
        },
        {
          title: t("common:type2"),
          dataField: "dans_id",
          dataSort: false,
          dataFormat: this.dansRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "200px",
        },
        {
          title: t("Data.Asuudal.ua_chiglel"),
          dataField: "ua_chiglel",
          dataSort: false,
          dataFormat: this.industryRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "100px",
        },
        {
          title: t("Data.Asuudal.tusgai_zovshoorol"),
          dataField: "tusgai_zovshoorol",
          dataSort: false,
          dataFormat: this.industryRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "120px",
        },
        {
          title: t("Data.Asuudal.priv_level"),
          dataField: "priv_level",
          dataSort: false,
          dataFormat: this.privLevelRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "80px",
        },
        {
          title: t("Data.created_by"),
          dataField: "created_by",
          dataSort: false,
          dataFormat: this.userRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
          width: "100px",
        },
        {
          title: t("common:date"),
          dataField: "created_at",
          dataSort: false,
          dataFormat: (d) => dateFormat(d),
          hidden: false,
          width: "80px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:status"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.statusRowFormat,
          hidden: false,
          width: "230px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("common:action"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "130px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    //husnegtiin medeelliig todorhoil bgaa haalt

    //ene neg haragdah baidliin zagvar
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    // uunees dooshoo yah renderlej zurj bgaa heseg
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle">
            <div className="filter">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.ua_chiglel")}</Label>
                    <Input
                      type="select"
                      name="ua_chiglel"
                      value={ua_chiglel}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {t("common:choose")}
                      </option>
                      {industryData.map((itt, iindex) => {
                        return itt.parent_id == 0 ? <option key={iindex} value={itt.id}>{itt.name}</option> : null
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.tusgai_zovshoorol")}</Label>
                    <Input
                      type="select"
                      name="tusgai_zovshoorol"
                      value={tusgai_zovshoorol}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {t("common:choose")}
                      </option>
                      {industryData.map((itt, iindex) => {
                        return itt.parent_id == ua_chiglel && !isNullOrEmpty(ua_chiglel) ? <option key={iindex} value={itt.id}>{itt.name}</option> : null
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.priv_level")}</Label>
                    <Input
                      type="select"
                      name="priv_level"
                      value={priv_level}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {t("common:choose")}
                      </option>
                      {magicnum.PrivLevel.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.PrivLevel.${item}`)}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.code")}</Label>
                    <Input
                      type="number"
                      name="code"
                      value={code}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:type1")}</Label>
                    <Input
                      type="select"
                      value={parent_dans_id}
                      name="parent_dans_id"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      {magicnum.Type.DansParent.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("common:type2")}</Label>
                    <Input
                      type="select"
                      name="dans_id"
                      value={dans_id}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {loadingTypeDans
                          ? t("common:loading")
                          : t("common:choose")}
                      </option>
                      {loadingTypeDans
                        ? null
                        : dataTypeDans.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name} - ({item.parent})
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.risk_type")}</Label>
                    <Input
                      type="select"
                      value={risk_type}
                      name="risk_type"
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      {magicnum.Data.RiskType.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:user")}</Label>
                    <Input
                      type="select"
                      name="created_by"
                      value={created_by}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">
                        {loading ? t("common:loading") : t("common:choose")}
                      </option>
                      {loading
                        ? null
                        : userData?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {LastAndFirstName(
                                item.lastname,
                                item.firstname
                              )}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("common:status")}</Label>
                    <Input
                      type="select"
                      name="status"
                      value={status}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      <option value={magicnum.Status.Active}>
                        {t("common:magicnum.Status.Active")}
                      </option>
                      <option value={magicnum.Status.InActive}>
                        {t("common:magicnum.Status.InActive")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Data.Asuudal.confirm")}</Label>
                    <Input
                      type="select"
                      name="is_confirm"
                      value={is_confirm}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      <option value={magicnum.Status.Active}>
                        {t("common:yes")}
                      </option>
                      <option value={magicnum.Status.InActive}>
                        {t("common:no")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col><Col></Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            <div style={{ display: "contents" }}>
              <Link to={`/asuudal/0`} style={{ marginRight: 20 }}>
                <span
                  style={{ color: "#009549", cursor: "cursor" }}
                  className={classnames({ activeSpan: is_confirm === "0" })}
                >
                  {t("common:magicnum.Status.InActive")}{" "}
                  {t("Data.Asuudal.Title")}
                </span>
              </Link>
              <Link to={`/asuudal/1`}>
                <span
                  style={{ color: "#009549", cursor: "cursor" }}
                  className={classnames({ activeSpan: is_confirm === "1" })}
                >
                  {t("common:magicnum.Status.Active")} {t("Data.Asuudal.Title")}
                </span>
              </Link>
            </div>
            <div className="float-right">
              <button
                onClick={() => {
                  this.addExcel("new", null);
                }}
                className="btn btn-sm btn-primary"
                style={{ marginRight: 10 }}
              >
                {t("common:excelimport")}
              </button>
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody className="nobg">
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(withParams(DataAsuudalListFunc));
