import React, { Component } from 'react';
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import "./LoginOrCreateBanner.scss";

import $ from "jquery";
class LoginOrCreateBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: this.props.t,
            i18n: this.props.i18n,
            width: window.innerWidth
        };
    }


    setClass() {
        var x = document.getElementById("myLinks");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    }

    render() {
        const { t, i18n } = this.state;
        var year = new Date();
        const changeLanguage = lng => {
            i18n.changeLanguage(lng);
            localStorage.setItem("Lang", lng);
        };
        return (
            <div className="body">
                {/* <div className="backgroundImage" /> */}
                {/* <div className="footer">
                    <div className="footer-link">
                        <div className="left">
                            <a href="#"></a>
                        </div>
                        <div className="right">
                            <div className="body" id="myLinks">
                                <div className="item">
                                    <a className="nolink"> &copy; {t("footer.copyrigth")} {year.getFullYear()}</a>
                                    <a className="dividerFooter"></a>
                                </div>
                                <div className="item">
                                    <a className="nolink">{t("footer.changeLang")}</a>
                                    <a onClick={() => changeLanguage("mn")}>MN</a>
                                    <a onClick={() => changeLanguage("en")}>EN</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default LoginOrCreateBanner;