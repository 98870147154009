import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  CustomerUserAddReq,
  CustomerUserUpdateReq,
  CustomerListReq,
} from "../../services/Request.js";
import {
  CustomerList,
  CustomerUserAdd,
  CustomerUserUpdate,
} from "../../services/main.js";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
import Autosuggest from "react-autosuggest";
import "./Project/CustomAutoSuggest.scss";
const renderSuggestion = (suggestion) => (
  <div style={{ cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    {suggestion.name}
  </div>
);
class DataZuvlumjAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: props.t,
      lastname: "",
      firstname: "",
      rd: "",
      type: "",
      phone: "",
      customer_id: isNullOrEmpty(props.customer_id) ? "" : props.customer_id,
      customer_rd: isNullOrEmpty(props.customer_rd) ? "" : props.customer_rd,
      status: magicnum.Status.Active,
      source: "mc",
      created_by: localStorage.getItem("EmployeeID"),
      validate: [],
      item: props.item,
      isSave: false,
      value: "",
      suggestions: [],
      selectedCustomer: null,
      selectedValue: null,
    };
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        lastname: item.lastname,
        firstname: item.firstname,
        rd: item.rd,
        customer_id: item.customer_id,
        customer_rd: item.customer_rd,
        type: item.type,
        phone: item.phone,
        status: item.status,
        source: item.source,
        id: item.id,
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save = () => {
    const { firstname, lastname, type, phone, rd, status, source, selectedCustomer, created_by, customer_id,customer_rd } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = CustomerUserAddReq;
      req.customer.created_by = created_by;
      req.customer.customer_id = !isNullOrEmpty(customer_id) ? customer_id : selectedCustomer?.id;
      req.customer.customer_rd = !isNullOrEmpty(customer_rd) ? customer_rd : selectedCustomer?.register_number;
      req.customer.firstname = firstname;
      req.customer.lastname = lastname;
      req.customer.phone = phone;
      req.customer.rd = rd;
      req.customer.source = source;
      req.customer.status = status;
      req.customer.type = type;
      req.customer.created_by = created_by;
      CustomerUserAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess = (response) => {
    const { t } = this.state;
    // socket.emit("datazuvlumj", "add");
    this.props.addSuccess(t("common:SuccessSave"), response);
    ModalManager.close();
  }

  Update = () => {
    const { t, rd, lastname, firstname, phone, source, type, status, id, selectedCustomer, customer_id, customer_rd } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = CustomerUserUpdateReq;
      req.customer.rd = rd
      req.customer.customer_id = !isNullOrEmpty(selectedCustomer) ? selectedCustomer?.id : customer_id
      req.customer.customer_rd = !isNullOrEmpty(selectedCustomer) ? selectedCustomer?.register_number : customer_rd
      req.customer.lastname = lastname
      req.customer.firstname = firstname
      req.customer.phone = phone
      req.customer.source = source
      req.customer.type = type
      req.customer.status = status
      req.customer.id = id
      CustomerUserUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
      loadingZurchil: false,
      loadingDuplicate: false,
    });
  }

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate = () => {
    var res = { retType: 0 };
    const { validate, firstname, lastname, rd, phone, type, selectedCustomer, item, customer_id } = this.state;
    if (selectedCustomer == null && isNullOrEmpty(item) && isNullOrEmpty(customer_id)) {
      res.retType = 1;
      validate["customer_id"] = true;
    }
    if (firstname == "") {
      res.retType = 1;
      validate["firstname"] = true;
    }
    if (lastname == "") {
      res.retType = 1;
      validate["lastname"] = true;
    }
    if (rd == "") {
      res.retType = 1;
      validate["rd"] = true;
    }
    if (phone == "") {
      res.retType = 1;
      validate["phone"] = true;
    }
    if (type == "") {
      res.retType = 1;
      validate["type"] = true;
    }
    this.setState({ validate });
    return res;
  }

  acpn(value) {
    var req = CustomerListReq;
    req.filter.name = value;
    req.pid = 1;
    req.pSize = 1000;
    if (value.length > 2) {
      CustomerList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess = (response) => {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedCustomer: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected = (e, val) => {
    //this.add(val.suggestion);
    this.setState({ selectedCustomer: val.suggestion });
  };

  onChange = (event, { newValue }) => {
    const { validate } = this.state;
    validate["customer_id"] = false;
    this.setState({
      value: newValue,
      selectedCustomer: null,
      apierror: false,
      validate,
    });
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      type,
      firstname, lastname,
      rd,
      phone, status, value, suggestions, customer_id
    } = this.state;
    const inputProps = {
      placeholder: isNullOrEmpty(item) ? t("Customer.Search") : item.cname,
      value,
      onChange: this.onChange,
    };
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <div
                className="animated fadeIn"
                style={{ borderBottom: "1px solid #0d9749", marginBottom: 15 }}
              >
                <Row>
                  {isNullOrEmpty(customer_id) ?
                    <Col>
                      <FormGroup>
                        <Label>{t("Project.customer_id")}</Label>
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          onSuggestionSelected={(e, val) =>
                            this.onSuggestionSelected(e, val)
                          }
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                        {validate["customer_id"] ? (
                          <div className="invalid-feedback show">
                            {t("common:SelectValid")}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col> : null}
                  <Col>
                    <FormGroup>
                      <Label>{t("CustomerUser.type")}</Label>
                      <Input
                        type="select"
                        value={type}
                        name="type"
                        onChange={(event) => {
                          this.handleChange(event, "type");
                        }}
                        invalid={validate["type"]}
                      >
                        <option value="">
                          {t("common:choose")}
                        </option>
                        <option value="zahiral">
                          {t("CustomerUser.zahiral")}
                        </option>
                        <option value="nyagtlan">
                          {t("CustomerUser.nyagtlan")}
                        </option>
                      </Input>
                      <FormFeedback>{t("common:SelectValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t("common:status")}</Label>
                      <Input
                        type="select"
                        value={status}
                        name="status"
                        onChange={(event) => {
                          this.handleChange(event, "status");
                        }}
                        invalid={validate["status"]}
                      >
                        <option value="">
                          {t("common:choose")}
                        </option>
                        <option value={magicnum.Status.Active}>
                          {t("common:magicnum.Status.Active")}
                        </option>
                        <option value={magicnum.Status.InActive}>
                          {t("common:magicnum.Status.InActive")}
                        </option>
                      </Input>
                      <FormFeedback>{t("common:SelectValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{t("CustomerUser.lastname")}</Label>
                      <Input
                        type="text"
                        value={lastname}
                        name="lastname"
                        onChange={(event) => {
                          this.handleChange(event, "lastname");
                        }}
                        invalid={validate["lastname"]}
                      >
                      </Input>
                      <FormFeedback>{t("common:InputValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t("CustomerUser.firstname")}</Label>
                      <Input
                        type="text"
                        value={firstname}
                        name="firstname"
                        onChange={(event) => {
                          this.handleChange(event, "firstname");
                        }}
                        invalid={validate["firstname"]}
                      >
                      </Input>
                      <FormFeedback>{t("common:InputValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t("CustomerUser.rd")}</Label>
                      <Input
                        type="input"
                        value={rd}
                        name="rd"
                        onChange={(event) => {
                          this.handleChange(event, "rd");
                        }}
                        invalid={validate["rd"]}
                      >
                      </Input>
                      <FormFeedback>{t("common:InputValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t("CustomerUser.phone")}</Label>
                      <Input
                        type="number"
                        value={phone}
                        name="phone"
                        onChange={(event) => {
                          this.handleChange(event, "phone");
                        }}
                        invalid={validate["phone"]}
                      >
                      </Input>
                      <FormFeedback>{t("common:InputValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default DataZuvlumjAddFunc;