import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  TypeZurchilAddReq,
  TypeZurchilUpdateReq,
} from "../../services/Request";
import { TypeZurchilUpdate, TypeZurchilAdd } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
class TypeAsuudalAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      Name: "",
      Code: "",
      Type: "",
      validate: [],
      Description: "",
      item: props.item,
      actionType: props.type,
      Status: magicnum.Status.Active,
      isSave: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {
    const { item, actionType } = this.state;
    if (item !== null && actionType == "update") {
      this.setState({
        ID: item.id,
        Name: item.name,
        Code: item.code,
        Type: item.type,
        Description: item.description,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    const { t, Name, Code, Type, Description } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = TypeZurchilAddReq;
      req.zurchil.name = Name;
      req.zurchil.code = Code;
      req.zurchil.type = Type;
      req.zurchil.description = Description;
      TypeZurchilAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    this.setState({ isSave: false });
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const { t, Name, ID, Code, Type, Description } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      var req = TypeZurchilUpdateReq;
      req.zurchil.name = Name;
      req.zurchil.code = Code;
      req.zurchil.type = Type;
      req.zurchil.description = Description;
      req.zurchil.id = ID;
      TypeZurchilUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const { Name, Code, Type, validate, Description } = this.state;
    if (Name == "") {
      res.retType = 1;
      validate["Name"] = true;
    }
    if (Code == "") {
      res.retType = 1;
      validate["Code"] = true;
    }
    if (Type == "") {
      res.retType = 1;
      validate["Type"] = true;
    }
    if (Description == "") {
      res.retType = 1;
      validate["Description"] = true;
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      Name,
      item,
      Code,
      Type,
      actionType,
      Description,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Type.Zurchil.Name")}</Label>
                <Input
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Name", event);
                  }}
                  invalid={validate["Name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Type.Zurchil.Code")}</Label>
                <Input
                  type="text"
                  name="Code"
                  value={Code}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Code", event);
                  }}
                  invalid={validate["Code"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("common:type")}</Label>
                <Input
                  type="select"
                  name="Type"
                  value={Type}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Type", event);
                  }}
                  invalid={validate["Type"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.Type.Zurchil.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.Type.Zurchil.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Type.Zurchil.Description")}</Label>
                <Input
                  type="textarea"
                  row={3}
                  cols={3}
                  name="Description"
                  value={Description}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Description", event);
                  }}
                  invalid={validate["Description"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={
                  item != null && actionType == "update"
                    ? this.Update
                    : this.save
                }
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default TypeAsuudalAddFunc;
