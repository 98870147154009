export const BaseRequest = {
    lang: localStorage.getItem("Lang") === null ? "mn" : localStorage.getItem("Lang"),
};

export const ListBase = {
    ...BaseRequest,
    pid: 1,
    pSize: 100,
};

export const magicnum = {
    Status: {
        Active: 1,
        InActive: 0,
    },
    UserType: ["manager", "admin", "director", "employee"],
    PrivLevel: ["1", "2", "3", "4"],
    Type: {
        Zurchil: ["law", "juram", "tushaal", "togtool", "standart"],
        DansParent: [
            "БХӨТ",
            "Зардал",
            "НДШ",
            "ОлзГарз",
            "Орлого",
            "Татвар",
            "УХӨТ",
            "ЭБХ",
            "Эзний өмч",
            "ЭХ",
            "СТ",
            "Санхүүгийн тайлагнал",
        ],
        Zuvlumj: ["ndtl", "stous", "nbbous", "nbbtl"],
    },
    Data: {
        RiskType: [
            "Уламжлалт",
            "Хяналтын",
            "Ангиллын",
            "Татвар",
            "Тооцооллын",
            "Бусад",
        ],
        CancelType: ["Давхацсан", "Өөрчлөгдсөн", "Утгагүй", "Бусад"],
    },
    Batlamj: [
        "Бодитой оршин байх ба тохиолдсон байх",
        "Иж бүрэн байдал",
        "Эрх ба үүрэг",
        "Үнэлгээ ба хуваарилалт",
        "Толилуулга ба тодруулга",
        "Үнэн зөв байх",
        "Тасалбар болголт",
    ],
    Company: {
        Type: ["ХХК", "ХК", "ТББ", "ТӨК", "ТӨСӨВТ", "ТӨРИЙН"],
        Size: ["Бичил", "Жижиг", "Дунд", "Том"],
    },
    Industry: ["architecture", "audit", "construction"],
    City: [
        "ulaanbaatar",
        "orkhon",
        "darkhan",
        "bayankhongor",
        "bayanulgii",
        "bulgan",
        "gobialtai",
        "gobisumber",
        "dornogobi",
        "dornod",
        "dundgobi",
        "zavkhan",
        "uvurkhangai",
        "umnugobi",
        "sukhbaatar",
        "selenge",
        "tov",
        "uvs",
        "khovd",
        "khovsgol",
        "khentii",
    ],
    HeleltsuulegTorol: [1, 2, 3, 7, 8, 9],
    filesInfo: [1, 2, 3, 4, 5, 6, 7, 18, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    filesInfoSub: [
        {
            parent: 1,
            id: 1,
            type: "PF",
        },
        {
            parent: 1,
            id: 2,
            type: "PF",
        },
        {
            parent: 1,
            id: 3,
            type: "PF",
        },
        {
            parent: 1,
            id: 4,
            type: "PF",
        },
        {
            parent: 1,
            id: 5,
            type: "PF",
        },
        {
            parent: 1,
            id: 6,
            type: "PF",
        },
        {
            parent: 1,
            id: 7,
            type: "PF",
        },
        {
            parent: 1,
            id: 8,
            type: "PF",
        },
        {
            parent: 1,
            id: 9,
            type: "PF",
        },
        {
            parent: 1,
            id: 10,
            type: "PF",
        },
        {
            parent: 1,
            id: 11,
            type: "Paper",
        },
        {
            parent: 1,
            id: 12,
            type: "PF",
        },
        {
            parent: 1,
            id: 13,
            type: "Paper",
        },
        {
            parent: 2,
            id: 1,
            type: "All",
        },
        {
            parent: 2,
            id: 2,
            type: "PF",
        },
        {
            parent: 2,
            id: 3,
            type: "PF",
        },
        {
            parent: 2,
            id: 4,
            type: "PF",
        },
        {
            parent: 2,
            id: 5,
            type: "PF",
        },
        {
            parent: 2,
            id: 6,
            type: "PF",
        },
        {
            parent: 2,
            id: 7,
            type: "File",
        },
        {
            parent: 2,
            id: 8,
            type: "File",
        },
        {
            parent: 2,
            id: 9,
            type: "File",
        },
        {
            parent: 2,
            id: 10,
            type: "PF",
        },
        {
            parent: 2,
            id: 11,
            type: "Paper",
        },
        {
            parent: 2,
            id: 12,
            type: "PF",
        },
        {
            parent: 2,
            id: 13,
            type: "PF",
        },
        {
            parent: 3,
            id: 1,
            type: "Paper",
        },
        {
            parent: 3,
            id: 2,
            type: "Paper",
        },
        {
            parent: 3,
            id: 3,
            type: "PF",
        },
        {
            parent: 3,
            id: 4,
            type: "PF",
        },
        {
            parent: 3,
            id: 5,
            type: "PF",
        },
        {
            parent: 3,
            id: 6,
            type: "PF",
        },
        {
            parent: 3,
            id: 7,
            type: "PF",
        },
        {
            parent: 3,
            id: 8,
            type: "File",
        },
        {
            parent: 3,
            id: 9,
            type: "PF",
        },
        {
            parent: 3,
            id: 10,
            type: "File",
        },
        {
            parent: 4,
            id: 1,
            type: "File",
        },
        {
            parent: 4,
            id: 2,
            type: "File",
        },
        {
            parent: 4,
            id: 3,
            type: "File",
        },
        {
            parent: 4,
            id: 4,
            type: "PF",
        },
        {
            parent: 4,
            id: 5,
            type: "File",
        },
        {
            parent: 4,
            id: 6,
            type: "File",
        },
        {
            parent: 4,
            id: 7,
            type: "PF",
        },
        {
            parent: 5,
            id: 1,
            type: "File",
        },
        {
            parent: 5,
            id: 2,
            type: "File",
        },
        {
            parent: 5,
            id: 3,
            type: "PF",
        },
        {
            parent: 5,
            id: 4,
            type: "File",
        },
        {
            parent: 5,
            id: 5,
            type: "File",
        },
        {
            parent: 6,
            id: 1,
            type: "File",
        },
        {
            parent: 6,
            id: 2,
            type: "File",
        },
        {
            parent: 6,
            id: 3,
            type: "PF",
        },
        {
            parent: 6,
            id: 4,
            type: "File",
        },
        {
            parent: 7,
            id: 1,
            type: "All",
        },
        {
            parent: 7,
            id: 2,
            type: "File",
        },
        {
            parent: 7,
            id: 3,
            type: "All",
        },
        {
            parent: 7,
            id: 4,
            type: "Paper",
        },
        {
            parent: 18,
            id: 1,
            type: "PF",
        },
        {
            parent: 18,
            id: 2,
            type: "PF",
        },
        {
            parent: 18,
            id: 2,
            type: "All",
        },
        {
            parent: 8,
            id: 1,
            type: "All",
        },
        {
            parent: 8,
            id: 2,
            type: "File",
        },
        {
            parent: 8,
            id: 3,
            type: "All",
        },
        {
            parent: 8,
            id: 4,
            type: "All",
        },
        {
            parent: 8,
            id: 5,
            type: "File",
        },
        {
            parent: 8,
            id: 6,
            type: "File",
        },
        {
            parent: 8,
            id: 7,
            type: "All",
        },
        {
            parent: 8,
            id: 8,
            type: "Paper",
        },
        {
            parent: 8,
            id: 9,
            type: "All",
        },
        {
            parent: 8,
            id: 10,
            type: "Paper",
        },
        {
            parent: 9,
            id: 1,
            type: "All",
        },
        {
            parent: 9,
            id: 2,
            type: "File",
        },
        {
            parent: 9,
            id: 3,
            type: "All",
        },
        {
            parent: 9,
            id: 4,
            type: "All",
        },
        {
            parent: 9,
            id: 5,
            type: "File",
        },
        {
            parent: 9,
            id: 6,
            type: "File",
        },
        {
            parent: 10,
            id: 1,
            type: "All",
        },
        {
            parent: 10,
            id: 2,
            type: "File",
        },
        {
            parent: 10,
            id: 3,
            type: "File",
        },
        {
            parent: 10,
            id: 4,
            type: "File",
        },
        {
            parent: 10,
            id: 5,
            type: "File",
        },
        {
            parent: 10,
            id: 6,
            type: "PF",
        },
        {
            parent: 10,
            id: 7,
            type: "PF",
        },
        {
            parent: 11,
            id: 1,
            type: "All",
        },
        {
            parent: 11,
            id: 2,
            type: "File",
        },
        {
            parent: 11,
            id: 3,
            type: "Paper",
        },
        {
            parent: 11,
            id: 4,
            type: "File",
        },
        {
            parent: 11,
            id: 5,
            type: "File",
        },
        {
            parent: 11,
            id: 6,
            type: "PF",
        },
        {
            parent: 11,
            id: 7,
            type: "File",
        },
        {
            parent: 12,
            id: 1,
            type: "PF",
        },
        {
            parent: 12,
            id: 2,
            type: "PF",
        },
        {
            parent: 12,
            id: 3,
            type: "PF",
        },
        {
            parent: 12,
            id: 4,
            type: "PF",
        },
        {
            parent: 13,
            id: 1,
            type: "File",
        },
        {
            parent: 13,
            id: 2,
            type: "File",
        },
        {
            parent: 13,
            id: 3,
            type: "File",
        },
        {
            parent: 14,
            id: 1,
            type: "File",
        },
        {
            parent: 14,
            id: 2,
            type: "File",
        },
        {
            parent: 14,
            id: 3,
            type: "File",
        },
        {
            parent: 14,
            id: 4,
            type: "File",
        },
        {
            parent: 15,
            id: 1,
            type: "File",
        },
        {
            parent: 15,
            id: 2,
            type: "PF",
        },
        {
            parent: 15,
            id: 3,
            type: "File",
        },
        {
            parent: 15,
            id: 4,
            type: "PF",
        },
        {
            parent: 15,
            id: 5,
            type: "File",
        },
        {
            parent: 15,
            id: 6,
            type: "PF",
        },
        {
            parent: 15,
            id: 7,
            type: "File",
        },
        {
            parent: 15,
            id: 8,
            type: "All",
        },
        {
            parent: 15,
            id: 9,
            type: "File",
        },
        {
            parent: 15,
            id: 10,
            type: "PF",
        },
        {
            parent: 16,
            id: 1,
            type: "File",
        },
        {
            parent: 16,
            id: 2,
            type: "File",
        },
        {
            parent: 16,
            id: 3,
            type: "",
        },
        {
            parent: 16,
            id: 4,
            type: "",
        },
        {
            parent: 16,
            id: 5,
            type: "",
        },
        {
            parent: 16,
            id: 6,
            type: "",
        },
        {
            parent: 17,
            id: 1,
            type: "File",
        },
        {
            parent: 17,
            id: 2,
            type: "File",
        },
    ],
};

//#region employee
export const AuthReq = {
    ...BaseRequest,
    Username: "",
    Password: "",
    Status: "",
    FirstName: "",
    LastName: "",
    ExpiredDate: "",
    TypeID: "",
    file: "",
};

export const AuthCreateReq = {
    ...BaseRequest,
    UserName: "",
    Password: "",
};

export const ChangePassReq = {
    ...BaseRequest,
    EmployeeID: "",
    newPassword: "",
    OLdPass: "",
    IsAdmin: false,
    realOldPass: "",
};

export const EmployeeChangeInfoReq = {
    ...BaseRequest,
    FirstName: "",
    LastName: "",
    id: "",
};

export const listEmployeeReq = {
    ...ListBase,
    filter: {
        Name: "",
        SoHoID: "",
        HeHoID: "",
        HesegID: "",
        BagID: "",
        Status: "",
    },
};

export const EmployeeSendOTPReq = {
    ...BaseRequest,
    user: {
        user_id: "",
        email: "",
        system: ""
    },
};

export const EmployeeCheckOTPReq = {
    ...BaseRequest,
    user: {
        user_id: "",
        code: "",
    },
};

export const EmployeeChangeStatusReq = {
    ...BaseRequest,
    employeeid: "",
    status: 0,
};
//#endregion employee

//#region Privilege
export const PrivilegeGroupAddReq = {
    ...BaseRequest,
    mPriv_Group: {},
};

export const PrivilegeGroupMoreReq = {
    ...BaseRequest,
    mPriv_Group: {},
};

export const PrivilegeGroupDeleteReq = {
    ...BaseRequest,
    mPriv_Group: {},
};

export const PrivilegeAddReq = {
    ...BaseRequest,
    mPriv: {},
};

export const PrivilegeDeleteReq = {
    ...BaseRequest,
    mPriv: {},
};

export const PrivilegeUserListReq = {
    ...BaseRequest,
    privNo: "",
};

export const GetEmployeeByIdReq = {
    ...BaseRequest,
    EmployeeID: "",
};

export const PrivilegeMP_GroupUserAddReq = {
    ...BaseRequest,
    userKey: "",
    mPriv_Groups: [],
};

export const AddMPriv_GroupPrivReq = {
    ...BaseRequest,
    privGroupId: "",
    mPrivs: [],
};

export const GetMPriv_GroupPrivReq = {
    ...BaseRequest,
    privGroupId: "",
};

export const ListMPriv_GroupPrivReq = {
    ...BaseRequest,
    MPriv_Group: {},
};
//#endregion

//#region Log
export const LogActionReq = {
    ...ListBase,
    filter: {
        Controller: "",
        Action: "",
        UserID: "",
        BeginDate: "",
        EndDate: "",
    },
};

export const LogErrorReq = {
    ...ListBase,
    filter: {
        Controller: "",
        Error: "",
        UserID: "",
        BeginDate: "",
        EndDate: "",
    },
};
//#endregion

//#region TypeAsuudal
export const TypeAsuudalAddReq = {
    ...BaseRequest,
    asuudal: {
        name: "",
    },
};
export const TypeAsuudalUpdateReq = {
    ...BaseRequest,
    asuudal: {
        id: "",
        name: "",
    },
};
export const TypeAsuudalListReq = {
    ...ListBase,
    filter: {
        name: "",
    },
};
//#endregion

//#region TypeZurchil
export const TypeZurchilAddReq = {
    ...BaseRequest,
    zurchil: {
        code: "",
        name: "",
        description: "",
        type: "",
    },
};
export const TypeZurchilUpdateReq = {
    ...BaseRequest,
    zurchil: {
        id: "",
        code: "",
        name: "",
        description: "",
        type: "",
    },
};
export const TypeZurchilListReq = {
    ...ListBase,
    filter: {
        type_id: "",
        name: "",
    },
};
//#endregion

//#region TypeNotolgoo
export const TypeNotolgooAddReq = {
    ...BaseRequest,
    notolgoo: {
        name: "",
    },
};
export const TypeNotolgooUpdateReq = {
    ...BaseRequest,
    notolgoo: {
        id: "",
        name: "",
    },
};
export const TypeNotolgooListReq = {
    ...ListBase,
    filter: {
        name: "",
    },
};
//#endregion

//#region TypeDans
export const TypeDansAddReq = {
    ...BaseRequest,
    dans: {
        name: "",
        parent: "",
    },
};
export const TypeDansUpdateReq = {
    ...BaseRequest,
    dans: {
        id: "",
        name: "",
        parent: "",
    },
};
export const TypeDansListReq = {
    ...ListBase,
    filter: {
        code: "",
        name: "",
        parent: "",
    },
};
//#endregion

//#region Customer
export const CustomerAddReq = {
    ...BaseRequest,
    customer: {
        register_number: "",
        name: "",
        email: "",
        mobile: "",
        telephone: "",
        director_name: "",
        accountant_name: "",
        company_type: "",
        company_size: "",
        industry_id: "",
        city_id: "",
        description: "",
        address: "",
        is_active: "",
    },
};
export const CustomerUpdateReq = {
    ...BaseRequest,
    customer: {
        register_number: "",
        name: "",
        email: "",
        mobile: "",
        telephone: "",
        director_name: "",
        accountant_name: "",
        company_type: "",
        company_size: "",
        industry_id: "",
        city_id: "",
        description: "",
        address: "",
        is_active: "",
        id: "",
    },
};
export const CustomerListReq = {
    ...ListBase,
    filter: {
        register_number: "",
        name: "",
        director_name: "",
        accountant_name: "",
    },
};
//#endregion

//#region DataAsuudal
export const DataAsuudalAddReq = {
    ...BaseRequest,
    asuudal: {
        code: "",
        name: "",
        dans_id: "",
        batlamj: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        risk_type: "",
        notolgoo_id: "",
        batlamj_type: "",
        deleted_at: "",
        deleted_by: "",
        bodit: "",
        status: "",
        gorim_sheet: "",
        gorim_tailbar: "",
        ua_chiglel: "",
        tusgai_zovshoorol: "",
        priv_level: "",
        version_root: null,
        version_parent: null,
    },
};
export const DataAsuudalExcelAddReq = {
    ...BaseRequest,
    datas: [],
};
export const DataAsuudalUpdateReq = {
    ...BaseRequest,
    asuudal: {
        code: "",
        name: "",
        dans_id: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        risk_type: "",
        notolgoo_id: "",
        batlamj_type: "",
        deleted_at: "",
        deleted_by: "",
        id: "",
        ua_chiglel: "",
        tusgai_zovshoorol: "",
        priv_level: "",
        bodit: "",
        status: "",
        gorim_sheet: "",
        gorim_tailbar: "",
        bodit: "",
    },
    type: "",
};
export const DataAsuudalRatingReq = {
    ...BaseRequest,
    id: "",
    rate: 0,
};

export const DataAsuudalCancelReq = {
    ...BaseRequest,
    cancel_type: "",
    cancel_at: "",
    id: "",
};

export const DataAsuudalListReq = {
    ...ListBase,
    filter: {
        name: "",
        code: "",
        staus: "",
        dans_id: "",
        risk_type: "",
        parent_dans_id: "",
        created_by: "",
        is_confirm: 1,
        ua_chiglel: "",
        priv_level: "",
        tusgai_zovshoorol: "",
        version_root: "",
        version_parent: "",
    },
};
export const DataAsuudalConnCountListReq = {
    ...ListBase,
    filter: {
        type: "",
    },
};

export const ConnectCustomerProjectCountListReq = {
    ...ListBase,
    filter: {
        order: "desc",
    },
};

export const AsuudalConnectProjectsReq = {
    ...ListBase,
    filter: {
        asuudal_id: "",
    },
};
//#endregion

//#region DataAsuulga
export const DataAsuulgaAddReq = {
    ...BaseRequest,
    asuulga: {
        name: "",
        company_size: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        deleted_at: "",
        deleted_by: "",
    },
};
export const DataAsuulgaUpdateReq = {
    ...BaseRequest,
    asuulga: {
        name: "",
        company_size: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        deleted_at: "",
        deleted_by: "",
        id: "",
    },
    type: "",
};
export const DataAsuulgaListReq = {
    ...ListBase,
    filter: {
        name: "",
    },
};
//#endregion

//#region DataZuvlumj
export const DataZuvlumjAddReq = {
    ...BaseRequest,
    zuvlumj: {
        code: "",
        name: "",
        type_id: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        deleted_at: "",
        deleted_by: "",
    },
};

export const DataZuvlumjDuplicateReq = {
    ...BaseRequest,
    name: "",
};

export const DataZuvlumjUpdateReq = {
    ...BaseRequest,
    zuvlumj: {
        code: "",
        name: "",
        type_id: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        deleted_at: "",
        deleted_by: "",
        id: "",
    },
    type: "",
};
export const DataZuvlumjListReq = {
    ...ListBase,
    filter: {
        name: "",
        type_id: "",
        type: ""
    },
};

export const DataZuvlumjCancelReq = {
    ...BaseRequest,
    cancel_type: "",
    cancel_at: "",
    cancel_torol: "",
    id: "",
};

export const DataZuvlumjConnectedAsuudalListReq = {
    ...ListBase,
    id: "",
};

export const DataZuvlumjConnectNewVersionReq = {
    ...ListBase,
    datas: [],
    newid: "",
    oldid: "",
};
//#endregion

//#region DataErsdel
export const DataErsdelCancelReq = {
    ...BaseRequest,
    cancel_type: "",
    cancel_at: "",
    cancel_torol: "",
    id: "",
};

export const DataErsdelAddReq = {
    ...BaseRequest,
    ersdel: {
        code: "",
        name: "",
        type_id: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        deleted_at: "",
        deleted_by: "",
    },
};
export const DataErsdelUpdateReq = {
    ...BaseRequest,
    ersdel: {
        code: "",
        name: "",
        type_id: "",
        is_confirm: "",
        confirm_at: "",
        confirm_by: "",
        cancel_at: "",
        cancel_type: "",
        cancel_by: "",
        deleted_at: "",
        deleted_by: "",
        id: "",
    },
    type: "",
};
export const DataErsdelListReq = {
    ...ListBase,
    filter: {
        name: "",
        type_id: "",
    },
};

export const DataErsdelConnectedAsuudalListReq = {
    ...ListBase,
    id: "",
};

export const DataErsdelConnectNewVersionReq = {
    ...ListBase,
    datas: [],
    newid: "",
    oldid: "",
};
//#endregion

//#region Connect
export const ConnectAddReq = {
    ...BaseRequest,
    connect: {
        asuudal_id: "",
        type: "",
        type_id: "",
        talbar: "",
        connect_by: "",
        connect_at: "",
        confirm_by: "",
        confirm_at: "",
        disconnect_by: "",
        disconnect_at: "",
    },
};
export const ConnectUpdateReq = {
    ...BaseRequest,
    connect: {
        asuudal_id: "",
        type: "",
        type_id: "",
        talbar: "",
        connect_by: "",
        connect_at: "",
        confirm_by: "",
        confirm_at: "",
        disconnect_by: "",
        disconnect_at: "",
        id: "",
    },
    type: "",
};
export const ConnectListReq = {
    ...ListBase,
    filter: {
        asuudal_id: "",
        type: "",
    },
};
//#endregion

//#region ConnectCustomer
export const ConnectCustomerAddReq = {
    ...BaseRequest,
    connectcustomer: {
        customer_id: "",
        asuudal_id: "",
        dun: "",
        tailbar: "",
        ersdel: "",
        zuvlumj: "",
        asuulga: "",
        ersdel_manual: "",
        zuvlumj_manual: "",
        project_id: "",
        connect_by: "",
        connect_at: "",
        confirm_by: "",
        confirm_at: "",
        disconnect_by: "",
        disconnect_at: "",
    },
};
export const ConnectCustomerUpdateReq = {
    ...BaseRequest,
    connectcustomer: {
        customer_id: "",
        asuudal_id: "",
        dun: "",
        tailbar: "",
        ersdel_manual: "",
        zuvlumj_manual: "",
        project_id: "",
        connect_by: "",
        connect_at: "",
        confirm_by: "",
        confirm_at: "",
        disconnect_by: "",
        disconnect_at: "",
        id: "",
    },
    type: "",
};
export const ConnectCustomerListReq = {
    ...ListBase,
    filter: {
        customer_id: "",
        asuudal_id: "",
        project_id: "",
        name: "",
    },
};

export const ConnectCustomerByYearReq = {
    ...ListBase,
    filter: {
        customer_id: "",
    },
};

export const ConnectCustomerGetReq = {
    ...ListBase,
    filter: {
        customer_id: "",
        asuudal_id: "",
        project_id: "",
        id: "",
    },
};
//#endregion

//#region CustomerProject
export const ProjectAddReq = {
    ...BaseRequest,
    project: {
        customer_id: "",
        type: "",
        report_year: "",
        check_user: "",
        work_user: "",
        tailbar: "",
        created_by: "",
        created_at: "",
        confirm_by: "",
        confirm_at: "",
        audit_no: "",
        audit_date: "",
        conn_limit: 10,
    },
};
export const ProjectUpdateReq = {
    ...BaseRequest,
    project: {
        customer_id: "",
        report_year: "",
        check_user: "",
        work_user: "",
        tailbar: "",
        created_by: "",
        created_at: "",
        confirm_by: "",
        confirm_at: "",
        audit_no: "",
        audit_date: "",
        type: "",
        id: "",
    },
    type: "",
};
export const ProjectListReq = {
    ...ListBase,
    filter: {
        customer_id: "",
        report_year: "",
        check_user: "",
        work_user: "",
        type: "",
        audit_no: "",
        EmployeeTypeID: "",
        mode: "all"
    },
};

export const ProjectReportReq = {
    ...ListBase,
    filter: {
        id: "",
    },
};

export const ProjectSendEmailReq = {
    ...BaseRequest,
    project: {
        email: "",
        title: "",
        customer_id: "",
        project_id: "",
        message: "",
        attach: "",
        attach2: "",
    }
};

export const ProjectAuditEmailListReq = {
    ...ListBase,
    project: {
        email: "",
        customer_id: "",
        project_id: ""
    }
};
//#endregion

//#region Comment
export const CommentAddReq = {
    ...BaseRequest,
    comment: {
        comment_id: "",
        customer_id: "",
        comment: "",
        haana: "",
        torol: "",
        reply_id: "",
        created_by: "",
        created_at: "",
    },
};

export const CommentUpdateReq = {
    ...BaseRequest,
    comment: {
        comment_id: "",
        comment: "",
        haana: "",
        torol: "",
        created_by: "",
        created_at: "",
        id: "",
    },
};



export const CommentListReq = {
    ...ListBase,
    filter: {
        comment_id: "",
        customer_id: "",
        type: "",
        torol: ""
    },
};
//#endregion

//#region Dashboard
export const DashboardNotifiWorkProjectReq = {
    ...BaseRequest,
    userid: "",
};

export const DashboardUserbyMounthReq = {
    ...BaseRequest,
    filter: {
        year: "",
        type: "",
    },
};

export const DashboardTopAsuudalReq = {
    ...BaseRequest,
    filter: {
        limit: 5,
    },
};
//#endregion

//#region Rate
export const RateListReq = {
    ...BaseRequest,
    filter: {
        relation_id: "",
        type: "",
        created_by: "",
    },
};
export const RateAddReq = {
    ...BaseRequest,
    rate: {
        type: "",
        relation_id: "",
        rate: "",
        created_by: "",
        created_at: "",
    },
};
//#endregion

//#region Chat
export const ChatListReq = {
    ...BaseRequest,
    filter: {
        sender_id: "",
    },
};
export const ChatAddReq = {
    ...BaseRequest,
    chat: {
        body: "",
    },
};
export const ChatUpdateReq = {
    ...BaseRequest,
    chat: {
        body: "",
    },
};
//#endregion

//#region Industry
export const IndustryListReq = {
    ...ListBase,
    filter: {
        name: "",
        parent_id: ""
    },
};
export const IndustryAddReq = {
    ...BaseRequest,
    name: "",
    parent_id: 0
};
export const IndustryUpdateReq = {
    ...BaseRequest,
    name: "",
    id: "",
};
//#endregion

//#region Bodit
export const BoditListReq = {
    ...ListBase,
    filter: {
        asuudal_id: "",
        created_by: "",
    },
};
export const BoditAddReq = {
    ...BaseRequest,
    bodit: {
        asuudal_id: "",
        bodit: "",
    },
};
export const BoditUpdateReq = {
    ...BaseRequest,
    bodit: {
        id: "",
        bodit: "",
    },
};
//#endregion

//#region CustomerUser
export const CustomerUserListReq = {
    ...BaseRequest,
    filter: {
        customer_id: "",
        customer_rd: "",
        firstname: "",
        lastname: "",
        rd: "",
        type: "",
        phone: "",
        status: "",
        source: "",
        created_by: ""
    },
};
export const CustomerUserAddReq = {
    ...BaseRequest,
    customer: {
        customer_id: "",
        customer_rd: "",
        firstname: "",
        lastname: "",
        rd: "",
        type: "",
        phone: "",
        status: "",
        source: "",
        created_by: ""
    },
};
export const CustomerUserUpdateReq = {
    ...BaseRequest,
    customer: {
        customer_id: "",
        customer_rd: "",
        firstname: "",
        lastname: "",
        rd: "",
        type: "",
        phone: "",
        status: "",
        source: "",
        id: "",
    },
};
//#endregion

export const ProjectFilesListReq = {
    ...ListBase,
    filter: {
        customer_id: "",
        project_id: "",
        status: ""
    },
};

export const ProjectFilesAddReq = {
    ...ListBase,
    customer_id: "",
    project_id: "",
    created_by: "",
    files: [],
};

export const ProjectFilesDeleteFileReq = {
    ...BaseRequest,
    file: {
        filepath: "",
        id: "",
    }
};

export const ProjectFilesChangeStatusReq = {
    ...BaseRequest,
    multi: [],
    file: {
        status: "",
        action_desc: "",
        id: "",
    }
};