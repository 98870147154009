import React, { FC, memo, useEffect, useState, ReactNode } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

interface IProps {
  modalName: string;
  closeModal: () => void;
  visible: boolean;
  className: string;
  children: ReactNode;
  noClose: Boolean;
}

const CustomModal: FC<IProps> = ({
  modalName,
  closeModal,
  visible,
  className,
  children,
  noClose,
}) => {
  const [showModal, setShowModal] = useState<boolean>(visible || false);
  const openHandler = () => {
    setShowModal(true);
  };

  const closeHandler = () => {
    if (noClose == false || noClose == undefined) {
      closeModal();
      setShowModal(false);
    }
  };
  const clickOutSideHandler = () => {
    if (noClose == false || noClose == undefined) {
      closeModal();
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (visible) openHandler();
  }, [visible]);

  return (
    <Modal
      open={showModal}
      onClose={closeHandler}
      onOverlayClick={clickOutSideHandler}
      closeOnEsc
      classNames={{
        overlay: "customOverlay",
        modal: className,
      }}
    >
      {children}
    </Modal>
  );
};

export default memo(CustomModal);
