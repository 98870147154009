import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  TypeDansAddReq,
  TypeDansUpdateReq,
} from "../../services/Request";
import { TypeDansUpdate, TypeDansAdd } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
class TypeDansAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      Name: "",
      Code: "",
      Parent: "",
      Type: "",
      validate: [],
      Description: "",
      item: props.item,
      actionType: props.type,
      Status: magicnum.Status.Active,
      isSave: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {
    const { item, actionType } = this.state;
    if (item !== null && actionType == "update") {
      this.setState({
        ID: item.id,
        Name: item.name,
        Parent: item.parent,
      });
    }

    if (item !== null && actionType == "new") {
      this.setState({
        Parent: item.id,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    const { t, Name, Parent } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = TypeDansAddReq;
      req.dans.name = Name;
      req.dans.parent = Parent;
      TypeDansAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    this.setState({ isSave: false });
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const { t, Name, ID, Parent } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      var req = TypeDansUpdateReq;
      req.dans.name = Name;
      req.dans.parent = Parent;
      req.dans.id = ID;
      TypeDansUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const { Name, Parent, validate } = this.state;
    if (Name == "") {
      res.retType = 1;
      validate["Name"] = true;
    }
    if (Parent == "") {
      res.retType = 1;
      validate["Parent"] = true;
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      Name,
      item,
      Parent,
      Type,
      actionType,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Type.Dans.Parent")}</Label>
                <Input
                  type="select"
                  value={Parent}
                  name="Parent"
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Parent", event);
                  }}
                  invalid={validate["Parent"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.Type.DansParent.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Type.Dans.Name")}</Label>
                <Input
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Name", event);
                  }}
                  invalid={validate["Name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={
                  item != null && actionType == "update"
                    ? this.Update
                    : this.save
                }
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default TypeDansAddFunc;
