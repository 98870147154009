import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import {
  GetEmployee,
  PrivilegeGroupList,
  AddMPriv_GroupUser,
  UpdateMPriv_GroupUser,
  ListMPriv_GroupsForUser,
  ChangePass,
} from "../../../services/main";
import {
  GetEmployeeByIdReq,
  BaseRequest,
  PrivilegeMP_GroupUserAddReq,
  magicnum,
  ChangePassReq,
} from "../../../services/Request";
import { toast, ToastContainer } from "react-toastify";
import { withTranslation } from "react-i18next";
import { dateFormat, isNullOrEmpty } from "../../../components/Utils/Utils";
import { useParams } from "react-router-dom";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class EmployeeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sizePerPage: 15,
      loading: true,
      apierror: false,
      employee: null,
      PrivGroups: [],
      apierrormsg: "",
      PID: 1,
      PTotal: 0,
      IsAdd: true,
      checkedGroups: [],
      currentPriv_Groups: [],
      t: this.props.t,
      Password: "",
      isSave: false,
      validate: [],
    };

    this.getEmployee = this.getEmployee.bind(this);
    this.getPrivGroupsList = this.getPrivGroupsList.bind(this);
    this.onPrivGroupListSuccess = this.onPrivGroupListSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.onGetEmployeeSuccess = this.onGetEmployeeSuccess.bind(this);
    this.checkPrivGroup = this.checkPrivGroup.bind(this);
    this.savePrivMpGroup_User = this.savePrivMpGroup_User.bind(this);
    this.GroupsForUserSuccess = this.GroupsForUserSuccess.bind(this);
    this.CurrentChecked = this.CurrentChecked.bind(this);
    this.StatusRowFormat = this.StatusRowFormat.bind(this);
  }
  componentDidMount() {
    this.getEmployee();
    this.getPrivGroupsList();
    this.GroupsForUser();
  }

  GroupsForUser() {
    var request = GetEmployeeByIdReq;
    ListMPriv_GroupsForUser(
      this.props.params.id,
      request,
      this.GroupsForUserSuccess,
      this.onFailed,
      "GET"
    );
  }

  GroupsForUserSuccess(response) {
    if (response.mPriv_GroupUser.length != 0) {
      this.setState({
        currentPriv_Groups: response.mPriv_GroupUser,
        IsAdd: false,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  }

  getEmployee() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    GetEmployee(
      this.props.params.id,
      BaseRequest,
      this.onGetEmployeeSuccess,
      this.onFailed,
      "GET"
    );
  }

  onGetEmployeeSuccess(response) {
    this.setState({
      employee: response.mEmployees,
    });
  }

  getPrivGroupsList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = BaseRequest;
    req.PID = this.state.PID;
    req.PSIZE = this.state.sizePerPage;
    PrivilegeGroupList(req, this.onPrivGroupListSuccess, this.onFailed, "GET");
  }

  onPrivGroupListSuccess(response) {
    this.setState({
      PrivGroups: response.mPriv_GroupForLists,
    });
  }

  onFailed(error, text) {
    this.setState({ loading: false, isSave: false });
    return toast.error(
      <div className="toast-section">
        <i className="fa fa-close icon" />
        {" " + text + " (" + error + ")"}
      </div>
    );
  }

  checkPrivGroup(array, index, e) {
    const { currentPriv_Groups } = this.state;

    if (e.target.checked == true) {
      currentPriv_Groups.push(array);
    } else {
      var a = "";

      currentPriv_Groups.map((i, index) => {
        if (array.PrivGroupID == i.PrivGroupID) {
          a = index;
        }
      });
      currentPriv_Groups.splice(a, 1);
    }

    this.setState({
      currentPriv_Groups,
    });
  }

  savePrivMpGroup_User() {
    const { currentPriv_Groups, IsAdd } = this.state;
    var request = PrivilegeMP_GroupUserAddReq;
    request.userKey = this.props.params.id;
    var Group = [];
    currentPriv_Groups.map((i) => {
      var priv = {};
      priv.privGroupId = i.PrivGroupID;
      Group.push(priv);
    });
    request.mPriv_Groups = Group;
    if (IsAdd) {
      AddMPriv_GroupUser(
        request,
        (res) => this.EditedPrivMpSuccess(res),
        this.onFailed,
        "POST"
      );
    } else {
      UpdateMPriv_GroupUser(
        request,
        (res) => this.EditedPrivMpSuccess(res),
        this.onFailed,
        "PUT"
      );
    }
  }

  EditedPrivMpSuccess(response) {
    const { t } = this.state;
    return toast.info(t("common:SuccessEdit"));
  }

  CurrentChecked(currentGroup, i) {
    var checker = false;
    if (currentGroup != undefined) {
      currentGroup.map((j, index) => {
        if (j.PrivGroupID == i) {
          checker = true;
        }
      });
    }
    return checker;
  }

  StatusRowFormat(cell) {
    const { t } = this.state;
    if (cell == magicnum.Status.Active) {
      return t("common:magicnum.Status.Active");
    } else {
      return t("common:magicnum.Status.InActive");
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate() {
    var res = { retType: 0 };
    const { Password, validate } = this.state;
    if (Password == "") {
      res.retType = 1;
      validate["Password"] = true;
    }
    this.setState({ validate });
    return res;
  }

  save = () => {
    const { Password } = this.state;
    this.setState({ isSave: true });
    var res = this.validate();
    if (res.retType === 0) {
      var req = ChangePassReq;
      req.EmployeeID = this.props.params.id;
      req.newPassword = Password;
      req.IsAdmin = true;
      ChangePass(req, this.onSuccess, this.onFailed, "POST");
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState({ isSave: false });
    return toast.info(t("employee.changePass"));
  };

  render() {
    const {
      apierror,
      apierrormsg,
      PrivGroups,
      employee,
      checkedGroups,
      currentPriv_Groups,
      loading,
      t,
      Password,
      validate,
    } = this.state;

    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };
    return (
      <div className="animated fadeIn more-privgroup-container">
        <ToastContainer position="top-right" autoClose={3000} />
        <Card>
          <CardHeader>{t("employee.detail")}</CardHeader>
          <CardBody>
            {
              apierror && (
                <div className="help-block text-danger">{apierrormsg}</div>
              ) // serverees aldaa garval aldaanii medeelel haragdana
            }
            {!loading && !isNullOrEmpty(employee) ? (
              <div>
                <Row form>
                  <Col md={3}>
                    {t("userName")} :{employee.firstname}
                  </Col>
                  <Col md={3}>
                    {t("common:CreatedAt")} : {dateFormat(employee.created_at)}
                  </Col>
                  <Col md={3}>
                    {t("status")} :{this.StatusRowFormat(employee.status)}
                  </Col>
                </Row>
                <hr />
                <Row form>
                  <Col>
                    <FormGroup>
                      <Input
                        type="text"
                        name="Password"
                        value={Password}
                        onChange={(event) => {
                          this.handleChange(event);
                          this.setValidate("Password", event);
                        }}
                        invalid={validate["Password"]}
                        placeholder={t("employee.changePlc")}
                      />
                      <FormFeedback>{t("common:InputValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="saveBtn">
                      <Button
                        color="primary"
                        onClick={this.save}
                        disabled={this.state.isSave}
                      >
                        {t("employee.changeBtn")}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="priv-container">
                  {PrivGroups.map((i, index) => (
                    <div className="priv" key={index}>
                      <label className="switch switch-3d switch-primary getBase">
                        <input
                          type="checkbox"
                          className="switch-input"
                          checked={this.CurrentChecked(
                            currentPriv_Groups,
                            i.PrivGroupID
                          )}
                          onChange={(e) => this.checkPrivGroup(i, index, e)}
                        />
                        <span className="switch-label" />
                        <span className="switch-handle" />
                      </label>
                      <Label className="privno">{i.PrivGroupName}</Label>
                    </div>
                  ))}
                </Row>
                <Row className="row-container action-row">
                  <Button
                    className="btn btn-success custom-btn"
                    onClick={this.savePrivMpGroup_User}
                  >
                    <i className="fa fa-save" /> {t("common:save")}
                  </Button>
                </Row>
              </div>
            ) : (
              <span>no data</span>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("employee")(withParams(EmployeeDetail));
