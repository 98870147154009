import React, { Component } from "react";
import { Navigate } from 'react-router-dom'
class Logout extends Component {
  constructor(props) {
    super(props);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.state = {
      redirect: true
    };
  }

  componentWillMount() {
    localStorage.removeItem("SystemToken")
    localStorage.removeItem("data1")
    localStorage.removeItem("data2")
    localStorage.removeItem("EmployeeID")
    localStorage.removeItem("EmployeeTypeID")
  }


  renderRedirect() {
    localStorage.removeItem("SystemToken")
    localStorage.removeItem("data1")
    localStorage.removeItem("data2")
    localStorage.removeItem("EmployeeID")
    localStorage.removeItem("EmployeeTypeID")
    if (this.state.redirect) {
      return <Navigate to="/login" />;
    }
  }
  render() {
    return <div>{this.renderRedirect()}</div>;
  }
}

export default Logout;
