import React, { Component } from "react";
import {
  Card,
  Button,
  Table,
  UncontrolledAlert,
  FormGroup,
  Row,
  Col, Label,
  Spinner,
} from "reactstrap";

import {
  DataErsdelList, DataErsdelConnectedAsuudalList, DataErsdelConnectNewVersion, DataZuvlumjConnectedAsuudalList, DataZuvlumjConnectNewVersion, DataZuvlumjList
} from "../../../services/main";
import {
  DataErsdelListReq, DataErsdelConnectedAsuudalListReq, DataErsdelConnectNewVersionReq, DataZuvlumjListReq,
} from "../../../services/Request";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
import {
  isNullOrEmpty,
  dateFormat,
} from "../../../components/Utils/Utils";
import { toast } from "react-toastify";
import { ModalManager } from "react-dynamic-modal";

const renderSuggestion = (suggestion) => (
  <div style={suggestion.cancel_by > 0 && !isNullOrEmpty(suggestion.cancel_type) ? { color: "red", backgroundColor: "yellow", padding: 15, cursor: "not-allowed" } : { cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    <span>{suggestion.name}</span>
    {suggestion.cancel_by > 0 && !isNullOrEmpty(suggestion.cancel_type) ? <span style={{ fontSize: 10, display: "block", marginTop: 5, color: "#151b1e" }}>{suggestion.cancel_type} - ({dateFormat(suggestion.cancel_at)})</span> : null}
  </div>
);
class ConnectedAsuudalFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      type: this.props.type,
      loading: false,
      apierrormsg: "",
      apierror: false,
      value: "",
      suggestions: [],
      selectedProd: null,
      sizePerPage: 1000,
      PID: 1,
      data: [],
    };
    this.onFailed = this.onFailed.bind(this);
    this.acpn = this.acpn.bind(this);
    this.onAcpnSuccess = this.onAcpnSuccess.bind(this);
    this.onConnectSuccess = this.onConnectSuccess.bind(this);
    this.onConnectAddSuccess = this.onConnectAddSuccess.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

  }

  componentDidMount() {
    this.getList();
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }
    const { item, type } = this.state;
    var req = DataErsdelConnectedAsuudalListReq;
    req.id = item.id;
    type == "ersdel" ? DataErsdelConnectedAsuudalList(req, this.onConnectSuccess, this.onFailed, "POST") : DataZuvlumjConnectedAsuudalList(req, this.onConnectSuccess, this.onFailed, "POST")
  }

  onConnectSuccess(response) {
    this.setState({
      loading: false,
      data: response.data,
    });
  }

  add(selected) {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }

    const { item, data, type } = this.state;
    if (!isNullOrEmpty(selected)) {
      var req = DataErsdelConnectNewVersionReq;
      req.datas = data;
      req.oldid = item.id;
      req.newid = selected.id;
      type == "ersdel" ? DataErsdelConnectNewVersion(req, this.onConnectAddSuccess, this.onFailed, "POST") : DataZuvlumjConnectNewVersion(req, this.onConnectAddSuccess, this.onFailed, "POST")
    }
  }

  onConnectAddSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  acpn(value) {
    const { type } = this.state
    var req = type == "ersdel" ? DataErsdelListReq : DataZuvlumjListReq;
    req.pid = 1;
    req.filter.name = value;
    if (value.length > 2) {
      type == "ersdel" ? DataErsdelList(req, this.onAcpnSuccess, this.onFailed, "POST") : DataZuvlumjList(req, this.onAcpnSuccess, this.onFailed, "POST")
    }
  }

  onAcpnSuccess(response) {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedProd: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected(e, val) {
    var ff = val.suggestion;
    if (ff.cancel_by > 0 && !isNullOrEmpty(ff.cancel_type)) {
      return toast.error(this.state.t("main:Data.Zuvlumj.disabledAsuudal"));
    } else {
      this.add(ff);
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedProd: null,
      apierror: false,
    });
  };


  render() {
    const {
      t,
      apierror,
      apierrormsg,
      value,
      suggestions,
      loading,
      data, item
    } = this.state;

    const inputProps = {
      placeholder: t("Data.Ersdel.Search"),
      value,
      onChange: this.onChange,
    };

    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    return (
      <div className="animated fadeIn getorder-container">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <Row>
          {!isNullOrEmpty(item.cancel_torol) && item.connectcount > 0 && isNullOrEmpty(item.cancel_parent) && item.cancel_torol == 4 ?
            <Col>
              <FormGroup style={{ position: "relative" }}>
                <Label>{t("Data.Ersdel.shinehuvilbarholbohheregtei")}</Label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionSelected={(e, val) =>
                    this.onSuggestionSelected(e, val)
                  }
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
              </FormGroup>
            </Col> : null}
        </Row>
        <Row>
          <Col style={{ maxHeight: "400px", overflowX: "auto" }}>
            {loading ? (
              <Spinner />
            ) : (
              <Table>
                <tbody>
                  {isNullOrEmpty(data)
                    ? null
                    : data.map((item, index) => {
                      var count = index + 1;
                      return (
                        <tr key={index}>
                          <td>{count}. {item.asuudal}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConnectedAsuudalFunc;
