import React, { Component } from "react";
import {
  Table,
  UncontrolledAlert,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { DataAsuudalList } from "../../../services/main";
import { DataAsuudalListReq } from "../../../services/Request";
import { LastAndFirstName, dateFormat, getUser, isNullOrEmpty } from "../../../components/Utils/Utils";
class VersionAsuudalFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      loading: false,
      apierrormsg: "",
      apierror: false,
      value: "",
      suggestions: [],
      selectedProd: null,
      sizePerPage: 1000,
      PID: 1,
      data: [],
      userData: []
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }
    const { item, PID, sizePerPage } = this.state;
    var req = DataAsuudalListReq;
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.version_root = isNullOrEmpty(item.version_root) ? item.id : item.version_root
    DataAsuudalList(req, this.onSuccess, this.onFailed, "POST");
  }

  onSuccess = (response) => {
    this.setState({
      loading: false,
      data: response.data,
      userData: response.users
    });
  }


  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  userRowFormat = (cell) => {
    var user = getUser(this.state.userData, cell);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  };


  render() {
    const {
      t,
      apierror,
      apierrormsg,
      loading,
      data,
      item
    } = this.state;


    return (
      <div className="animated fadeIn getorder-container">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <Row>
          <Col style={{ maxHeight: "400px", overflowX: "auto" }}>
            <Table>
              <thead>
                <tr>
                  <th>{t("Data.Asuudal.code")}</th>
                  <th>{t("Data.Asuudal.name")}</th>
                  <th>{t("Data.created_by")}</th>
                  <th>{t("common:date")}</th>
                </tr>
              </thead>
              {loading ? (
                <Spinner />
              ) : (
                <tbody>
                  {isNullOrEmpty(data)
                    ? null
                    : data.length > 1 ? data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                          <td>{this.userRowFormat(item.created_by)}</td>
                          <td>{dateFormat(item.created_at)}</td>
                        </tr>
                      );
                    }) : null}
                </tbody>
              )}
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default VersionAsuudalFunc;
