import React, { Component } from "react";
import {
  EmployeeCheckOTP,
  EmployeeSendOTP,
  loginGetToken,
  setPageTitle,
} from "../../services/main.js";
import {
  AuthReq,
  EmployeeCheckOTPReq,
  EmployeeSendOTPReq,
} from "../../services/Request";
import { withTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {
  FormGroup,
  Button,
  Input,
  FormFeedback,
  Spinner,
  Label,
} from "reactstrap";
import "./Login.scss";
import { ToastContainer, toast } from "react-toastify";
import LoginOrCreateBanner from "./LoginOrCreateBanner/LoginOrCreateBanner";
import CryptoJS from "crypto-js";
import config from "../../services/config.json";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
import logoImg from "../../img/logo.png";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      userName: "",
      code: "",
      validSeconds: 300,
      otpData: {},
      isSuccess: false, //Амьжилттай нэвтэрсэн бол redirect хийх
      loading: true,
      loginSuccess: false,
      twoFactorSuccess: false,
      loadingSendOTP: false,
      loadingChechOTP: false,
      validate: [],
      empData: undefined,
      t: this.props.t,
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.onhandleChanges = this.onhandleChanges.bind(this);
  }

  componentWillMount() {
    setPageTitle(this.props.t("Container.title"));
    var getSystemToken = localStorage.getItem("SystemToken");
    if (getSystemToken != null) {
      this.setState({
        isSuccess: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (
        prevState.validSeconds !== this.state.validSeconds ||
        this.state.validSeconds == 300
      ) {
        this.setState({ validSeconds: this.state.validSeconds - 1 });
      }
    }, 2000);
  }

  sendOTP = (user) => {
    const { t } = this.state;
    var request = EmployeeSendOTPReq;
    this.setState({ loadingSendOTP: true });
    request.user.user_id = user.id;
    request.user.email = user.email;
    request.user.system = "mc";
    EmployeeSendOTP(
      request,
      this.onSendOTPSuccess,
      (err) => this.onFailed(err, t("common:ErrorSendOTP")),
      "POST"
    );
  };

  onSendOTPSuccess = (response) => {
    this.setState({
      loadingSendOTP: false,
      otpData: response,
      validSeconds: 300,
    });
  };

  handleForms(str, e) {
    this.setState({
      [str]: e.target.value,
    });
  }

  formSubmit() {
    const { t, userName, password } = this.state;
    if (this.validate() & !isNullOrEmpty(userName) & !isNullOrEmpty(password)) {
      var request = AuthReq;
      request.Username = CryptoJS.AES.encrypt(
        userName,
        config.engryptPass
      ).toString();
      request.Password = CryptoJS.AES.encrypt(
        password,
        config.engryptPass
      ).toString();
      this.setState({
        loading: false,
      });
      loginGetToken(
        request,
        this.onSuccess,
        (err) => this.onFailed(err, t("common:ErrorLogin")),
        "POST"
      );
    }
  }

  validate() {
    const { userName, password, validate } = this.state;
    var valid = true;
    if (userName.length === 0) {
      validate["userName-"] = true;
      valid = false;
    }
    if (password.length === 0) {
      validate["password-"] = true;
      valid = false;
    }

    this.setState({ validate });
    return valid;
  }

  onhandleChanges(stateName, event) {
    this.setState({
      [stateName]: event.target.value,
    });
  }

  onSuccess(response) {
    var priv = CryptoJS.AES.encrypt(
      JSON.stringify(response.privs),
      config.engryptPass
    );
    var employeeData = CryptoJS.AES.encrypt(
      JSON.stringify(response.data),
      config.engryptPass
    );

    localStorage.setItem("SystemToken", response.token);
    localStorage.setItem("data1", priv);
    localStorage.setItem("data2", employeeData);
    localStorage.setItem("EmployeeID", response.data.id);
    localStorage.setItem("EmployeeTypeID", response.data.typeid);
    this.sendOTP(response.data);
    var twofa = localStorage.getItem("twofa-" + response.data.id);
    this.setState({
      empData: response.data,
      isSuccess: true,
      loading: true,
      loginSuccess: true,
      twoFactorSuccess: isNullOrEmpty(twofa) ? false : true,
    });
  }

  Redirect() {
    var EmployeeID = localStorage.getItem("EmployeeID");
    var twofa = localStorage.getItem("twofa-" + EmployeeID);
    if (
      this.state.isSuccess &&
      this.state.empData != undefined &&
      !isNullOrEmpty(twofa)
    ) {
      return <Navigate to="/dashboard" />;
    }
  }

  onFailed(err, text) {
    console.log(err);
    this.setState({
      loading: true,
      loadingSendOTP: false,
      loadingChechOTP: false,
    });
    return toast.error(
      <div className="toast-section">
        <i className="fa fa-close icon" />
        {err}
      </div>
    );
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    if (id === "checkCondition") {
      val = e.target.checked;
    }
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.formSubmit();
    }
  }

  secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + " цаг" : "";
    var mDisplay = m > 0 ? m + " минут " : "";
    var sDisplay = s > 0 ? s + " секунд" : "";
    return hDisplay + mDisplay + sDisplay;
  };

  checkOTP = () => {
    const { t, code, otpData } = this.state;
    var request = EmployeeCheckOTPReq;
    this.setState({ loadingChechOTP: true });
    request.user.user_id = otpData.user_id;
    request.user.code = code;
    EmployeeCheckOTP(
      request,
      this.onCheckOTPSuccess,
      (err) => this.onFailed(err, t("common:ErrorCheckOTP")),
      "POST"
    );
  };

  onCheckOTPSuccess = (response) => {
    const { t } = this.state;
    var codeExpired = response?.codeExpired;
    if (codeExpired) {
      this.setState({
        loadingChechOTP: false,
      });
      return toast.warning(
        <div className="toast-section">{t("Container.expiredCode")}</div>
      );
    } else {
      var EmployeeID = localStorage.getItem("EmployeeID");
      this.setState({
        loadingChechOTP: false,
      });
      localStorage.setItem("twofa-" + EmployeeID, EmployeeID);
    }
  };

  render() {
    const { t, i18n } = this.props;
    const {
      validate,
      loading,
      loginSuccess,
      twoFactorSuccess,
      otpData,
      validSeconds,
      loadingSendOTP,
      empData,
    } = this.state;

    return (
      <div className="layout-login-container">
        <LoginOrCreateBanner t={t} i18n={i18n} />
        <ToastContainer position="top-right" autoClose={5000} />

        <div className="body">
          {this.Redirect()}
          <div className="content">
            <img
              src={logoImg}
              style={{
                width: 280,
                float: "left",
              }}
            />
            {!loginSuccess ? (
              <div className="form">
                <FormGroup>
                  <Input
                    placeholder={t("Container.email")}
                    onKeyPress={this.handleKeyPress}
                    className="login-input"
                    onChange={(e) => {
                      this.handleForms("userName", e);
                      this.setValidate("userName-", e);
                    }}
                    invalid={validate["userName-"]}
                  />
                  <FormFeedback>{t("Container.emailValid")}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    placeholder={t("Container.password")}
                    onKeyPress={this.handleKeyPress}
                    type="password"
                    className="login-input"
                    onChange={(e) => {
                      this.handleForms("password", e);
                      this.setValidate("password-", e);
                    }}
                    invalid={validate["password-"]}
                  />
                  <FormFeedback>{t("Container.passwordValid")}</FormFeedback>
                </FormGroup>
                <FormGroup className="text-right">
                  <div>
                    <Button
                      onKeyPress={(e) => this.handleKeyPress(e)}
                      onClick={this.formSubmit}
                      className="login-btn"
                      color="primary"
                      disabled={!loading}
                    >
                      {!loading ? (
                        <div>
                          {t("Container.loading")}{" "}
                          <Spinner size="sm" color="light" />
                        </div>
                      ) : (
                        t("Container.title")
                      )}
                    </Button>
                  </div>
                </FormGroup>
              </div>
            ) : !twoFactorSuccess ? (
              <div className="form">
                <FormGroup className="text-right">
                  <div>
                    <div style={{ marginBottom: 10 }}>
                      {validSeconds > 0 ? t("Container.validseconds") : null}
                      {this.secondsToHms(validSeconds)}
                    </div>
                    <FormGroup>
                      <Input
                        placeholder={
                          otpData?.toNumber + t("Container.validcode")
                        }
                        onKeyPress={this.handleKeyPress}
                        className="login-input"
                        onChange={(e) => {
                          this.handleForms("code", e);
                          this.setValidate("code-", e);
                        }}
                        invalid={validate["code-"]}
                      />
                      <FormFeedback>{t("Container.emailValid")}</FormFeedback>
                    </FormGroup>
                    {validSeconds > 0 ? (
                      <Button
                        onClick={() => this.checkOTP()}
                        className="login-btn"
                        color="primary"
                        disabled={!loading}
                      >
                        {!loading ? (
                          <div>
                            {t("Container.loading")}{" "}
                            <Spinner size="sm" color="light" />
                          </div>
                        ) : (
                          t("Container.checkOTP")
                        )}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => this.sendOTP(empData)}
                        className="login-btn"
                        color="primary"
                        disabled={loadingSendOTP}
                      >
                        {loadingSendOTP ? (
                          <div>
                            {t("Container.loading")}{" "}
                            <Spinner size="sm" color="light" />
                          </div>
                        ) : (
                          t("Container.reSendOTP")
                        )}
                      </Button>
                    )}
                  </div>
                </FormGroup>
              </div>
            ) : (
              <div className="form"></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("login")(Login);
