import React, { Component } from "react";
import {
  Card,
  Button,
  Table,
  UncontrolledAlert,
  FormGroup,
  Row,
  Col,
  Spinner,
  Input,
  Label,
  FormFeedback,
  CardTitle,
  InputGroup,
} from "reactstrap";

import {
  DataAsuudalList,
  ConnectCustomerAdd,
  ProjectReport,
} from "../../../services/main";
import {
  DataAsuudalListReq,
  ConnectCustomerAddReq,
  ProjectReportReq,
} from "../../../services/Request";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
import { dateFormat, isNullOrEmpty } from "../../../components/Utils/Utils";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import { ModalManager } from "react-dynamic-modal";
import CustomModal from "../../../components/Modal/CustomModal/custom-modal.tsx";
import AsuudalFilter from "./AsuudalFilter"
const renderSuggestion = (suggestion) => (
  <div style={{ cursor: "pointer", backgroundColor: "#fff", padding: 5 }}>
    {suggestion.name}
  </div>
);
class ConnectAsuudalAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      loading: false,
      apierrormsg: "",
      apierror: false,
      value: "",
      suggestions: [],
      selectedProd: null,
      sizePerPage: 1000,
      PID: 1,
      data: [],
      dataUser: [],
      dun: "",
      tailbar: "",
      ersdel_manual: "",
      zuvlumj_manual: "",
      validate: [],
      isSave: false,
      connection: null,
      zuvlumj: [],
      ersdel: [],
      asuulga: [],
      versiondata: [],
      asuudal: null,
      showModal: false
    };
    this.onFailed = this.onFailed.bind(this);
    this.acpn = this.acpn.bind(this);
    this.onAcpnSuccess = this.onAcpnSuccess.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.add = this.add.bind(this);
    this.onConnectAddSuccess = this.onConnectAddSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setValidate = this.setValidate.bind(this);

    this.getConnections = this.getConnections.bind(this);
    this.onGetConnectionsSuccess = this.onGetConnectionsSuccess.bind(this);
    this.setConn = this.setConn.bind(this);
  }

  componentDidMount() { }

  add() {
    const {
      item,
      tailbar,
      dun,
      ersdel_manual,
      zuvlumj_manual,
      selectedProd,
      zuvlumj,
      asuulga,
      ersdel,
      t,
    } = this.state;
    if (!isNullOrEmpty(selectedProd)) {
      var res = this.validate();
      if (res.retType === 0) {
        var req = ConnectCustomerAddReq;
        req.connectcustomer.customer_id = item.customer_id;
        req.connectcustomer.project_id = item.id;
        req.connectcustomer.asuudal_id = selectedProd.id;
        req.connectcustomer.dun = dun;
        req.connectcustomer.tailbar = tailbar;
        req.connectcustomer.ersdel_manual = ersdel_manual;
        req.connectcustomer.zuvlumj_manual = zuvlumj_manual;
        var z = "",
          a = "",
          e = "";
        if (zuvlumj.length > 0) {
          zuvlumj.map((ite) => {
            if (isNullOrEmpty(z)) {
              z += ":";
            }
            if (!isNullOrEmpty(ite)) {
              z += ite + ":";
            }
          });
        } else {
          return toast.error(t("Project.validSelectZuvlumj"));
        }
        if (ersdel.length > 0) {
          ersdel.map((ite) => {
            if (isNullOrEmpty(e)) {
              e += ":";
            }
            if (!isNullOrEmpty(ite)) {
              e += ite + ":";
            }
          });
        } else {
          return toast.error(t("Project.validSelectErsdel"));
        }

        if (!this.state.loading) {
          this.setState({ loading: true, isSave: true });
        }
        req.connectcustomer.ersdel = e;
        req.connectcustomer.zuvlumj = z;
        req.connectcustomer.asuulga = a;
        ConnectCustomerAdd(req, this.onConnectAddSuccess, this.onFailed, "POST");
      }
    } else {
      return toast.error(t("Project.validSelectAsuudal"));
    }
  }

  validate() {
    var res = { retType: 0 };
    const {
      validate,
      dun
    } = this.state;
    if (dun == "") {
      res.retType = 1;
      validate["dun"] = true;
    }

    this.setState({ validate });
    return res;
  }

  onConnectAddSuccess(response) {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  acpn(value) {
    var req = DataAsuudalListReq;
    req.filter.name = value;
    req.filter.version_parent = "";
    req.filter.version_root = "";
    if (value.length > 3) {
      DataAsuudalList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess(response) {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedProd: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected(e, val) {
    this.setState({ selectedProd: val.suggestion }, function () {
      this.versionList(val.suggestion)
      this.getConnections(val.suggestion);
    });
  }

  onSuggestionSelectedModal = (val) => {
    this.setState({ suggestion: val, value: val.suggestion.name })
    this.onSuggestionSelected(null, val)
    this.versionList(val.suggestion)
  }

  versionList = (item) => {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }
    const { PID, sizePerPage } = this.state;
    var req = DataAsuudalListReq;
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.name = "";
    req.filter.version_root = isNullOrEmpty(item.version_root) ? item.id : item.version_root
    DataAsuudalList(req, this.onSuccess, this.onFailed, "POST");
  }

  onSuccess = (response) => {
    this.setState({
      loading: false,
      versiondata: response.data,
    });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedProd: null,
      apierror: false,
    });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  getConnections(asuudal) {
    this.setState({ loading: true });
    var req = ProjectReportReq;
    req.filter.id = asuudal.id;
    req.pid = 1;
    ProjectReport(req, this.onGetConnectionsSuccess, this.onFailed, "POST");
  }

  onGetConnectionsSuccess(response) {
    this.setState({
      loading: false,
      asuudal: response.asuudal,
      connection: response.connection,
      userData: response.users,
    });
  }

  setConn(e, type) {
    const { name, value, checked } = e.target;
    const { zuvlumj, asuulga, ersdel } = this.state;
    if (type === "zuvlumj") {
      if (checked) {
        zuvlumj.push(value);
      } else {
        var index = zuvlumj.indexOf(value);
        if (index !== -1) {
          zuvlumj.splice(index, 1);
        }
      }
    } else if (type === "ersdel") {
      if (checked) {
        ersdel.push(value);
      } else {
        var index = ersdel.indexOf(value);
        if (index !== -1) {
          ersdel.splice(index, 1);
        }
      }
    }

    this.setState({ ersdel, zuvlumj });
  }

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      value,
      suggestions,
      loading,
      data,
      dun,
      tailbar,
      ersdel_manual,
      zuvlumj_manual,
      validate,
      connection,
      showModal,
      selectedProd,
      versiondata
    } = this.state;

    const inputProps = {
      placeholder: t("Data.Asuudal.Search"),
      value,
      onChange: this.onChange,
    };

    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };

    return (
      <div className="animated fadeIn getorder-container">
        {showModal ? (
          <CustomModal
            name="modal-sm"
            visible={showModal}
            closeModal={() => this.setState({ showModal: false })}
            className=""
          >
            <AsuudalFilter selectAsuudal={this.onSuggestionSelectedModal} t={t} />
          </CustomModal>
        ) : null}
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={containerStyle}
        />

        <Row>
          <Col md={8}>
            <FormGroup style={{ position: "relative" }}>
              <Label>{t("Project.asuudal_id")}</Label>
              <div style={{ display: "flex" }}>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionSelected={(e, val) =>
                    this.onSuggestionSelected(e, val)
                  }
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
                <Button onClick={() => this.setState({ showModal: true })}><i className="fa fa-filter" /></Button>
              </div>
              {validate["customer_id"] ? (
                <div className="invalid-feedback show">
                  {t("common:SelectValid")}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>{t("Project.dun")}</Label>
              <Input
                type="number"
                name="dun"
                value={dun}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("dun", event);
                }}
                invalid={validate["dun"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {versiondata.length > 1 ?
          <Row>
            <Col>
              <Card body>
                <CardTitle className="label-title">
                  <label>{t("common:version")}</label>
                </CardTitle>
                <div className="detail">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {versiondata.map((vitem, vindex) => {
                      return <div key={vindex} onClick={() => this.onSuggestionSelectedModal({ suggestion: vitem })} style={{ display: "flex", flexDirection: "column", gap: 1, cursor: "pointer", padding: 10, borderBottom: "1px solid #f3f2f2" }}>
                        <div style={{ display: "flex", gap: 2 }}>
                          <b>{t('common:version')}-{vindex + 1}</b>
                          <span>{vitem.name}</span>
                        </div>
                        <span style={{ fontSize: 10, color: "gray" }}>{dateFormat(vitem.created_at)}</span>
                      </div>
                    })}
                  </div>
                </div>
              </Card>
            </Col>
          </Row> : null}
        {isNullOrEmpty(connection) ? null : (
          <Row>
            <Col>
              <Card body>
                <CardTitle className="label-title">
                  <label>{t("common:ersdel")}</label>
                </CardTitle>
                <div className="detail">
                  {isNullOrEmpty(connection)
                    ? null
                    : connection.map((item, index) => {
                      if (item.type == "ersdel") {
                        return (
                          <div key={index}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  value={item.id}
                                  onChange={(e) => this.setConn(e, "ersdel")}
                                />{" "}
                                {item.ersdel}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      }
                    })}
                </div>
              </Card>
              <Card body>
                <CardTitle className="label-title">
                  <label>{t("common:zuvlumj")}</label>
                </CardTitle>
                <div className="detail">
                  {isNullOrEmpty(connection)
                    ? null
                    : connection.map((item, index) => {
                      if (item.type == "zuvlumj") {
                        return (
                          <div key={index}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  value={item.id}
                                  onChange={(e) => this.setConn(e, "zuvlumj")}
                                />{" "}
                                {item.zuvlumj}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      }
                    })}
                </div>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label>{t("Project.ersdel_manual")}</Label>
              <Input
                type="textarea"
                name="ersdel_manual"
                value={ersdel_manual}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("ersdel_manual", event);
                }}
                invalid={validate["ersdel_manual"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{t("Project.zuvlumj_manual")}</Label>
              <Input
                type="textarea"
                name="zuvlumj_manual"
                value={zuvlumj_manual}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("zuvlumj_manual", event);
                }}
                invalid={validate["zuvlumj_manual"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <FormGroup>
              <Label>{t("Project.tailbar")}</Label>
              <Input
                type="textarea"
                name="tailbar"
                value={tailbar}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("tailbar", event);
                }}
                invalid={validate["tailbar"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={2} style={{ marginTop: 35 }}>
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={this.add}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConnectAsuudalAddFunc;
