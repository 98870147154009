import React, { Component, useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import parser from "html-react-parser";
import {
  CommentAdd,
  CommentUpdate,
  CommentList,
  CommentDelete,
  setPageTitle,
  socket,
  ChatAdd,
  BoditList,
  BoditAdd,
  BoditUpdate,
  BoditDelete,
} from "../../../services/main";
import {
  Button,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap";
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  timeAgo,
  getRealData,
} from "../../../components/Utils/Utils";
import {
  CommentAddReq,
  CommentUpdateReq,
  CommentListReq,
  BaseRequest,
  BoditListReq,
  BoditAddReq,
  BoditUpdateReq,
} from "../../../services/Request";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

class DataAsuudalBoditFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      type: this.props.type,
      apierrormsg: "",
      apierror: false,
      userData: [],
      data: [],
      message: "",
      isSave: false,
      loading: false,
      sizePerPage: 25,
      PID: 1,
      PTotal: 0,
      refreshType: "direct",
      editorData: "",
      commentID: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.getList = this.getList.bind(this);
    this.onListSuccess = this.onListSuccess.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInEditor = this.onChangeInEditor.bind(this);

    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    var aa = getRealData("data2");
    var id = "";
    if (!isNullOrEmpty(aa)) {
      id = JSON.parse(aa).id;
    }
    this.setState({ loading: true, id }, function () {
      this.getList();
    });
    socket.on("comment", (msg) => {
      this.getList();
    });
  }

  getList() {
    const { item, PID, sizePerPage } = this.state;

    this.setState({ loading: true });
    var req = BoditListReq;
    req.filter.asuudal_id = item.id;
    req.pid = PID;
    req.pSize = sizePerPage;
    BoditList(req, this.onListSuccess, this.onFailed, "POST");
  }

  onListSuccess(response) {
    const { refreshType, data } = this.state;
    var array = data;
    if (refreshType == "more") {
      response.data.map((item) => {
        array.push(item);
      });
    }

    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: refreshType == "more" ? array : response.data,
      userData: response.users,
      isSave: false,
    });
  }

  save() {
    const { message, item, type } = this.state;
    if (!isNullOrEmpty(message)) {
      this.setState({ message: "" });
      var req = BoditAddReq;
      req.bodit.bodit = message;
      req.bodit.asuudal_id = item.id;
      socket.emit("bodit", "send");

      BoditAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  update() {
    const { message, commentID } = this.state;
    if (!isNullOrEmpty(message)) {
      this.setState({ message: "" });
      var req = BoditUpdateReq;
      req.bodit.bodit = message;
      req.bodit.id = commentID;
      socket.emit("bodit", "send");
      BoditUpdate(req, this.onUpdateSuccess, this.onFailed, "PUT");
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    this.setState({ refreshType: "direct" }, function () {
      this.getList();
      this.setState({
        message: "",
        commentID: "",
      });
    });
    return toast.success(t("common:SuccessSave"));
  }

  onUpdateSuccess(response) {
    const { t } = this.state;
    this.setState({ refreshType: "direct" }, function () {
      this.getList();
      this.setState({
        message: "",
        commentID: "",
      });
    });
    return toast.success(t("common:SuccessEdit"));
  }


  onFailed(error) {
    //console.log(error);
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  handleChange(e) {
    //const { name, value } = e.target;
    //this.setState({ [name]: value });
    this.setState({
      data: e.target.value,
    });
  }

  handleSubmit = (e) => {
    if (e.keyCode == 13) {
      this.save();
    }
  };

  onSubmit(e) {
    e.preventDefault();
    this.save();
  }

  onChangeInEditor = (e) => {
    const { value } = e.target;
    this.setState({ message: value });
  };

  userRowFormat = (cell, row) => {
    var user = getUser(this.state.userData, cell);
    return isNullOrEmpty(user) ? (
      <span></span>
    ) : (
      <span>{LastAndFirstName(user.lastname, user.firstname)}</span>
    );
  };

  more = () => {
    this.setState(
      { PID: this.state.PID + 1, refreshType: "more", isSave: true },
      function () {
        this.getList();
      }
    );
  };

  submit(row, type) {
    const { t } = this.state;
    var title = " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = " " + t("Data.Asuudal.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = " dd" + t("Data.Asuudal.delete");
      var message = t("common:confirm");
    }
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: t("common:yes"),
          onClick: () => (type == "deleteMain" ? this.delete(row) : ""),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }


  editChat(row) {
    this.setState({
      message: row.bodit,
      commentID: row.id,
    });

  }


  delete(row) {
    BoditDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  render() {
    const { t, message, data, commentID, loading, PTotal, sizePerPage, PID } = this.state;


    return (
      <div>
        <div
          className="separator separator-dashed my-10"
          style={{
            maxHeight: 450,
            overflowX: "auto",
            transform: "rotate(180deg)",
            direction: "rtl",
            scrollBehavior: "smooth",
          }}
        >
          {loading ? (
            <Spinner />
          ) : data.length == 0 ? null : (
            data.map((item, index) => {
              var userD = this.userRowFormat(item.created_by);
              return (
                <div
                  className="timeline timeline-3"
                  key={index}
                  style={{ transform: "rotate(180deg)", direction: "ltr" }}
                >
                  <div className="timeline-items">
                    <div className="timeline-content">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="mr-2">
                          <Button
                            className="button delete-button"
                            onClick={() => {
                              this.submit(item, "deleteMain");
                            }}
                            title={t("common:delete")}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            className="button edit-button"
                            onClick={() => {
                              this.editChat(item);
                            }}
                            title={t("common:edit")}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <a className="text-dark-75 text-hover-primary font-weight-bold">
                            {userD}
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          <span className="text-muted ml-2">
                            {timeAgo(item.created_at)}
                          </span>
                        </div>
                        <div
                          className="dropdown ml-2"
                          data-toggle="tooltip"
                          title=""
                          data-placement="left"
                          data-original-title="Quick actions"
                        >
                          <a
                            href="#"
                            className="btn btn-hover-light-primary btn-sm btn-icon"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="ki ki-more-hor icon-md"></i>
                          </a>
                        </div>
                      </div>
                      <div className="p-0">{item.bodit}</div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          <div
            style={{
              textAlign: "center",
              margin: 20,
              transform: "rotate(180deg)",
              direction: "ltr",
            }}
          >
            {(data.length > 0 && PTotal / sizePerPage < PID) ||
              data.length == 0 ? null : (
              <Button
                color="primary"
                onClick={this.more}
                disabled={this.state.isSave}
              >
                {t("common:tsaashid")}
              </Button>
            )}
          </div>
        </div>
        <div className="form-group" style={{ marginTop: 40 }}>
          <FormGroup>
            <textarea
              onChange={(event) => this.onChangeInEditor(event)}
              rows={4} cols={4}
              value={message}
              className="form-control"
              onKeyDown={(e) => this.handleSubmit(e)}
              placeholder={t("common:message")}
            ></textarea>
            {isNullOrEmpty(commentID) ? (
              <button
                onClick={this.onSubmit}
                className="btn-sm btn btn-primary"
                style={{ marginTop: 20, width: "100%" }}
              >
                Нэмэх
              </button>
            ) : (
              <div>
                <Input
                  style={{ marginTop: 10 }}
                  type="text"
                  name="commentID"
                  value={this.state.commentID}
                  disabled={true}
                />
                <button
                  onClick={this.update}

                  className="btn-sm btn btn-primary"
                  style={{ marginTop: 20, width: "100%" }}
                >
                  Хадгалах
                </button></div>
            )}

          </FormGroup>
        </div>
      </div>
    );
  }
}

export default DataAsuudalBoditFunc;
