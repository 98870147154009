import React, { Component } from "react";
import {
  Card,
  Button,
  Table,
  UncontrolledAlert,
  FormGroup,
  Row,
  Col,
  Spinner,
  Input,
  Label,
  FormFeedback,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";

import {
  ConnectCustomerDelete,
  DataAsuudalList,
  ConnectCustomerAdd,
  ConnectCustomerUpdate,
  ConnectCustomerList,
  ProjectReport,
} from "../../../services/main";
import {
  BaseRequest,
  DataAsuudalListReq,
  ConnectCustomerAddReq,
  ConnectCustomerUpdateReq,
  ConnectCustomerListReq,
  ProjectReportReq,
} from "../../../services/Request";
import Autosuggest from "react-autosuggest";
import "./CustomAutoSuggest.scss";
import {
  isNullOrEmpty,
  LastAndFirstName,
  getUser,
  dateFormat,
  moneyFormat,
} from "../../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import AsuudalAdd from "../../Data/AsuudalAdd";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import { Link } from "react-router-dom";
import ProjectGetEXCEL from "../Project/ProjectGetEXCEL";

class ConnectAsuudalFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      loading: false,
      apierrormsg: "",
      apierror: false,
      value: "",
      suggestions: [],
      selectedProd: null,
      sizePerPage: 1000,
      PID: 1,
      data: [],
      dataUser: [],
      dun: "",
      tailbar: "",
      ersdel_manual: "",
      zuvlumj_manual: "",
      validate: [],
      isSave: false,
      connection: null,
      zuvlumj: [],
      ersdel: [],
      asuulga: [],
      asuudal: null,
    };
    this.onFailed = this.onFailed.bind(this);
    this.acpn = this.acpn.bind(this);
    this.onAcpnSuccess = this.onAcpnSuccess.bind(this);
    this.onConnectSuccess = this.onConnectSuccess.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.Update = this.Update.bind(this);

    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setValidate = this.setValidate.bind(this);

    this.getConnections = this.getConnections.bind(this);
    this.onGetConnectionsSuccess = this.onGetConnectionsSuccess.bind(this);
    this.setConn = this.setConn.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  Update(item, type) {
    var req = ConnectCustomerUpdateReq;
    req.connectcustomer = item;
    req.type = type;
    ConnectCustomerUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
  }

  onUpdatSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.success(t("common:SuccessEdit"));
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true }); //unshij bgaa esehiig tiim bolgoj solij bn
    }
    const { PID, sizePerPage, item } = this.state;
    var req = ConnectCustomerListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.project_id = item.id;
    ConnectCustomerList(req, this.onConnectSuccess, this.onFailed, "POST"); //serverluu huselt ilgeej bn  !!!!!
  }

  onConnectSuccess(response) {
    this.setState({
      loading: false,
      data: response.data,
      userData: response.users,
    });
    this.props.setConnCount(response.data.length);
  }

  onFailed(error) {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  }

  acpn(value) {
    var req = DataAsuudalListReq;
    req.filter.name = value;
    if (value.length > 2) {
      DataAsuudalList(req, this.onAcpnSuccess, this.onFailed, "POST");
    }
  }

  onAcpnSuccess(response) {
    this.setState({ suggestions: response.data });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.acpn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ selectedProd: suggestion });
    return suggestion.name;
  };

  onSuggestionSelected(e, val) {
    this.getConnections(val.suggestion);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedProd: null,
      apierror: false,
    });
  };

  submit(row, type) {
    const { t } = this.state;
    var title = row.dname + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.dname + " " + t("Data.Asuudal.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.dname + " " + t("Data.Asuudal.delete");
      var message = t("common:confirm");
    }
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: t("common:yes"),
          onClick: () =>
            type == "deleteMain" ? this.delete(row) : this.Update(row, type),
        },
        {
          label: t("common:no"),
        },
      ],
    });
  }

  delete(row) {
    ConnectCustomerDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  zadd(type, row) {
    const { t } = this.state;
    var title = t("Data.Asuudal.Add");
    if (type == "update") {
      title = t("Data.Asuudal.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <AsuudalAdd
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  }

  addSuccess(text) {
    return toast.success(text);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  getConnections(asuudal) {
    this.setState({ loading: true });
    var req = ProjectReportReq;
    req.filter.id = asuudal.id;
    ProjectReport(req, this.onGetConnectionsSuccess, this.onFailed, "POST");
  }

  onGetConnectionsSuccess(response) {
    this.setState({
      loading: false,
      asuudal: response.asuudal,
      connection: response.connection,
      userData: response.users,
    });
  }

  setConn(e, type) {
    const { name, value, checked } = e.target;
    const { zuvlumj, asuulga, ersdel } = this.state;
    if (type === "zuvlumj") {
      if (checked) {
        zuvlumj.push(value);
      } else {
        var index = zuvlumj.indexOf(value);
        if (index !== -1) {
          zuvlumj.splice(index, 1);
        }
      }
    } else if (type === "asuulga") {
      if (checked) {
        asuulga.push(value);
      } else {
        var index = asuulga.indexOf(value);
        if (index !== -1) {
          asuulga.splice(index, 1);
        }
      }
    } else if (type === "ersdel") {
      if (checked) {
        ersdel.push(value);
      } else {
        var index = ersdel.indexOf(value);
        if (index !== -1) {
          ersdel.splice(index, 1);
        }
      }
    }

    this.setState({ ersdel, asuulga, zuvlumj });
  }

  showEXCEL = (row) => {
    const { t } = this.state;
    var title = t("common:excel");
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <ProjectGetEXCEL
            t={this.state.t}
            item={row}
            userData={this.state.userData}
            data={this.state.data}
          />
        }
      />
    );
  };

  render() {
    const { t, apierror, apierrormsg, value, loading, data, item } = this.state;

    const inputProps = {
      placeholder: t("Data.Asuudal.Search"),
      value,
      onChange: this.onChange,
    };

    return (
      <div className="animated fadeIn getorder-container">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}

        <Card>
          <CardHeader>
            {t("Data.Asuudal.Title")}
            <div className="float-right">
              {/* {isNullOrEmpty(item.confirm_by) ? null : ( */}
                <Button
                  onClick={() => {
                    this.showEXCEL(item);
                  }}
                  color="primary"
                  className="btn-sm"
                  disabled={loading}
                >
                  {t("common:excel")}
                </Button>
              {/* )} */}
            </div>
          </CardHeader>
          <CardBody className="nobg">
            {loading ? (
              <Spinner />
            ) : (
              <Table>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>{t("Data.Asuudal.code")}</th>
                    <th>{t("Project.asuudal")}</th>
                    {/* <th>{t("Project.customer_id")} </th> */}
                    <th>{t("Project.dun")} </th>
                    <th> {t("common:CreatedBy")}</th>
                    <th> {t("common:ConfirmBy")}</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {isNullOrEmpty(data)
                    ? null
                    : data.map((item, index) => {
                        var user = getUser(
                          this.state.userData,
                          item.connect_by
                        );
                        var userC = getUser(
                          this.state.userData,
                          item.confirm_by
                        );
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.acode}</td>
                            <td style={{ maxWidth: 350 }}>
                              <Link
                                to={`/projectasuudal/${item.asuudal_id}/${item.project_id}`}
                              >
                                {item.aname}
                              </Link>
                            </td>
                            {/* <td>{item.dname}</td> */}
                            <td>{moneyFormat(item.dun, "MNT")}</td>
                            <td>
                              {!isNullOrEmpty(user)
                                ? LastAndFirstName(
                                    user.lastname,
                                    user.firstname
                                  )
                                : null}{" "}
                              - {dateFormat(item.connect_at)}
                            </td>
                            <td>
                              {!isNullOrEmpty(userC)
                                ? LastAndFirstName(
                                    userC.lastname,
                                    userC.firstname
                                  )
                                : null}{" "}
                              - {dateFormat(item.confirm_at)}
                            </td>
                            <td style={{ width: 160 }}>
                              <div className="text-center">
                                <Button
                                  className="button delete-button"
                                  onClick={() => {
                                    this.submit(item, "deleteMain");
                                  }}
                                  title={t("common:delete")}
                                  color="success"
                                  outline
                                >
                                  <i className="fa fa-trash" />
                                </Button>

                                <Button
                                  style={{ marginLeft: 10 }}
                                  className={`button delete-button ${
                                    !isNullOrEmpty(item.confirm_by)
                                      ? "btn-success"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    this.submit(item, "confirm");
                                  }}
                                  title={t("Data.Asuudal.confirm")}
                                >
                                  <i className="fa fa-check-circle" />
                                </Button>

                                <Button
                                  className={`button delete-button ${
                                    !isNullOrEmpty(item.disconnect_by)
                                      ? "btn-danger"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    this.submit(item, "disconnect");
                                  }}
                                  title={t("Data.Asuudal.delete")}
                                >
                                  <i className="fa fa-trash" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ConnectAsuudalFunc;
