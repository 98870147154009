import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Button,
  Input,
  Spinner,
  Label,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import { ProjectAuditEmailListReq, ProjectFilesDeleteFileReq, ProjectFilesListReq, magicnum } from "../../../../services/Request";
import { CDN_URL, ProjectAuditEmailList, ProjectFilesDeleteFile, ProjectFilesList } from "../../../../services/main";
import { dateFormat, isNullOrEmpty } from "../../../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const FilesEmail = (props) => {
  const t = props.t
  const [item, setItem] = useState(props.item);
  const [yitem, setYItem] = useState(props.yitem);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [data, setData] = useState([]);
  const [dataStored, setDataStored] = useState([]);
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      list()
    }
  }, [item]);

  const list = () => {
    setLoadingList(true)
    var req = ProjectAuditEmailListReq
    req.project.customer_id = item?.customer_id
    req.project.project_id = yitem?.id
    req.pid = 1;
    req.pSize = 100;
    ProjectAuditEmailList(
      req,
      onListSuccess,
      onFailed,
      "POST"
    );
  }

  const onListSuccess = (response) => {
    console.log(response)
    setDataStored(response.data)
    setLoadingList(false)
  }

  const onFailed = (error) => {
    setLoading(false)
    setLoadingList(false)
    return toast.error(error)
  }


  const submit = (selectedfile) => {
    var title = t("common:magicnum.filesInfoSub." + selectedfile.parentcat_id + "-" + selectedfile.cat_id);
    var message = t("common:confirmdelete");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1 style={{ fontSize: 14 }}>{title.substr(0, 100)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                deleteFunc(selectedfile);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  }

  const deleteFunc = (selectedfile) => {
    setLoading(true)
    var req = ProjectFilesDeleteFileReq
    req.file.filepath = selectedfile?.filepath
    req.file.id = selectedfile?.storedid
    ProjectFilesDeleteFile(
      req,
      onDeleteSuccess,
      onFailed,
      "PUT"
    );
  }

  const onDeleteSuccess = (response) => {
    setLoading(false)
    list()
    return toast.success(t("main:Project.files.deleteSuccess"))
  }

  return (
    <div>
      <Table >
        <thead>
          <th>№</th>
          <th>Имэйл</th>
          <th>Захидал</th>
          <th>Хавсралт-1</th>
          <th>{t("common:CreatedAt")}</th>
        </thead>
        <tbody>
          {loadingList ? 
          <tr><td colSpan={5} className="text-center">{t("common:loading")}</td></tr> : dataStored.length == 0 ? 
          <tr><td colSpan={5} className="text-center">{t("common:emptyList")}</td></tr> : dataStored.map((ditem, dindex) => {
            return <tr key={dindex}>
              <td>{dindex + 1}</td>
              <td>{ditem?.email}</td>
              <td><a className="btn btn-primary" href={CDN_URL + ditem?.attach1} target="_blank">Харах</a></td>
              <td><a className="btn btn-primary" href={CDN_URL + ditem?.attach2} target="_blank">Харах</a></td>
              <td>{dateFormat(ditem.created_at)}</td>
            </tr>
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default FilesEmail;
