import React from "react";
import { createRoot } from 'react-dom/client';

import "font-awesome/css/font-awesome.min.css";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";

//helnii file bgaa shuu
import { I18nextProvider } from "react-i18next";
import HttpsRedirect from "react-https-redirect";
import i18next from "i18next";
import "./i18n";
//helnii file bgaa shuu

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(<HttpsRedirect><I18nextProvider i18n={i18next}><App /></I18nextProvider></HttpsRedirect>)
};
