import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFViewer,
//   Font,
//   Link,
// } from "@react-pdf/renderer";
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
} from "../../../components/Utils/Utils";
// Font.register({
//   family: "Roboto",
//   src:
//     "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
// });

class ProjectAsuudalPDFFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: props.item,
      userData: props.userData,
      connection: props.connection,
    };
  }

  render() {
    const { t, connection, item } = this.state;
    var user,
      userC = "";
    if (!isNullOrEmpty(item)) {
      user = getUser(this.state.userData, item.created_by);
      userC = getUser(this.state.userData, item.confirm_by);
    }
    const styles = StyleSheet.create({
      viewer: {
        width: "100%",
        height: "65vh",
      },
      page: {
        backgroundColor: "#E4E4E4",
        fontFamily: "Roboto",
        padding: "40px,20px",
      },
      text: {
        fontSize: 12,
      },
      view: {
        display: "block",
        width: "100%",
      },
    });

    return (
      <></>
      // <PDFViewer style={styles.viewer}>
      //   <Document>
      //     <Page size="A4" style={styles.page}>
      //       <View style={styles.view}>
      //         <Text style={styles.text}>{item.name}</Text>
      //         <Text style={styles.text}>{item.code}</Text>
      //         <Text style={styles.text}>{item.dname}</Text>
      //         <Text style={styles.text}>{item.nname}</Text>
      //         <Text style={styles.text}>{item.risk_type}</Text>
      //         <Text style={styles.text}>
      //           {!isNullOrEmpty(user)
      //             ? LastAndFirstName(user.lastname, user.firstname)
      //             : null}{" "}
      //           - {dateFormat(item.created_at)}
      //         </Text>
      //         <Text style={styles.text}>
      //           {!isNullOrEmpty(userC)
      //             ? LastAndFirstName(userC.lastname, userC.firstname)
      //             : null}{" "}
      //           - {dateFormat(item.confirm_at)}
      //         </Text>
      //       </View>
      //       <View style={styles.view}>
      //         {connection.map((item, index) => {
      //           if (item.type == "ersdel") {
      //             return (
      //               <View key={index}>
      //                 <Text style={styles.text}>{item.ersdel}</Text>
      //               </View>
      //             );
      //           }
      //         })}
      //       </View>
      //       <View style={styles.view}>
      //         {connection.map((item, index) => {
      //           if (item.type == "zuvlumj") {
      //             return (
      //               <View key={index}>
      //                 <Text style={styles.text}>{item.zuvlumj}</Text>
      //               </View>
      //             );
      //           }
      //         })}
      //       </View>
      //     </Page>
      //   </Document>
      // </PDFViewer>
    );
  }
}

export default ProjectAsuudalPDFFunc;
