import React from 'react';

const ProjectInfo = () => {
    return (
        <div>
            test
        </div>
    );
};

export default ProjectInfo;