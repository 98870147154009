import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  TypeAsuudalAddReq,
  TypeAsuudalUpdateReq,
} from "../../services/Request";
import { TypeAsuudalUpdate, TypeAsuudalAdd } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
class TypeAsuudalAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      Name: "",
      SoHoID: "",
      HeHoID: "",
      validate: [],
      Description: "",
      item: props.item,
      Status: magicnum.Status.Active,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.Update = this.Update.bind(this);
    this.onUpdatSuccess = this.onUpdatSuccess.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        ID: item.id,
        Name: item.name
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    const { t, Name } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      var req = TypeAsuudalAddReq;
      req.asuudal.name = Name;
      TypeAsuudalAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess(response) {
    const { t } = this.state;    
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  }

  Update() {
    const { t, Name, ID } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      var req = TypeAsuudalUpdateReq;
      req.asuudal.name = Name;
      req.asuudal.id = ID;
      TypeAsuudalUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  }

  onUpdatSuccess(response) {
    const { t } = this.state;   
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const { Name, validate } = this.state;
    if (Name == "") {
      res.retType = 1;
      validate["Name"] = true;
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const { t, validate, apierror, apierrormsg, Name, item } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Type.Asuudal.Name")}</Label>
                <Input
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("Name", event);
                  }}
                  invalid={validate["Name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default TypeAsuudalAddFunc;
