import React, { useEffect, useState } from "react";
import {
  Button, FormFeedback, FormGroup,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { ProjectFilesSaveFile } from "../../../../services/main";
import { isNullOrEmpty } from "../../../../components/Utils/Utils";
import * as xlsx from "xlsx";
import { ModalManager } from "react-dynamic-modal/lib/Modal";
const FilesAdd = (props) => {
  const t = props.t
  const system = props.system
  const selectedfile = props.selectedfile
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState(props?.item);
  const [file, setFile] = useState('')
  const [validate, setValidate] = useState([])
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (!isNullOrEmpty(props?.item)) {
      setItem(props?.item)
    }
  }, [props?.item]);

  const save = () => {    
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var data = {
        id: selectedfile?.storedid,
        system: system
      }
      var formdata = new FormData()
      formdata.append('file', file)
      formdata.append('file', JSON.stringify(data))
      ProjectFilesSaveFile(formdata, onSuccess, onFailed, 'PUT', 'file')
    }
    else{
      return toast.warning(t("common:SelectValid"))
    }
  }

  const onSuccess = (response) => {
    setLoading(false)
    props.addSuccess(t("common:Success"));
    ModalManager.close();
  }

  const onFailed = (error) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateFunc = () => {
    var res = { retType: 0 }
    if (isNullOrEmpty(file)) {
      res.retType = 1
      validate['file'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const handlefiles = (files) => {
    console.log(files);
    files.map((file) => {
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const data = reader.result;
        console.log("loaded ", data);
        // setitems([...items, data]);
      };
    });
  };

  const readexcel = (file) => {
    const filereader = new FileReader();
    filereader.readAsArrayBuffer(file);
    filereader.onload = (e) => {
      console.log("here0", e.target.result);
      const bufferarray = e.target.result;
      const wb = xlsx.read(bufferarray, { type: "buffer" });
      const wsname = wb.sheetnames[0];
      const ws = wb.sheets[wsname];

      const data = xlsx.utils.sheet_to_json(ws);
      console.log("here1 ", data);
      resolve(data);
    };
    filereader.onerror = (error) => {
      console.log("here2", error);
      reject(error);
    };
  };

  const handleSelectFile = (event) => {
    const files = event.target.files

    if (files && files.length > 0) {
      setFile(files[0])
    }
  }

  return (
    <div className="customer-project-files">      
      <FormGroup>
        <input
          type="file"
          className="input-field"
          accept=".csv, .xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
          onChange={(e) => {
            handleSelectFile(e)
          }}
          invalid={validate["file"]}
        />
        <FormFeedback>{t("common:SelectValid")}</FormFeedback>
      </FormGroup>
      <div className="action text-rigth" style={{ marginTop: 20 }}>
        <Button onClick={save} disabled={loading}>{loading ? t("common:loading") : t("common:save")}</Button>
      </div>
      <span style={{ visibility: "hidden",fontSize:1 }}>{count}</span>
    </div>
  );
};

export default FilesAdd;
